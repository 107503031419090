import { CoolHttp } from '@angular-cool/http';
import { Inject, Injectable } from '@angular/core';
import { CompanySubscriptionSettingsDTO } from '../../../../../../server/src/dto/comapny-subscription-settings.dto';
import { CompanyRoleDTO } from '../../../../../../server/src/dto/company-role.dto';
import { CompanyDTO } from '../../../../../../server/src/dto/company.dto';
import { DepartmentDTO } from '../../../../../../server/src/dto/department.dto';
import { Environment } from '../../../../environments/environment.interface';
import { ENVIRONMENT } from '../injection-tokens';
import { UserContextService } from '../user-context/user-context.service';

@Injectable()
export class CompanyService {
  constructor(private _http: CoolHttp,
              @Inject(ENVIRONMENT) private _environment: Environment,
              private _userContextService: UserContextService) {

  }

  public async getJoinLinkAsync(): Promise<string> {
    const joinData: { token: string } = await this._http.getAsync('api/companies/active/invite-link');

    return `${ this._environment.appUrl }/company/join?joinToken=${ encodeURIComponent(joinData.token) }`;
  }

  public async joinCompanyAsync(token: string, userDepartments: DepartmentDTO[], userCompanyRole: CompanyRoleDTO) {
    await this._http.postAsync(`api/companies/join`, {
      token: token,
      departments: userDepartments,
      companyRole: userCompanyRole,
    });

    await this._userContextService.refreshUserSettingsAsync();
  }

  public async getCompanyDataFromJoinTokenAsync(token: string): Promise<{
    company: CompanyDTO;
    departments: DepartmentDTO[];
    companyRoles: CompanyRoleDTO[];
  }> {
    return await this._http.getAsync(`api/companies?token=${ token }`);
  }

  public async getCompanySubscriptionSettingsAsync(): Promise<CompanySubscriptionSettingsDTO> {
    return await this._http.getAsync('api/companies/active/subscription-settings');
  }

  public async sendInviteToEmailAddressesAsync(emailAddresses: string[]) {
    await this._http.postAsync('api/companies/active/invite-links', {
      emails: emailAddresses,
    });
  }
}
