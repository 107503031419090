import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LearningCampaignDTO } from '../../../../../../../server/src/dto/learning-campaign.dto';
import { RouteNavigationService } from '../../../../shared/routing/route-navigation.service';
import { LanguageService } from '../../../../shared/services/language/language.service';
import { LearningCampaignsService } from '../../../../shared/services/learning-campaigns/learning-campaigns.service';
import { DateProviderService } from '../../../../shared/utils/date-provider.service';

export enum LearningCampaignStatus {
  NotPublished = 'not-published',

  NotStarted = 'not-started',

  InProgress = 'in-progress',

  Ended = 'ended',
}

export interface LearningCampaignDTOWithStatus extends LearningCampaignDTO {
  status: LearningCampaignStatus;
}

@Component({
  selector: 'app-learning-campaign-list',
  templateUrl: './learning-campaign-list.component.html',
  styleUrls: ['./learning-campaign-list.component.scss'],
})
export class LearningCampaignListComponent implements OnInit {

  constructor(private _learningCampaignsService: LearningCampaignsService,
              private _dateProviderService: DateProviderService,
              private _languageService: LanguageService,
              private _routeNavigationService: RouteNavigationService) {
  }

  public displayedColumns = ['name', 'startDate', 'endDate', 'status', 'departments', 'actions'];

  public dataSource: MatTableDataSource<LearningCampaignDTOWithStatus>;

  public isLoading: boolean;

  @ViewChild(MatSort, { static: true })
  public sort: MatSort;

  public async ngOnInit() {
    try {
      this.isLoading = true;

      await this._loadDataAsync();
    } finally {
      this.isLoading = false;
    }
  }

  public filterCampaigns(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public async addNewCampaignAsync() {
    const createdCampaign = await this._learningCampaignsService.createLearningCampaignAsync({
      id: null,
      name: await this._languageService.getTranslationAsync('Dashboard.LearningCampaigns.NewCampaignName'),
      description: await this._languageService.getTranslationAsync('Dashboard.LearningCampaigns.NewCampaignDescription'),
      finishedDescription: null,
      startDate: new Date(this._dateProviderService.now().setMonth(this._dateProviderService.now().getMonth() + 1)),
      endDate: new Date(this._dateProviderService.now().setMonth(this._dateProviderService.now().getMonth() + 2)),
      minutesRequiredToComplete: 10,
      enforceMinutesRequiredToComplete: false,
      forceRetakeUnderSuccessPercentage: null,
      departments: [],
      procedures: [],
      sendEmailOnStart: false,
      sendEmailOnEndingSoon: false,
      isPublished: false,
    });

    await this.editLearningCampaignAsync(createdCampaign);
  }

  public async editLearningCampaignAsync(campaign: LearningCampaignDTO) {
    await this._routeNavigationService.goToEditLearningCampaignAsync(campaign.id);
  }

  private async _loadDataAsync() {
    const learningCampaigns = await this._learningCampaignsService.getLearningCampaignsAsync();

    const now = this._dateProviderService.now();

    this.dataSource = new MatTableDataSource(learningCampaigns.map(campaign => {
      const withStatusCampaign = <LearningCampaignDTOWithStatus>campaign;

      const startDate = new Date(campaign.startDate);
      const endDate = new Date(campaign.endDate);

      if(!campaign.isPublished) {
        withStatusCampaign.status = LearningCampaignStatus.NotPublished;
      } else if (startDate <= now && endDate > now) {
        withStatusCampaign.status = LearningCampaignStatus.InProgress;
      } else if (startDate > now) {
        withStatusCampaign.status = LearningCampaignStatus.NotStarted;
      } else if (endDate <= now) {
        withStatusCampaign.status = LearningCampaignStatus.Ended;
      }

      return withStatusCampaign;
    }));

    this.dataSource.sort = this.sort;
  }
}
