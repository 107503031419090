import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AssetRoomCategoryDTO } from '../../../../../../../../server/src/dto/asset-control.dto';
import { LanguageService } from '../../../../../shared/services/language/language.service';
import { AssetRoomService } from '../../services/asset-room.service';

@Component({
  selector: 'app-asset-room-category-select',
  templateUrl: './asset-room-category-select.component.html',
  styleUrls: ['./asset-room-category-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: AssetRoomCategorySelectComponent,
    multi: true,
  }],
})
export class AssetRoomCategorySelectComponent implements OnInit, ControlValueAccessor {
  private _onChange: (roomCategory: AssetRoomCategoryDTO) => void;
  private _onTouched: (roomCategory: AssetRoomCategoryDTO) => void;

  constructor(private _assetRoomsService: AssetRoomService,
              private _languageService: LanguageService) {
  }

  @Input()
  public displayOnly: boolean;

  @Input()
  public name: string;

  @Input()
  public placeholder: string;

  @Input()
  public roomCategories: AssetRoomCategoryDTO[];

  @Input()
  public required: boolean;

  public isDisabled: boolean;

  public selectedRoomCategory: AssetRoomCategoryDTO;

  async ngOnInit() {
    this.roomCategories = this.roomCategories || await this._assetRoomsService.getRoomCategoriesAsync();

    this.placeholder = this.placeholder || await this._languageService.getTranslationAsync('Common.Category');
  }

  public onInnerChange(change: string) {
    this.selectedRoomCategory = this.roomCategories.find(category => category.id === change);

    this._onTouched && this._onTouched(this.selectedRoomCategory);
    this._onChange && this._onChange(this.selectedRoomCategory);
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public writeValue(roomCategory: AssetRoomCategoryDTO): void {
    this.selectedRoomCategory = roomCategory;
  }
}
