import { CoolDialogService } from '@angular-cool/dialogs';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subject } from 'rxjs';
import { AssetDTO, AssetRoomDTO } from '../../../../../../../server/src/dto/asset-control.dto';
import { LanguageService } from '../../../../shared/services/language/language.service';
import { Editable } from '../../../../shared/utils/editable';
import { RoomCanvasComponent } from '../../shared/components/room-canvas/room-canvas.component';
import { AssetRoomService } from '../../shared/services/asset-room.service';

@Component({
  selector: 'app-asset-control-rooms-assets',
  templateUrl: './asset-control-rooms-assets.component.html',
  styleUrls: ['./asset-control-rooms-assets.component.scss'],
})
export class AssetControlRoomsAssetsComponent implements OnInit, OnDestroy {
  private _roomId: string;

  constructor(private _assetRoomService: AssetRoomService,
              private _dialogService: CoolDialogService,
              private _changeDetector: ChangeDetectorRef,
              private _languageService: LanguageService,
              private _elementRef: ElementRef,
              private _activatedRoute: ActivatedRoute) {
  }

  public room: AssetRoomDTO;
  public assets: AssetDTO[];

  public assetsSubject = new Subject<AssetDTO[]>();

  public selectedAssetId: string;

  public updateAssetInProgress: boolean;
  public currentlyEditingAsset: AssetDTO;

  public editableAssets: Editable<AssetDTO>[];

  @ViewChild(RoomCanvasComponent)
  public roomCanvas: RoomCanvasComponent;

  public ngOnInit() {
    this._activatedRoute.params
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(async (params: Params) => {
        this._roomId = params.roomId;

        if (!this._roomId) {
          return;
        }

        await this._loadDataAsync();
      });
  }

  public ngOnDestroy(): void {
  }

  public selectAsset(asset: AssetDTO) {
    this.selectedAssetId = asset.id;

    this._scrollToAsset(asset.id);
  }

  public trackAsset(index: number, asset: Editable<AssetDTO>) {
    return asset?.value?.id;
  }

  public async addNewAssetAsync() {
    const createdAsset = await this._assetRoomService.addAssetToRoomAsync(this._roomId, {
      id: null,
      type: null,
      name: await this._languageService.getTranslationAsync('Dashboard.AssetControlRooms.NewAssetName'),
      comment: null,
      responsibleCompanyRole: null,
      responsibleCompanyUser: null,
      positionX: 200,
      positionY: 200,
    });

    await this._loadDataAsync();

    this._scrollToAsset(createdAsset.id);
  }

  public async editAssetAsync(asset: Editable<AssetDTO>) {
    if (!asset.value.name) {
      return;
    }

    try {
      this.updateAssetInProgress = true;

      const elementCoords = this.roomCanvas.getAssetPosition(asset.value.id);

      asset.value.positionY = elementCoords.y;
      asset.value.positionX = elementCoords.x;

      await this._assetRoomService.updateAssetAsync(this._roomId, asset.value);

      asset.stopEditing(true);
    } catch {
      asset.stopEditing(false);
    } finally {
      this.updateAssetInProgress = false;
    }

    this.roomCanvas.setAllAssetProperties({ visible: true, selectable: false });

    this.currentlyEditingAsset = null;

    await this._loadDataAsync();

    this.roomCanvas.requestRenderAll();

    this._scrollToAsset(asset.value.id);
  }

  public async startEditingAssetAsync(asset: Editable<AssetDTO>) {
    asset.startEditing();

    this.selectAsset(asset.value);
    this.currentlyEditingAsset = asset.value;

    this._changeDetector.detectChanges();

    setTimeout(() => {
      this.roomCanvas.setAllAssetProperties({ visible: false });

      this.roomCanvas.setAssetProperties(asset.value.id, {
        selectable: true,
        visible: true,
      });

      this.roomCanvas.requestRenderAll();

      this._scrollToAsset(asset.value.id);
    }, 200);
  }

  public async cancelEditingAssetAsync(asset: Editable<AssetDTO>) {
    asset.stopEditing(false);

    this.currentlyEditingAsset = null;

    await this._loadDataAsync();

    this._scrollToAsset(asset.value.id);
  }

  public async removeAssetAsync(asset: Editable<AssetDTO>) {
    const removeDialogResult = await this._dialogService.showDialog({
      titleText: await this._languageService.getTranslationAsync('Dashboard.AssetControlRooms.RemoveAssetTitle', { assetName: asset.value.name }),
      questionText: await this._languageService.getTranslationAsync('Dashboard.AssetControlRooms.RemoveAssetDescription', { assetName: asset.value.name }),
      confirmActionButtonText: await this._languageService.getTranslationAsync('Common.Remove'),
      cancelActionButtonText: await this._languageService.getTranslationAsync('Common.Cancel'),
      confirmActionButtonColor: 'warn',
    });

    if (!removeDialogResult.isConfirmed) {
      return;
    }

    await this._assetRoomService.removeAssetAsync(this._roomId, asset.value.id);

    await this._loadDataAsync();
  }

  private async _loadDataAsync() {
    this.room = await this._assetRoomService.getRoomByIdAsync(this._roomId);

    this.assets = await this._assetRoomService.getRoomAssetsAsync(this._roomId);

    this.assetsSubject.next(this.assets);

    if (!this.selectedAssetId && this.assets.length) {
      this.selectedAssetId = this.assets[0].id;
    }

    this.editableAssets = this.assets.map(asset => new Editable(asset));
  }

  private _scrollToAsset(assetId: string) {
    const assetElement = this._elementRef.nativeElement.querySelector(`#asset-${ assetId }`);

    if (!assetElement) {
      return;
    }

    assetElement.scrollIntoView({
      behavior: 'smooth',
    });
  }
}
