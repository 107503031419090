<div id="terms" class="page">
  <div *ngIf="currentLanguage === Language.English" class="box" >
    <h2 class="text-center mb-4">Terms and Conditions ("Terms")</h2>

    <p>
      Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the
      https://www.proceduremanager.com website (the "Service") operated by Procedure Manager and Amill Kft. ("us", "we", or "our").

      Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These
      Terms apply to all visitors, users and others who access or use the Service.

      By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms
      then you may not access the Service.
    </p>

    <h3>Accounts</h3>
    <p>
      When you create an account with us, you must provide us information that is accurate, complete, and current at all
      times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your
      account
      on our Service.

      You are responsible for safeguarding the password that you use to access the Service and for any activities or
      actions under your password, whether your password is with our Service or a third-party service.

      You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of
      any breach of security or unauthorized use of your account.
    </p>

    <h3>Content from Other Web Sites</h3>
    <p>
      Our Service may contain links, images and other content hosted by third-party web sites or services that are not
      owned or controlled by Procedure Manager or Amill Kft.

      Procedure Manager has no control over, and assumes no responsibility for, the content, privacy policies, or practices of
      any third party web sites or services. You further acknowledge and agree that Procedure Manager shall not be responsible
      or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with
      use of or reliance on any such content, goods or services available on or through any such web sites or services.

      We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or
      services that you visit.
    </p>

    <h3>Exclusion of Liability</h3>
    <p>
      We are not responsible or liable in any manner for any user generated content. Although we include strict
      provisions
      regarding user generated content in these terms and conditions. We do not control and are not responsible for what
      users post on or through our sites and are not responsible for any offensive, inappropriate, obscene, unlawful,
      infringing or otherwise objectional or illegal user generated content you may encounter on our sites or in
      connection with your use of our site.
    </p>

    <h3>Termination</h3>
    <p>
      We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason
      whatsoever, including without limitation if you breach the Terms.

      All provisions of the Terms which by their nature should survive termination shall survive termination, including,
      without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.

      Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you
      may simply discontinue using the Service.
    </p>

    <h3>Governing Law</h3>
    <p>
      These Terms shall be governed and construed in accordance with the laws of Hungary, without regard to its conflict
      of law provisions.

      Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If
      any
      provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these
      Terms
      will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede
      and replace any prior agreements we might have between us regarding the Service.
    </p>

    <h3>Changes</h3>
    <p>
      We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is
      material we will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes a
      material change will be determined at our sole discretion.

      By continuing to access or use our Service after those revisions become effective, you agree to be bound by the
      revised terms. If you do not agree to the new terms, please stop using the Service.
    </p>

    <h3>Contact Us</h3>
    <p>
      If you have any questions about these Terms, please contact us.
    </p>

    <h6 class="text-right mt-5">Last updated: May 1, 2019</h6>
  </div>

  <div *ngIf="currentLanguage === Language.Hungarian" class="box">
    <h2 class="text-center mb-4">Felhasználás feltételei ("Feltételek")</h2>

    <p>
      Kérjük figyelmesen olvassa el a Felhasználás feltételei ("Feltételek") dokumentumot mielőtt használatba veszi a
      https://www.proceduremanager.com weboldalt ("Szolgáltatás", "Applikáció") amelyet a Procedure Manager csapata (továbbiakban "Procedure Manager", "Amill Kft", "mi") üzemeltet.

      Ön ezen szolgáltatásunk használatára csak akkor jogosult, hogyha elfogadja ezen dokumentumot és az abban elvárások
      betartásával veszi igénybe a szolgáltatást. Ezek a feltételek érvényesek mindent látogatóra, felhasználóra és bárkire, aki hozzáfér ezen applikációhoz.

      Ön a hozzáférésével és használatával beleegyezik az ezen dokumentumban szereplő feltételekbe. Amennyiben nem ért egyet
      a felhasználási feltételekkel, abban az esetben ennek a szolgáltatásnak nem jogosult a használatára.
    </p>

    <h3>Felhasználok</h3>
    <p>
      Amennyiben ön egy felhasználót hoz létre a rendszerünkben, ön vállalja, hogy a megadott információk pontosak, teljesek és
      megfelelnek a jelen és jövőbeli valóságnak. Amennyiben nem ennek megfelelően jár el az a feltételek megszegésének minősül és
      fenntartjuk a jogot, hogy azonnalian megszakítsuk az ön számára nyújtott szolgáltatásunkat.

      Ön felelős a szolgáltatás hozzáféréséhez használt autentikációs titoknak (például jelszó) megfelelő védelméért,
      továbbá minden olyan tevékenységért, amelyet az ön hozzáférésével végeztek el, legyen az autentikáció módja ennek
      a szolgáltatásnak vagy egy harmadik fél által nyújtott szolgáltatásnak része.

      Ön beleegyezik, hogy a jelszavát és hozzáféréséhez használt titkot nem oszt meg harmadik féllel. Amennyiben tudomására
      jutott, hogy a jelszavához, autentikációjához vagy felhasználójához illetéktelenek fértek hozzá, azonnal tájékoztatnia kell minket.
    </p>

    <h3>Más weboldalakról és szolgáltatásokból származó tartalom</h3>
    <p>
      A szolgáltatásban szerepelhetnek hivatkozások, képek, illetve tartalom melyet más harmadik fél szolgáltat és melyeket
      nem kontrollál sem tulajdona a Procedure Manager-nek vagy az Amill Kft-nek.

      Nincs kontrolunk felette és nem vállalunk felelősséget olyan tartalomért, adatvédelmi irányelvért és gyakorlatért mely
      harmadik fél szolgáltat. Ezen felül ön beleegyezik és elfogadja, hogy az Amill Kft nem lesz felelős olyan direkt vagy indirekt
      kárért vagy veszteségért mely önt érte, állítólagosan érte, ön okozott és ön állítólagosan okozott ezeknek a tartalmaknak,
      termékeknek, szolgáltatásoknak vagy weboldalaknak használatával.

      Erősen ajánljuk ezeknek a harmadik fél által szolgáltatott tartalmak és szolgáltatásoknak a felhasználási feltételeinek és
      adatbiztonsági tájékoztatójának elolvasását.
    </p>

    <h3>Felelősség kizárása</h3>
    <p>
      Nem vagyunk felelősek és nem vállalunk felelősséget a felhasználóink által létrehozott tartalmakért.
      Mi nem kontrolláljuk és nem vállalunk felelősséget a felhasználók által létrehozott tartalmakért,
      melyekkel a szolgáltatás használata közben találkozhat, legyen az
      támadó, felbujtó, alkalmatlan, sértő, obszcén, törvénytelen, jogsértő, bármilyen módon kifogásolható vagy illegális
    </p>

    <h3>Szolgáltatás megszűntetése</h3>
    <p>
      Fenntartjuk a jogot, hogy a felhasználás feltételeinek megsértése esetén előzetes felszólítás, bármilyen értesítés
      és időkorlát nélkül megszakítsuk az ön számára nyújtott szolgáltatásunkat.

      A mindenkori felhasználási feltételek a szolgáltatás megszűntetése után is limitáció nélkül érvényben maradnak.

      A szolgáltatás megszűnése után, ön elveszíti a szolgáltatás használatának jogát. Amennyiben meg szeretné szűntetni a
      felhasználóját kérjük ne használja a szolgáltatásunkat. Illetve kérheti a felhasználója törlését.
    </p>

    <h3>Irányadó jog</h3>
    <p>
      Ezek a Felhasználási Feltételek a Magyarországi jogok elsődleges figyelembevételével és összeegyeztetésével értendőek,
      abban az esetben is, amennyiben a összeférhetetlenség lenne a kettő közt.

      A jelen Feltételek bármely jogának vagy rendelkezésének végrehajtásának elmulasztása nem tekinthető e jogokról való lemondásnak.

      Amennyiben a jövőben bíróság vagy törvény miatt valamely kikötés érvénytelenné válna,
      a többi pont továbbra is érvényben marad.

      Jelen feltételek felülírják és kiváltják az ez előtt kiadott felhasználási feltételeket.
    </p>

    <h3>Változások</h3>
    <p>
      Fenntartjuk a változtatás jogát, hogy a jövőben bármikor, saját belátásunk szerint változtassuk vagy lecseréljük
      jelen Felhasználási Feltételeket.

      Nagyobb változtatás esetén megpróbálunk 30 nappal előre tájékoztatni felhasználóinkat.
      A tájékoztatás szükségességet, illetve a változtatás nagyságának eldöntésének saját belátásunk szerint végezzük.

      A szolgáltatás folytatólagos használatával ön beleegyezik az új és mindenkori Felhasználási Feltétek betartásába.
      Amennyiben nem egyezik bele az új feltételekbe kérjük hagyja abba a szolgáltatásunk használatát.
    </p>

    <h3>Lépjen velünk kapcsolatba</h3>
    <p>
      Amennyiben kérdése merülne fel a feltételekkel kapcsolatban, kérjük keressen fel minket.
    </p>

    <h6 class="text-right mt-5">Utolsó frissítés: May 1, 2019</h6>
  </div>
</div>
