import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SettingsService } from '../../services/settings/settings.service';

@Component({
  selector: 'app-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss'],
})
export class CountdownTimerComponent implements OnInit, OnDestroy {
  constructor(private _settingsService: SettingsService,
              private _changeDetector: ChangeDetectorRef) {
  }

  @Input()
  public targetTime: string;

  @Input()
  public warnFromSeconds: number = 30;

  @Output()
  public finished: EventEmitter<void> = new EventEmitter<void>();

  public secondsRemaining: number;

  public showWarning: boolean;

  public displayValues: { hours: string; minutes: string; seconds: string; } = {
    hours: null,
    minutes: null,
    seconds: null,
  };

  public async ngOnInit() {
    await this._updateServerTimeAsync();

    interval(1000 * 60 * 2)
      .pipe(
        takeUntil(this.finished),
        untilDestroyed(this),
      )
      .subscribe(async () => {
        await this._updateServerTimeAsync();

        this._changeDetector.markForCheck();
      });

    interval(1000)
      .pipe(
        takeUntil(this.finished),
        untilDestroyed(this),
      )
      .subscribe(() => {
        if (this.secondsRemaining <= 0) {
          return;
        }

        this.secondsRemaining--;

        if (this.secondsRemaining > 0 && this.secondsRemaining <= this.warnFromSeconds) {
          this.showWarning = true;
        }

        if (this.secondsRemaining <= 0) {
          this.showWarning = false;

          this.finished.emit();
        }

        this._updateDisplayValues();

        this._changeDetector.markForCheck();
      });
  }

  public ngOnDestroy(): void {
  }

  private async _updateServerTimeAsync() {
    if (!this.targetTime) {
      return;
    }

    const currentServerTime = await this._settingsService.getServerTimeAsync();

    this.secondsRemaining = Math.floor((new Date(this.targetTime).getTime() - currentServerTime.getTime()) / 1000);

    this._updateDisplayValues();
  }

  private _updateDisplayValues() {
    if (this.secondsRemaining <= 0) {
      this.displayValues = {
        hours: null,
        minutes: '00',
        seconds: '00',
      };

      return;
    }

    const hours = Math.floor(this.secondsRemaining / 60 / 60);
    const minutes = Math.floor((this.secondsRemaining - (hours * 60 * 60)) / 60);
    const seconds = Math.floor(this.secondsRemaining - (minutes * 60));

    this.displayValues = {
      hours: hours ? hours.toString().padStart(2, '0') : null,
      minutes: minutes.toString().padStart(2, '0'),
      seconds: seconds.toString().padStart(2, '0'),
    };
  }
}
