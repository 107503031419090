import { CoolDialogService } from '@angular-cool/dialogs';
import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyUserDTO } from '../../../../../../../server/src/dto/company-user.dto';
import { LanguageService } from '../../../../shared/services/language/language.service';
import { UserContextService } from '../../../../shared/services/user-context/user-context.service';
import { OrganizationService } from '../../../../shared/services/organization/organization.service';
import { EditUserDialogConfig } from './edit-user-dialog-config.interface';

@Component({
  selector: 'app-edit-user-dialog',
  templateUrl: './edit-user-dialog.component.html',
  styleUrls: ['./edit-user-dialog.component.scss'],
})
export class EditUserDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) private _dialogConfig: EditUserDialogConfig,
              private _dialog: MatDialogRef<EditUserDialogComponent>,
              private _dialogService: CoolDialogService,
              private _languageService: LanguageService,
              private _userContextService: UserContextService,
              private _organizationService: OrganizationService) {

  }

  public saveInProgress: boolean;

  public companyUser: CompanyUserDTO;
  public isCurrentUser: boolean;

  public async ngOnInit() {
    this.companyUser = JSON.parse(JSON.stringify(this._dialogConfig.user));
    this.isCurrentUser = this.companyUser.id === this._userContextService.user.id;
  }

  public async saveChangesAsync(userEditForm: NgForm) {
    if (!userEditForm.valid) {
      return;
    }

    this.saveInProgress = true;

    try {
      await this._organizationService.updateCompanyUserAsync(this.companyUser);

      this.closeDialog();
    } finally {
      this.saveInProgress = false;
    }
  }

  public async removeButtonClickAsync() {
    if (this.isCurrentUser) {
      return;
    }

    const removeDialogResult = await this._dialogService.showDialog({
      titleText: await this._languageService.getTranslationAsync('Dashboard.Users.RemoveUserTitle', { userName: this.companyUser.name || this.companyUser.email }),
      questionText: await this._languageService.getTranslationAsync('Dashboard.Users.RemoveUserDescription', { userName: this.companyUser.name || this.companyUser.email }),
      confirmActionButtonText: await this._languageService.getTranslationAsync('Common.Remove'),
      cancelActionButtonText: await this._languageService.getTranslationAsync('Common.Cancel'),
      confirmActionButtonColor: 'warn',
    });

    if (!removeDialogResult.isConfirmed) {
      return;
    }

    await this._organizationService.removeCompanyUserAsync(this.companyUser.id);

    this.closeDialog();
  }

  public closeDialog() {
    this._dialog.close();
  }

}
