<div class="d-flex flex-column h-100 align-items-center justify-content-center"
     [class.small]="size === 'small'">
  <div class="position-relative">
    <div class="circle" [class.has-image]="step.featuredImage">
      <h5>{{ index }}</h5>
    </div>
    <div *ngIf="step.featuredImage" class="step-featured-image">
      <app-file-item-display [file]="step.featuredImage"></app-file-item-display>
    </div>
  </div>
</div>