import { Component } from '@angular/core';

@Component({
  selector: 'app-learning-reports',
  templateUrl: './learning-reports.component.html',
  styleUrls: ['./learning-reports.component.scss'],
})
export class LearningReportsComponent {
  constructor() {
  }
}
