import { CoolDialogService } from '@angular-cool/dialogs';
import { Component, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { FileDTO } from '../../../../../../../server/src/dto/file.dto';
import { ProcedureTestDTO, ProcedureTestQuestionDTO } from '../../../../../../../server/src/dto/procedure-test.dto';
import { FileType } from '../../../../../../../server/src/models/file-type.enum';
import { ProcedureTestQuestionShowType } from '../../../../../../../server/src/models/procedure-test-question-show-type.enum';
import { ProcedureTestQuestionType } from '../../../../../../../server/src/models/procedure-test-question-type.enum';
import { ListUtils } from '../../../../../../../server/src/utils/list-utils';
import { RouteNavigationService } from '../../../../shared/routing/route-navigation.service';
import { LanguageService } from '../../../../shared/services/language/language.service';
import { ProcedureTestService } from '../../../../shared/services/procedures/procedure-test.service';
import { ProceduresService } from '../../../../shared/services/procedures/procedures.service';
import { EditProcedureTestQuestionComponent } from './edit-procedure-test-question/edit-procedure-test-question.component';

@Component({
  selector: 'app-edit-procedure-test',
  templateUrl: './edit-procedure-test.component.html',
  styleUrls: ['./edit-procedure-test.component.scss'],
})
export class EditProcedureTestComponent implements OnInit, OnDestroy {
  private _procedureId: string;

  constructor(private _activatedRoute: ActivatedRoute,
              private _procedureTestService: ProcedureTestService,
              private _proceduresService: ProceduresService,
              private _languageService: LanguageService,
              private _dialogService: CoolDialogService,
              private _routeNavigationService: RouteNavigationService) {

  }

  public inProgress: boolean;
  public hasErrorInSave: boolean;

  public procedureVideos: FileDTO[];

  public procedureTest: ProcedureTestDTO;

  public ProcedureTestQuestionShowType = ProcedureTestQuestionShowType;

  @ViewChildren(EditProcedureTestQuestionComponent)
  public questionComponents: EditProcedureTestQuestionComponent[];

  public ngOnInit() {
    this._activatedRoute.parent.params
      .pipe(untilDestroyed(this))
      .subscribe(async (params: Params) => {
        this._procedureId = params.procedureId;

        if (!this._procedureId) {
          return;
        }

        await this._loadDataAsync();
      });
  }

  public ngOnDestroy(): void {
  }

  public async addQuestionAsync() {
    this.procedureTest.questions.push({
      id: null,
      type: ProcedureTestQuestionType.MultipleChoice,
      questionText: await this._languageService.getTranslationAsync('Dashboard.Procedures.TestNewQuestionText'),
      rank: null,
      answers: [
        {
          id: null,
          answerText: await this._languageService.getTranslationAsync('Dashboard.Procedures.TestNewAnswerText', { index: 1 }),
          isCorrectAnswer: false,
          rank: 'a'
        },
        {
          id: null,
          answerText: await this._languageService.getTranslationAsync('Dashboard.Procedures.TestNewAnswerText', { index: 2 }),
          isCorrectAnswer: true,
          rank: 'b'
        }
      ],
    });

    ListUtils.setRankForListItems(this.procedureTest.questions);
  }

  public async removeQuestionAsync(question: ProcedureTestQuestionDTO) {
    const removeDialogResult = await this._dialogService.showDialog({
      titleText: await this._languageService.getTranslationAsync('Dashboard.Procedures.TestRemoveQuestionTitle'),
      questionText: await this._languageService.getTranslationAsync('Dashboard.Procedures.TestRemoveQuestionDescription'),
      confirmActionButtonText: await this._languageService.getTranslationAsync('Common.Remove'),
      cancelActionButtonText: await this._languageService.getTranslationAsync('Common.Cancel'),
      confirmActionButtonColor: 'warn',
    });

    if (!removeDialogResult.isConfirmed) {
      return;
    }

    this.procedureTest.questions.splice(this.procedureTest.questions.indexOf(question), 1);
  }

  public async saveChangesAsync(questionsForm: NgForm) {
    if (!questionsForm.valid || this.questionComponents.find(questionComponent => !questionComponent.questionForm.valid)) {
      questionsForm.form.markAllAsTouched();

      for (const questionComponent of this.questionComponents) {
        questionComponent.questionForm.form.markAllAsTouched();
      }

      return;
    }

    try {
      this.inProgress = true;
      this.hasErrorInSave = false;

      await this._procedureTestService.updateProcedureTestAsync(this._procedureId, this.procedureTest);

      questionsForm.form.markAsPristine();
      questionsForm.form.markAsUntouched();

      for (const questionComponent of Array.from(this.questionComponents)) {
        questionComponent.questionForm.form.markAsUntouched();
        questionComponent.questionForm.form.markAsPristine();
      }
    } catch {
      this.hasErrorInSave = true;
    } finally {
      this.inProgress = false;
    }
  }

  public async cancelClickAsync() {
    await this._routeNavigationService.goToViewProcedureAsync(this._procedureId);
  }

  private async _loadDataAsync() {
    const procedure = await this._proceduresService.getProcedureByIdAsync(this._procedureId);
    this.procedureVideos = procedure.files.filter(file => file.type === FileType.Video);

    this.procedureTest = await this._procedureTestService.getProcedureTestAsync(this._procedureId);
  }
}
