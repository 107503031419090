import { Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Language } from '../../../../server/src/models/language.enum';
import { LanguageService } from '../shared/services/language/language.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent implements OnInit, OnDestroy {
  constructor(private _languageService: LanguageService) {
  }

  public currentLanguage: Language;

  public Language = Language;

  ngOnInit() {
    this._languageService.getCurrentLanguageObservable()
      .pipe(untilDestroyed(this))
      .subscribe(language => {
        this.currentLanguage = language;
      });
  }

  public ngOnDestroy(): void {
  }
}
