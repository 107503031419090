import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { CompanyUserDTO } from '../../../../../../server/src/dto/company-user.dto';
import { CompanyService } from '../../../shared/services/company/company.service';
import { OrganizationService } from '../../../shared/services/organization/organization.service';
import { AddUserDialogComponent } from './add-user-dialog/add-user-dialog.component';
import { EditUserDialogConfig } from './edit-user-dialog/edit-user-dialog-config.interface';
import { EditUserDialogComponent } from './edit-user-dialog/edit-user-dialog.component';

export const PAGE_SIZE = 10;

@Component({
  selector: 'app-organization-users',
  templateUrl: './organization-users.component.html',
  styleUrls: ['./organization-users.component.scss'],
})
export class OrganizationUsersComponent implements OnInit, OnDestroy {
  private _maxNumberOfItems: number;
  private _lastLoadedPageIndex: number;
  private _loadedAllUsers: boolean;

  private _currentFilterText: string;
  private _filterSubject = new Subject<string>();

  constructor(private _organizationService: OrganizationService,
              private _companyService: CompanyService,
              private _changeDetector: ChangeDetectorRef,
              private _dialogService: MatDialog) {
  }

  public displayedColumns = ['name', 'email', 'companyRole', 'departments', 'permissionRole', 'notes', 'actions'];
  public isLoading: boolean;

  public currentNumberOfUsers: number;
  public maxNumberOfUsers: number;

  public data: CompanyUserDTO[];
  public dataSource: MatTableDataSource<CompanyUserDTO>;
  public isPaginatorDisabled: boolean;

  public pageSize = PAGE_SIZE;

  @ViewChild(MatPaginator, { static: true })
  public paginator: MatPaginator;

  public async ngOnInit() {
    this._maxNumberOfItems = await this._organizationService.getNumberOfCompanyUsersAsync();
    this.currentNumberOfUsers = this._maxNumberOfItems;

    const companySubscriptionSettings = await this._companyService.getCompanySubscriptionSettingsAsync();
    this.maxNumberOfUsers = companySubscriptionSettings.maxNumberOfCompanyUsers;

    this.data = new Array(this._maxNumberOfItems);

    await this._loadNextBatchOfDataIntoDataSourceAsync(0, 2 * PAGE_SIZE, false);

    this.dataSource.paginator.page
      .pipe(
        filter(page => page.pageIndex > this._lastLoadedPageIndex),
        untilDestroyed(this),
      )
      .subscribe(async page => {
        this.isPaginatorDisabled = true;
        this._changeDetector.markForCheck();

        await this._loadNextBatchOfDataIntoDataSourceAsync(page.pageIndex, PAGE_SIZE, false);

        this.isPaginatorDisabled = false;
        this._changeDetector.markForCheck();
      });

    this._filterSubject
      .pipe(
        debounceTime(500),
        untilDestroyed(this),
      )
      .subscribe(async (filterText: string) => {
        this.isPaginatorDisabled = true;
        this._changeDetector.markForCheck();

        this._currentFilterText = filterText;

        this._loadedAllUsers = false;
        this._maxNumberOfItems = await this._organizationService.getNumberOfCompanyUsersAsync(this._currentFilterText);

        this.data = new Array(this._maxNumberOfItems);

        await this._loadNextBatchOfDataIntoDataSourceAsync(0, PAGE_SIZE, false);

        this.isPaginatorDisabled = false;
        this._changeDetector.markForCheck();
      });
  }

  public ngOnDestroy(): void {
  }

  public async addNewCompanyUserAsync() {
    this._dialogService.open(AddUserDialogComponent);
  }

  public async editingCompanyUserAsync(companyUser: CompanyUserDTO) {
    if (!companyUser) {
      return;
    }

    const dialog = this._dialogService.open<EditUserDialogComponent, EditUserDialogConfig>(EditUserDialogComponent, {
      data: {
        user: companyUser,
      },
    });

    await dialog.afterClosed().toPromise();

    await this._loadNextBatchOfDataIntoDataSourceAsync(this.paginator.pageIndex, PAGE_SIZE, true);
  }

  public filterCompanyUsers(filterValue: string) {
    this._filterSubject.next(filterValue.trim().toLowerCase());
  }

  private async _loadNextBatchOfDataIntoDataSourceAsync(pageIndex: number, numberOfItemsToLoad: number, forceReload: boolean) {
    if (this._loadedAllUsers && !forceReload) {
      return;
    }

    try {
      this.isLoading = true;

      const lastItemIndex = (pageIndex * PAGE_SIZE) - 1;

      const lastLoadedUserId = this.data[lastItemIndex] ? this.data[lastItemIndex].id : '-9223372036854775807';

      const companyUsers = await this._organizationService.getCompanyUsersAsync(lastLoadedUserId, numberOfItemsToLoad, this._currentFilterText);

      if (!companyUsers.length || companyUsers.length < numberOfItemsToLoad) {
        this._loadedAllUsers = true;
      }

      for (let i = 0; i < companyUsers.length; i++) {
        this.data[lastItemIndex + 1 + i] = companyUsers[i];
      }

      this._lastLoadedPageIndex = pageIndex;

      this.dataSource = new MatTableDataSource(this.data);
      this.dataSource.paginator = this.paginator;
    } finally {
      this.isLoading = false;
    }
  }
}
