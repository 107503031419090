<div *ngIf="settings">
  <form #ssoForm="ngForm">
    <div>
      <mat-form-field>
        <input matInput readonly
               #input
               (click)="input.select()"
               [value]="settings.company.identifierName"
               placeholder="{{ 'Dashboard.CompanySettings.CompanyIdentifierName' | translate }}">
      </mat-form-field>
    </div>

    <div class="mt-2">
      <mat-form-field class="w-50">
        <input matInput name="id-provider-url"
               [(ngModel)]="settings.idProviderUrl" type="url"
               placeholder="{{ 'Dashboard.CompanySettings.IdProviderUrl' | translate }}"
               required>
      </mat-form-field>
    </div>

    <div class="mt-2">
      <mat-form-field class="w-50">
        <textarea matInput name="public-key"
                  [(ngModel)]="settings.publicKey"
                  placeholder="{{ 'Dashboard.CompanySettings.PublicKey' | translate }}"
                  required>
        </textarea>
      </mat-form-field>
    </div>
  </form>

  <div class="mt-3 text-right">
    <span *ngIf="hasError" class="color-warn mr-3">
      {{ 'Common.Failed' | translate }}
    </span>
    <app-button color="primary" [loading]="inProgress" (clicked)="onSaveAsync(ssoForm)">
      {{ 'Common.Save' | translate }}
    </app-button>
  </div>
</div>

<div *ngIf="isLoading" class="text-center mt-3">
  <app-loading-indicator></app-loading-indicator>
</div>