import { CoolHttp } from '@angular-cool/http';
import { Injectable } from '@angular/core';
import { ControlCheckEventDTO } from '../../../../../../../server/src/dto/control-check-event.dto';

@Injectable()
export class ControlChecksService {
  constructor(private _http: CoolHttp) {

  }

  public async getControlCheckEventsAsync(fromEventId: string, limit: number = 20): Promise<ControlCheckEventDTO[]> {
    return this._http.getAsync(`api/companies/active/control/control-checks/events`, {
      params: {
        fromId: fromEventId,
        limit: limit.toString(),
      },
    });
  }

  public async getControlCheckEventByIdAsync(controlCheckEventId: string): Promise<ControlCheckEventDTO> {
    return this._http.getAsync(`api/companies/active/control/control-checks/events/${ controlCheckEventId }`);
  }

  public async getNumberOfControlCheckEventsAsync(): Promise<{ eventCount: number }> {
    return this._http.getAsync(`api/companies/active/control/control-checks/events/count`);
  }

  public async saveControlCheckEventAsync(controlCheckEvent: ControlCheckEventDTO) {
    return this._http.postAsync(`api/companies/active/control/control-checks/events`, controlCheckEvent);
  }
}