<div class="p-2">
  <h3>{{ (template?.id ? 'Dashboard.LearningCertificateTemplates.EditLearningCertificateTemplateTitle' : 'Dashboard.LearningCertificateTemplates.AddLearningCertificateTemplateTitle') | translate }}</h3>

  <form #templateSaveForm="ngForm" class="mt-3 w-100">
    <mat-form-field class="w-100">
      <input matInput name="name" [(ngModel)]="template.name"
             placeholder="{{ 'Common.Name' | translate }}"
             required>
    </mat-form-field>

    <div class="mt-3 text-center">
      <app-file-upload-selector [(ngModel)]="template.templateFile"
                                [single]="true" name="templateFile"
                                required>
      </app-file-upload-selector>
    </div>
  </form>

  <div class="mt-4 text-right">
    <div class="text-danger mr-4 d-inline-block" *ngIf="showError">
      {{ 'Common.FailedToSave' | translate }}
    </div>

    <button mat-raised-button (click)="closeDialog()">
      {{ 'Common.Cancel' | translate }}
    </button>

    <app-button class="ml-3" color="primary"
                [loading]="saveInProgress"
                (clicked)="saveChangesAsync(templateSaveForm)">
      {{ 'Common.Save' | translate }}
    </app-button>
  </div>
</div>