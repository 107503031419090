import { Inject, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { Environment } from '../../../../environments/environment.interface';
import { ENVIRONMENT } from '../injection-tokens';
import { UserContextService } from '../user-context/user-context.service';

@Injectable()
export class LoggerService {
  constructor(private _userContextService: UserContextService,
              @Inject(ENVIRONMENT) private _environment: Environment) {
    this._userContextService.isUserLoggedInObservable.subscribe((isUserLoggedIn: boolean) => {
      if (isUserLoggedIn) {
        this._onUserLoggedIn();
      }
    });
  }

  public initialize() {
    if (this._environment.loggingEnabled) {
      Sentry.init({
        dsn: this._environment.loggingUrl,
      });
    }
  }

  public error(error: Error, data?: any) {
    console.error(error, data);

    if (data) {
      this.captureBreadcrumb('error-data', data);
    }

    if ((<any>error).data) {
      try {
        this.captureBreadcrumb(error.message, JSON.stringify((<any>error).data));
      } catch {
        console.log('Failed to capture breadcrumb');
      }
    }

    if ((<any>error).body) {
      try {
        this.captureBreadcrumb(error.message, JSON.stringify((<any>error).body));
      } catch {
        console.log('Failed to capture breadcrumb');
      }
    }

    Sentry.captureException(error);
  }

  public captureBreadcrumb(message: string, data: any = null) {
    Sentry.addBreadcrumb({
      message,
      data,
    });
  }

  private _onUserLoggedIn() {
    Sentry.setContext('user', {
      id: this._userContextService?.user?.id,
    });
  }
}

