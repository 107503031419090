<div>
  <div>
    <form #procedureStepsEditForm="ngForm">
      <div *ngFor="let step of steps; let i = index; let last = last" class="mb-3 w-100">
        <mat-card class="mat-elevation-z4">
          <mat-card-header>
            <mat-card-title>
              {{ 'Dashboard.Procedures.StepIndex' | translate:{ index: i + 1 } }}

            </mat-card-title>
            <div class="position-top-right d-flex flex-row">
              <div class="hover-strong cursor-pointer" *ngIf="!last"
                   (click)="moveStepDown(step, i)"
                   title="{{ 'Common.MoveDown' | translate }}">
                <fa-icon icon="level-down-alt"></fa-icon>
              </div>

              <div class="hover-strong cursor-pointer ml-3" *ngIf="i > 0"
                   (click)="moveStepUp(step, i)"
                   title="{{ 'Common.MoveUp' | translate }}">
                <fa-icon icon="level-up-alt"></fa-icon>
              </div>

              <div class="hover-strong cursor-pointer ml-4"
                   (click)="removeStepAsync(step)"
                   title="{{ 'Common.Remove' | translate }}">
                <fa-icon icon="trash"></fa-icon>
              </div>
            </div>
          </mat-card-header>

          <mat-card-content>
            <div class="px-3 pb-2">
              <div>
                <mat-form-field class="w-100">
                  <input matInput placeholder="{{ 'Common.Name' | translate }}" name="name-{{ i }}"
                         [(ngModel)]="step.name" required>
                </mat-form-field>
              </div>
              <div class="mt-2">
                <app-rich-editor placeholder="{{ 'Common.Description' | translate }}"
                                 name="description-{{ i }}"
                                 [(ngModel)]="step.description"
                                 required>
                </app-rich-editor>
              </div>

              <div class="mt-3 row">
                <div class="col-2 d-flex align-items-center">{{ 'Dashboard.Procedures.DisplayImage' | translate }}</div>
                <div class="col-10">
                  <app-file-upload-selector name="files-{{ i }}-featured-image"
                                            [single]="true"
                                            displayFormat="small"
                                            [(ngModel)]="step.featuredImage">
                  </app-file-upload-selector>
                </div>
              </div>

              <div class="mt-3 row">
                <div class="col-2 d-fex align-items-center">{{ 'Common.Attachments' | translate }}</div>
                <div class="col-10">
                  <app-file-upload-selector name="files-{{ i }}" displayFormat="small" [(ngModel)]="step.files">
                  </app-file-upload-selector>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="mt-4">
        <button mat-raised-button color="primary" (click)="addStepAsync(procedureStepsEditForm)">
          <fa-icon icon="plus" class="mr-1"></fa-icon>

          {{ 'Dashboard.Procedures.AddStep' | translate }}
        </button>
      </div>
    </form>
  </div>

  <div class="text-center mt-3" *ngIf="isLoading">
    <app-loading-indicator></app-loading-indicator>
  </div>

  <div class="text-right mt-3">
    <span class="text-danger mr-4" *ngIf="hasErrorInSave">
      {{ 'Common.ErrorSaving' | translate }}
    </span>

    <button mat-raised-button class="mr-3" (click)="cancelClickAsync()">
      {{ 'Common.Cancel' | translate }}
    </button>

    <app-button color="primary" [loading]="inProgress"
                [disabled]="procedureStepsEditForm.untouched || !procedureStepsEditForm.valid"
                (clicked)="saveChangesAsync(procedureStepsEditForm)">
      {{ 'Common.SaveChanges' | translate }}
    </app-button>
  </div>
</div>