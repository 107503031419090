import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UserSettingsDTO } from '../../../../../server/src/dto/user-settings.dto';
import { SettingsService } from '../../shared/services/settings/settings.service';
import { UserContextService } from '../../shared/services/user-context/user-context.service';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss'],
})
export class GeneralSettingsComponent implements OnInit {
  constructor(private _userContextService: UserContextService,
              private _changeDetector: ChangeDetectorRef,
              private _settingsService: SettingsService) {

  }

  public user: UserSettingsDTO;

  public saveInProgress: boolean;

  ngOnInit() {
    this.user = JSON.parse(JSON.stringify(this._userContextService.user));
  }

  public async saveGeneralSettingsAsync(generalSettingsForm: NgForm) {
    if (!generalSettingsForm.valid) {
      return;
    }

    try {
      this.saveInProgress = true;

      await this._settingsService.setUserSettingsAsync(this.user);

      await this._userContextService.refreshUserSettingsAsync();

      generalSettingsForm.form.markAsPristine();
      generalSettingsForm.form.markAsUntouched();

      this._changeDetector.markForCheck();
    } finally {
      this.saveInProgress = false;
    }
  }
}
