import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { KnowledgeBaseArticleDTO } from '../../../../../../server/src/dto/knowledge-base-article.dto';
import { FeaturePermissions } from '../../../../../../server/src/models/feature-permissions.enum';
import { RouteNavigationService } from '../../../shared/routing/route-navigation.service';
import { UserContextService } from '../../../shared/services/user-context/user-context.service';
import { KnowledgeBasesService } from '../shared/services/knowledge-bases.service';

@Component({
  selector: 'app-view-knowledge-base-article',
  templateUrl: './view-knowledge-base-article.component.html',
  styleUrls: ['./view-knowledge-base-article.component.scss'],
})
export class ViewKnowledgeBaseArticleComponent implements OnInit, OnDestroy {
  constructor(private _userContextService: UserContextService,
              private _knowledgeBasesService: KnowledgeBasesService,
              private _activatedRoute: ActivatedRoute,
              private _routeNavigationService: RouteNavigationService) {

  }

  public canUserEditArticles: boolean;
  public isLoading: boolean;
  public showNotFound: boolean;

  public article: KnowledgeBaseArticleDTO;

  public ngOnInit() {
    this._activatedRoute.params
      .pipe(untilDestroyed(this))
      .subscribe(async (params: Params) => {
        const articleId = params.articleId;

        if (!articleId) {
          return;
        }

        try {
          this.isLoading = true;
          this.showNotFound = false;

          this.article = await this._knowledgeBasesService.getArticleByIdAsync(articleId);
        } catch {
          this.showNotFound = true;
        } finally {
          this.isLoading = false;
        }

        this.canUserEditArticles = this._userContextService.user.featurePermissions.includes(FeaturePermissions.EditKnowledgeBaseArticles);
      });
  }

  public ngOnDestroy(): void {
  }

  public async editArticleAsync() {
    await this._routeNavigationService.goToEditArticleAsync(this.article.id);
  }
}
