import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageHeaderComponent implements OnInit {

  constructor(private _location: Location) {
  }

  @Input()
  public pageTitle: string;

  @Input()
  public showBack: boolean;

  ngOnInit() {
  }

  public goBack() {
    this._location.back();
  }
}
