<div>
  <app-page-header pageTitle="{{ 'Dashboard.AssetControlReports.Title' | translate }}"
                   [showBack]="true"></app-page-header>

  <mat-card *ngIf="report" class="mat-elevation-z4 mt-4">
    <mat-card-header>
      <mat-card-title>
        <div class="d-flex flex-row">
          <div *ngIf="report.filters.room" class="mr-3">
            {{ report.filters.room.name }}
          </div>

          <div *ngIf="report.filters.roomCategory" class="mr-3">
            {{ report.filters.roomCategory.name }}
          </div>

          <div *ngIf="report.filters.assetType" class="mr-3">
            {{ report.filters.assetType.name }}
          </div>

          <div *ngIf="report.filters.responsibleRole" class="mr-3">
            {{ report.filters.responsibleRole.name }}
          </div>

          <div *ngIf="report.filters.responsibleUser">
            {{ report.filters.responsibleUser.name }}
          </div>
        </div>

        <div *ngIf="report.filters.startDate || report.filters.endDate" class="d-flex flex-row">
          <div *ngIf="report.filters.startDate">{{ report.filters.startDate | date }}</div>
          <div class="mx-2">&nbsp;-&nbsp;</div>
          <div *ngIf="report.filters.endDate">{{ report.filters.endDate | date }}</div>
        </div>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="p-3">
        <div class="row text-center">
          <div class="col-6">
            <h5 class="mb-3">{{ 'Dashboard.AssetControlReports.Rooms' | translate }}</h5>

            <div *ngIf="roomBasedReportChartData">
              <canvas baseChart
                      [datasets]="roomBasedReportChartData"
                      [labels]="roomBasedReportChartLabels"
                      [options]="roomBasedReportChartOptions"
                      [legend]="true"
                      chartType="line">
              </canvas>
            </div>
            <div *ngIf="!roomBasedReportChartData">{{ 'Common.NoData' | translate }}</div>
          </div>

          <div class="col-6">
            <h5 class="mb-3">{{ 'Dashboard.AssetControlReports.AssetTypes' | translate }}</h5>

            <div *ngIf="assetTypeBasedReportChartData">
              <canvas baseChart
                      [datasets]="assetTypeBasedReportChartData"
                      [labels]="assetTypeBasedReportChartLabels"
                      [options]="assetTypeBasedReportChartOptions"
                      [legend]="true"
                      chartType="line">
              </canvas>
            </div>
            <div *ngIf="!assetTypeBasedReportChartData">{{ 'Common.NoData' | translate }}</div>
          </div>
        </div>

        <div class="mt-5">
          <h5 class="mb-3 text-center">{{ 'Dashboard.AssetControlReports.MeasurementResult' | translate }}</h5>

          <div *ngIf="report.rawResults?.length">
            <div class="d-flex flex-row justify-content-end">
              <mat-form-field>
                <mat-label>{{ 'Dashboard.AssetControlRooms.Assets' | translate }}</mat-label>
                <mat-select name="raw-result-asset-filter"
                            [(ngModel)]="rawResultAssetIdFilter"
                            (ngModelChange)="filterRawResults()">
                  <mat-option [value]="null">{{ 'Common.All' | translate }}</mat-option>
                  <mat-option *ngFor="let asset of report.rawResultDictionary.assets | keyvalue" [value]="asset.key">
                    {{ asset.value.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="ml-2">
                <mat-label>{{ 'Dashboard.AssetControlReports.AssetType' | translate }}</mat-label>
                <mat-select name="raw-result-asset-type-filter"
                            [(ngModel)]="rawResultAssetTypeIdFilter"
                            (ngModelChange)="filterRawResults()">
                  <mat-option [value]="null">{{ 'Common.All' | translate }}</mat-option>
                  <mat-option *ngFor="let assetType of report.rawResultDictionary.assetTypes | keyvalue" [value]="assetType.key">
                    {{ assetType.value.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="ml-2">
                <mat-label>{{ 'Dashboard.AssetControlReports.AssetRoom' | translate }}</mat-label>
                <mat-select name="raw-result-asset-room-filter"
                            [(ngModel)]="rawResultRoomIdFilter"
                            (ngModelChange)="filterRawResults()">
                  <mat-option [value]="null">{{ 'Common.All' | translate }}</mat-option>
                  <mat-option *ngFor="let room of report.rawResultDictionary.rooms | keyvalue" [value]="room.key">
                    {{ room.value.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="ml-2">
                <mat-label>{{ 'Dashboard.AssetControlReports.RoomCategory' | translate }}</mat-label>
                <mat-select name="raw-result-asset-room-category-filter"
                            [(ngModel)]="rawResultRoomCategoryIdFilter"
                            (ngModelChange)="filterRawResults()">
                  <mat-option [value]="null">{{ 'Common.All' | translate }}</mat-option>
                  <mat-option *ngFor="let roomCategory of report.rawResultDictionary.roomCategories | keyvalue" [value]="roomCategory.key">
                    {{ roomCategory.value.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <table mat-table [dataSource]="rawResultsDataSource" class="mt-2 w-100">
              <!-- Position Date -->
              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>{{ 'Common.Date' | translate }}</th>
                <td mat-cell *matCellDef="let data">{{ data.date | date: 'shortDate' }}</td>
              </ng-container>

              <!-- Position Asset -->
              <ng-container matColumnDef="asset">
                <th mat-header-cell *matHeaderCellDef>{{ 'Common.Name' | translate }}</th>
                <td mat-cell *matCellDef="let data">{{ report.rawResultDictionary.assets[data.assetId]?.name }}</td>
              </ng-container>

              <!-- Position Asset Type -->
              <ng-container matColumnDef="assetType">
                <th mat-header-cell *matHeaderCellDef>{{ 'Dashboard.AssetControlReports.AssetType' | translate }}</th>
                <td mat-cell *matCellDef="let data">
                  {{ report.rawResultDictionary.assetTypes[report.rawResultDictionary.assets[data.assetId]?.typeId]?.name }}
                </td>
              </ng-container>

              <!-- Position Room -->
              <ng-container matColumnDef="room">
                <th mat-header-cell *matHeaderCellDef>{{ 'Dashboard.AssetControlReports.AssetRoom' | translate }}</th>
                <td mat-cell *matCellDef="let data">
                  {{ report.rawResultDictionary.rooms[report.rawResultDictionary.assets[data.assetId]?.roomId].name }}
                </td>
              </ng-container>

              <!-- Position Room Category -->
              <ng-container matColumnDef="roomCategory">
                <th mat-header-cell
                    *matHeaderCellDef>{{ 'Dashboard.AssetControlReports.RoomCategory' | translate }}</th>
                <td mat-cell *matCellDef="let data">
                  {{ report.rawResultDictionary.roomCategories[report.rawResultDictionary.rooms[report.rawResultDictionary.assets[data.assetId]?.roomId]?.categoryId]?.name }}
                </td>
              </ng-container>

              <!-- Position Result -->
              <ng-container matColumnDef="result">
                <th mat-header-cell
                    *matHeaderCellDef>{{ 'Dashboard.AssetControlReports.MeasurementResult' | translate }}</th>
                <td mat-cell *matCellDef="let data">
                  <div class="d-flex flex-row">
                    <div>
                      <app-asset-control-result-image [result]="data.result"></app-asset-control-result-image>
                    </div>
                    <div
                        class="ml-2">{{ ('Dashboard.AssetControlReports.ResultTypes.' + data.result) | translate }}</div>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="rawResultsDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: rawResultsDisplayedColumns;"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[50, 100]" showFirstLastButtons></mat-paginator>
          </div>

          <div *ngIf="!report.rawResults?.length" class="text-center">{{ 'Common.NoData' | translate }}</div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <div class="text-center" *ngIf="isLoading">
    <app-loading-indicator></app-loading-indicator>
  </div>

  <mat-card *ngIf="hasError">
    <h6 class="color-warn text-center">{{ 'Common.FailedToGenerateReport' | translate }}</h6>
  </mat-card>
</div>
