import { CoolHttp } from '@angular-cool/http';
import { Injectable } from '@angular/core';
import {
  ControlReportDTO,
  ControlReportGenerateRequestDTO,
} from '../../../../../../../../server/src/dto/control-report.dto';
import { ReportDTO } from '../../../../../../../../server/src/dto/report.dto';


@Injectable()
export class ControlReportsService {
  constructor(private _http: CoolHttp) {

  }

  public async getReportByIdAsync(reportId: string): Promise<ControlReportDTO> {
    return await this._http.getAsync(`api/companies/active/control/reports/${ reportId }`);
  }

  public async deleteReportByIdAsync(reportId: string): Promise<ControlReportDTO> {
    return await this._http.deleteAsync(`api/companies/active/control/reports/${ reportId }`);
  }

  public async getReportsAsync(): Promise<ReportDTO<ControlReportGenerateRequestDTO>[]> {
    return await this._http.getAsync('api/companies/active/control/reports');
  }

  public async generateReportAsync(generateRequest: ControlReportGenerateRequestDTO): Promise<void> {
    await this._http.postAsync('api/companies/active/control/reports', generateRequest);
  }
}