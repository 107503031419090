import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import {
  ProcedureTestQuestionAnswerDTO,
  ProcedureTestQuestionDTO,
} from '../../../../../../../../server/src/dto/procedure-test.dto';
import { ProcedureTestQuestionType } from '../../../../../../../../server/src/models/procedure-test-question-type.enum';
import { ListUtils } from '../../../../../../../../server/src/utils/list-utils';
import { LanguageService } from '../../../../../shared/services/language/language.service';

@Component({
  selector: 'app-edit-procedure-test-question',
  templateUrl: './edit-procedure-test-question.component.html',
  styleUrls: ['./edit-procedure-test-question.component.scss'],
})
export class EditProcedureTestQuestionComponent implements OnInit {

  constructor(private _languageService: LanguageService) {
  }

  @Input()
  public question: ProcedureTestQuestionDTO;

  @ViewChild('questionForm', { static: true })
  public questionForm: NgForm;

  public singleChoiceCorrectAnswer: ProcedureTestQuestionAnswerDTO;

  public ProcedureTestQuestionType = ProcedureTestQuestionType;

  ngOnInit() {
    this.singleChoiceCorrectAnswer = this.question.answers.find(answer => answer.isCorrectAnswer);
  }

  public async addAnswerAsync() {
    this.question.answers.push({
      id: null,
      answerText: await this._languageService.getTranslationAsync('Dashboard.Procedures.TestNewAnswerText', { index: this.question.answers.length + 1 }),
      rank: null,
      isCorrectAnswer: false,
    });

    ListUtils.setRankForListItems(this.question.answers);
  }

  public onSingleChoiceValueChange(change: MatRadioChange) {
    this._selectCorrectSingleChoiceAnswer(change.value);
  }

  public onMultipleChoiceAnswerClick(answer: ProcedureTestQuestionAnswerDTO) {
    answer.isCorrectAnswer = !answer.isCorrectAnswer;
  }

  public onQuestionTypeChange() {
    if (this.question.type === ProcedureTestQuestionType.SingleChoice) {
      this._selectCorrectSingleChoiceAnswer(this.question.answers.find(a => a.isCorrectAnswer) || this.question.answers[0]);
    }
  }

  public removeAnswer(answer: ProcedureTestQuestionAnswerDTO) {
    if (this.question.answers.length <= 2) {
      return;
    }

    const indexOfAnswer = this.question.answers.indexOf(answer);

    this.question.answers.splice(indexOfAnswer, 1);

    if (this.question.type === ProcedureTestQuestionType.SingleChoice && answer.isCorrectAnswer) {
      this._selectCorrectSingleChoiceAnswer(this.question.answers[0]);
    }
  }

  private _selectCorrectSingleChoiceAnswer(selectedAnswer: ProcedureTestQuestionAnswerDTO) {
    for (const answer of this.question.answers) {
      answer.isCorrectAnswer = selectedAnswer === answer;
    }

    this.singleChoiceCorrectAnswer = selectedAnswer;
  }
}
