import { CoolHttp } from '@angular-cool/http';
import { Injectable } from '@angular/core';
import { RequestDemoDTO } from '../../../../../../server/src/dto/request-demo.dto';

@Injectable()
export class RequestDemoService {
  constructor(private _http: CoolHttp) {

  }

  public async requestDemoAsync(requestDemoData: RequestDemoDTO) {
    return this._http.postAsync('api/demo-requests', requestDemoData);
  }
}