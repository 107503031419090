export interface LearningCampaignCompletionSessionDTO {
  isCompleted: boolean;

  startedAt: Date;

  completedAt: Date;

  enforcedFinishTime: null | Date;

  currentStep: LearningCampaignCompletionSessionStepDTO;

  previousSteps: LearningCampaignCompletionSessionStepDTO[];

  procedures: LearningCampaignCompletionSessionProcedureDTO[];

  results: LearningCampaignCompletionSessionResults;

  progress: {
    allStepCount: number;

    currentStepIndex: number;
  };
}

export interface LearningCampaignCompletionSessionResults {
  successRate: number;

  isSuccessful: boolean;

  finishedDescription: string;

  minutesTaken: number;

  incorrectlyAnsweredQuestions: {
    id: string;

    selectedAnswers: {
      id: string;
      rank: string;
    }[];

    correctAnswers: {
      id: string;
      rank: string;
    }[];
  }[];
}

export interface LearningCampaignCompletionSessionProcedureDTO {
  id: string;

  videos: {
    id: string;
  }[];

  questions: LearningCampaignCompletionSessionProcedureTestQuestionAnswersDTO[];
}

export interface LearningCampaignCompletionSessionProcedureTestQuestionAnswersDTO {
  id: string;

  selectedAnswers: {
    id: string;

    rank: string;
  }[];
}

export enum LearningCampaignCompletionSessionStepType {
  Start = 'start',

  ProcedureContent = 'procedure-content',
  ProcedureVideos = 'procedure-videos',
  ProcedureTest = 'procedure-test',

  BeforeResults = 'before-results',

  Results = 'results',
}

export interface LearningCampaignCompletionSessionStepDTO {
  type: LearningCampaignCompletionSessionStepType;

  procedureId: string;
  procedureVideoId: string;
  procedureTestQuestionId: string;
}
