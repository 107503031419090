import { CoolDialogService } from '@angular-cool/dialogs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { KnowledgeBaseArticleDTO } from '../../../../../../server/src/dto/knowledge-base-article.dto';
import { RouteNavigationService } from '../../../shared/routing/route-navigation.service';
import { LanguageService } from '../../../shared/services/language/language.service';
import { KnowledgeBasesService } from '../shared/services/knowledge-bases.service';

@Component({
  selector: 'app-edit-knowledge-base-article',
  templateUrl: './edit-knowledge-base-article.component.html',
  styleUrls: ['./edit-knowledge-base-article.component.scss'],
})
export class EditKnowledgeBaseArticleComponent implements OnInit, OnDestroy {
  constructor(private _knowledgeBasesService: KnowledgeBasesService,
              private _activatedRoute: ActivatedRoute,
              private _dialogService: CoolDialogService,
              private _languageService: LanguageService,
              private _routeNavigationService: RouteNavigationService) {

  }

  public article: KnowledgeBaseArticleDTO;

  public hasErrorInSave: boolean;
  public inProgress: boolean;

  public ngOnInit() {
    this._activatedRoute.params
      .pipe(untilDestroyed(this))
      .subscribe(async (params: Params) => {
        const articleId = params.articleId;

        if (!articleId) {
          return;
        }

        this.article = await this._knowledgeBasesService.getArticleByIdAsync(articleId);
      });
  }

  public ngOnDestroy(): void {
  }

  public async removeArticleAsync() {
    const removeDialogResult = await this._dialogService.showDialog({
      titleText: await this._languageService.getTranslationAsync('Dashboard.KnowledgeBase.RemoveArticleTitle', { name: this.article.name }),
      questionText: await this._languageService.getTranslationAsync('Dashboard.KnowledgeBase.RemoveArticleDescription', { name: this.article.name }),
      confirmActionButtonText: await this._languageService.getTranslationAsync('Common.Remove'),
      cancelActionButtonText: await this._languageService.getTranslationAsync('Common.Cancel'),
      confirmActionButtonColor: 'warn',
    });

    if (!removeDialogResult.isConfirmed) {
      return;
    }

    await this._knowledgeBasesService.removeArticleByIdAsync(this.article.id);

    await this._routeNavigationService.goToArticleListAsync();
  }

  public async saveChangesAsync(editArticleForm: NgForm) {
    if (editArticleForm.invalid) {
      return;
    }

    try {
      this.inProgress = true;
      this.hasErrorInSave = false;

      await this._knowledgeBasesService.updateArticleAsync(this.article);

      editArticleForm.form.markAsUntouched();
      editArticleForm.form.markAsPristine();

      await this._routeNavigationService.goToViewArticleAsync(this.article.id);
    } catch {
      this.hasErrorInSave = true;
    } finally {
      this.inProgress = false;
    }
  }

  public async cancelClickAsync() {
    await this._routeNavigationService.goToViewArticleAsync(this.article.id);
  }

}
