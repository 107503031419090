<div id="side-nav">
  <div class="mb-5">
    <app-company-selector></app-company-selector>
  </div>

  <a class="item" [routerLink]="['/', RouteLocations.Dashboard]"
     routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
     (click)="closeNavbar()">
    <fa-icon icon="home"></fa-icon>

    {{ 'Dashboard.Navigation.Dashboard' | translate }}
  </a>

  <div class="item parent" [class.opened]="openedState.knowledgeBaseOpened" (click)="toggleKnowledgeBase()">
    <div>
      <fa-icon icon="book"></fa-icon>

      {{ 'Dashboard.Navigation.KnowledgeBase' | translate }}

      <fa-icon class="ml-1" [icon]="openedState.knowledgeBaseOpened ? 'chevron-down' : 'chevron-left'"
               size="xs"></fa-icon>
    </div>

    <div (click)="closeNavbar(); $event.stopPropagation()">
      <a class="item" [routerLink]="[RouteLocations.DashboardChildren.Procedures]"
         routerLinkActive="active">
        {{ 'Dashboard.Navigation.Procedures' | translate }}
      </a>

      <a class="item" [routerLink]="RouteLocations.DashboardChildren.KnowledgeBaseArticles"
         routerLinkActive="active">

        {{ 'Dashboard.Navigation.KnowledgeBaseArticles' | translate }}
      </a>

      <a class="item" *ngIf="showProcedureCategories" [routerLink]="[
            RouteLocations.DashboardChildren.ProcedureCategories
          ]"
         routerLinkActive="active">
        {{ 'Dashboard.Navigation.ProcedureCategories' | translate }}
      </a>
    </div>
  </div>


  <div *ngIf="showLearning" class="item parent" [class.opened]="openedState.learningOpened" (click)="toggleLearning()">
    <div>
      <fa-icon icon="graduation-cap"></fa-icon>

      {{ 'Dashboard.Navigation.Learning' | translate }}

      <fa-icon class="ml-1" [icon]="openedState.learningOpened ? 'chevron-down' : 'chevron-left'" size="xs"></fa-icon>
    </div>

    <div (click)="closeNavbar(); $event.stopPropagation()">
      <a class="item" *ngIf="showLearningCampaigns" [routerLink]="[
        RouteLocations.DashboardChildren.Learning,
        RouteLocations.DashboardChildren.LearningChildren.Campaigns
      ]"
         routerLinkActive="active">
        {{ 'Dashboard.Navigation.LearningCampaigns' | translate }}
      </a>

      <a class="item" *ngIf="showLearningCampaignReports" [routerLink]="[
        RouteLocations.DashboardChildren.Learning,
        RouteLocations.DashboardChildren.LearningChildren.Reports
      ]"
         routerLinkActive="active">
        {{ 'Dashboard.Navigation.Reports' | translate }}
      </a>

      <a class="item" *ngIf="showLearningCertificates" [routerLink]="[
        RouteLocations.DashboardChildren.Learning,
        RouteLocations.DashboardChildren.LearningChildren.Certificates
      ]"
         routerLinkActive="active">
        {{ 'Dashboard.Navigation.Certificates' | translate }}
      </a>

      <a class="item" *ngIf="showLearningCertificates" [routerLink]="[
        RouteLocations.DashboardChildren.Learning,
        RouteLocations.DashboardChildren.LearningChildren.CertificateTemplates
      ]"
         routerLinkActive="active">
        {{ 'Dashboard.Navigation.CertificateTemplates' | translate }}
      </a>
    </div>
  </div>

  <div *ngIf="showControl" class="item parent" [class.opened]="openedState.controlOpened" (click)="toggleControl()">
    <div>
      <fa-icon icon="clipboard-check"></fa-icon>

      {{ 'Dashboard.Navigation.Control' | translate }}

      <fa-icon class="ml-1" [icon]="openedState.controlOpened ? 'chevron-down' : 'chevron-left'" size="xs"></fa-icon>
    </div>

    <div (click)="closeNavbar(); $event.stopPropagation()">
      <a class="item" *ngIf="showControlCheckEvents" [routerLink]="[
        RouteLocations.DashboardChildren.Control,
        RouteLocations.DashboardChildren.ControlChildren.ControlCheckEvents
      ]"
         routerLinkActive="active">
        {{ 'Dashboard.Navigation.ControlChecks' | translate }}
      </a>

      <a class="item" *ngIf="showControlCheckReports" [routerLink]="[
        RouteLocations.DashboardChildren.Control,
        RouteLocations.DashboardChildren.ControlChildren.ControlReports
      ]"
         routerLinkActive="active">
        {{ 'Dashboard.Navigation.Reports' | translate }}
      </a>
    </div>
  </div>

  <div *ngIf="showAssetControl" class="item parent" [class.opened]="openedState.assetControlOpened"
       (click)="toggleAssetControl()">
    <div>
      <fa-icon icon="cube"></fa-icon>

      {{ 'Dashboard.Navigation.AssetControl' | translate }}

      <fa-icon class="ml-1" [icon]="openedState.assetControlOpened ? 'chevron-down' : 'chevron-left'"
               size="xs"></fa-icon>
    </div>

    <div (click)="closeNavbar(); $event.stopPropagation()">
      <a class="item" *ngIf="showAssetControlRooms" [routerLink]="[
        RouteLocations.DashboardChildren.AssetControl,
        RouteLocations.DashboardChildren.AssetControlChildren.Rooms
      ]"
         routerLinkActive="active">
        {{ 'Dashboard.Navigation.AssetControlRooms' | translate }}
      </a>

      <a class="item" *ngIf="showAssetControlReports" [routerLink]="[
        RouteLocations.DashboardChildren.AssetControl,
        RouteLocations.DashboardChildren.AssetControlChildren.Reports
      ]"
         routerLinkActive="active">
        {{ 'Dashboard.Navigation.AssetControlReports' | translate }}
      </a>

      <a class="item" *ngIf="showAssetControlAssetTypes" [routerLink]="[
        RouteLocations.DashboardChildren.AssetControl,
        RouteLocations.DashboardChildren.AssetControlChildren.AssetTypes
      ]"
         routerLinkActive="active">
        {{ 'Dashboard.Navigation.AssetControlAssetTypes' | translate }}
      </a>

      <a class="item" *ngIf="showAssetControlRoomCategories" [routerLink]="[
        RouteLocations.DashboardChildren.AssetControl,
        RouteLocations.DashboardChildren.AssetControlChildren.RoomCategories
      ]"
         routerLinkActive="active">
        {{ 'Dashboard.Navigation.AssetControlRoomCategories' | translate }}
      </a>
    </div>
  </div>

  <div *ngIf="showOrganization" class="item parent" [class.opened]="openedState.organizationOpened"
       (click)="toggleOrganization()">
    <div>
      <fa-icon icon="building"></fa-icon>

      {{ 'Dashboard.Navigation.Organization' | translate }}

      <fa-icon class="ml-1" [icon]="openedState.organizationOpened ? 'chevron-down' : 'chevron-left'"
               size="xs"></fa-icon>
    </div>

    <div (click)="closeNavbar(); $event.stopPropagation()">
      <a class="item" [routerLink]="[
        RouteLocations.DashboardChildren.Organization,
        RouteLocations.DashboardChildren.OrganizationChildren.Departments
      ]"
         routerLinkActive="active">
        {{ 'Dashboard.Navigation.Departments' | translate }}
      </a>

      <a class="item" [routerLink]="[
        RouteLocations.DashboardChildren.Organization,
        RouteLocations.DashboardChildren.OrganizationChildren.Roles
      ]"
         routerLinkActive="active">
        {{ 'Dashboard.Navigation.Roles' | translate }}
      </a>

      <a class="item" [routerLink]="[
        RouteLocations.DashboardChildren.Organization,
        RouteLocations.DashboardChildren.OrganizationChildren.Permissions
      ]"
         routerLinkActive="active">
        {{ 'Dashboard.Navigation.Permissions' | translate }}
      </a>

      <a class="item" [routerLink]="[
        RouteLocations.DashboardChildren.Organization,
        RouteLocations.DashboardChildren.OrganizationChildren.Users
      ]"
         routerLinkActive="active">
        {{ 'Dashboard.Navigation.Users' | translate }}
      </a>
    </div>
  </div>

  <a *ngIf="showSettings" class="item" [routerLink]="RouteLocations.DashboardChildren.Settings"
     routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="closeNavbar()">
    <fa-icon icon="cog"></fa-icon>

    {{ 'Dashboard.Navigation.Settings' | translate }}
  </a>
</div>
