import { ErrorHandler, Injectable } from '@angular/core';
import { LoggerService } from './logger.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private _logger: LoggerService) {
  }

  handleError(err: any): void {
    this._logger.error(err.originalError || err);
  }
}
