import { CoolDialogService } from '@angular-cool/dialogs';
import { BACKSPACE, DELETE } from '@angular/cdk/keycodes';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { fabric } from 'fabric';
import { Image } from 'fabric/fabric-impl';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AssetRoomDTO } from '../../../../../../../server/src/dto/asset-control.dto';
import { RouteNavigationService } from '../../../../shared/routing/route-navigation.service';
import { LanguageService } from '../../../../shared/services/language/language.service';
import { AssetRoomService } from '../../shared/services/asset-room.service';
import { DRAW_ITEM_GROUPS, DrawItem } from './draw-items.enum';

@Component({
  selector: 'app-asset-control-rooms-edit',
  templateUrl: './asset-control-rooms-edit.component.html',
  styleUrls: ['./asset-control-rooms-edit.component.scss'],
})
export class AssetControlRoomsEditComponent implements OnInit, AfterViewInit, OnDestroy {
  private _roomId: string;

  constructor(private _assetRoomService: AssetRoomService,
              private _dialogService: CoolDialogService,
              private _languageService: LanguageService,
              private _changeDetector: ChangeDetectorRef,
              private _routeNavigationService: RouteNavigationService,
              private _activatedRoute: ActivatedRoute) {

  }

  public roomCanvas: fabric.Canvas;

  public hasErrorInSave: boolean;
  public saveInProgress: boolean;

  public room: AssetRoomDTO;

  public drawItemGroups = DRAW_ITEM_GROUPS;

  @ViewChild('roomCanvasElement')
  public roomCanvasElement: ElementRef<HTMLCanvasElement>;

  @ViewChild('roomEditForm')
  public roomEditForm: NgForm;

  public async ngOnInit() {
    this._activatedRoute.params
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(async (params: Params) => {
        this._roomId = params.roomId;

        if (!this._roomId) {
          return;
        }

        await this._loadDataAsync();

        // load canvas element
        this._changeDetector.detectChanges();

        await this._buildRoomCanvasAsync();
      });
  }

  public ngOnDestroy(): void {
  }

  public ngAfterViewInit(): void {

  }

  public async addTextAsync() {
    this.roomCanvas.add(new fabric.IText(
      await this._languageService.getTranslationAsync('Dashboard.AssetControlRooms.NewText'),
      {
        left: 100,
        top: 100,
        textAlign: 'center',
      }));
  }

  public async addLineAsync(coordinates: number[] = [100, 100, 100, 200]) {
    this.roomCanvas.add(new fabric.Line(coordinates, {
      fill: 'black',
      stroke: 'black',
      strokeWidth: 4,
    }));
  }

  public async addRectangleAsync() {
    this.roomCanvas.add(new fabric.Rect({
      fill: 'transparent',
      stroke: 'black',
      width: 60,
      height: 60,
      strokeWidth: 3,
    }));
  }

  public addItemAsync(drawItem: DrawItem) {
    return new Promise(resolve => {
      fabric.Image.fromURL(drawItem.imagePath, (img: Image) => {
        img.scale(0.15);

        this.roomCanvas.add(img);

        resolve();
      }, {
        left: 100,
        top: 100,
      });
    });
  }

  public async saveChangesAsync() {
    if (!this.roomEditForm.valid) {
      return;
    }

    try {
      this.saveInProgress = true;
      this.hasErrorInSave = false;

      this.room.backgroundJson = JSON.parse(JSON.stringify(this.roomCanvas.toDatalessJSON()).replace(/(https:\/\/[^\/]*)/g, ''));

      this.room.backgroundJsonSettings = this.room.backgroundJsonSettings || {
        zoom: 1,
        translateX: 0,
        translateY: 0,
      };

      this.room.backgroundJsonSettings.zoom = this.roomCanvas.getZoom();
      this.room.backgroundJsonSettings.translateX = this.roomCanvas.viewportTransform[4];
      this.room.backgroundJsonSettings.translateY = this.roomCanvas.viewportTransform[5];


      await this._assetRoomService.upsertRoomAsync(this.room);

      await this._routeNavigationService.goToAssetRoomListAsync();
    } catch {
      this.hasErrorInSave = true;
    } finally {
      this.saveInProgress = false;
    }
  }

  public async removeRoomAsync() {
    const removeDialogResult = await this._dialogService.showDialog({
      titleText: await this._languageService.getTranslationAsync('Dashboard.AssetControlRooms.RemoveRoomTitle', { roomName: this.room.name }),
      questionText: await this._languageService.getTranslationAsync('Dashboard.AssetControlRooms.RemoveRoomDescription', { roomName: this.room.name }),
      confirmActionButtonText: await this._languageService.getTranslationAsync('Common.Remove'),
      cancelActionButtonText: await this._languageService.getTranslationAsync('Common.Cancel'),
      confirmActionButtonColor: 'warn',
    });

    if (!removeDialogResult.isConfirmed) {
      return;
    }

    await this._assetRoomService.removeRoomByIdAsync(this.room.id);

    await this._routeNavigationService.goToAssetRoomListAsync();
  }

  public async cancelClickAsync() {
    await this._routeNavigationService.goToAssetRoomListAsync();
  }

  private async _loadDataAsync() {
    this.room = await this._assetRoomService.getRoomByIdAsync(this._roomId);
  }

  private _buildRoomCanvasAsync() {
    return new Promise(async resolve => {
      this.roomCanvas = new fabric.Canvas(this.roomCanvasElement.nativeElement);

      const canvasContainer = this.roomCanvasElement.nativeElement.parentElement;

      canvasContainer.tabIndex = 0;

      if (this.room.backgroundJson) {
        this.roomCanvas.loadFromJSON(this.room.backgroundJson, () => {
          if (this.room.backgroundJsonSettings) {
            this.roomCanvas.setZoom(this.room.backgroundJsonSettings.zoom);

            this.roomCanvas.viewportTransform[4] = this.room.backgroundJsonSettings.translateX;
            this.roomCanvas.viewportTransform[5] = this.room.backgroundJsonSettings.translateY;
          }

          resolve();
        });
      } else {
        await this._addDefaultItemsAsync();
      }

      this.roomCanvas.on('after:render', () => {
        this.roomEditForm.form.markAllAsTouched();
        this.roomEditForm.form.markAsDirty();
      });

      fromEvent<KeyboardEvent>(canvasContainer, 'keyup')
        .pipe(
          untilDestroyed(this),
          filter(ev => ev.keyCode === DELETE || ev.keyCode === BACKSPACE),
        )
        .subscribe((ev) => {
          const selectedObjects = this.roomCanvas.getActiveObjects();

          if (!selectedObjects || !selectedObjects.length) {
            return;
          }

          for (const canvasObject of selectedObjects) {
            this.roomCanvas.remove(canvasObject);
          }

          this.roomCanvas.discardActiveObject().renderAll();

          ev.preventDefault();
          ev.stopPropagation();
        });
    });
  }

  private async _addDefaultItemsAsync() {
    await this.addLineAsync([10, 10, 10, 430]);

    await this.addLineAsync([10, 430, 580, 430]);

    await this.addLineAsync([580, 430, 580, 10]);

    await this.addLineAsync([10, 10, 580, 10]);
  }
}
