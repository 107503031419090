<div class="editor-container" [class.has-placeholder]="placeholder">
  <div *ngIf="placeholder && value" class="top-placeholder text-muted">
    {{ placeholder }}
  </div>

  <quill-editor #editor
                (ngModelChange)="onInnerValueChanged($event)"
                [ngModel]="value"
                [placeholder]="placeholder"
                [modules]="modules">
  </quill-editor>
</div>