import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-control-check-events',
  templateUrl: './control-check-events.component.html',
  styleUrls: ['./control-check-events.component.scss']
})
export class ControlCheckEventsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
