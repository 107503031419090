import { Component, Input, OnInit } from '@angular/core';
import { ProcedureStepDTO } from '../../../../../../../../server/src/dto/procedure.dto';

export type ProcedureStepImageSize = 'normal' | 'small';

@Component({
  selector: 'app-procedure-step-image',
  templateUrl: './procedure-step-image.component.html',
  styleUrls: ['./procedure-step-image.component.scss']
})
export class ProcedureStepImageComponent implements OnInit {

  constructor() { }

  @Input()
  public step: ProcedureStepDTO;

  @Input()
  public index: number;

  @Input()
  public size: ProcedureStepImageSize = 'normal';

  ngOnInit(): void {
  }

}
