import { CoolDialogsModule } from '@angular-cool/dialogs';
import { CoolHttpModule } from '@angular-cool/http';
import { CoolStorageModule } from '@angular-cool/storage';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faGoogle, faSearchengin } from '@fortawesome/free-brands-svg-icons';
import {
  faBars,
  faBath,
  faBook,
  faBuilding,
  faChartBar,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClipboardCheck,
  faCog,
  faCogs, faCopy,
  faCube,
  faCubes,
  faDownload,
  faEnvelope,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFile,
  faGraduationCap,
  faHome,
  faLevelDownAlt,
  faLevelUpAlt,
  faMinus,
  faPencilAlt,
  faPlus,
  faQuestion,
  faRedoAlt,
  faSortDown,
  faSprayCan,
  faTimes,
  faTimesCircle,
  faTrash,
  faUser,
  faVial,
} from '@fortawesome/free-solid-svg-icons';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsModule } from '@ngxs/store';
import { Angulartics2Module } from 'angulartics2';
import { ChartsModule } from 'ng2-charts';
import { CookieService } from 'ngx-cookie-service';
import { QuillModule } from 'ngx-quill';
import { environment } from '../environments/environment';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { GeneralSettingsComponent } from './account-settings/general-settings/general-settings.component';
import { NotificationSettingsComponent } from './account-settings/notification-settings/notification-settings.component';
import { NotificationSettingsService } from './account-settings/notification-settings/shared/services/notification-settings.service';
import { PasswordSettingsComponent } from './account-settings/password-settings/password-settings.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CompanyJoinComponent } from './company-join/company-join.component';
import { AssetControlAssetTypesComponent } from './dashboard/asset-control/asset-control-asset-types/asset-control-asset-types.component';
import { AssetControlReportGenerateDialogComponent } from './dashboard/asset-control/asset-control-reports/asset-control-report-list/asset-control-report-generate-dialog/asset-control-report-generate-dialog.component';
import { AssetControlReportListComponent } from './dashboard/asset-control/asset-control-reports/asset-control-report-list/asset-control-report-list.component';
import { AssetControlReportViewComponent } from './dashboard/asset-control/asset-control-reports/asset-control-report-view/asset-control-report-view.component';
import { AssetControlReportsComponent } from './dashboard/asset-control/asset-control-reports/asset-control-reports.component';
import { AssetControlRoomCategoriesComponent } from './dashboard/asset-control/asset-control-room-categories/asset-control-room-categories.component';
import { AssetControlRoomsAssetsComponent } from './dashboard/asset-control/asset-control-rooms/asset-control-rooms-assets/asset-control-rooms-assets.component';
import { AssetControlRoomsEditComponent } from './dashboard/asset-control/asset-control-rooms/asset-control-rooms-edit/asset-control-rooms-edit.component';
import { AssetControlRoomEventEditorComponent } from './dashboard/asset-control/asset-control-rooms/asset-control-rooms-events/asset-control-room-event-editor/asset-control-room-event-editor.component';
import { AssetControlRoomsEventsComponent } from './dashboard/asset-control/asset-control-rooms/asset-control-rooms-events/asset-control-rooms-events.component';
import { AssetControlRoomsListComponent } from './dashboard/asset-control/asset-control-rooms/asset-control-rooms-list/asset-control-rooms-list.component';
import { AssetControlRoomsComponent } from './dashboard/asset-control/asset-control-rooms/asset-control-rooms.component';
import { AssetControlComponent } from './dashboard/asset-control/asset-control.component';
import { AssetControlResultImageComponent } from './dashboard/asset-control/shared/components/asset-control-result-image/asset-control-result-image.component';
import { AssetRoomCategorySelectComponent } from './dashboard/asset-control/shared/components/asset-room-category-select/asset-room-category-select.component';
import { AssetRoomSelectComponent } from './dashboard/asset-control/shared/components/asset-room-select/asset-room-select.component';
import { AssetTypeSelectComponent } from './dashboard/asset-control/shared/components/asset-type-select/asset-type-select.component';
import { CanvasControlsComponent } from './dashboard/asset-control/shared/components/canvas-controls/canvas-controls.component';
import { RoomCanvasComponent } from './dashboard/asset-control/shared/components/room-canvas/room-canvas.component';
import { AssetControlReportsService } from './dashboard/asset-control/shared/services/asset-control-reports.service';
import { AssetRoomCheckpointsService } from './dashboard/asset-control/shared/services/asset-room-checkpoints.service';
import { AssetRoomService } from './dashboard/asset-control/shared/services/asset-room.service';
import { AssetsService } from './dashboard/asset-control/shared/services/assets.service';
import { ControlCheckEventsEditComponent } from './dashboard/control/control-check-events/control-check-events-edit/control-check-events-edit.component';
import { ControlCheckEventsListComponent } from './dashboard/control/control-check-events/control-check-events-list/control-check-events-list.component';
import { ControlCheckEventsComponent } from './dashboard/control/control-check-events/control-check-events.component';
import { ControlReportViewComponent } from './dashboard/control/control-reports/control-report-view/control-report-view.component';
import { ControlReportGenerateDialogComponent } from './dashboard/control/control-reports/control-reports-list/control-report-generate-dialog/control-report-generate-dialog.component';
import { ControlReportsListComponent } from './dashboard/control/control-reports/control-reports-list/control-reports-list.component';
import { ControlReportsComponent } from './dashboard/control/control-reports/control-reports.component';
import { ControlReportsService } from './dashboard/control/control-reports/shared/services/control-reports.service';
import { ControlComponent } from './dashboard/control/control.component';
import { ControlChecksService } from './dashboard/control/shared/services/control-checks.service';
import { ActiveLearningCampaignsWidgetComponent } from './dashboard/dashboard-home/active-learning-campaigns-widget/active-learning-campaigns-widget.component';
import { DashboardHomeComponent } from './dashboard/dashboard-home/dashboard-home.component';
import { DashboardService } from './dashboard/dashboard-home/shared/services/dashboard.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EditKnowledgeBaseArticleComponent } from './dashboard/knowledge-base/edit-knowledge-base-article/edit-knowledge-base-article.component';
import { KnowledgeBaseArticleListComponent } from './dashboard/knowledge-base/knowledge-base-article-list/knowledge-base-article-list.component';
import { KnowledgeBaseComponent } from './dashboard/knowledge-base/knowledge-base.component';
import { KnowledgeBasesService } from './dashboard/knowledge-base/shared/services/knowledge-bases.service';
import { ViewKnowledgeBaseArticleComponent } from './dashboard/knowledge-base/view-knowledge-base-article/view-knowledge-base-article.component';
import { CompleteLearningCampaignComponent } from './dashboard/learning/complete-learning-campaign/complete-learning-campaign.component';
import { CompleteCampaignService } from './dashboard/learning/complete-learning-campaign/shared/services/complete-campaign.service';
import { EditLearningCampaignComponent } from './dashboard/learning/learning-campaigns/edit-learning-campaign/edit-learning-campaign.component';
import { LearningCampaignListComponent } from './dashboard/learning/learning-campaigns/learning-campaign-list/learning-campaign-list.component';
import { LearningCampaignsComponent } from './dashboard/learning/learning-campaigns/learning-campaigns.component';
import { EditLearningCertificateTemplateDialogComponent } from './dashboard/learning/learning-certificate-templates/edit-learning-certificate-template-dialog/edit-learning-certificate-template-dialog.component';
import { LearningCertificateTemplatesComponent } from './dashboard/learning/learning-certificate-templates/learning-certificate-templates.component';
import { LearningCertificateGenerateDialogComponent } from './dashboard/learning/learning-certificates/learning-certificate-generate-dialog/learning-certificate-generate-dialog.component';
import { LearningCertificatesComponent } from './dashboard/learning/learning-certificates/learning-certificates.component';
import { LearningReportViewComponent } from './dashboard/learning/learning-reports/learning-report-view/learning-report-view.component';
import { LearningReportGenerateDialogComponent } from './dashboard/learning/learning-reports/learning-reports-list/learning-report-generate-dialog/learning-report-generate-dialog.component';
import { LearningReportsListComponent } from './dashboard/learning/learning-reports/learning-reports-list/learning-reports-list.component';
import { LearningReportsComponent } from './dashboard/learning/learning-reports/learning-reports.component';
import { LearningReportService } from './dashboard/learning/learning-reports/shared/services/learning-report.service';
import { LearningComponent } from './dashboard/learning/learning.component';
import { OrganizationDepartmentsComponent } from './dashboard/organization/organization-departments/organization-departments.component';
import { EditPermissionRoleDialogComponent } from './dashboard/organization/organization-permission-roles/edit-permission-role-dialog/edit-permission-role-dialog.component';
import { OrganizationPermissionRolesComponent } from './dashboard/organization/organization-permission-roles/organization-permission-roles.component';
import { OrganizationRolesComponent } from './dashboard/organization/organization-roles/organization-roles.component';
import { AddUserDialogComponent } from './dashboard/organization/organization-users/add-user-dialog/add-user-dialog.component';
import { EditUserDialogComponent } from './dashboard/organization/organization-users/edit-user-dialog/edit-user-dialog.component';
import { OrganizationUsersComponent } from './dashboard/organization/organization-users/organization-users.component';
import { OrganizationComponent } from './dashboard/organization/organization.component';
import { PermissionRoleSelectComponent } from './dashboard/organization/shared/components/permission-role-select/permission-role-select.component';
import { EditProcedureGeneralComponent } from './dashboard/procedures/edit-procedure/edit-procedure-general/edit-procedure-general.component';
import { EditProcedureStepsComponent } from './dashboard/procedures/edit-procedure/edit-procedure-steps/edit-procedure-steps.component';
import { EditProcedureTestQuestionComponent } from './dashboard/procedures/edit-procedure/edit-procedure-test/edit-procedure-test-question/edit-procedure-test-question.component';
import { EditProcedureTestComponent } from './dashboard/procedures/edit-procedure/edit-procedure-test/edit-procedure-test.component';
import { EditProcedureComponent } from './dashboard/procedures/edit-procedure/edit-procedure.component';
import { ProcedureCategoriesComponent } from './dashboard/procedures/procedure-categories/procedure-categories.component';
import { ProcedureListComponent } from './dashboard/procedures/procedure-list/procedure-list.component';
import { ProceduresComponent } from './dashboard/procedures/procedures.component';
import { CopyProcedureToCompanyDialogComponent } from './dashboard/procedures/shared/copy-procedure-to-company-dialog/copy-procedure-to-company-dialog.component';
import { ViewProcedureComponent } from './dashboard/procedures/view-procedure/view-procedure.component';
import { CompanyGeneralSettingsComponent } from './dashboard/settings/company-general-settings/company-general-settings.component';
import { CompanySettingsComponent } from './dashboard/settings/company-settings.component';
import { CompanySettingsService } from './dashboard/settings/shared/services/company-settings.service';
import { SsoSettingsComponent } from './dashboard/settings/sso-settings/sso-settings.component';
import { WhiteLabelingSettingsComponent } from './dashboard/settings/white-labeling-settings/white-labeling-settings.component';
import { ProcedureDisplayComponent } from './dashboard/shared/components/procedure-display/procedure-display.component';
import { ProcedureStepImageComponent } from './dashboard/shared/components/procedure-display/procedure-step-image/procedure-step-image.component';
import { ScoreColorDirective } from './dashboard/shared/directives/score-color.directive';
import { CompanySelectorComponent } from './dashboard/side-nav/company-selector/company-selector.component';
import { SideNavComponent } from './dashboard/side-nav/side-nav.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LanguageSelectorComponent } from './header/language-selector/language-selector.component';
import { HomeComponent } from './home/home.component';
import { ActivateEmailComponent } from './login/activate-email/activate-email.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { LoginBoxComponent } from './login/login-box/login-box.component';
import { LoginSuccessComponent } from './login/login-success/login-success.component';
import { LoginComponent } from './login/login.component';
import { RegisterBoxComponent } from './login/register-box/register-box.component';
import { RestorePasswordComponent } from './login/restore-password/restore-password.component';
import { GoogleButtonComponent } from './login/shared/components/google-button/google-button.component';
import { SSOLoginComponent } from './login/sso-login/sso-login.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { RequestDemoComponent } from './request-demo/request-demo.component';
import { RequestDemoService } from './request-demo/shared/services/request-demo.service';
import { ButtonComponent } from './shared/components/button/button.component';
import { CompanyRoleSelectComponent } from './shared/components/company-role-select/company-role-select.component';
import { SubCompanySelectComponent } from './shared/components/sub-company-select/sub-company-select.component';
import { CountdownTimerComponent } from './shared/components/countdown-timer/countdown-timer.component';
import { DepartmentSelectComponent } from './shared/components/department-select/department-select.component';
import { FileDisplayPreviewDialogComponent } from './shared/components/file-display/file-display-preview-dialog/file-display-preview-dialog.component';
import { FileDisplayComponent } from './shared/components/file-display/file-display.component';
import { FileItemDisplayComponent } from './shared/components/file-display/shared/components/file-item-display/file-item-display.component';
import { FileUploadSelectorComponent } from './shared/components/file-upload-selector/file-upload-selector.component';
import { LearningCampaignSelectComponent } from './shared/components/learning-campaign-select/learning-campaign-select.component';
import { LoadingIndicatorComponent } from './shared/components/loading-indicator/loading-indicator.component';
import { NotificationMessageComponent } from './shared/components/notification-message/notification-message.component';
import { OrderingListComponent } from './shared/components/ordering-list/ordering-list.component';
import { PageHeaderComponent } from './shared/components/page-header/page-header.component';
import { ProcedureCategorySelectComponent } from './shared/components/procedure-category-select/procedure-category-select.component';
import { ProcedureSelectComponent } from './shared/components/procedure-select/procedure-select.component';
import { RichDisplayComponent } from './shared/components/rich-display/rich-display.component';
import { RichEditorComponent } from './shared/components/rich-editor/rich-editor.component';
import { EditorImageUploadService } from './shared/components/rich-editor/shared/services/editor-image-upload.service';
import { SingleUserSelectComponent } from './shared/components/single-user-select/single-user-select.component';
import { SubscriptionUpgradeDialogComponent } from './shared/components/subscription-upgrade/subscription-upgrade-dialog.component';
import { SubscriptionUpgradeService } from './shared/components/subscription-upgrade/subscription-upgrade.service';
import { UserSelectorComponent } from './shared/components/user-selector/user-selector.component';
import { OrderByRankPipe } from './shared/pipes/order-by-rank.pipe';
import { RouteNavigationService } from './shared/routing/route-navigation.service';
import { CompanyProfileService } from './shared/services/company/company-profile.service';
import { CompanyService } from './shared/services/company/company.service';
import { SubCompanyService } from './shared/services/company/sub-company.service';
import { ENVIRONMENT, WINDOW } from './shared/services/injection-tokens';
import { LanguageService } from './shared/services/language/language.service';
import { LearningCampaignsService } from './shared/services/learning-campaigns/learning-campaigns.service';
import { LearningCertificateTemplatesService } from './shared/services/learning-certificates/learning-certificate-templates.service';
import { LearningCertificatesService } from './shared/services/learning-certificates/learning-certificates.service';
import { GlobalErrorHandler } from './shared/services/logger/global-error-handler';
import { LoggerService } from './shared/services/logger/logger.service';
import { OrganizationService } from './shared/services/organization/organization.service';
import { ProcedureCategoriesService } from './shared/services/procedures/procedure-categories.service';
import { ProcedureTestService } from './shared/services/procedures/procedure-test.service';
import { ProceduresService } from './shared/services/procedures/procedures.service';
import { SettingsService } from './shared/services/settings/settings.service';
import { StartupService, startupServiceFactory } from './shared/services/startup/startup.service';
import { UnauthenticatedInterceptor } from './shared/services/startup/unauthenticated.interceptor';
import { TrackingService } from './shared/services/tracking/tracking.service';
import { UserContextService } from './shared/services/user-context/user-context.service';
import { AppState } from './shared/state/app.state';
import { DateProviderService } from './shared/utils/date-provider.service';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { CompleteLearningCampaignHeaderComponent } from './dashboard/learning/complete-learning-campaign/complete-learning-campaign-header/complete-learning-campaign-header.component';
import { NotFoundComponent } from './dashboard/shared/components/not-found/not-found.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    PageHeaderComponent,

    RichEditorComponent,
    RichDisplayComponent,

    AppComponent,
    FooterComponent,
    HeaderComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    HomeComponent,
    LoginComponent,
    LoginSuccessComponent,

    SingleUserSelectComponent,
    AccountSettingsComponent,
    NotificationSettingsComponent,
    LanguageSelectorComponent,
    DashboardComponent,
    CompanyJoinComponent,
    RegisterBoxComponent,
    LoginBoxComponent,
    GoogleButtonComponent,
    SideNavComponent,
    CompanySelectorComponent,
    ProceduresComponent,
    SubscriptionUpgradeDialogComponent,
    ProcedureListComponent,
    EditProcedureComponent,
    ViewProcedureComponent,
    EditProcedureGeneralComponent,
    EditProcedureStepsComponent,
    FileUploadSelectorComponent,
    ButtonComponent,
    FileDisplayComponent,
    FileDisplayPreviewDialogComponent,
    FileItemDisplayComponent,
    OrganizationComponent,
    OrganizationRolesComponent,
    OrganizationUsersComponent,
    OrganizationDepartmentsComponent,
    PermissionRoleSelectComponent,
    CompanyRoleSelectComponent,
    DepartmentSelectComponent,
    SubCompanySelectComponent,
    ProcedureCategorySelectComponent,
    LearningCampaignSelectComponent,
    OrganizationPermissionRolesComponent,
    EditPermissionRoleDialogComponent,
    EditUserDialogComponent,
    AddUserDialogComponent,
    GeneralSettingsComponent,
    EditProcedureTestComponent,
    EditProcedureTestQuestionComponent,
    LearningComponent,
    LearningCampaignsComponent,
    LearningCampaignListComponent,
    EditLearningCampaignComponent,
    DashboardHomeComponent,
    ActiveLearningCampaignsWidgetComponent,
    CompleteLearningCampaignComponent,
    ProcedureDisplayComponent,
    LearningReportsComponent,
    ScoreColorDirective,
    ControlComponent,
    ControlCheckEventsComponent,
    ControlCheckEventsListComponent,
    ControlCheckEventsEditComponent,
    ControlReportsComponent,
    ProcedureSelectComponent,
    RequestDemoComponent,
    CompanySettingsComponent,
    WhiteLabelingSettingsComponent,
    ActivateEmailComponent,
    LoadingIndicatorComponent,
    PasswordSettingsComponent,
    ForgotPasswordComponent,
    RestorePasswordComponent,
    KnowledgeBaseComponent,
    KnowledgeBaseArticleListComponent,
    EditKnowledgeBaseArticleComponent,
    ViewKnowledgeBaseArticleComponent,
    CompanyGeneralSettingsComponent,
    LearningReportViewComponent,
    LearningReportsListComponent,
    LearningReportGenerateDialogComponent,
    ControlReportViewComponent,
    ControlReportsListComponent,
    ControlReportGenerateDialogComponent,
    SsoSettingsComponent,
    SSOLoginComponent,
    ProcedureCategoriesComponent,
    LearningCertificateTemplatesComponent,
    LearningCertificatesComponent,
    EditLearningCertificateTemplateDialogComponent,
    LearningCertificateGenerateDialogComponent,
    NotificationMessageComponent,
    UserSelectorComponent,
    CountdownTimerComponent,
    AssetControlComponent,
    AssetControlReportsComponent,
    AssetControlReportViewComponent,
    AssetControlReportListComponent,
    AssetControlRoomsComponent,
    AssetControlRoomsListComponent,
    AssetControlRoomsEditComponent,
    AssetControlRoomsEventsComponent,
    AssetControlRoomEventEditorComponent,
    AssetTypeSelectComponent,
    AssetControlAssetTypesComponent,
    AssetControlRoomsAssetsComponent,
    CanvasControlsComponent,
    RoomCanvasComponent,
    AssetControlReportGenerateDialogComponent,
    AssetControlResultImageComponent,
    AssetControlRoomCategoriesComponent,
    AssetRoomCategorySelectComponent,
    AssetRoomSelectComponent,
    OrderingListComponent,
    OrderByRankPipe,
    ProcedureStepImageComponent,
    CompleteLearningCampaignHeaderComponent,
    NotFoundComponent,
    CopyProcedureToCompanyDialogComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,

    DragDropModule,
    MatCardModule,
    MatCheckboxModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSelectModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatButtonModule,
    MatDialogModule,
    MatSidenavModule,
    MatTableModule,
    MatPaginatorModule,
    MatListModule,
    MatSortModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatCheckboxModule,

    ChartsModule,

    NgxsModule.forRoot([
      AppState,
    ]),

    QuillModule.forRoot(),

    Angulartics2Module.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),

    CoolStorageModule,
    CoolHttpModule,
    CoolDialogsModule,

    AppRoutingModule,

    FontAwesomeModule,

    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.isServiceWorkerEnabled }),
  ],
  providers: [
    CookieService,

    DatePipe,

    RouteNavigationService,
    TrackingService,
    DateProviderService,

    EditorImageUploadService,

    LoggerService,

    UserContextService,
    SettingsService,
    NotificationSettingsService,
    SubscriptionUpgradeService,

    StartupService,
    LanguageService,

    CompanyService,
    CompanyProfileService,

    ProceduresService,
    ProcedureTestService,
    ProcedureCategoriesService,
    OrganizationService,
    LearningCampaignsService,
    LearningReportService,
    LearningCertificatesService,
    LearningCertificateTemplatesService,
    DashboardService,
    CompleteCampaignService,
    ControlChecksService,
    ControlReportsService,
    CompanySettingsService,
    SubCompanyService,
    KnowledgeBasesService,

    AssetControlReportsService,
    AssetsService,
    AssetRoomService,
    AssetRoomCheckpointsService,

    UnauthenticatedInterceptor,

    RequestDemoService,

    { provide: WINDOW, useValue: window },
    { provide: ENVIRONMENT, useValue: environment },

    { provide: ErrorHandler, useClass: environment.loggingEnabled ? GlobalErrorHandler : ErrorHandler },
    { provide: APP_INITIALIZER, useFactory: startupServiceFactory, deps: [StartupService], multi: true },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    SubscriptionUpgradeDialogComponent,
    FileDisplayPreviewDialogComponent,
    EditPermissionRoleDialogComponent,
    EditUserDialogComponent,
    AddUserDialogComponent,
    LearningReportGenerateDialogComponent,
    ControlReportGenerateDialogComponent,
    AssetControlReportGenerateDialogComponent,
    EditLearningCertificateTemplateDialogComponent,
    LearningCertificateGenerateDialogComponent,
    AssetControlRoomEventEditorComponent,
    CopyProcedureToCompanyDialogComponent,
  ],
  exports: [
    PermissionRoleSelectComponent,
    ScoreColorDirective,
  ],
})
export class AppModule {
  constructor(fontAwesomeLibrary: FaIconLibrary) {
    fontAwesomeLibrary.addIcons(
      faEnvelope,
      faSortDown,
      faGoogle,
      faCubes,
      faBuilding,
      faPlus,
      faChevronUp,
      faChevronDown,
      faChevronLeft,
      faChevronRight,
      faPencilAlt,
      faCogs,
      faFile,
      faTrash,
      faDownload,
      faRedoAlt,
      faTimes,
      faEye,
      faEyeSlash,
      faUser,
      faGraduationCap,
      faHome,
      faBook,
      faChartBar,
      faCheckSquare,
      faClipboardCheck,
      faCog,
      faQuestion,
      faExclamationTriangle,
      faLevelUpAlt,
      faLevelDownAlt,
      faCube,
      faSearchengin,
      faSprayCan,
      faVial,
      faCheck,
      faMinus,
      faBath,
      faBars,
      faCheckCircle,
      faTimesCircle,
      faCopy,
    );
  }
}
