<div class="file-item" [class.big]="size === 'big'" *ngIf="file">
  <div *ngIf="file.type === FileType.Image"
       [style.background-image]="'url(' + file.url + ')'"
       class="image">
  </div>

  <div *ngIf="file.type === FileType.Video" class="m-auto">
    <video controls class="h-100 w-100">
      <source [src]="file.url" type="video/{{ extension }}">
    </video>
  </div>

  <div *ngIf="file.type === FileType.Other"
       class="other cursor-pointer"
       (click)="openOtherFileClick(); $event.stopPropagation()">
    <fa-icon icon="file" [size]="size === 'big' ? '10x' : '4x'"></fa-icon>

    <div class="text-truncate mt-4" [attr.title]="file.name">
      {{ file.name }}
    </div>
  </div>
</div>