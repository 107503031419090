<form class="p-4" #registerForm="ngForm">
  <div>
    <mat-checkbox color="primary" name="termsCheckbox" #termsCheckbox ngModel required>
      {{ 'Login.AgreeTo' | translate }} <a (click)="openTermsInNewWindow()"
                                           target="_blank">{{ 'Login.TermsAndConditions' | translate }}</a>
    </mat-checkbox>
  </div>
  <div>
    <mat-checkbox color="primary" name="emailsCheckbox" #emailsCheckbox ngModel required>
      {{ 'Login.AgreeToEmails' | translate }}
    </mat-checkbox>
  </div>
  <div class="mt-3">
    <div class="px-3">
      <div class="mb-2">
        <mat-form-field class="w-100">
          <input [(ngModel)]="email" [readonly]="isEmailReadonly"
                 name="emailInput" matInput placeholder="{{ 'Common.Email' | translate }}"
                 type="email" #emailModel="ngModel"
                 [pattern]="emailRegexp"
                 required>

          <mat-error *ngIf="emailModel.invalid && triedToSubmit">
            {{ 'Login.ValidEmailAddress' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="mb-3">
        <mat-form-field class="w-100">
          <input name="passwordInput" matInput placeholder="{{ 'Common.Password' | translate }}"
                 type="password" #passwordModel="ngModel"
                 [pattern]="passwordRegexp"
                 ngModel required>

          <mat-error *ngIf="passwordModel.invalid && triedToSubmit">
            {{ 'Login.ValidPassword' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div>
        <div>
          <app-button color="primary"
                      [loading]="inProgress"
                      [disabled]="!termsCheckbox.checked || !emailsCheckbox.checked"
                      (clicked)="registerEmailClickAsync(registerForm)">
            {{ 'Login.RegisterWithEmail' | translate }}
          </app-button>
        </div>

        <div class="mt-2 text-danger" *ngIf="showRegisterError">
          {{ 'Login.FailedToRegister' | translate }}
        </div>
      </div>
    </div>

    <div class="text-muted mt-3">{{ 'Common.OR' | translate }}</div>

    <div class="mt-3">
      <app-google-button text="{{ 'Login.RegisterWithGoogle' | translate }}"
                         [disabled]="!termsCheckbox.checked || !emailsCheckbox.checked"
                         (clicked)="registerGoogleClick()">
      </app-google-button>
    </div>

    <div class="mt-3">
      <button mat-raised-button color="primary"
              [disabled]="!termsCheckbox.checked || !emailsCheckbox.checked"
              (click)="ssoRegistrationClickAsync()">
        {{ 'Login.CompanySSOLogin' | translate }}
      </button>
    </div>
  </div>
</form>