<div class="files-container"
     [class.small]="displayFormat === 'small'"
     [class.centered]="centered"
     *ngIf="(files && files.length) || forceDisplay">
  <div *ngFor="let file of files"
       class="file"
       [class.selected]="selectedFileIds[file.id]">
    <app-file-item-display [file]="file" (click)="onFileClick(file)"></app-file-item-display>

    <div class="actions">
      <div *ngIf="deleteFileButton"
           class="hover-strong cursor-pointer"
           title="{{ 'Common.Remove' | translate }}"
           (click)="deleteFileClick(file); $event.stopPropagation()">
        <fa-icon icon="trash"></fa-icon>
      </div>
    </div>

    <div class="selection-marker">
      <fa-icon icon="check-square" size="2x"></fa-icon>
    </div>
  </div>

  <ng-content></ng-content>
</div>