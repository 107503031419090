<div id="home-page">
  <div id="main" class="section background-overlay-gradient">
    <div class="title mb-2">{{ 'Home.MainTitle' | translate }}</div>

    <div class="subtitle">{{ 'Home.MainSubtitle' | translate }}</div>

    <div class="mt-5 pt-3 mb-5">
      <button mat-raised-button color="primary" class="action-button"
              (click)="onRequestDemoClickAsync()">
        {{ 'Home.ScheduleADemo' | translate }}
      </button>
    </div>
  </div>

  <div class="section px-5">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="content-center mb-4 px-3">
          <div>
            <div class="title">
              {{ 'Home.ManageYourProcedures' | translate }}
            </div>

            <div class="my-3 px-3">
              {{ 'Home.ManageYourProceduresDescription' | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <img class="img-fluid mat-elevation-z4" src="assets/images/home/organize.png">
      </div>
    </div>
  </div>

  <div class="section px-5" style="background-color: #f5f5f5;">
    <div class="row">
      <div class="col-12 col-md-6">
        <img class="img-fluid mat-elevation-z4" src="assets/images/home/learn.png">
      </div>
      <div class="col-12 col-md-6">
        <div class="content-center my-4 px-3">
          <div>
            <div class="title">
              {{ 'Home.LearnTitle' | translate }}
            </div>

            <div class="mt-3 px-3">
              {{ 'Home.LearnDescription' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section px-5">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="content-center mb-4 px-3">
          <div>
            <div class="title">
              {{ 'Home.ControlTitle' | translate }}
            </div>

            <div class="my-3 px-3">
              {{ 'Home.ControlDescription' | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <img class="img-fluid mat-elevation-z4" src="assets/images/home/control.png">
      </div>
    </div>
  </div>

  <div class="section px-5" style="background-color: #f5f5f5;">
    <div class="row">
      <div class="col-12 col-md-6">
        <img class="img-fluid mat-elevation-z4" src="assets/images/home/asset-control.png">
      </div>
      <div class="col-12 col-md-6">
        <div class="content-center my-4 px-3">
          <div>
            <div class="title">
              {{ 'Home.AssetControlTitle' | translate }}
            </div>

            <div class="mt-3 px-3">
              {{ 'Home.AssetControlDescription' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="say-hello" class="section">
    <div class="title">{{ 'Home.SayHello' | translate }}</div>

    <div class="mt-4">
      <button mat-raised-button color="primary" class="action-button"
              (click)="onRequestDemoClickAsync()">
        {{ 'Home.ScheduleADemo' | translate }}
      </button>
    </div>
  </div>
</div>