import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LimitedKnowledgeBaseArticleDTO } from '../../../../../../server/src/dto/knowledge-base-article.dto';
import { FeaturePermissions } from '../../../../../../server/src/models/feature-permissions.enum';
import { RouteNavigationService } from '../../../shared/routing/route-navigation.service';
import { LanguageService } from '../../../shared/services/language/language.service';
import { UserContextService } from '../../../shared/services/user-context/user-context.service';
import { KnowledgeBasesService } from '../shared/services/knowledge-bases.service';

@Component({
  selector: 'app-knowledge-base-article-list',
  templateUrl: './knowledge-base-article-list.component.html',
  styleUrls: ['./knowledge-base-article-list.component.scss'],
})
export class KnowledgeBaseArticleListComponent implements OnInit {

  constructor(private _userContextService: UserContextService,
              private _knowledgeBaseService: KnowledgeBasesService,
              private _languageService: LanguageService,
              private _routeNavigationService: RouteNavigationService) {

  }

  public displayedColumns = ['name'];
  public isLoading: boolean;

  public canAddArticle: boolean;

  public dataSource: MatTableDataSource<LimitedKnowledgeBaseArticleDTO>;

  @ViewChild(MatSort, { static: true })
  public sort: MatSort;

  public async ngOnInit() {
    this.canAddArticle = this._userContextService.user.featurePermissions.includes(FeaturePermissions.EditKnowledgeBaseArticles);

    if (this.canAddArticle) {
      this.displayedColumns.push('actions');
    }

    try {
      this.isLoading = true;

      await this._loadDataAsync();
    } finally {
      this.isLoading = false;
    }
  }

  public async addNewArticleAsync() {
    const createdArticle = await this._knowledgeBaseService.createArticleAsync(
      await this._languageService.getTranslationAsync('Dashboard.KnowledgeBase.NewArticleName'),
      await this._languageService.getTranslationAsync('Dashboard.KnowledgeBase.NewArticleDescription'),
    );

    await this.editArticleAsync(createdArticle);
  }

  public async viewArticleAsync(article: LimitedKnowledgeBaseArticleDTO) {
    await this._routeNavigationService.goToViewArticleAsync(article.id);
  }

  public async editArticleAsync(article: LimitedKnowledgeBaseArticleDTO) {
    await this._routeNavigationService.goToEditArticleAsync(article.id);
  }

  public filterArticles(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  private async _loadDataAsync() {
    const articles = await this._knowledgeBaseService.getLimitedKnowledgeBaseArticlesAsync();

    this.dataSource = new MatTableDataSource(articles);
    this.dataSource.sort = this.sort;
  }
}
