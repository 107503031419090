import { CoolHttp } from '@angular-cool/http';
import { Injectable } from '@angular/core';
import { LimitedLearningCampaignWithResultDTO } from '../../../../../../../server/src/dto/learning-campaign.dto';

@Injectable()
export class DashboardService {
  constructor(private _http: CoolHttp) {

  }

  public async getMyActiveLearningCampaignsAsync(includeCompleted: boolean): Promise<LimitedLearningCampaignWithResultDTO[]> {
    return await this._http.getAsync('api/companies/active/learning/campaigns/my/active', {
      params: {
        includeCompleted: includeCompleted ? '1' : '0',
      },
    });
  }
}