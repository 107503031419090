import { Component, Input, OnInit } from '@angular/core';
import { ProcedureDTO } from '../../../../../../../server/src/dto/procedure.dto';

@Component({
  selector: 'app-procedure-display',
  templateUrl: './procedure-display.component.html',
  styleUrls: ['./procedure-display.component.scss'],
})
export class ProcedureDisplayComponent implements OnInit {

  constructor() {
  }

  @Input()
  public procedure: ProcedureDTO;

  @Input()
  public hideSteps: boolean;

  @Input()
  public hideName: boolean;

  ngOnInit() {
  }

}
