<div>
  <app-page-header pageTitle="{{ 'Dashboard.LearningReports.Title' | translate }} - {{ report?.filters.learningCampaign.name }}">
  </app-page-header>

  <mat-card *ngIf="report" class="mat-elevation-z4 mt-4">
    <mat-card-header>
      <mat-card-title>
        <div>{{ report.filters.learningCampaign.name }}</div>
        <div class="d-flex flex-row second-row">
          <div *ngIf="report.filters.departments && report.filters.departments.length">
            {{ getDepartmentNamesFromFilter() }}
          </div>
          <div *ngIf="report.filters.startDate || report.filters.endDate" class="ml-4 d-flex flex-row">
            <div *ngIf="report.filters.startDate">{{ report.filters.startDate | date }}</div>
            <div class="mx-2">&nbsp;-&nbsp;</div>
            <div *ngIf="report.filters.endDate">{{ report.filters.endDate | date }}</div>
          </div>
        </div>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="p-3">
      <div class="row text-center">
        <div class="col-6">
          <h6 class="mb-3">{{ 'Dashboard.LearningReports.CompletionRate' | translate }}</h6>
          <div>
            <canvas baseChart
                    [data]="completionRateChartData"
                    [labels]="completionRateChartLabels"
                    [colors]="completionRateChartColors"
                    [options]="{
                      responsive: true,
                      maintainAspectRatio: false,
                      legend: {
                        position: 'bottom'
                      }
                    }"
                    chartType="doughnut">
            </canvas>
          </div>
        </div>

        <div class="col-6">
          <h6>{{ 'Dashboard.LearningReports.AverageScore' | translate }}:</h6>
          <h1 class="ml-3 pt-5" [scoreColor]="report.averageSuccessRate">
            {{ report.averageSuccessRate | number }}%
          </h1>
        </div>
      </div>

      <div *ngIf="report.procedureSuccessRates?.length" class="mt-5">
        <h5 class="mb-3">{{ 'Common.Procedures' | translate }}</h5>

        <div class="row">
          <div class="col-6">
            <div class="procedures-table-container mat-elevation-z4">
              <table mat-table [dataSource]="report.procedureSuccessRates" class="w-100">
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>{{ 'Common.Name' | translate }}</th>
                  <td mat-cell *matCellDef="let procedureResult" class="text-truncate">
                    {{ procedureResult.name }}
                  </td>
                </ng-container>

                <!-- Success Rate Column -->
                <ng-container matColumnDef="successRate">
                  <th mat-header-cell *matHeaderCellDef>{{ 'Common.Score' | translate }}</th>
                  <td mat-cell *matCellDef="let procedureResult">
                    <div [scoreColor]="procedureResult.successRate">
                      {{ procedureResult.successRate | number }}%
                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="proceduresDisplayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: proceduresDisplayedColumns;"></tr>
              </table>
            </div>
          </div>

          <div class="col-6">
            <canvas baseChart
                    [datasets]="procedureSuccessRateChartData"
                    [labels]="procedureSuccessRateChartLabels"
                    [options]="procedureSuccessRateChartOptions"
                    [legend]="false"
                    chartType="bar">
            </canvas>
          </div>
        </div>
      </div>

      <div *ngIf="report.companyUserResults?.length" class="mt-5">
        <div class="d-flex flex-row">
          <h5 class="flex-grow-1">{{ 'Dashboard.LearningReports.UserResults' | translate }}</h5>

          <div>
            <mat-form-field>
              <input matInput (keyup)="filterCompanyUserResults($event.target.value)"
                     placeholder="{{ 'Common.Filter' | translate }}">
            </mat-form-field>
          </div>
        </div>

        <div class="user-table-container mat-elevation-z4">
          <table mat-table [dataSource]="companyUserResultsDataSource" class="w-100">
            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>{{ 'Common.Name' | translate }}</th>
              <td mat-cell *matCellDef="let res">{{ res.name }}</td>
            </ng-container>

            <!-- Completion Date Column -->
            <ng-container matColumnDef="completionDate">
              <th mat-header-cell *matHeaderCellDef>{{ 'Dashboard.LearningReports.CompletionDate' | translate }}</th>
              <td mat-cell *matCellDef="let res">{{ res.completionDate | date: 'shortDate' }}</td>
            </ng-container>

            <!-- Success rate Column -->
            <ng-container matColumnDef="successRate">
              <th mat-header-cell *matHeaderCellDef>{{ 'Common.Result' | translate }}</th>
              <td mat-cell *matCellDef="let res">
                <div [scoreColor]="res.successRate">
                  {{ res.successRate | number }}%
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="['name', 'completionDate', 'successRate']; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: ['name', 'completionDate', 'successRate'];"></tr>
          </table>
        </div>
      </div>

      <div *ngIf="report.companyUsersWithoutResult?.length" class="mt-5">
        <div class="d-flex flex-row">
          <h5 class="flex-grow-1">{{ 'Dashboard.LearningReports.UsersWithoutResults' | translate }}</h5>

          <div>
            <mat-form-field>
              <input matInput (keyup)="filterCompanyUsersWithoutResult($event.target.value)"
                     placeholder="{{ 'Common.Filter' | translate }}">
            </mat-form-field>
          </div>
        </div>

        <div class="user-table-container mat-elevation-z4">
          <table mat-table [dataSource]="companyUsersWithoutResultDataSource" class="w-100">
            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>{{ 'Common.Name' | translate }}</th>
              <td mat-cell *matCellDef="let res">{{ res.name }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="['name']; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: ['name'];"></tr>
          </table>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <div class="text-center" *ngIf="isLoading">
    <app-loading-indicator></app-loading-indicator>
  </div>

  <mat-card *ngIf="hasError">
    <h6 class="color-warn text-center">{{ 'Common.FailedToGenerateReport' | translate }}</h6>
  </mat-card>
</div>