import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileDTO } from '../../../../../../../server/src/dto/file.dto';
import { FileDisplayPreviewDialogConfig } from './file-display-preview-dialog-config.interface';

@Component({
  selector: 'app-file-display-preview-dialog',
  templateUrl: './file-display-preview-dialog.component.html',
  styleUrls: ['./file-display-preview-dialog.component.scss'],
})
export class FileDisplayPreviewDialogComponent implements OnInit {
  private _currentFileItemIndex: number;

  constructor(private _dialog: MatDialogRef<FileDisplayPreviewDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private _dialogConfig: FileDisplayPreviewDialogConfig) {
  }

  public files: FileDTO[];

  public currentFileItem: FileDTO;

  public async ngOnInit() {
    this.files = this._dialogConfig.files;

    this._currentFileItemIndex = this.files.indexOf(this._dialogConfig.startingFile);

    if (this._currentFileItemIndex < 0) {
      this._currentFileItemIndex = 0;
    }

    this.currentFileItem = this.files[this._currentFileItemIndex];
  }

  public nextFileItemClick() {
    this._currentFileItemIndex++;

    if (this._currentFileItemIndex > this.files.length - 1) {
      this._currentFileItemIndex = 0;
    }

    this.currentFileItem = this.files[this._currentFileItemIndex];
  }

  public previousFileItemClick() {
    this._currentFileItemIndex--;

    if (this._currentFileItemIndex < 0) {
      this._currentFileItemIndex = this.files.length - 1;
    }

    this.currentFileItem = this.files[this._currentFileItemIndex];
  }

  public closeClick() {
    this._dialog.close();
  }
}
