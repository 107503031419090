import { CoolHttp } from '@angular-cool/http';
import { Injectable } from '@angular/core';
import { UserSettingsDTO } from '../../../../../../server/src/dto/user-settings.dto';

@Injectable()
export class SettingsService {
  constructor(private _http: CoolHttp) {
  }

  public async getUserSettingsAsync(): Promise<UserSettingsDTO> {
    return await this._http.getAsync('api/settings');
  }

  public async setUserSettingsAsync(settings: Partial<UserSettingsDTO>) {
    return await this._http.postAsync('api/settings', settings);
  }

  public async getServerTimeAsync(): Promise<Date> {
    const response = await this._http.getAsync('api/settings/time');

    return new Date(response.time);
  }
}
