import { Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Language, LANGUAGE_NAMES } from '../../../../../server/src/models/language.enum';
import { LanguageService } from '../../shared/services/language/language.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit, OnDestroy {
  constructor(private _languageService: LanguageService) {
  }

  public currentLanguage: string;

  public languages: { key: Language; name: string; }[] = Object.keys(LANGUAGE_NAMES).map((langKey: Language) => {
    return {
      key: langKey,
      name: LANGUAGE_NAMES[langKey],
    };
  });

  public ngOnInit() {
    this._languageService.getCurrentLanguageObservable()
      .pipe(untilDestroyed(this))
      .subscribe(language => {
        this.currentLanguage = LANGUAGE_NAMES[language] || LANGUAGE_NAMES[Language.English];
      });
  }

  public ngOnDestroy(): void {
  }

  public async selectLanguageAsync(lang: Language) {
    await this._languageService.setLanguageAsync(lang);
  }
}
