<div *ngIf="initialLoaded">
  <div class="mb-4">
    <app-complete-learning-campaign-header
        [showCampaignName]="true"
        [showStepProgress]="true"
        [showTimeProgress]="true"
        [learningCampaign]="learningCampaign"
        [session]="session"
        [isStartLoading]="isStartLoading"
        [showStartButton]="showStartButton"
        [isNextLoading]="isNextLoading"
        [showNextButton]="showNextButton"
        [isPreviousLoading]="isPreviousLoading"
        [showPreviousButton]="showPreviousButton"
        [isSubmitLoading]="isSubmitLoading"
        [showSubmitButton]="showSubmitButton"
        (nextClick)="nextStepAsync()"
        (previousClick)="previousStepAsync()"
        (startClick)="startCompletingCampaignAsync()"
        (submitClick)="submitCampaignAsync()"
        (onSessionTimeout)="onSessionTimeout()">
    </app-complete-learning-campaign-header>
  </div>

  <!-- Start -->
  <div *ngIf="!session || session.currentStep?.type === LearningCampaignCompletionSessionStepType.Start">
    <mat-card class="mat-elevation-z4">
      <mat-card-content>
        <div>
          <app-rich-display [contentHTML]="learningCampaign.description"></app-rich-display>
        </div>
        <div class="mt-2">
          {{ 'Dashboard.LearningCampaigns.MinutesRequiredToComplete' | translate }}:
          <span class="ml-3">
          {{ learningCampaign.minutesRequiredToComplete }}
            {{ 'Common.Time.Minutes' | translate }}
        </span>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- Procedure Content -->
  <div *ngIf="session && session.currentStep.type === LearningCampaignCompletionSessionStepType.ProcedureContent">
    <app-procedure-display [procedure]="currentProcedure" [hideSteps]="!currentProcedure?.test?.showSteps">
    </app-procedure-display>
  </div>

  <!-- Procedure Videos -->
  <div *ngIf="session && session.currentStep.type === LearningCampaignCompletionSessionStepType.ProcedureVideos">
    <mat-card class="mat-elevation-z4">
      <mat-card-content>
        <div class="p-2">
          <h4>{{ currentProcedure?.name }}</h4>

          <div class="text-center mt-4">
            <app-file-item-display size="big" [file]="currentVideo"></app-file-item-display>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- Procedure Questions -->
  <div *ngIf="session && session.currentStep.type === LearningCampaignCompletionSessionStepType.ProcedureTest">
    <mat-card class="mat-elevation-z4">
      <mat-card-content>
        <div class="p-2">
          <h4 class="mb-3">{{ currentProcedure.name }}</h4>
          <h5 class="mb-2">{{ currentQuestion.questionText }}</h5>

          <div *ngIf="currentQuestion.type === ProcedureTestQuestionType.SingleChoice">
            <mat-radio-group [(ngModel)]="currentlySelectedAnswerId" (ngModelChange)="onSingleChoiceAnswerClick()"
                             name="question-single">
              <mat-list>
                <mat-list-item *ngFor="let answer of currentQuestion.answers">
                  <div matLine>
                    <mat-radio-button [value]="answer.id">
                      {{ answer.answerText }}
                    </mat-radio-button>
                  </div>
                </mat-list-item>
              </mat-list>
            </mat-radio-group>
          </div>

          <div *ngIf="currentQuestion.type === ProcedureTestQuestionType.MultipleChoice">
            <mat-list>
              <mat-list-item *ngFor="let answer of currentQuestion.answers">
                <div matLine>
                  <mat-checkbox [checked]="currentlySelectedAnswerIds.includes(answer.id)"
                                (change)="onMultipleChoiceAnswerClick(answer)">
                    {{ answer.answerText }}
                  </mat-checkbox>
                </div>
              </mat-list-item>
            </mat-list>
          </div>

          <div *ngIf="currentQuestion.type === ProcedureTestQuestionType.Ordering">
            <app-ordering-list [(ngModel)]="currentQuestion.answers"
                               name="ordering-question-{{ currentQuestion.id }}">
              <ng-template let-item>
                <div class="d-flex flex-row p-2">
                  <div class="d-flex align-items-center cursor-grab">
                    <fa-icon icon="bars"></fa-icon>
                  </div>

                  <div class="ml-3 flex-grow-1">
                    {{ item.answerText }}
                  </div>
                </div>
              </ng-template>
            </app-ordering-list>
          </div>

          <div *ngIf="showAtLeastOneAnswerNeeded" class="mt-3 color-warn">
            {{ 'Dashboard.LearningCampaigns.AtLeastOneAnswerIsRequired' | translate }}
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- Before Results -->
  <div *ngIf="session && session.currentStep.type === LearningCampaignCompletionSessionStepType.BeforeResults">
    <mat-card class="mat-elevation-z4">
      <mat-card-content class="text-center">
        <h5>{{ 'Dashboard.LearningCampaigns.BeforeResultsTitle' | translate }}</h5>
        <div class="mt-3">
          {{ 'Dashboard.LearningCampaigns.BeforeResultsDescription' | translate }}
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- Results -->
  <div *ngIf="session && session.currentStep.type === LearningCampaignCompletionSessionStepType.Results">
    <mat-card class="mat-elevation-z4">
      <mat-card-content class="text-center">
        <h5 *ngIf="!session.results.finishedDescription">{{ 'Dashboard.LearningCampaigns.ResultsTitle' | translate }}</h5>

        <div *ngIf="session.results.finishedDescription" class="mt-3">
          <app-rich-display [contentHTML]="session.results.finishedDescription"></app-rich-display>
        </div>

        <div *ngIf="!session.results.isSuccessful" class="mt-3 color-warn">
          <h6 class="m-0">{{ 'Dashboard.LearningCampaigns.UnsuccessfulRetakeNeeded' | translate }}</h6>

          <div class="mt-2 text-center">
            <button mat-raised-button color="primary" (click)="restartCampaignAsync()">
              {{ 'Dashboard.LearningCampaigns.StartCampaignAgain' | translate }}
            </button>
          </div>
        </div>

        <h5 class="mt-3">
          {{ 'Common.Score' | translate }}:
        </h5>
        <h4 class="mt-2" [scoreColor]="session.results.successRate">
          {{ session.results.successRate }}%
        </h4>
      </mat-card-content>
    </mat-card>

    <div class="mt-4" *ngIf="failedProcedureQuestions.length">
      <mat-card class="mat-elevation-z4">
        <mat-card-content>
          <h5 class="m-0 text-center">{{ 'Dashboard.LearningCampaigns.FailedQuestionsTitle' | translate }}</h5>
        </mat-card-content>
      </mat-card>

      <mat-card class="mat-elevation-z4 mt-3" *ngFor="let procedure of failedProcedureQuestions">
        <mat-card-content>
          <div class="d-flex mb-3">
            <h6 class="flex-grow-1">{{ procedure.name }}</h6>
            <div class="ml-3">
              <button mat-raised-button
                      (click)="learnMoreAboutProcedureAsync(procedure.id)"
                      color="primary">
                <fa-icon icon="book" class="mr-2"></fa-icon>
                {{ 'Common.LearnMore' | translate }}
              </button>
            </div>
          </div>

          <div *ngFor="let question of procedure.failingQuestions; let i = index" class="mb-3">
            <mat-divider *ngIf="i"></mat-divider>

            <div [class.pt-4]="i">
              {{ question.questionText }}
            </div>
            <div class="mt-2">
              <div *ngIf="question.type === ProcedureTestQuestionType.SingleChoice">
                <mat-radio-group [value]="getSelectedAnswerId(question.answers)">
                  <mat-list>
                    <mat-list-item *ngFor="let answer of question.answers">
                      <div matLine>
                        <mat-radio-button [value]="answer.id" [disabled]="true">
                          <span [class.bad-answer]="answer.isSelected && !answer.isCorrect"
                                [class.good-answer]="answer.isCorrect">
                            {{ answer.answerText }}
                          </span>
                        </mat-radio-button>
                      </div>
                    </mat-list-item>
                  </mat-list>
                </mat-radio-group>
              </div>

              <div *ngIf="question.type === ProcedureTestQuestionType.MultipleChoice">
                <mat-list>
                  <mat-list-item *ngFor="let answer of question.answers">
                    <div matLine>
                      <mat-checkbox [checked]="answer.isSelected" [disabled]="true">
                        <span [class.bad-answer]="answer.isSelected && !answer.isCorrect"
                              [class.good-answer]="answer.isCorrect">
                          {{ answer.answerText }}
                        </span>
                      </mat-checkbox>
                    </div>
                  </mat-list-item>
                </mat-list>
              </div>

              <div *ngIf="question.type === ProcedureTestQuestionType.Ordering">
                <div class="row">
                  <div class="col-5">
                    <ul>
                      <li *ngFor="let answer of question.answers | orderByRank:'selectedRank'; let i = index"
                          class=""
                          [class.good-answer]="answer.isCorrect"
                          [class.bad-answer]="!answer.isCorrect">
                        <span>{{ i + 1 }}.</span>
                        <span class="ml-2 text-break">
                          {{ answer.answerText }}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div class="col-1 d-flex align-items-center justify-content-center">
                    <fa-icon icon="chevron-right"></fa-icon>
                  </div>
                  <div class="col-6">
                    <ul>
                      <li *ngFor="let answer of question.answers | orderByRank: 'correctRank'; let i = index"
                          class="good-answer">
                        <span>{{ i + 1 }}.</span>
                        <span class="ml-2 text-break">
                          {{ answer.answerText }}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="mt-4">
    <app-complete-learning-campaign-header
        [weakBackground]="true"
        [learningCampaign]="learningCampaign"
        [session]="session"
        [isStartLoading]="isStartLoading"
        [showStartButton]="showStartButton"
        [isNextLoading]="isNextLoading"
        [showNextButton]="showNextButton"
        [isPreviousLoading]="isPreviousLoading"
        [showPreviousButton]="showPreviousButton"
        [isSubmitLoading]="isSubmitLoading"
        [showSubmitButton]="showSubmitButton"
        (nextClick)="nextStepAsync()"
        (previousClick)="previousStepAsync()"
        (startClick)="startCompletingCampaignAsync()"
        (submitClick)="submitCampaignAsync()">
    </app-complete-learning-campaign-header>
  </div>
</div>