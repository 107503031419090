<nav class="navbar navbar-light">
  <div>
    <div id="navigation-bar-toggle" *ngIf="isOnDashboard" (click)="toggleNavigationBar()">
      <fa-icon icon="bars"></fa-icon>
    </div>

    <div class="navbar-brand mb-0 h1">
      <a href="/">
        <img class="img-fluid" [src]="user && user.customLogoUrl ? user.customLogoUrl : 'assets/images/logo-text.png'">
      </a>
    </div>
  </div>

  <div>
    <div *ngIf="isInitialized" class="d-flex flex-row">
      <div *ngIf="isUserLoggedIn">
        <div class="mr-5 hover-strong cursor-pointer" (click)="goToDashboardClickAsync()">
          {{ 'Header.Dashboard' | translate }}
        </div>
      </div>

      <div class="mr-4 cursor-pointer" [class.pt-1]="!isUserLoggedIn">
        <app-language-selector></app-language-selector>
      </div>

      <button *ngIf="!isUserLoggedIn" mat-raised-button color="primary" (click)="loginClickAsync()">
        {{ 'Common.Login' | translate }}
      </button>

      <div *ngIf="isUserLoggedIn">
        <div [matMenuTriggerFor]="accountMenu" class="d-flex flex-row cursor-pointer">
          <span>{{ user.name || user.email }}</span>

          <fa-icon icon="sort-down" class="ml-2" style="position: relative; top: -2px;"></fa-icon>
        </div>

        <mat-menu #accountMenu="matMenu">
          <button mat-menu-item (click)="settingsClickAsync()">
            {{ 'Header.AccountMenu.Settings' | translate }}
          </button>
          <button mat-menu-item (click)="logoutClick()">
            {{ 'Header.AccountMenu.Logout' | translate }}
          </button>
        </mat-menu>
      </div>
    </div>

    <app-loading-indicator *ngIf="!isInitialized"></app-loading-indicator>
  </div>
</nav>