import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as jwtDecode from 'jwt-decode';
import { JoinCompanyWithEmail, JWTTypes } from '../../../../../server/src/models/jwt-types.enum';
import { EMAIL_REGEXP, PASSWORD_REGEXP } from '../../../../../server/src/utils/regex.validators';
import { RouteNavigationService } from '../../shared/routing/route-navigation.service';
import { UserContextService } from '../../shared/services/user-context/user-context.service';

@Component({
  selector: 'app-register-box',
  templateUrl: './register-box.component.html',
  styleUrls: ['./register-box.component.scss'],
})
export class RegisterBoxComponent implements OnInit {
  private _joinToken: string;

  constructor(private _userContextService: UserContextService,
              private _activatedRoute: ActivatedRoute,
              private _routeNavigationService: RouteNavigationService,
              private _changeDetector: ChangeDetectorRef) {
  }

  public email: string;
  public isEmailReadonly: boolean;

  public emailRegexp = EMAIL_REGEXP;
  public passwordRegexp = PASSWORD_REGEXP;

  public triedToSubmit: boolean;
  public showRegisterError: boolean;

  public inProgress: boolean;

  @ViewChild('emailInput', { static: true })
  public emailInput: ElementRef<HTMLInputElement>;

  public ngOnInit() {
    this._tryPrefillBasedOnJoinToken();
  }

  public registerGoogleClick() {
    this._userContextService.goToGoogleLogin();
  }

  public async ssoRegistrationClickAsync() {
    await this._routeNavigationService.goToSSOLoginAsync();
  }

  public async registerEmailClickAsync(registerForm: NgForm) {
    this.triedToSubmit = true;

    if (!registerForm.valid) {
      return;
    }

    const email = registerForm.controls.emailInput.value;
    const password = registerForm.controls.passwordInput.value;

    this.inProgress = true;

    try {
      await this._userContextService.registerWithEmailAsync(email, password, this._joinToken);
      await this._userContextService.loginWithEmailAsync(email, password);

      await this._routeNavigationService.goToLoginSuccessAsync();
    } catch {
      this.showRegisterError = true;

    }

    this.inProgress = false;

    this._changeDetector.markForCheck();
  }

  public openTermsInNewWindow() {
    this._routeNavigationService.openTermsInNewWindow();
  }

  private _tryPrefillBasedOnJoinToken() {
    try {
      this._joinToken = this._activatedRoute.snapshot.queryParams['joinToken'];

      if (!this._joinToken) {
        return;
      }

      const tokenData = <JoinCompanyWithEmail>jwtDecode(this._joinToken);

      if (tokenData.type !== JWTTypes.JoinCompanyWithEmail) {
        return;
      }

      this.email = tokenData.email;
      this.isEmailReadonly = true;
    } catch (e) {
      console.log(e);
    }
  }
}
