import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { RouteNavigationService } from '../../shared/routing/route-navigation.service';
import { UserContextService } from '../../shared/services/user-context/user-context.service';

@Component({
  selector: 'app-restore-password',
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.scss'],
})
export class RestorePasswordComponent implements OnInit {

  constructor(private _activatedRoute: ActivatedRoute,
              private _userContextService: UserContextService,
              private _routeNavigationService: RouteNavigationService) {
  }

  public inProgress: boolean;
  public showError: boolean;

  public password: string;
  public token: string;

  public async ngOnInit() {
    this._activatedRoute.queryParams
      .pipe(untilDestroyed(this))
      .subscribe(async queryParams => {
        this.token = queryParams.token;

        if (!this.token) {
          this.showError = true;

          return;
        }
      });
  }

  public ngOnDestroy(): void {
  }

  public async restorePasswordAsync() {
    try {
      this.inProgress = true;

      await this._userContextService.restorePasswordAsync(this.token, this.password);

      await this._routeNavigationService.goToLoginAsync();
    } catch {
      this.showError = true;
    } finally {
      this.inProgress = false;
    }
  }
}
