import { Environment } from './environment.interface';

export const environment: Environment = {
  production: true,

  productionAppUrl: 'https://www.proceduremanager.com',
  developmentKeyHash: '$2a$08$AiyrNS9ETPMWVXm7B3xAG.tIYoy1ZuP0CIf.Qm/xtKvsksc65qvsi',

  appUrl: 'https://www.proceduremanager.com',
  backendUrl: 'https://api.proceduremanager.com',
  helpUrl: 'https://help.proceduremanager.com',
  contactEmail: 'info@proceduremanager.com',

  loggingEnabled: true,
  loggingUrl: 'https://f6ccb5bc5f8641b7b1ed24a7f3bbc1a7@sentry.io/1488384',

  isServiceWorkerEnabled: true,

  analytics: {
    trackingEnabled: true,

    googleAnalyticsAccountId: 'UA-142546788-1',
  },

  featureSwitches: {

  },
};
