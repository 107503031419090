<img *ngIf="result === AssetRoomCheckpointEventResult.Bad"
     [class.big]="size === 'big'"
     [attr.title]="'Dashboard.AssetControlReports.ResultTypes.' + AssetRoomCheckpointEventResult.Bad | translate"
     src="assets/images/icons/emoticons/sad.svg">

<img *ngIf="result === AssetRoomCheckpointEventResult.Neutral"
     [class.big]="size === 'big'"
     [attr.title]="'Dashboard.AssetControlReports.ResultTypes.' + AssetRoomCheckpointEventResult.Neutral | translate"
     src="assets/images/icons/emoticons/neutral.svg">

<img *ngIf="result === AssetRoomCheckpointEventResult.Good"
     [class.big]="size === 'big'"
     [attr.title]="'Dashboard.AssetControlReports.ResultTypes.' + AssetRoomCheckpointEventResult.Good | translate"
     src="assets/images/icons/emoticons/smile.svg">

<img *ngIf="result === AssetRoomCheckpointEventResult.Great"
     [class.big]="size === 'big'"
     [attr.title]="'Dashboard.AssetControlReports.ResultTypes.' + AssetRoomCheckpointEventResult.Great | translate"
     src="assets/images/icons/emoticons/smiling.svg">
