<app-page-header pageTitle="{{ 'Dashboard.LearningCampaigns.EditLearningCampaign' | translate }} - {{ learningCampaign?.name }}">
</app-page-header>

<div *ngIf="learningCampaign">
  <mat-card class="mat-elevation-z4">
    <mat-card-header>
      <mat-card-title>{{ learningCampaign.name }}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="px-3 pt-2">
      <div>
        <form #campaignEditForm="ngForm">
          <div>
            <mat-form-field class="w-100">
              <input matInput placeholder="{{ 'Common.Name' | translate }}" name="name"
                     [(ngModel)]="learningCampaign.name"
                     required>
            </mat-form-field>
          </div>
          <div class="mt-2">
            <mat-form-field>
              <input matInput [matDatepicker]="startDatePicker"
                     name="start-date"
                     [(ngModel)]="learningCampaign.startDate"
                     (click)="startDatePicker.open()"
                     placeholder="{{ 'Common.StartDate' | translate }}">
              <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #startDatePicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="ml-3">
              <input matInput [matDatepicker]="endDatePicker"
                     name="end-date"
                     [(ngModel)]="learningCampaign.endDate"
                     (click)="endDatePicker.open()"
                     placeholder="{{ 'Common.EndDate' | translate }}">
              <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #endDatePicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="mt-2">
            <mat-form-field>
              <input matInput name="minutes-to-complete"
                     placeholder="{{ 'Dashboard.LearningCampaigns.MinutesRequiredToComplete' | translate }}"
                     [(ngModel)]="learningCampaign.minutesRequiredToComplete"
                     type="number"
                     required>
              <span matSuffix>{{ 'Common.Time.Minutes' | translate }}</span>
            </mat-form-field>

            <mat-checkbox name="enforce-minutes"
                          color="primary"
                          class="ml-4"
                          [(ngModel)]="learningCampaign.enforceMinutesRequiredToComplete">
              {{ 'Dashboard.LearningCampaigns.EnforceMinutesRequiredToComplete' | translate }}
            </mat-checkbox>
          </div>
          <div class="mt-3">
            <app-rich-editor placeholder="{{ 'Common.Message' | translate }}"
                             name="description"
                             [(ngModel)]="learningCampaign.description"
                             required>
            </app-rich-editor>
          </div>
          <div class="mt-3">
            <app-rich-editor
                placeholder="{{ 'Dashboard.LearningCampaigns.DescriptionToShowAfterCompletion' | translate }}"
                name="finished-description"
                [(ngModel)]="learningCampaign.finishedDescription">
            </app-rich-editor>
          </div>
          <div class="mt-5">
            <mat-slide-toggle name="is-published"
                              color="primary"
                              (ngModelChange)="campaignEditForm.form.markAsTouched()"
                              [(ngModel)]="learningCampaign.isPublished"
                              #publishToggle>
              <fa-icon [icon]="publishToggle.checked ? 'eye' : 'eye-slash'" class="mr-1"></fa-icon>

              {{ (publishToggle.checked ? 'Dashboard.LearningCampaigns.CampaignIsPublished' : 'Dashboard.LearningCampaigns.CampaignIsDraft') | translate }}
            </mat-slide-toggle>
          </div>
          <div class="mt-3">
            <mat-slide-toggle name="send-email-on-start"
                              color="primary"
                              (ngModelChange)="campaignEditForm.form.markAsTouched()"
                              [(ngModel)]="learningCampaign.sendEmailOnStart">
              {{ 'Dashboard.LearningCampaigns.SendEmailWhenCampaignStarts' | translate }}
            </mat-slide-toggle>
          </div>
          <div class="mt-3">
            <mat-slide-toggle name="send-email-before-end"
                              color="primary"
                              (ngModelChange)="campaignEditForm.form.markAsTouched()"
                              [(ngModel)]="learningCampaign.sendEmailOnEndingSoon">
              {{ 'Dashboard.LearningCampaigns.SendEmailBeforeCampaignEnd' | translate }}
            </mat-slide-toggle>
          </div>
          <div class="mt-3">
            <mat-slide-toggle name="enforce-percentage"
                              color="primary"
                              (ngModelChange)="campaignEditForm.form.markAsTouched()"
                              [(ngModel)]="enforceSuccessPercentage">
              {{ (enforceSuccessPercentage ? 'Dashboard.LearningCampaigns.EnforceSuccessPercentage' : 'Dashboard.LearningCampaigns.NotEnforceSuccessPercentage') | translate }}
            </mat-slide-toggle>

            <div *ngIf="enforceSuccessPercentage" class="d-inline-block ml-3">
              <mat-form-field>
                <input matInput name="force-retake-under"
                       placeholder="{{ 'Dashboard.LearningCampaigns.EnforceSuccessPercentage' | translate }}"
                       [(ngModel)]="learningCampaign.forceRetakeUnderSuccessPercentage"
                       type="number"
                       required>
                <span matSuffix>%</span>
              </mat-form-field>
            </div>
          </div>
          <div class="mt-3">
            <mat-slide-toggle name="departments-type"
                              color="primary"
                              (ngModelChange)="campaignEditForm.form.markAsTouched()"
                              [(ngModel)]="hasDepartmentFilter">
              {{ (hasDepartmentFilter ? 'Common.FilteredForDepartments' : 'Common.NotFilteredForDepartments') | translate }}
            </mat-slide-toggle>

            <div *ngIf="hasDepartmentFilter" class="d-inline-block ml-3">
              <app-department-select placeholder="{{ 'Common.Departments' | translate }}"
                                     name="departments"
                                     [(ngModel)]="learningCampaign.departments">
              </app-department-select>
            </div>
          </div>
          <div class="mt-5">
            <h6>
              {{ 'Dashboard.LearningCampaigns.ProceduresToLearn' | translate }}
              <sup class="color-warn font-weight-normal">{{ 'Common.AtLeastOneMustBeSelected' | translate }} *</sup>
            </h6>

            <div class="pl-3 d-flex flex-row">
              <div class="align-self-center">
                {{ 'Dashboard.LearningCampaigns.SelectProceduresInCategory' | translate }}
              </div>

              <mat-form-field class="ml-3">
                <mat-label>{{ 'Common.Select' | translate }}</mat-label>
                <mat-select #categorySelect>
                  <mat-option *ngFor="let category of procedureCategories" [value]="category">
                    {{ category.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <div>
                <button mat-raised-button color="primary"
                        class="ml-3"
                        [disabled]="!categorySelect.value"
                        (click)="selectProceduresInCategory(categorySelect.value, campaignEditForm); categorySelect.value = null;">
                  {{ 'Common.Select' | translate }}
                </button>
              </div>
            </div>

            <div>
              <mat-selection-list>
                <mat-list-option *ngFor="let procedure of procedures"
                                 (click)="onProcedureSelectClick(procedure, campaignEditForm)"
                                 color="primary"
                                 [disabled]="!procedure.isPublished"
                                 [selected]="procedure.isSelected"
                                 checkboxPosition="before">
                  <div matLine>
                    {{ procedure.name }}

                    <sub *ngIf="!procedure.isPublished"
                         class="ml-2 font-italic">
                      {{ 'Common.Draft' | translate }}
                    </sub>
                  </div>
                </mat-list-option>
              </mat-selection-list>
            </div>
          </div>
        </form>
      </div>

      <div class="text-right mt-4">
        <button mat-raised-button
                color="warn"
                class="mr-3 float-left"
                (click)="removeLearningCampaignAsync()">
          {{ 'Common.Remove' | translate }}
        </button>

        <span class="text-danger mr-4" *ngIf="hasErrorInSave">
      {{ 'Common.ErrorSaving' | translate }}
    </span>

        <button mat-raised-button class="mr-3" (click)="cancelClickAsync()">
          {{ 'Common.Cancel' | translate }}
        </button>

        <app-button color="primary" [loading]="inProgress"
                    [disabled]="campaignEditForm.untouched || !campaignEditForm.valid"
                    (clicked)="saveChangesAsync(campaignEditForm)">
          {{ 'Common.SaveChanges' | translate }}
        </app-button>
      </div>
    </mat-card-content>
  </mat-card>
</div>