<div *ngIf="procedure">
  <mat-card class="p-4">
    <div>
      <form #procedureEditForm="ngForm">
        <div class="row">
          <div class="col-8 pr-2">
            <mat-form-field class="w-100">
              <input matInput placeholder="{{ 'Common.Name' | translate }}" name="name" [(ngModel)]="procedure.name"
                     required>
            </mat-form-field>
          </div>

          <div class="col-4">
            <app-procedure-category-select name="categories"
                                           [(ngModel)]="procedure.categories">
            </app-procedure-category-select>
          </div>
        </div>

        <div class="mt-2">
          <app-rich-editor placeholder="{{ 'Common.Description' | translate }}"
                           name="description"
                           [(ngModel)]="procedure.description"
                           required>
          </app-rich-editor>
        </div>

        <div class="mt-3 row">
          <div class="col-2 d-flex align-items-center">{{ 'Dashboard.Procedures.DisplayImage' | translate }}</div>
          <div class="col-10">
            <app-file-upload-selector name="featured-image"
                                      [single]="true"
                                      [(ngModel)]="procedure.featuredImage">
            </app-file-upload-selector>
          </div>
        </div>

        <div class="mt-3 row">
          <div class="col-2">{{ 'Common.Visibility' | translate }}</div>
          <div class="col-10">
            <mat-slide-toggle name="published"
                              color="primary"
                              (ngModelChange)="procedureEditForm.form.markAsTouched()"
                              [(ngModel)]="procedure.isPublished"
                              #publishToggle>
              <fa-icon [icon]="publishToggle.checked ? 'eye' : 'eye-slash'" class="mr-1"></fa-icon>

              {{ (publishToggle.checked ? 'Dashboard.Procedures.ProcedureIsPublished' : 'Dashboard.Procedures.ProcedureIsDraft') | translate }}
            </mat-slide-toggle>
          </div>
        </div>

        <div class="mt-3 row">
          <div class="col-2">{{ 'Common.Departments' | translate }}</div>
          <div class="col-10">
            <mat-slide-toggle name="departments-type"
                              color="primary"
                              (ngModelChange)="procedureEditForm.form.markAsTouched()"
                              [(ngModel)]="hasDepartmentFilter">
              {{ (hasDepartmentFilter ? 'Common.FilteredForDepartments' : 'Common.NotFilteredForDepartments') | translate }}
            </mat-slide-toggle>

            <div *ngIf="hasDepartmentFilter" class="d-inline-block ml-3">
              <app-department-select name="departments"
                                     [(ngModel)]="procedure.departments">
              </app-department-select>
            </div>
          </div>
        </div>

        <div class="mt-3 row">
          <div class="col-2 d-flex align-items-center">{{ 'Common.Attachments' | translate }}</div>
          <div class="col-10">
            <app-file-upload-selector name="files" [(ngModel)]="procedure.files">
            </app-file-upload-selector>
          </div>
        </div>
      </form>
    </div>

    <div class="text-right mt-3">
      <div class="float-left mr-3">
        <button mat-raised-button
                color="warn"
                (click)="removeProcedureAsync()">
          {{ 'Common.Remove' | translate }}
        </button>

        <span *ngIf="hasErrorInRemove" class="text-danger ml-3">
          {{ 'Dashboard.Procedures.CannotRemoveProcedure' | translate }}
        </span>
      </div>

      <span class="text-danger mr-4" *ngIf="hasErrorInSave">
      {{ 'Dashboard.Procedures.CannotSaveProcedure' | translate }}
    </span>

      <button mat-raised-button class="mr-3" (click)="cancelClickAsync()">
        {{ 'Common.Cancel' | translate }}
      </button>

      <app-button color="primary" [loading]="inProgress"
                  [disabled]="procedureEditForm.untouched || !procedureEditForm.valid"
                  (clicked)="saveChangesAsync(procedureEditForm)">
        {{ 'Common.SaveChanges' | translate }}
      </app-button>
    </div>
  </mat-card>
</div>

<div class="text-center mt-3" *ngIf="!procedure">
  <app-loading-indicator></app-loading-indicator>
</div>