import { CoolLocalStorage } from '@angular-cool/storage';
import { Component, Input, OnInit } from '@angular/core';

export interface NotificationMessageState {
  isOpened: boolean;
}

@Component({
  selector: 'app-notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.scss'],
})
export class NotificationMessageComponent implements OnInit {
  private _storageKey: string;

  constructor(private _localStorage: CoolLocalStorage) {
  }

  @Input()
  public id: 'certificateTemplateInformation';

  @Input()
  public title: string;

  public state: NotificationMessageState;

  ngOnInit() {
    this._storageKey = `notification_${ this.id }`;

    this.state = this._localStorage.getObject<NotificationMessageState>(this._storageKey) || {
      isOpened: true,
    };
  }

  public toggleIsOpened() {
    this.state.isOpened = !this.state.isOpened;

    this._saveState();
  }

  private _saveState() {
    this._localStorage.setObject(this._storageKey, this.state);
  }
}
