import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

export interface ApplicationStateModel {
  isNavbarOpened: boolean;
}

export namespace AppStateActions {
  export class ToggleNavbar {
    static readonly type = '[ApplicationState] Toggle Navbar';
  }
}

@State<ApplicationStateModel>({
  name: 'appState',
  defaults: {
    isNavbarOpened: false,
  },
})
@Injectable()
export class AppState {
  @Action(AppStateActions.ToggleNavbar)
  public toggleNavbar(ctx: StateContext<ApplicationStateModel>) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      isNavbarOpened: !state.isNavbarOpened,
    });
  }

  @Selector()
  static isNavbarOpened(state: ApplicationStateModel) {
    return state.isNavbarOpened;
  }
}