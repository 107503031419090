<div *ngIf="procedureTest">
  <form #questionsForm="ngForm">
    <mat-card class="mat-elevation-z4">
      <mat-card-header>
        <mat-card-title>{{ 'Dashboard.Procedures.TestFlow' | translate }}</mat-card-title>
      </mat-card-header>
      <mat-card-content class="p-3">
        <div>
          <div>
            <mat-checkbox name="show-main-content" [(ngModel)]="procedureTest.showMainContent">
              {{ 'Dashboard.Procedures.TestShowMainContent' | translate }}
            </mat-checkbox>
          </div>

          <div class="mt-3">
            <mat-checkbox name="show-steps" [(ngModel)]="procedureTest.showSteps">
              {{ 'Dashboard.Procedures.TestShowSteps' | translate }}
            </mat-checkbox>
          </div>

          <div class="mt-3">
            <h6 class="mb-1">{{ 'Dashboard.Procedures.TestShowVideos' | translate }}</h6>
            <div class="pl-3">
              <app-file-display [selectionMode]="true"
                                [files]="procedureVideos"
                                [(selectedFiles)]="procedureTest.showVideos">
              </app-file-display>
            </div>
            <div *ngIf="!procedureVideos || !procedureVideos.length" class="pl-3">
              {{ 'Dashboard.Procedures.TestNoVideos' | translate }}
            </div>
          </div>

          <div class="mt-3">
            <mat-radio-group name="test-type" [(ngModel)]="procedureTest.questionShowType">
              <mat-radio-button [value]="ProcedureTestQuestionShowType.All">
                {{ 'Dashboard.Procedures.TestQuestionShowTypeAll' | translate }}
              </mat-radio-button>
              <mat-radio-button [value]="ProcedureTestQuestionShowType.Random" class="ml-2">
                {{ 'Dashboard.Procedures.TestQuestionShowTypeRandom' | translate }}

                <mat-form-field class="ml-2">
                  <input matInput name="random-question-number"
                         type="number"
                         [disabled]="procedureTest.questionShowType !== ProcedureTestQuestionShowType.Random"
                         [(ngModel)]="procedureTest.numberOfQuestionsToShow">
                </mat-form-field>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </mat-card-content>
    </mat-card>


    <mat-card class="mt-3 mat-elevation-z2" *ngFor="let question of procedureTest.questions; let i = index">
      <mat-card-header>
        <mat-card-title>{{ 'Dashboard.Procedures.TestQuestionIndex' | translate:{index: i + 1} }}</mat-card-title>

        <div class="position-top-right hover-strong cursor-pointer"
             (click)="removeQuestionAsync(question)"
             title="{{ 'Common.Remove' | translate }}">
          <fa-icon icon="trash"></fa-icon>
        </div>
      </mat-card-header>

      <mat-card-content class="p-3">
        <app-edit-procedure-test-question [question]="question">
        </app-edit-procedure-test-question>
      </mat-card-content>
    </mat-card>
  </form>

  <div class="mt-4">
    <button mat-raised-button color="primary" (click)="addQuestionAsync()">
      <fa-icon icon="plus" class="mr-1"></fa-icon>

      {{ 'Dashboard.Procedures.TestAddQuestion' | translate }}
    </button>
  </div>

  <div class="text-right mt-3">
    <span class="text-danger mr-4" *ngIf="hasErrorInSave">
      {{ 'Common.ErrorSaving' | translate }}
    </span>

    <button mat-raised-button class="mr-3" (click)="cancelClickAsync()">
      {{ 'Common.Cancel' | translate }}
    </button>

    <app-button color="primary"
                [loading]="inProgress"
                (clicked)="saveChangesAsync(questionsForm)">
      {{ 'Common.SaveChanges' | translate }}
    </app-button>
  </div>
</div>

<div class="text-center mt-3" *ngIf="!procedureTest">
  <app-loading-indicator></app-loading-indicator>
</div>