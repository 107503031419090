<form #notificationSettingsForm="ngForm" *ngIf="pageLoaded">
  <div>
    <mat-slide-toggle name="new-email" color="primary"
                      (ngModelChange)="notificationSettingsForm.form.markAsTouched()"
                      [(ngModel)]="notificationSettings.newsEmailEnabled">
      {{ 'AccountSettings.NotificationSettings.ReceiveOccasionalEmails' | translate }}
    </mat-slide-toggle>
  </div>

  <div class="mt-3 mb-2 text-right">
    <app-button color="primary"
                [disabled]="notificationSettingsForm.invalid || notificationSettingsForm.pristine"
                [loading]="saveInProgress"
                (clicked)="saveNotificationsSettingsAsync(notificationSettingsForm)">
      {{ 'Common.SaveChanges' | translate }}
    </app-button>
  </div>
</form>
