<div>
  <app-page-header pageTitle="{{ 'Dashboard.AssetControlReports.Title' | translate }}">
    <div>
      <div class="d-inline-block mr-4 align-middle hover-strong cursor-pointer"
           (click)="loadDataAsync()"
           title="{{ 'Common.Reload' | translate }}">
        <fa-icon icon="redo-alt"></fa-icon>
      </div>

      <button mat-raised-button color="primary"
              (click)="addReportAsync()">
        <fa-icon icon="plus" class="mr-1"></fa-icon>

        {{ 'Common.CreateReport' | translate }}
      </button>
    </div>
  </app-page-header>

  <div class="w-100">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z4 w-100">
      <!-- Date Resolution Column -->
      <ng-container matColumnDef="dateResolution">
        <th mat-header-cell *matHeaderCellDef
            mat-sort-header>{{ 'Dashboard.ReportsCommon.DateResolution' | translate }}</th>
        <td mat-cell *matCellDef="let report">
          <div *ngIf="report.generateRequestData.dateResolution === AssetControlReportDateResolution.Days">
            {{ 'Common.Time.Days' | translate }}
          </div>
          <div *ngIf="report.generateRequestData.dateResolution === AssetControlReportDateResolution.Months">
            {{ 'Common.Time.Months' | translate }}
          </div>
        </td>
      </ng-container>

      <!-- Start Date Column -->
      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Common.StartDate' | translate }}</th>
        <td mat-cell *matCellDef="let report">{{ report.generateRequestData.startDate | date:'shortDate' }}</td>
      </ng-container>

      <!-- End Date Column -->
      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Common.EndDate' | translate }}</th>
        <td mat-cell *matCellDef="let report">{{ report.generateRequestData.endDate | date:'shortDate' }}</td>
      </ng-container>

      <!-- Room Category Column -->
      <ng-container matColumnDef="roomCategory">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Dashboard.AssetControlReports.RoomCategory' | translate }}</th>
        <td mat-cell *matCellDef="let report">{{ report.generateRequestData.roomCategory?.name }}</td>
      </ng-container>

      <!-- Room Column -->
      <ng-container matColumnDef="room">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Dashboard.AssetControlReports.AssetRoom' | translate }}</th>
        <td mat-cell *matCellDef="let report">{{ report.generateRequestData.room?.name }}</td>
      </ng-container>

      <!-- Asset Type Column -->
      <ng-container matColumnDef="assetType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Dashboard.AssetControlReports.AssetType' | translate }}</th>
        <td mat-cell *matCellDef="let report">{{ report.generateRequestData.assetType?.name }}</td>
      </ng-container>

      <!-- Role Column -->
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Dashboard.AssetControlReports.ResponsibleRole' | translate }}</th>
        <td mat-cell *matCellDef="let report">{{ report.generateRequestData.responsibleRole?.name }}</td>
      </ng-container>

      <!-- User Column -->
      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Dashboard.AssetControlReports.ResponsibleUser' | translate }}</th>
        <td mat-cell *matCellDef="let report">{{ report.generateRequestData.responsibleUser?.name }}</td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Common.Status' | translate }}</th>
        <td mat-cell *matCellDef="let report">
          <mat-spinner *ngIf="report.status === ReportStatus.ToDo || report.status === ReportStatus.InProgress"
                       class="mr-2"
                       [diameter]="20">
          </mat-spinner>

          {{ 'Dashboard.ReportsCommon.Status.' + report.status | translate }}
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ 'Common.Actions' | translate }}</th>
        <td mat-cell *matCellDef="let report">
          <button *ngIf="report.status === ReportStatus.Done" mat-raised-button color="primary"
                  [attr.title]="'Common.View' | translate"
                  (click)="viewReportAsync(report)">
            <fa-icon icon="eye"></fa-icon>
          </button>

          <button *ngIf="report.status === ReportStatus.Done && report.reportFileUrl"
                  mat-raised-button color="primary"
                  class="ml-2"
                  [attr.title]="'Common.Download' | translate"
                  (click)="downloadReport(report)">
            <fa-icon icon="download"></fa-icon>
          </button>

          <button *ngIf="report.status === ReportStatus.Done || report.status === ReportStatus.Failed"
                  mat-raised-button
                  color="warn"
                  class="ml-2"
                  [attr.title]="'Common.Remove' | translate"
                  (click)="deleteReportAsync(report)">
            <fa-icon icon="trash"></fa-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div class="text-center mt-3" *ngIf="isLoading">
      <app-loading-indicator></app-loading-indicator>
    </div>
  </div>
</div>
