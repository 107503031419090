<div [matMenuTriggerFor]="languageMenu" class="d-flex flex-row hover-strong cursor-pointer">
  <span>{{ currentLanguage }}</span>

  <fa-icon icon="sort-down" class="ml-2" style="position: relative; top: -2px;"></fa-icon>
</div>

<mat-menu #languageMenu="matMenu">
  <button mat-menu-item *ngFor="let lang of languages" (click)="selectLanguageAsync(lang.key)">
    {{ lang.name }}
  </button>
</mat-menu>
