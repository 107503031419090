<div class="chekpoint-event-dialog">
  <div *ngIf="checkpointEvent">
    <h4>
      <span *ngIf="checkpointEvent.id">{{ 'Dashboard.AssetControlRooms.EditEventDialogTitle' | translate }}</span>
      <span *ngIf="!checkpointEvent.id">{{ 'Dashboard.AssetControlRooms.AddEventDialogTitle' | translate }}</span>
    </h4>

    <div class="mt-5">
      <div>
        <div class="divider">
          <div>{{ 'Dashboard.AssetControlRooms.EventType' | translate }}</div>
        </div>

        <div class="option-container">
          <div class="option-item"
               [class.selected]="checkpointEvent.type === AssetRoomCheckpointEventType.Preparation"
               (click)="setCheckpointType(AssetRoomCheckpointEventType.Preparation)">
            {{ 'Dashboard.AssetControlRooms.PreparationEventName' | translate }}
          </div>

          <div class="option-item"
               [class.selected]="checkpointEvent.type === AssetRoomCheckpointEventType.CheckResult"
               (click)="setCheckpointType(AssetRoomCheckpointEventType.CheckResult)">
            {{ 'Dashboard.AssetControlRooms.CheckResult' | translate }}
          </div>
        </div>
      </div>

      <div class="mt-5">
        <div class="divider">
          <div>{{ 'Dashboard.AssetControlRooms.EventResult' | translate }}</div>
        </div>

        <div class="option-container">
          <div *ngFor="let resultType of resultTypes"
               class="option-item text-center"
               [class.selected]="checkpointEvent.result === resultType"
               (click)="setCheckpointResult(resultType)">
            <app-asset-control-result-image [result]="resultType" size="big">
            </app-asset-control-result-image>

            <div class="mt-2">
              {{ ('Dashboard.AssetControlReports.ResultTypes.' + resultType) | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <mat-form-field class="w-100">
        <textarea matInput name="description"
                  [(ngModel)]="checkpointEvent.description" placeholder="{{ 'Common.Description' | translate }}">
        </textarea>
        </mat-form-field>
      </div>

      <div class="mt-4 file-container">
        <app-file-upload-selector name="files" displayFormat="small" [centered]="true"
                                  [(ngModel)]="checkpointEvent.files">
        </app-file-upload-selector>
      </div>
    </div>

    <div class="mt-3 text-right">
      <span class="text-danger" *ngIf="showError">
        {{ 'Common.FailedToSave' | translate }}
      </span>

      <button mat-raised-button
              *ngIf="showRemoveButton"
              (click)="removeButtonClickAsync()"
              color="warn" class="float-left mr-2">
        {{ 'Common.Remove' | translate }}
      </button>

      <button mat-raised-button (click)="cancelClick()">
        {{ 'Common.Cancel' | translate }}
      </button>

      <app-button class="ml-3" color="primary"
                  [loading]="saveInProgress"
                  (clicked)="saveChangesAsync()">
        {{ 'Common.Save' | translate }}
      </app-button>
    </div>
  </div>

  <div *ngIf="!checkpointEvent" class="text-center">
    <app-loading-indicator></app-loading-indicator>
  </div>
</div>
