import { DepartmentDTO } from './department.dto';
import { LimitedProcedureDTO } from './procedure.dto';

export interface ControlReportDTO {
  filters: ControlReportGenerateRequestDTO;

  dateResolutionTicks: Date[];

  departmentReports: {
    department: DepartmentDTO;

    successPercentagesByTimeTick: number[];
  }[];

  procedureStepSuccessPercentages: {
    id: string;

    name: string;

    successRate: number;
  }[];
}

export enum ControlReportDateResolution {
  Days = 'days',

  Months = 'months',
}

export interface ControlReportGenerateRequestDTO {
  startDate: Date | null;
  endDate: Date | null;
  departments: DepartmentDTO[];
  procedure: LimitedProcedureDTO;
  dateResolution: ControlReportDateResolution;
  userIds: string[];
}