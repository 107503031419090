<div class="h-75">
  <div class="p-5 image-faded-background">
    <mat-card class="mat-elevation-z8 pt-4">
      <mat-card-header>
        <mat-card-title class="text-center">
          {{ 'Login.ValidateEmailTitle' | translate }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="p-4 pb-2">
        <div *ngIf="!showActivationMessage">
          <div>{{ 'Login.ValidateEmailDescription' | translate }}</div>
          <div class="mt-3 text-muted">{{ 'Login.ValidateEmailDescriptionNote' | translate }}</div>

          <div class="mt-5">
            <app-button *ngIf="!showValidationEmailResent"
                        [loading]="resendInProgress"
                        (clicked)="trySendEmailValidationEmailAsync()">
              {{ 'Login.ResendActivationEmail' | translate }}
            </app-button>

            <div *ngIf="showValidationEmailResent" class="text-success">
              {{ 'Login.ActivationEmailResent' | translate }}
            </div>
          </div>
        </div>

        <div *ngIf="showActivationMessage">
          {{ 'Login.ActivationInProgress' | translate }}
        </div>

        <div *ngIf="showError" class="mt-3 color-warn">
          {{ 'Login.FailedToActivateEmail' | translate }}
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>