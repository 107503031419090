<mat-form-field class="w-100">
  <mat-label>{{ placeholder }}</mat-label>
  <mat-select name="{{ name }}-inner-select"
              [ngModel]="selectedProcedure?.id"
              (ngModelChange)="onInnerChange($event)"
              [disabled]="isDisabled"
              [required]="required">
    <mat-option *ngFor="let procedure of procedures" [value]="procedure.id">
      {{ procedure.name }}
    </mat-option>
  </mat-select>
</mat-form-field>