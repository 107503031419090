import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ProcedureCategoryDTO } from '../../../../../../server/src/dto/procedure.dto';
import { LanguageService } from '../../services/language/language.service';
import { ProcedureCategoriesService } from '../../services/procedures/procedure-categories.service';

@Component({
  selector: 'app-procedure-category-select',
  templateUrl: './procedure-category-select.component.html',
  styleUrls: ['./procedure-category-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: ProcedureCategorySelectComponent,
    multi: true,
  }],
})
export class ProcedureCategorySelectComponent implements OnInit, ControlValueAccessor {
  private _onChange: (categories: ProcedureCategoryDTO | ProcedureCategoryDTO[]) => void;
  private _onTouched: (categories: ProcedureCategoryDTO | ProcedureCategoryDTO[]) => void;

  constructor(private _procedureCategoriesService: ProcedureCategoriesService,
              private _languageService: LanguageService) {
  }

  @Input()
  public displayOnly: boolean;

  @Input()
  public name: string;

  @Input()
  public placeholder: string;

  @Input()
  public multiple: boolean = true;

  @Input()
  public categories: ProcedureCategoryDTO[];

  @Input()
  public required: boolean;

  public isDisabled: boolean;
  public selectedProcedureCategoryIds: string | string[];

  public selectedProcedureCategories: ProcedureCategoryDTO | ProcedureCategoryDTO[];

  public async ngOnInit() {
    this.categories = this.categories || await this._procedureCategoriesService.getProcedureCategoriesAsync();

    this.placeholder = this.placeholder || await this._languageService.getTranslationAsync(this.multiple ? 'Common.Categories' : 'Common.Category');
  }

  public onInnerChange(change: string[]) {
    this.selectedProcedureCategoryIds = change;

    this.selectedProcedureCategories = this.categories.filter(category => this.selectedProcedureCategoryIds.includes(category.id));

    const valueToWrite = this.multiple ? this.selectedProcedureCategories : this.selectedProcedureCategories[0];

    this._onTouched && this._onTouched(valueToWrite);
    this._onChange && this._onChange(valueToWrite);
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public writeValue(categories: ProcedureCategoryDTO | ProcedureCategoryDTO[]): void {
    if (this.multiple) {
      this.selectedProcedureCategories = categories || [];
      this.selectedProcedureCategoryIds = (<ProcedureCategoryDTO[]>this.selectedProcedureCategories).map(dep => dep.id);
    } else {
      this.selectedProcedureCategories = categories || null;
      this.selectedProcedureCategoryIds = categories ? (<ProcedureCategoryDTO>categories).id : null;
    }
  }
}
