<div class="page">
  <app-page-header pageTitle="{{ 'AccountSettings.Title' | translate }}"></app-page-header>

  <mat-accordion>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>{{ 'AccountSettings.General' | translate }}</mat-panel-title>
      </mat-expansion-panel-header>

      <app-general-settings></app-general-settings>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>{{ 'AccountSettings.Notifications' | translate }}</mat-panel-title>
      </mat-expansion-panel-header>

      <app-notification-settings></app-notification-settings>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>{{ 'AccountSettings.PasswordSettings.ChangePassword' | translate }}</mat-panel-title>
      </mat-expansion-panel-header>

      <app-password-settings></app-password-settings>
    </mat-expansion-panel>
  </mat-accordion>
</div>
