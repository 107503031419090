<div>
  <app-page-header [pageTitle]="procedure?.name" [showBack]="true">
    <div *ngIf="canUserEditProcedures && procedure">
      <button *ngIf="canCopyToOtherCompany" mat-raised-button
              class="mr-2"
              (click)="copyProcedureToOtherCompanyAsync()">
        <fa-icon icon="copy" class="mr-1"></fa-icon>

        {{ 'Dashboard.Procedures.CopyProcedureToOtherCompany' | translate }}
      </button>

      <button mat-raised-button color="primary"
              (click)="editProcedureAsync()">
        <fa-icon icon="pencil-alt" class="mr-1"></fa-icon>

        {{ 'Dashboard.Procedures.EditProcedure' | translate }}
      </button>
    </div>
  </app-page-header>

  <div *ngIf="procedure">
    <app-procedure-display [procedure]="procedure" [hideName]="true"></app-procedure-display>
  </div>

  <div *ngIf="isLoading" class="text-center mt-3">
    <app-loading-indicator></app-loading-indicator>
  </div>

  <div *ngIf="showProcedureNotFound" class="text-center mt-3">
    <app-not-found></app-not-found>
  </div>
</div>