export interface DrawItem {
  imagePath: string;
}

export const DRAW_ITEM_GROUPS: {
  nameTranslationKey: string,
  imagePathFolderPrefix: string,
  numberOfDrawItems: number,
  drawItems?: DrawItem[]
}[] = [
  {
    nameTranslationKey: 'General',
    imagePathFolderPrefix: 'general',
    numberOfDrawItems: 4,
  },
  {
    nameTranslationKey: 'Sanitary',
    imagePathFolderPrefix: 'sanitary',
    numberOfDrawItems: 18,
  },
  {
    nameTranslationKey: 'Other',
    imagePathFolderPrefix: 'other',
    numberOfDrawItems: 127,
  },
];

for (const group of DRAW_ITEM_GROUPS) {
  group.drawItems = [];

  for (let i = 0; i < group.numberOfDrawItems; i++) {
    group.drawItems.push({
      imagePath: `/assets/images/editor/${ group.imagePathFolderPrefix }/${ i }.svg`,
    });
  }
}
