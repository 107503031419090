import { CoolHttp } from '@angular-cool/http';
import { CoolLocalStorage } from '@angular-cool/storage';
import { Inject, Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { UserSettingsDTO } from '../../../../../../server/src/dto/user-settings.dto';
import { Environment } from '../../../../environments/environment.interface';
import { ENVIRONMENT } from '../injection-tokens';
import { SettingsService } from '../settings/settings.service';

const SAVED_LOGIN_URL_STORAGE_KEY = 'after-login';
const SAVED_URL_AFTER_ACTIVATION_STORAGE_KEY = 'after-activate';
const SSO_AUTO_LOGIN_IDENTIFIER_CANDIDATE_STORAGE_KEY = 'sso-auto-login-identifier-candidate';
const SSO_AUTO_LOGIN_IDENTIFIER_STORAGE_KEY = 'sso-auto-login-identifier';

@Injectable()
export class UserContextService {
  constructor(private _settingsService: SettingsService,
              @Inject(ENVIRONMENT) private _environment: Environment,
              private _http: CoolHttp,
              private _localStorage: CoolLocalStorage) {
  }

  public user: UserSettingsDTO;
  public userObservable: ReplaySubject<UserSettingsDTO> = new ReplaySubject<UserSettingsDTO>(1);

  public isUserLoggerIn: boolean;
  public isUserLoggedInObservable: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  public async initializeAsync(): Promise<void> {
    await this._tryGetUserContextAsync();
  }

  public goToGoogleLogin() {
    window.top.location.href = `${ this._environment.backendUrl }/api/authentication/google`;
  }

  public goToSSOLogin(identifier: string) {
    if (!identifier) {
      return;
    }

    this._localStorage.setItem(SSO_AUTO_LOGIN_IDENTIFIER_CANDIDATE_STORAGE_KEY, identifier);

    window.top.location.href = `${ this._environment.backendUrl }/api/authentication/sso?identifier=${ encodeURIComponent(identifier) }`;
  }

  public tryAutomaticallyLogInToSSO() {
    const identifier = this._localStorage.getItem(SSO_AUTO_LOGIN_IDENTIFIER_STORAGE_KEY);

    if (!identifier) {
      return;
    }

    this.goToSSOLogin(identifier);
  }

  public promoteSSOAutoLoginIdentifierCandidate() {
    const candidate = this._localStorage.getItem(SSO_AUTO_LOGIN_IDENTIFIER_CANDIDATE_STORAGE_KEY);

    if (!candidate) {
      return;
    }

    this._localStorage.setItem(SSO_AUTO_LOGIN_IDENTIFIER_STORAGE_KEY, candidate);
  }

  public getSSOAutoLoginIdentifier(): string {
    return this._localStorage.getItem(SSO_AUTO_LOGIN_IDENTIFIER_STORAGE_KEY) || this._localStorage.getItem(SSO_AUTO_LOGIN_IDENTIFIER_CANDIDATE_STORAGE_KEY);
  }

  public async registerWithEmailAsync(email: string, password: string, joinToken: string) {
    await this._http.postAsync('api/authentication/local/register', {
      email: email,
      password: password,
      joinToken: joinToken,
    });
  }

  public async loginWithEmailAsync(email: string, password: string) {
    await this._http.postAsync('api/authentication/local', {
      email: email,
      password: password,
    });

    await this.refreshUserSettingsAsync();
  }

  public goToLogout() {
    window.top.location.href = `${ this._environment.backendUrl }/api/authentication/logout`;
  }

  public async getUserSettingsAsync(): Promise<UserSettingsDTO> {
    if (!this.user) {
      await this.refreshUserSettingsAsync();
    }

    return this.user;
  }

  public async refreshUserSettingsAsync() {
    this.user = await this._settingsService.getUserSettingsAsync();

    this.userObservable.next(this.user);
    this.isUserLoggedInObservable.next(true);
  }

  public saveRouteToNavigateAfterLogin(url: string) {
    this._localStorage.setItem(SAVED_LOGIN_URL_STORAGE_KEY, url);
  }

  public getAndClearRouteToNavigateAfterLogin() {
    const url = this._localStorage.getItem(SAVED_LOGIN_URL_STORAGE_KEY);

    this._localStorage.removeItem(SAVED_LOGIN_URL_STORAGE_KEY);

    return url;
  }

  public saveRouteToNavigateAfterActivateAccount(url: string) {
    this._localStorage.setItem(SAVED_URL_AFTER_ACTIVATION_STORAGE_KEY, url);
  }

  public getAndClearRouteToNavigateAfterActivateAccount() {
    const url = this._localStorage.getItem(SAVED_URL_AFTER_ACTIVATION_STORAGE_KEY);

    this._localStorage.removeItem(SAVED_URL_AFTER_ACTIVATION_STORAGE_KEY);

    return url;
  }

  public async getUserAsync() {
    return await this.userObservable
      .pipe(
        take(1),
      )
      .toPromise();
  }

  public async getIsUserLoggerInAsync() {
    return await this.isUserLoggedInObservable
      .pipe(
        take(1),
      )
      .toPromise();
  }

  private async _tryGetUserContextAsync(): Promise<boolean> {
    try {
      await this.getUserSettingsAsync();

      this.isUserLoggerIn = true;
      this.isUserLoggedInObservable.next(true);

      return true;
    } catch (ex) {
      this.isUserLoggerIn = false;
      this.isUserLoggedInObservable.next(false);
    }

    return false;
  }

  public async activateUserAsync(token: string) {
    await this._http.postAsync('api/settings/activations', {
      token: token,
    });

    await this.refreshUserSettingsAsync();
  }

  public async updatePasswordAsync(oldPassword: string, newPassword: string) {
    await this._http.postAsync('api/authentication/local/password', {
      oldPassword: oldPassword,
      newPassword: newPassword,
    });
  }

  public async sendForgotPasswordEmailAsync(email: string) {
    await this._http.postAsync('api/authentication/local/password/forgot', {
      email: email,
    });
  }

  public async restorePasswordAsync(token: string, password: string) {
    await this._http.postAsync('api/authentication/local/password/restore', {
      token: token,
      newPassword: password,
    });
  }

  public async tryResendEmailValidationEmailAsync() {
    await this._http.postAsync('api/authentication/local/email/validation');
  }
}
