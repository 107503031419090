<app-page-header
    [showBack]="true"
    pageTitle="{{ 'Dashboard.AssetControlRooms.EditRoom' | translate }} - {{ room?.name }}"></app-page-header>

<div *ngIf="room">
  <mat-card class="p-4">
    <form #roomEditForm="ngForm">
      <div class="d-flex flex-row">
        <div class="flex-grow-1 px-2">
          <mat-form-field class="w-100">
            <input matInput placeholder="{{ 'Common.Name' | translate }}"
                   name="name" [(ngModel)]="room.name"
                   required>
          </mat-form-field>
        </div>
        <div class="flex-grow-1 px-2">
          <app-asset-room-category-select
              name="category"
              [(ngModel)]="room.category">
          </app-asset-room-category-select>
        </div>
      </div>

      <div class="d-flex flex-row mt-3">
        <div id="canvas-holder">
          <canvas #roomCanvasElement width="550" height="450" class="mat-elevation-z1"></canvas>

          <app-canvas-controls [canvas]="roomCanvas" [ignorePanOnSelection]="true"></app-canvas-controls>
        </div>

        <div class="flex-grow-1 d-flex flex-column ml-3">
          <div>
            <div class="draw-item" (click)="addLineAsync()">
              <img src="assets/images/icons/line.svg">
            </div>

            <div class="draw-item" (click)="addRectangleAsync()">
              <img src="assets/images/icons/rectangle.svg">
            </div>

            <div class="draw-item" (click)="addTextAsync()">
              <img src="assets/images/icons/font.svg">
            </div>
          </div>

          <div id="draw-item-container" class="flex-grow-1 overflow-auto">
            <mat-accordion [multi]="true">
              <mat-expansion-panel *ngFor="let drawGroup of drawItemGroups">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{ 'Dashboard.AssetControlRooms.DrawItemGroups.' + drawGroup.nameTranslationKey | translate }}
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <ng-template matExpansionPanelContent>
                  <div>
                    <div class="draw-item" *ngFor="let drawItem of drawGroup.drawItems" (click)="addItemAsync(drawItem)">
                      <img [src]="drawItem.imagePath">
                    </div>
                  </div>
                </ng-template>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </form>

    <div class="text-right mt-4">
      <button mat-raised-button
              color="warn"
              class="mr-3 float-left"
              (click)="removeRoomAsync()">
        {{ 'Common.Remove' | translate }}
      </button>

      <span class="text-danger mr-4" *ngIf="hasErrorInSave">
      {{ 'Common.ErrorSaving' | translate }}
    </span>

      <button mat-raised-button class="mr-3" (click)="cancelClickAsync()">
        {{ 'Common.Cancel' | translate }}
      </button>

      <app-button color="primary" [loading]="saveInProgress"
                  [disabled]="roomEditForm.untouched || !roomEditForm.valid"
                  (clicked)="saveChangesAsync()">
        {{ 'Common.SaveChanges' | translate }}
      </app-button>
    </div>
  </mat-card>
</div>
