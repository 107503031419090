<form class="p-4" #loginForm="ngForm">
  <div class="px-2">
    <div class="mb-2">
      <mat-form-field class="w-100">
        <input name="emailInput" matInput placeholder="{{ 'Common.Email' | translate }}"
               type="email"
               ngModel required>
      </mat-form-field>
    </div>
    <div class="mb-3">
      <mat-form-field class="w-100">
        <input name="passwordInput" matInput placeholder="{{ 'Common.Password' | translate }}"
               type="password"
               ngModel required>
      </mat-form-field>
    </div>

    <div class="mb-3 text-right">
      <a class="text-decoration-none" href="#" (click)="onForgotPasswordClickAsync(); $event.preventDefault();">
        {{ 'Login.ForgotPasswordLink' | translate }}
      </a>
    </div>

    <div>
      <div>
        <app-button color="primary"
                    [loading]="inProgress"
                    [disabled]="!loginForm.valid"
                    (clicked)="loginEmailClickAsync(loginForm)">
          {{ 'Login.LoginWithEmail' | translate }}
        </app-button>
      </div>

      <div class="mt-2 text-danger" *ngIf="showLoginError">
        {{ 'Login.FailedToLogin' | translate }}
      </div>
    </div>
  </div>

  <div class="text-muted mt-3">{{ 'Common.OR' | translate }}</div>

  <div class="mt-3">
    <app-google-button text="{{ 'Login.LoginWithGoogle' | translate }}"
                       (clicked)="loginGoogleClick()">
    </app-google-button>
  </div>

  <div class="mt-3">
    <button mat-raised-button color="primary" (click)="ssoLoginClickAsync()">
      {{ 'Login.CompanySSOLogin' | translate }}
    </button>
  </div>
</form>