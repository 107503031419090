import { Component, Input, OnInit } from '@angular/core';
import { AssetRoomCheckpointEventResult } from '../../../../../../../../server/src/models/asset-room-checkpoint.enum';

export type AssetControlResultImageComponentSizeType = 'normal' | 'big';

@Component({
  selector: 'app-asset-control-result-image',
  templateUrl: './asset-control-result-image.component.html',
  styleUrls: ['./asset-control-result-image.component.scss']
})
export class AssetControlResultImageComponent implements OnInit {

  constructor() { }

  @Input()
  public result: AssetRoomCheckpointEventResult;

  @Input()
  public size: AssetControlResultImageComponentSizeType = 'normal';

  public AssetRoomCheckpointEventResult = AssetRoomCheckpointEventResult;

  ngOnInit() {
  }

}
