<div>
  <app-page-header pageTitle="{{ 'Dashboard.AssetControlAssetType.Title' | translate }}">
    <div>
      <div class="d-inline-block">
        <mat-form-field>
          <input matInput (keyup)="filterAssetTypes($event.target.value)"
                 placeholder="{{ 'Common.Filter' | translate }}">
        </mat-form-field>
      </div>

      <button class="ml-5"
              mat-raised-button color="primary"
              (click)="addNewAssetTypeAsync()">
        <fa-icon icon="plus" class="mr-1"></fa-icon>

        {{ 'Dashboard.AssetControlAssetType.AddNewAssetType' | translate }}
      </button>
    </div>
  </app-page-header>

  <div class="w-100">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z4 w-100">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Common.Name' | translate }}</th>
        <td mat-cell *matCellDef="let assetType">
          <div *ngIf="!assetType.isEditing">{{ assetType.value.name }}</div>

          <div *ngIf="assetType.isEditing">
            <mat-form-field>
              <input matInput placeholder="{{ 'Common.Name' | translate }}" name="name" [(ngModel)]="assetType.value.name"
                     required>
            </mat-form-field>
          </div>
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ 'Common.Actions' | translate }}</th>
        <td mat-cell *matCellDef="let assetType">
          <button mat-raised-button color="primary" class="mr-2"
                  [attr.title]="'Common.Edit' | translate"
                  *ngIf="!assetType.isEditing"
                  (click)="startEditingAssetTypeAsync(assetType)">
            <fa-icon icon="pencil-alt"></fa-icon>
          </button>

          <button mat-raised-button class="mr-2"
                  *ngIf="assetType.isEditing"
                  (click)="cancelEditingAssetTypeAsync(assetType)">
            {{ 'Common.Cancel' | translate }}
          </button>

          <app-button [loading]="updateAssetTypeInProgress" color="primary" class="mr-2"
                      *ngIf="assetType.isEditing"
                      (click)="editAssetTypeAsync(assetType)">
            {{ 'Common.Save' | translate }}
          </app-button>

          <button mat-raised-button color="warn"
                  [attr.title]="'Common.Remove' | translate"
                  (click)="removeAssetTypeAsync(assetType)">
            <fa-icon icon="trash"></fa-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div class="text-center mt-3" *ngIf="isLoading">
      <app-loading-indicator></app-loading-indicator>
    </div>
  </div>
</div>