import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
  ControlReportDateResolution,
  ControlReportGenerateRequestDTO,
} from '../../../../../../../../server/src/dto/control-report.dto';
import { DateUtility } from '../../../../../../../../server/src/utils/date-utility';
import { ProcedureSelectComponent } from '../../../../../shared/components/procedure-select/procedure-select.component';
import { DateProviderService } from '../../../../../shared/utils/date-provider.service';
import { ControlReportsService } from '../../shared/services/control-reports.service';

@Component({
  selector: 'app-control-report-generate-dialog',
  templateUrl: './control-report-generate-dialog.component.html',
  styleUrls: ['./control-report-generate-dialog.component.scss'],
})
export class ControlReportGenerateDialogComponent implements OnInit {

  constructor(private _controlReportsService: ControlReportsService,
              private _dateProviderService: DateProviderService,
              private _dialog: MatDialogRef<ControlReportGenerateDialogComponent>) {

  }

  public isLoading: boolean;
  public hasError: boolean;

  public filters: ControlReportGenerateRequestDTO = {
    startDate: null,
    endDate: null,
    departments: [],
    procedure: null,
    dateResolution: ControlReportDateResolution.Days,
    userIds: [],
  };

  @ViewChild(ProcedureSelectComponent, { static: true })
  public procedureSelect: ProcedureSelectComponent;

  public ControlReportDateResolution = ControlReportDateResolution;

  public ngOnInit() {
    this.filters.startDate = DateUtility.addDays(this._dateProviderService.now(), -20);
    this.filters.endDate = DateUtility.addDays(this._dateProviderService.now(), 20);
  }

  public onCancel() {
    this._dialog.close();
  }

  public async onGenerateReportClickAsync(filterForm: NgForm) {
    filterForm.form.markAllAsTouched();

    this.procedureSelect.markAsTouched();

    if (!filterForm.valid) {
      return;
    }

    try {
      this.hasError = false;
      this.isLoading = true;

      await this._controlReportsService.generateReportAsync(this.filters);

      this._dialog.close();
    } catch {
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  }
}
