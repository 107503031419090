import { CoolDialogService } from '@angular-cool/dialogs';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AssetRoomCheckpointEventDTO } from '../../../../../../../../server/src/dto/asset-room-checkpoint.dto';
import {
  AssetRoomCheckpointEventResult,
  AssetRoomCheckpointEventType,
} from '../../../../../../../../server/src/models/asset-room-checkpoint.enum';
import { LanguageService } from '../../../../../shared/services/language/language.service';
import { AssetRoomCheckpointsService } from '../../../shared/services/asset-room-checkpoints.service';
import { AssetControlRoomEventEditorDialogConfig } from './asset-control-room-event-editor-dialog.config';

@Component({
  selector: 'app-asset-control-room-event-editor',
  templateUrl: './asset-control-room-event-editor.component.html',
  styleUrls: ['./asset-control-room-event-editor.component.scss'],
})
export class AssetControlRoomEventEditorComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) private _dialogConfig: AssetControlRoomEventEditorDialogConfig,
              private _assetRoomCheckpointsService: AssetRoomCheckpointsService,
              private _dialog: MatDialogRef<AssetControlRoomEventEditorComponent>,
              private _dialogService: CoolDialogService,
              private _languageService: LanguageService) {
  }

  public showError: boolean;
  public saveInProgress: boolean;

  public checkpointEvent: AssetRoomCheckpointEventDTO;

  public AssetRoomCheckpointEventType = AssetRoomCheckpointEventType;

  public resultTypes = [
    AssetRoomCheckpointEventResult.Bad,
    AssetRoomCheckpointEventResult.Neutral,
    AssetRoomCheckpointEventResult.Good,
    AssetRoomCheckpointEventResult.Great,
  ];

  public showRemoveButton: boolean;

  public async ngOnInit() {
    if (this._dialogConfig.checkpointEventId) {
      this.checkpointEvent = await this._assetRoomCheckpointsService.getCheckpointEventAsync(
        this._dialogConfig.roomId,
        this._dialogConfig.checkpointId,
        this._dialogConfig.checkpointEventId,
      );
    } else {
      this.checkpointEvent = {
        id: null,
        type: AssetRoomCheckpointEventType.Preparation,
        createdAt: null,
        createdBy: null,
        description: '',
        result: AssetRoomCheckpointEventResult.Neutral,
        files: [],
      };
    }

    this.showRemoveButton = !!this._dialogConfig.checkpointEventId;
  }

  public setCheckpointType(type: AssetRoomCheckpointEventType) {
    this.checkpointEvent.type = type;
  }

  public setCheckpointResult(result: AssetRoomCheckpointEventResult) {
    this.checkpointEvent.result = result;
  }

  public async saveChangesAsync() {
    try {
      this.saveInProgress = true;
      this.showError = false;

      await this._assetRoomCheckpointsService.upsertCheckpointEventAsync(
        this._dialogConfig.roomId,
        this._dialogConfig.checkpointId,
        this.checkpointEvent,
      );

      this._dialog.close();
    } catch {
      this.showError = true;
    } finally {
      this.saveInProgress = false;
    }
  }

  public async removeButtonClickAsync() {
    const removeDialogResult = await this._dialogService.showDialog({
      titleText: await this._languageService.getTranslationAsync('Dashboard.AssetControlRooms.RemoveEventTitle'),
      questionText: await this._languageService.getTranslationAsync('Dashboard.AssetControlRooms.RemoveEventDescription'),
      confirmActionButtonText: await this._languageService.getTranslationAsync('Common.Remove'),
      cancelActionButtonText: await this._languageService.getTranslationAsync('Common.Cancel'),
      confirmActionButtonColor: 'warn',
    });

    if (!removeDialogResult.isConfirmed) {
      return;
    }

    this.checkpointEvent = await this._assetRoomCheckpointsService.removeCheckpointEventAsync(
      this._dialogConfig.roomId,
      this._dialogConfig.checkpointId,
      this._dialogConfig.checkpointEventId,
    );

    this._dialog.close();
  }

  public cancelClick() {
    this._dialog.close();
  }
}
