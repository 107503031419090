<div *ngIf="displayOnly">
  <mat-chip-list>
    <mat-chip *ngFor="let category of selectedProcedureCategories">{{ category.name }}</mat-chip>
  </mat-chip-list>
</div>

<mat-form-field *ngIf="!displayOnly" class="w-100">
  <mat-label>{{ placeholder }}</mat-label>
  <mat-select name="{{ name }}-inner-select"
              [ngModel]="selectedProcedureCategoryIds"
              (ngModelChange)="onInnerChange($event)"
              [disabled]="isDisabled"
              [required]="required"
              [multiple]="multiple">
    <mat-option *ngFor="let category of categories" [value]="category.id">
      {{ category.name }}
    </mat-option>
  </mat-select>
</mat-form-field>