<div *ngIf="displayOnly">{{ selectedCompanyRole?.name }}</div>

<mat-form-field *ngIf="!displayOnly" class="w-100">
  <mat-label>{{ placeholder }}</mat-label>
  <mat-select name="{{ name }}-inner-select"
              [ngModel]="selectedCompanyRole?.id"
              (ngModelChange)="onInnerChange($event)"
              [disabled]="isDisabled"
              [required]="required">
    <mat-option *ngIf="unselectable" [value]="null">
      {{ 'Common.None' | translate }}
    </mat-option>

    <mat-option *ngFor="let role of companyRoles" [value]="role.id">
      {{ role.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
