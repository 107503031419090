<div *ngIf="settings">
  <div class="mt-3">
    <app-file-upload-selector [(ngModel)]="settings.logo" [single]="true" name="logo"></app-file-upload-selector>
  </div>
  <p class="mt-2">{{ 'Dashboard.CompanySettings.WhiteLabelDescription' | translate }}</p>
  <div class="mt-3 text-right">
    <app-button color="primary" [loading]="inProgress" (clicked)="onSaveAsync()">
      {{ 'Common.Save' | translate }}
    </app-button>
  </div>
</div>

<div *ngIf="isLoading" class="text-center mt-3">
  <app-loading-indicator></app-loading-indicator>
</div>