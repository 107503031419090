<div id="footer">
  <div class="row">
    <div class="col-12 col-md-6 mb-3">
      <div class="right-container link-container pt-3 justify-content-center justify-content-md-start">
        <a class="nav-link opacity-background-badge"
           title="{{ 'Footer.ContactUs' | translate }}"
           [href]="contactUrl"
           (click)="mailClick()"
           target="_blank">
          <fa-icon icon="envelope" class="mr-2"></fa-icon>
          e-mail
        </a>
        <a class="nav-link opacity-background-badge"
           title="{{ 'Footer.Help' | translate }}"
           [href]="helpUrl"
           (click)="helpClick()"
           target="_blank">
          <fa-icon icon="question" class="mr-2"></fa-icon>
          {{ 'Footer.Help' | translate }}
        </a>
      </div>
    </div>
    <div class="col-12 col-md-6 text-center text-md-right">
      <div class="d-inline-block">
        <div class="link-container justify-content-center">
          <div>
            <div class="nav-link" (click)="termsClick()">
              {{ 'Footer.Terms' | translate }}
            </div>
          </div>
          <div>
            <div class="nav-link" (click)="privacyClick()">
              {{ 'Footer.Privacy' | translate }}
            </div>
          </div>
        </div>

        <div id="divider"></div>

        <div class="pl-3 text-center text-md-left">
          Copyright {{ thisYear }} @ Procedure Manager
        </div>
      </div>
    </div>
  </div>
</div>
