<div>
  <app-page-header pageTitle="{{ 'Dashboard.Navigation.LearningCampaigns' | translate }}">
    <div>
      <div class="d-inline-block">
        <mat-form-field>
          <input matInput (keyup)="filterCampaigns($event.target.value)"
                 placeholder="{{ 'Common.Filter' | translate }}">
        </mat-form-field>
      </div>

      <button class="ml-5"
              mat-raised-button color="primary"
              (click)="addNewCampaignAsync()">
        <fa-icon icon="plus" class="mr-1"></fa-icon>

        {{ 'Dashboard.LearningCampaigns.AddNewLearningCampaign' | translate }}
      </button>
    </div>
  </app-page-header>

  <div class="w-100">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z4 w-100">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Common.Name' | translate }}</th>
        <td mat-cell *matCellDef="let campaign">{{ campaign.name }}</td>
      </ng-container>

      <!-- Start Date Column -->
      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Common.StartDate' | translate }}</th>
        <td mat-cell *matCellDef="let campaign">{{ campaign.startDate | date }}</td>
      </ng-container>

      <!-- End Date Column -->
      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Common.EndDate' | translate }}</th>
        <td mat-cell *matCellDef="let campaign">{{ campaign.endDate | date }}</td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Common.Status' | translate }}</th>
        <td mat-cell *matCellDef="let campaign">
          {{ ('Dashboard.LearningCampaigns.Status.' + campaign.status) | translate }}
        </td>
      </ng-container>

      <!-- Departments Column -->
      <ng-container matColumnDef="departments">
        <th mat-header-cell *matHeaderCellDef>{{ 'Common.Departments' | translate }}</th>
        <td mat-cell *matCellDef="let campaign">
          <div *ngIf="!campaign.departments.length">{{ 'Common.AllDepartments' | translate }}</div>

          <div *ngIf="campaign.departments.length && campaign.departments.length < 3">
            <span *ngFor="let department of campaign.departments; let i = index">
              {{ i !== 0 ? ', ' : '' }}
              {{ department.name }}
            </span>
          </div>

          <div *ngIf="campaign.departments.length > 2" class="font-italic">
            {{ 'Common.MultipleDepartments' | translate: { departmentCount: campaign.departments.length } }}
          </div>
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ 'Common.Actions' | translate }}</th>
        <td mat-cell *matCellDef="let campaign">
          <button mat-raised-button color="primary"
                  [attr.title]="'Common.Edit' | translate"
                  (click)="editLearningCampaignAsync(campaign)">
            <fa-icon icon="pencil-alt"></fa-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div class="text-center mt-3" *ngIf="isLoading">
      <app-loading-indicator></app-loading-indicator>
    </div>
  </div>
</div>