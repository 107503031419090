import { CoolHttp } from '@angular-cool/http';
import { Injectable } from '@angular/core';
import {
  AssetControlReportDTO,
  AssetControlReportGenerateRequestDTO,
} from '../../../../../../../server/src/dto/asset-control-report.dto';
import { ReportDTO } from '../../../../../../../server/src/dto/report.dto';


@Injectable()
export class AssetControlReportsService {
  constructor(private _http: CoolHttp) {

  }

  public async getReportByIdAsync(reportId: string): Promise<AssetControlReportDTO> {
    return await this._http.getAsync(`api/companies/active/asset-control/reports/${ reportId }`);
  }

  public async deleteReportByIdAsync(reportId: string): Promise<AssetControlReportDTO> {
    return await this._http.deleteAsync(`api/companies/active/asset-control/reports/${ reportId }`);
  }

  public async getReportsAsync(): Promise<ReportDTO<AssetControlReportGenerateRequestDTO>[]> {
    return await this._http.getAsync('api/companies/active/asset-control/reports');
  }

  public async generateReportAsync(generateRequest: AssetControlReportGenerateRequestDTO): Promise<void> {
    await this._http.postAsync('api/companies/active/asset-control/reports', generateRequest);
  }
}