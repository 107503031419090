<div class="position-relative">
  <h4>{{ companyUser.name || companyUser.email }}</h4>

  <div class="p-4">
    <form #userEditForm="ngForm">
      <div>
        <mat-form-field>
          <input matInput
                 name="name"
                 placeholder="{{ 'Common.Name' | translate }}"
                 [(ngModel)]="companyUser.name"
                 required>
        </mat-form-field>
      </div>
      <div class="mt-2">
        <app-permission-role-select
            name="permission"
            placeholder="{{ 'Common.Permission' | translate }}"
            [(ngModel)]="companyUser.permissionRole"
            required>
        </app-permission-role-select>
      </div>
      <div class="mt-2">
        <app-company-role-select
            name="role"
            placeholder="{{ 'Common.Role' | translate }}"
            [(ngModel)]="companyUser.companyRole"
            required>
        </app-company-role-select>
      </div>
      <div class="mt-2">
        <app-department-select
            name="departments"
            placeholder="{{ 'Common.Departments' | translate }}"
            [(ngModel)]="companyUser.departments"
            required>
        </app-department-select>
      </div>
      <div class="mt-2">
        <mat-form-field class="w-100">
          <textarea matInput
                    class="w-100"
                    name="notes"
                    placeholder="{{ 'Common.Notes' | translate }}"
                    [(ngModel)]="companyUser.notes">
          </textarea>
        </mat-form-field>
      </div>
    </form>
  </div>

  <div class="mt-1 text-right">
    <button mat-raised-button *ngIf="!isCurrentUser"
            (click)="removeButtonClickAsync()"
            color="warn" class="float-left mr-2">
      {{ 'Common.Remove' | translate }}
    </button>

    <button mat-raised-button (click)="closeDialog()">
      {{ 'Common.Cancel' | translate }}
    </button>

    <app-button class="ml-3" color="primary"
                [loading]="saveInProgress"
                (clicked)="saveChangesAsync(userEditForm)">
      {{ 'Common.Save' | translate }}
    </app-button>
  </div>
</div>
