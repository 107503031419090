<div class="w-100 h-75 position-relative image-faded-background pt-5">
  <div id="join-box">
    <mat-card class="p-5 mat-elevation-z4">
      <mat-card-content>
        <div class="text-center">
          <div *ngIf="!missingTokenError && !invalidTokenError && !userAlreadyInCompany && company">
            <h4 class="mb-4">
              {{ 'Company.Join' | translate }} "{{ company?.name }}"
            </h4>

            <div>
              <div class="mb-3">
                {{ 'Company.InvitedToJoin' | translate:{companyName: company?.name} }}
              </div>

              <div *ngIf="error"
                   class="alert alert-danger mb-3"
                   [innerHTML]="'Company.FailedToJoin' | translate">
              </div>

              <div class="mt-3">
                <form #joinForm="ngForm">
                  <div *ngIf="!user.name">
                    <mat-form-field class="w-100">
                      <input matInput name="name" [(ngModel)]="userName"
                             placeholder="{{ 'Common.Name' | translate }}" required>
                    </mat-form-field>
                  </div>
                  <div class="mt-3">
                    <app-department-select #departmentsSelector
                                           [departments]="departments"
                                           [required]="true">
                    </app-department-select>
                  </div>
                  <div class="mt-3">
                    <app-company-role-select #companyRoleSelector
                                             [companyRoles]="companyRoles"
                                             [required]="true">
                    </app-company-role-select>
                  </div>
                </form>

                <div class="mt-4">
                  <app-button color="primary"
                              [loading]="inProgress"
                              [disabled]="userAlreadyInCompany || invalidTokenError || !joinForm.valid"
                              (clicked)="joinCompanyAsync(joinForm, departmentsSelector.selectedDepartments, companyRoleSelector.selectedCompanyRole)">
                    {{ 'Company.JoinCompany' | translate }}
                  </app-button>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="userAlreadyInCompany"
               class="alert alert-danger mb-3"
               [innerHTML]="'Company.AlreadyJoinedCompany' | translate">
          </div>

          <div *ngIf="invalidTokenError"
               class="alert alert-danger mb-3"
               [innerHTML]="'Company.InvalidToken' | translate">
          </div>

          <div *ngIf="missingTokenError" class="alert alert-danger"
               [innerHTML]="'Company.NoTokenNoCompany' | translate">
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
