<div>

  <app-page-header pageTitle="{{ 'Dashboard.Navigation.ControlChecks' | translate }}">
    <div>
      <button class="float-right mb-3"
              mat-raised-button color="primary"
              (click)="addNewControlCheckEventAsync()">
        <fa-icon icon="plus" class="mr-1"></fa-icon>

        {{ 'Dashboard.Control.AddNewControlEvent' | translate }}
      </button>
    </div>
  </app-page-header>

  <div class="w-100">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z4 w-100">
      <!-- Procedure Name Column -->
      <ng-container matColumnDef="procedure">
        <th mat-header-cell *matHeaderCellDef>{{ 'Common.Procedure' | translate }}</th>
        <td mat-cell *matCellDef="let controlCheckEvent">{{ controlCheckEvent?.procedure.name }}</td>
      </ng-container>

      <!-- Department Name Column -->
      <ng-container matColumnDef="department">
        <th mat-header-cell *matHeaderCellDef>{{ 'Common.Department' | translate }}</th>
        <td mat-cell *matCellDef="let controlCheckEvent">{{ controlCheckEvent?.department.name }}</td>
      </ng-container>

      <!-- CreatedAt Column -->
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>{{ 'Common.Date' | translate }}</th>
        <td mat-cell *matCellDef="let controlCheckEvent">{{ controlCheckEvent?.createdAt | date }}</td>
      </ng-container>

      <!-- RecordedBy Column -->
      <ng-container matColumnDef="recordedBy">
        <th mat-header-cell *matHeaderCellDef>{{ 'Common.RecordedBy' | translate }}</th>
        <td mat-cell *matCellDef="let controlCheckEvent">{{ controlCheckEvent?.recordedBy.name }}</td>
      </ng-container>

      <!-- Success Percentage Column -->
      <ng-container matColumnDef="successPercentage">
        <th mat-header-cell *matHeaderCellDef>{{ 'Common.Result' | translate }}</th>
        <td mat-cell *matCellDef="let controlCheckEvent">
          <div [scoreColor]="controlCheckEvent?.successPercentage">
            {{ controlCheckEvent?.successPercentage }}%
          </div>
        </td>
      </ng-container>

      <!-- Notes Column -->
      <ng-container matColumnDef="notes">
        <th mat-header-cell *matHeaderCellDef>{{ 'Common.Notes' | translate }}</th>
        <td mat-cell *matCellDef="let controlCheckEvent">
          <div class="notes-container" [attr.title]="controlCheckEvent?.notes">
            {{ controlCheckEvent?.notes }}
          </div>
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ 'Common.Actions' | translate }}</th>
        <td mat-cell *matCellDef="let controlCheckEvent">
          <button mat-raised-button color="primary"
                  [attr.title]="'Common.Edit' | translate"
                  (click)="editControlCheckEventAsync(controlCheckEvent)">
            <fa-icon icon="pencil-alt"></fa-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator class="mat-elevation-z4"
                   [disabled]="isPaginatorDisabled"
                   [showFirstLastButtons]="false"
                   [pageSize]="pageSize"
                   [hidePageSize]="true">
    </mat-paginator>

    <div class="text-center mt-3" *ngIf="isLoading">
      <app-loading-indicator></app-loading-indicator>
    </div>
  </div>
</div>