export class Editable<T> {
  private _originalValue: T;

  constructor(public value: T) {
    this._saveOriginalValue();
  }

  public isEditing: boolean;

  public startEditing() {
    this.isEditing = true;
  }

  public stopEditing(saveChanges: boolean) {
    this.isEditing = false;

    if (saveChanges) {
      this._saveOriginalValue();
    } else {
      this.value = this._originalValue;
    }
  }

  private _saveOriginalValue() {
    this._originalValue = JSON.parse(JSON.stringify(this.value));
  }
}