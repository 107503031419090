<div *ngIf="companySettings">
  <div>
    <mat-form-field>
      <mat-label>{{ 'Dashboard.CompanySettings.CompanyLanguage' | translate }}</mat-label>
      <mat-select name="language" [(ngModel)]="companySettings.language">
        <mat-option *ngFor="let language of languages" [value]="language.key">
          {{ language.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="mt-3 text-right">
    <span *ngIf="hasError" class="color-warn mr-3">
      {{ 'Common.Failed' | translate }}
    </span>
    <app-button color="primary" [loading]="inProgress" (clicked)="onSaveAsync()">
      {{ 'Common.Save' | translate }}
    </app-button>
  </div>
</div>

<div *ngIf="isLoading" class="text-center mt-3">
  <app-loading-indicator></app-loading-indicator>
</div>