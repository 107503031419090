<div class="add-user-dialog">
  <h4>{{ 'Dashboard.Users.InviteUsers' | translate }}</h4>

  <div class="mt-3 p-3 text-center">
    <div>
      <div>
        <mat-form-field class="w-100">
          <textarea matInput placeholder="{{ 'Dashboard.Users.EmailsSeparatedByComma' | translate }}"
                    name="addresses" [(ngModel)]="emailAddresses" required></textarea>
        </mat-form-field>
      </div>
      <div class="mt-2">
        <div>
          <app-button color="primary"
                      (clicked)="sendInviteEmailsAsync()"
                      [disabled]="!emailAddresses"
                      [loading]="inProgress">
            {{ 'Dashboard.Users.SendInvite' | translate }}
          </app-button>
        </div>
        <div *ngIf="hasError" class="color-warn mt-2">
          {{ 'Dashboard.Users.FailedToInvite' | translate }}
        </div>
        <div *ngIf="isDone" class="text-success mt-2">
          {{ 'Dashboard.Users.InviteSentToEmails' | translate }}
        </div>
      </div>
    </div>

    <div *ngIf="inviteLink">
      <div class="my-4">{{ 'Common.OR' | translate }}</div>

      <div>{{ 'Dashboard.Users.SendThemThisLink' | translate }}</div>

      <div class="d-flex flex-row">
        <mat-form-field class="flex-grow-1">
          <input #inviteLinkInput
                 matInput
                 (click)="inviteLinkInput.select()"
                 [value]="inviteLink"
                 readonly>
        </mat-form-field>

        <button mat-raised-button color="primary" class="copy-button ml-3" (click)="copyClick(inviteLinkInput)">
          {{ 'Common.Copy' | translate }}
        </button>
      </div>
    </div>
  </div>

  <div class="mt-3 text-right">
    <button mat-raised-button (click)="closeDialog()">
      {{ 'Common.Close' | translate }}
    </button>
  </div>
</div>