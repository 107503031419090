import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { LimitedLearningCampaignWithResultDTO } from '../../../../../../server/src/dto/learning-campaign.dto';
import { RouteNavigationService } from '../../../shared/routing/route-navigation.service';
import { DashboardService } from '../shared/services/dashboard.service';

@Component({
  selector: 'app-active-learning-campaigns-widget',
  templateUrl: './active-learning-campaigns-widget.component.html',
  styleUrls: ['./active-learning-campaigns-widget.component.scss'],
})
export class ActiveLearningCampaignsWidgetComponent implements OnInit {

  constructor(private _dashboardService: DashboardService,
              private _routeNavigationService: RouteNavigationService) {

  }

  public displayedColumns = ['name', 'endDate', 'minutesRequiredToComplete', 'actions'];
  public isLoading: boolean;

  public dataSource: MatTableDataSource<LimitedLearningCampaignWithResultDTO>;

  public completedAreShownAsWell: boolean;

  public async ngOnInit() {
    try {
      this.isLoading = true;

      await this._loadDataAsync(false);
    } finally {
      this.isLoading = false;
    }
  }

  public async startCampaignAsync(campaign: LimitedLearningCampaignWithResultDTO) {
    await this._routeNavigationService.goToCompleteCampaignAsync(campaign.id);
  }

  public async loadCompletedAsWellAsync() {
    try {
      this.isLoading = true;

      await this._loadDataAsync(true);

      this.displayedColumns.splice(this.displayedColumns.length - 1, 0, 'result');

      this.completedAreShownAsWell = true;
    } finally {
      this.isLoading = false;
    }
  }

  private async _loadDataAsync(includeCompleted: boolean) {
    const activeLearningCampaigns = await this._dashboardService.getMyActiveLearningCampaignsAsync(includeCompleted);

    this.dataSource = new MatTableDataSource(activeLearningCampaigns);
  }
}
