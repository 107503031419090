<div *ngIf="room">
  <app-page-header
      [showBack]="true"
      pageTitle="{{ 'Dashboard.AssetControlRooms.RoomAssets' | translate }} - {{ room?.name }}">
  </app-page-header>

  <mat-card class="p-4 card-darker">
    <div class="text-right">
      <button mat-raised-button color="primary" (click)="addNewAssetAsync()">
        <fa-icon icon="plus" class="mr-1"></fa-icon>

        {{ 'Dashboard.AssetControlRooms.AddAsset' | translate }}
      </button>
    </div>

    <div class="d-flex flex-row mt-3">
      <app-room-canvas [selectedAssetId]="selectedAssetId"
                       (selectedAssetIdChanged)="selectedAssetId = $event"
                       [assets]="assetsSubject"
                       [assetSelectionDisabled]="!!currentlyEditingAsset"
                       [backgroundJson]="room.backgroundJson"
                       [backgroundJsonSettings]="room.backgroundJsonSettings">
      </app-room-canvas>

      <div id="assets-holder" class="flex-grow-1 d-flex flex-column ml-3">
        <div class="right-container overflow-auto">
          <mat-accordion *ngIf="editableAssets">
            <mat-expansion-panel id="asset-{{ asset.value.id }}"
                                 [expanded]="selectedAssetId === asset.value.id"
                                 (afterExpand)="selectAsset(asset.value)"
                                 *ngFor="let asset of editableAssets; trackBy: trackAsset">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="d-flex flex-row align-items-center">
                    <div>
                      <mat-radio-button readonly
                                        [checked]="selectedAssetId === asset.value.id"
                                        color="primary">
                      </mat-radio-button>
                    </div>
                    <div>
                      {{ asset.value.name }}
                    </div>
                  </div>
                </mat-panel-title>
                <mat-panel-description>
                  {{ asset.value.type?.name }}
                </mat-panel-description>
              </mat-expansion-panel-header>

              <ng-template matExpansionPanelContent>
                <div>
                  <div>
                    <table class="table table-hover" [class.table-sm]="asset.isEditing">
                      <tbody>
                      <tr>
                        <th scope="row">{{ 'Common.Name' | translate }}</th>
                        <td>
                          <div *ngIf="!asset.isEditing">{{ asset.value.name }}</div>

                          <div *ngIf="asset.isEditing">
                            <mat-form-field>
                              <input matInput placeholder="{{ 'Common.Name' | translate }}"
                                     [(ngModel)]="asset.value.name"
                                     required>
                            </mat-form-field>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">{{ 'Common.Type' | translate }}</th>
                        <td>
                          <div *ngIf="!asset.isEditing">{{ asset.value.type?.name }}</div>

                          <div *ngIf="asset.isEditing">
                            <app-asset-type-select name="type" [(ngModel)]="asset.value.type"></app-asset-type-select>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">{{ 'Dashboard.AssetControlRooms.ResponsibleRole' | translate }}</th>
                        <td>
                          <app-company-role-select name="role"
                                                   placeholder="{{ 'Dashboard.AssetControlRooms.ResponsibleRole' | translate }}"
                                                   [displayOnly]="!asset.isEditing"
                                                   [unselectable]="true"
                                                   [(ngModel)]="asset.value.responsibleCompanyRole">
                          </app-company-role-select>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">{{ 'Dashboard.AssetControlRooms.ResponsibleUser' | translate }}</th>
                        <td>
                          <app-single-user-select name="user"
                                                  placeholder="{{ 'Dashboard.AssetControlRooms.ResponsibleUser' | translate }}"
                                                  [displayOnly]="!asset.isEditing"
                                                  [(ngModel)]="asset.value.responsibleCompanyUser">
                          </app-single-user-select>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">{{ 'Common.Comment' | translate }}</th>
                        <td>
                          <div *ngIf="!asset.isEditing">{{ asset.value.comment }}</div>

                          <div *ngIf="asset.isEditing" class="w-100">
                            <mat-form-field class="w-100">
                              <input matInput placeholder="{{ 'Common.Comment' | translate }}"
                                     [(ngModel)]="asset.value.comment">
                            </mat-form-field>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="text-right mt-3">
                    <button mat-raised-button color="primary" class="mr-2"
                            [attr.title]="'Common.Edit' | translate"
                            *ngIf="!asset.isEditing && !currentlyEditingAsset"
                            (click)="startEditingAssetAsync(asset)">
                      {{ 'Common.Edit' | translate }}
                    </button>

                    <button mat-raised-button class="ml-2"
                            *ngIf="asset.isEditing"
                            [attr.title]="'Common.Cancel' | translate"
                            (click)="cancelEditingAssetAsync(asset)">
                      {{ 'Common.Cancel' | translate }}
                    </button>

                    <app-button [loading]="updateAssetInProgress" color="primary" class="ml-2"
                                *ngIf="asset.isEditing"
                                [attr.title]="'Common.Save' | translate"
                                (click)="editAssetAsync(asset)">
                      {{ 'Common.Save' | translate }}
                    </app-button>

                    <button mat-raised-button color="warn"
                            class="float-left mr-2"
                            [attr.title]="'Common.Remove' | translate"
                            *ngIf="asset.isEditing"
                            (click)="removeAssetAsync(asset)">
                      {{ 'Common.Remove' | translate }}
                    </button>
                  </div>
                </div>
              </ng-template>
            </mat-expansion-panel>
          </mat-accordion>

          <div *ngIf="!editableAssets" class="text-center">
            <app-loading-indicator></app-loading-indicator>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
