import { CoolDialogService } from '@angular-cool/dialogs';
import { Component, OnInit } from '@angular/core';
import { DepartmentDTO } from '../../../../../../server/src/dto/department.dto';
import { ProcedureCategoryDTO, ProcedureDTO } from '../../../../../../server/src/dto/procedure.dto';
import { FeaturePermissions } from '../../../../../../server/src/models/feature-permissions.enum';
import { RouteNavigationService } from '../../../shared/routing/route-navigation.service';
import { LanguageService } from '../../../shared/services/language/language.service';
import { ProceduresService } from '../../../shared/services/procedures/procedures.service';
import { UserContextService } from '../../../shared/services/user-context/user-context.service';

@Component({
  selector: 'app-procedure-list',
  templateUrl: './procedure-list.component.html',
  styleUrls: ['./procedure-list.component.scss'],
})
export class ProcedureListComponent implements OnInit {
  private _filterText: string;
  private _procedures: ProcedureDTO[];

  constructor(private _proceduresService: ProceduresService,
              private _userContextService: UserContextService,
              private _dialogService: CoolDialogService,
              private _languageService: LanguageService,
              private _routeNavigationService: RouteNavigationService) {
  }

  public isLoading: boolean;

  public canUserEditProcedures: boolean;
  public proceduresToShow: ProcedureDTO[];

  public selectedDepartments: DepartmentDTO[];
  public selectedCategories: ProcedureCategoryDTO[];

  public async ngOnInit() {
    this.selectedDepartments = this._userContextService.user.departments;

    this.canUserEditProcedures = this._userContextService.user.featurePermissions.includes(FeaturePermissions.EditProcedures);

    await this._loadDataAsync();

    this.filterAndSelectProceduresToShow(null);
  }

  public async addNewProcedureAsync() {
    const newName = await this._languageService.getTranslationAsync('Dashboard.Procedures.NewProcedureName');
    const newDescription = await this._languageService.getTranslationAsync('Dashboard.Procedures.NewProcedureDescription');

    try {
      const createdProcedure = await this._proceduresService.createProcedureAsync(newName, newDescription);

      await this._routeNavigationService.goToEditProcedureAsync(createdProcedure.id);
    } catch (e) {
      await this._dialogService.showDialog({
        titleText: await this._languageService.getTranslationAsync('Dashboard.Procedures.CannotAddMoreTitle'),
        questionText: await this._languageService.getTranslationAsync('Dashboard.Procedures.CannotAddMoreDescription'),
        confirmActionButtonText: await this._languageService.getTranslationAsync('Common.Ok'),
        showCancelActionButton: false,
      });

      throw e;
    }
  }

  public async onDepartmentFilterChangeAsync() {
    await this._loadDataAsync();
  }

  public async onCategoryFilterChangeAsync() {
    await this._loadDataAsync();
  }

  public async viewProcedureAsync(procedure: ProcedureDTO) {
    await this._routeNavigationService.goToViewProcedureAsync(procedure.id);
  }

  public filterAndSelectProceduresToShow(filterText: string) {
    this._filterText = filterText;

    if (!filterText) {
      this.proceduresToShow = this._procedures;
    } else {
      const lowerCaseFilterText = filterText.toLowerCase();

      this.proceduresToShow = this._procedures.filter(procedure => procedure.name.toLowerCase().includes(lowerCaseFilterText));
    }

    if (this.proceduresToShow) {
      this.proceduresToShow.sort((a, b) => a.name.localeCompare(b.name));
    }
  }

  public trackProcedure(index: number, procedure: ProcedureDTO) {
    return procedure ? procedure.id : undefined;
  }

  private async _loadDataAsync() {
    try {
      this.isLoading = true;

      this._procedures = await this._proceduresService.getProceduresAsync(true, this.selectedDepartments, this.selectedCategories);

      this.filterAndSelectProceduresToShow(this._filterText);
    } finally {
      this.isLoading = false;
    }
  }
}
