import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FileDTO } from '../../../../../../server/src/dto/file.dto';
import { FileDisplayFormat } from './file-display-format.enum';
import { FileDisplayPreviewDialogConfig } from './file-display-preview-dialog/file-display-preview-dialog-config.interface';
import { FileDisplayPreviewDialogComponent } from './file-display-preview-dialog/file-display-preview-dialog.component';

@Component({
  selector: 'app-file-display',
  templateUrl: './file-display.component.html',
  styleUrls: ['./file-display.component.scss'],
})
export class FileDisplayComponent implements OnInit {
  constructor(private _matDialogService: MatDialog) {
  }

  @Input()
  public files: FileDTO[];

  @Input()
  public deleteFileButton: boolean;

  @Input()
  public forceDisplay: boolean;

  @Input()
  public displayFormat: FileDisplayFormat;

  @Input()
  public selectionMode: boolean;

  @Input()
  public centered: boolean;

  @Input()
  public selectedFiles: FileDTO[] = [];

  @Output()
  public deleteFile: EventEmitter<FileDTO> = new EventEmitter<FileDTO>();

  @Output()
  public selectedFilesChange: EventEmitter<FileDTO[]> = new EventEmitter<FileDTO[]>();

  public selectedFileIds = {};

  ngOnInit() {
    for (const selectedFile of this.selectedFiles) {
      this.selectedFileIds[selectedFile.id] = true;
    }
  }

  public deleteFileClick(file: FileDTO) {
    this.deleteFile.emit(file);
  }

  public onFileClick(file: FileDTO) {
    if (!this.selectionMode) {
      this._matDialogService.open<FileDisplayPreviewDialogComponent, FileDisplayPreviewDialogConfig>(FileDisplayPreviewDialogComponent, {
        panelClass: 'mobile-friendly-dialog',
        data: {
          files: this.files,
          startingFile: file,
        },
      });
    } else {
      if (this.selectedFiles.includes(file)) {
        this.selectedFiles.splice(this.selectedFiles.indexOf(file), 1);

        this.selectedFileIds[file.id] = false;
      } else {
        this.selectedFiles.push(file);

        this.selectedFileIds[file.id] = true;
      }

      this.selectedFilesChange.emit(this.selectedFiles);
    }
  }
}
