import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import { untilDestroyed } from 'ngx-take-until-destroy';
import {
  AssetControlReportDateResolution,
  AssetControlReportDTO,
  AssetControlReportDTORawResult,
} from '../../../../../../../server/src/dto/asset-control-report.dto';
import {
  ASSET_ROOM_CHECKPOINT_EVENT_RESULT_NUMERIC_MAP,
  AssetRoomCheckpointEventResult,
} from '../../../../../../../server/src/models/asset-room-checkpoint.enum';
import { LanguageService } from '../../../../shared/services/language/language.service';
import { DateProviderService } from '../../../../shared/utils/date-provider.service';
import { AssetControlReportsService } from '../../shared/services/asset-control-reports.service';

@Component({
  selector: 'app-asset-control-report-view',
  templateUrl: './asset-control-report-view.component.html',
  styleUrls: ['./asset-control-report-view.component.scss'],
})
export class AssetControlReportViewComponent implements OnInit {
  private _reportId: string;
  private _chartYAxis: any = {};

  constructor(private _assetControlReportsService: AssetControlReportsService,
              private _activatedRoute: ActivatedRoute,
              private _languageService: LanguageService,
              private _dateProviderService: DateProviderService) {

  }

  public report: AssetControlReportDTO;
  public isLoading: boolean;
  public hasError: boolean;

  public roomBasedReportChartData: ChartDataSets[];
  public roomBasedReportChartLabels: Label[];
  public roomBasedReportChartOptions: ChartOptions = {
    responsive: true,
    spanGaps: true,
    elements: {
      line: {
        tension: 0,
      },
    },
    scales: {
      yAxes: [{
        ticks: {
          min: -3,
          max: 3,
          stepSize: 1,
          callback: this._getLabelNameForValue.bind(this),
        },
      }],
    },
  };

  public assetTypeBasedReportChartData: ChartDataSets[];
  public assetTypeBasedReportChartLabels: Label[];
  public assetTypeBasedReportChartOptions: ChartOptions = {
    responsive: true,
    spanGaps: true,
    elements: {
      line: {
        tension: 0,
      },
    },
    scales: {
      yAxes: [{
        ticks: {
          min: -3,
          max: 3,
          stepSize: 1,
          callback: this._getLabelNameForValue.bind(this),
        },
      }],
    },
  };

  public rawResultAssetIdFilter: string;
  public rawResultAssetTypeIdFilter: string;
  public rawResultRoomIdFilter: string;
  public rawResultRoomCategoryIdFilter: string;

  public rawResultsDisplayedColumns: string[] = ['date', 'asset', 'assetType', 'room', 'roomCategory', 'result'];
  public rawResultsDataSource: MatTableDataSource<AssetControlReportDTORawResult>;

  @ViewChild(MatPaginator)
  public rawResultsPaginator: MatPaginator;

  public async ngOnInit() {
    for (const eventResult of [AssetRoomCheckpointEventResult.Great, AssetRoomCheckpointEventResult.Good, AssetRoomCheckpointEventResult.Neutral, AssetRoomCheckpointEventResult.Bad]) {
      this._chartYAxis[ASSET_ROOM_CHECKPOINT_EVENT_RESULT_NUMERIC_MAP[eventResult]] = await this._languageService.getTranslationAsync('Dashboard.AssetControlReports.ResultTypes.' + eventResult);
    }

    this._activatedRoute.params
      .pipe(untilDestroyed(this))
      .subscribe(async params => {
        this._reportId = params.reportId;

        if (this._reportId) {
          await this._loadReportAsync();
        }
      });
  }

  public ngOnDestroy(): void {
  }

  public filterRawResults() {
    // Apply custom filter again
    this.rawResultsDataSource.filter = Math.random().toString();
  }

  private async _loadReportAsync() {
    try {
      this.isLoading = true;
      this.hasError = false;

      this.report = await this._assetControlReportsService.getReportByIdAsync(this._reportId);

      const labelFormat = this.report.filters.dateResolution === AssetControlReportDateResolution.Days ? 'shortDate' : 'yyyy. MMM';

      this.roomBasedReportChartLabels = this.report.dateResolutionTicks.map(month => this._dateProviderService.formatDate(new Date(month), labelFormat));
      this.roomBasedReportChartData = this.report.rooms.length ? this.report.rooms.map(roomData => {
        return {
          data: roomData.resultsByTimeTick,
          label: roomData.room.name,
          fill: false,
        };
      }) : null;

      this.assetTypeBasedReportChartLabels = this.report.dateResolutionTicks.map(month => this._dateProviderService.formatDate(new Date(month), labelFormat));
      this.assetTypeBasedReportChartData = this.report.assetTypes.length ? this.report.assetTypes.map(assetTypeData => {
        return {
          data: assetTypeData.resultsByTimeTick,
          label: assetTypeData.assetType.name,
          fill: false,
        };
      }) : null;

      this.rawResultsDataSource = new MatTableDataSource<AssetControlReportDTORawResult>(this.report.rawResults);

      this.rawResultsDataSource.filterPredicate = this._rawResultsFilterPredicate.bind(this);
    } catch {
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  }

  private _getLabelNameForValue(value: number): string {
    return this._chartYAxis[value] || '';
  }

  private _rawResultsFilterPredicate(data: AssetControlReportDTORawResult): boolean {
    if (this.rawResultAssetIdFilter && data.assetId !== this.rawResultAssetIdFilter) {
      return false;
    }

    const asset = this.report.rawResultDictionary.assets[data.assetId];

    if (this.rawResultAssetTypeIdFilter && asset.typeId !== this.rawResultAssetTypeIdFilter) {
      return false;
    }

    if (this.rawResultRoomIdFilter && asset.roomId !== this.rawResultRoomIdFilter) {
      return false;
    }

    const room = this.report.rawResultDictionary.rooms[asset.roomId];

    if (this.rawResultRoomCategoryIdFilter && room.categoryId !== this.rawResultRoomCategoryIdFilter) {
      return false;
    }

    return true;
  }
}
