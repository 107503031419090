import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ProcedureDTO } from '../../../../../../server/src/dto/procedure.dto';
import { FeaturePermissions } from '../../../../../../server/src/models/feature-permissions.enum';
import { RouteLocations } from '../../../shared/routing/route-locations.enum';
import { UserContextService } from '../../../shared/services/user-context/user-context.service';
import { ProceduresService } from '../../../shared/services/procedures/procedures.service';

@Component({
  selector: 'app-edit-procedure',
  templateUrl: './edit-procedure.component.html',
  styleUrls: ['./edit-procedure.component.scss'],
})
export class EditProcedureComponent implements OnInit {
  constructor(private _proceduresService: ProceduresService,
              private _userContextService: UserContextService,
              private _activatedRoute: ActivatedRoute) {

  }

  public RouteLocations = RouteLocations;

  public procedure: ProcedureDTO;

  public showTestMenu: boolean;

  public ngOnInit() {
    this.showTestMenu = this._userContextService.user.featurePermissions.includes(FeaturePermissions.EditProcedureTests);

    this._activatedRoute.params
      .pipe(untilDestroyed(this))
      .subscribe(async (params: Params) => {
        const procedureId = params.procedureId;

        if (!procedureId) {
          return;
        }

        this.procedure = await this._proceduresService.getProcedureByIdAsync(procedureId);
      });
  }

  public ngOnDestroy(): void {
  }
}
