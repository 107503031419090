import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RouteNavigationService } from '../../shared/routing/route-navigation.service';
import { UserContextService } from '../../shared/services/user-context/user-context.service';

@Component({
  selector: 'app-login-box',
  templateUrl: './login-box.component.html',
  styleUrls: ['./login-box.component.scss'],
})
export class LoginBoxComponent implements OnInit {

  constructor(private _userContextService: UserContextService,
              private _routeNavigationService: RouteNavigationService,
              private _changeDetector: ChangeDetectorRef) {
  }

  public triedToSubmit: boolean;
  public showLoginError: boolean;

  public inProgress: boolean;

  ngOnInit() {
  }

  public async loginEmailClickAsync(loginForm: NgForm) {
    this.triedToSubmit = true;
    this.showLoginError = false;

    if (!loginForm.valid) {
      return;
    }

    const email = loginForm.controls.emailInput.value;
    const password = loginForm.controls.passwordInput.value;

    this.inProgress = true;

    try {
      await this._userContextService.loginWithEmailAsync(email, password);

      await this._routeNavigationService.goToLoginSuccessAsync();
    } catch {
      this.showLoginError = true;
    }

    this.inProgress = false;

    this._changeDetector.markForCheck();
  }

  public async onForgotPasswordClickAsync() {
    await this._routeNavigationService.goToForgotPasswordAsync();
  }

  public loginGoogleClick() {
    this._userContextService.goToGoogleLogin();
  }

  public async ssoLoginClickAsync() {
    await this._routeNavigationService.goToSSOLoginAsync();
  }
}
