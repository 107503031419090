<div *ngIf="room">
  <app-page-header [showBack]="true" [pageTitle]="room.name"></app-page-header>

  <mat-card class="p-4">
    <div class="d-flex flex-row mt-3">
      <app-room-canvas [selectedAssetId]="selectedAssetId"
                       (selectedAssetIdChanged)="onSelectedAssetIdChangedAsync($event)"
                       [assets]="assetsSubject"
                       [backgroundJson]="room.backgroundJson"
                       [backgroundJsonSettings]="room.backgroundJsonSettings">
      </app-room-canvas>

      <div class="flex-grow-1 d-flex flex-column ml-3">
        <div class="d-flex flex-row align-items-center">
          <div>
            <mat-form-field>
              <mat-label>{{ 'Dashboard.AssetControlRooms.Assets' | translate }}</mat-label>
              <mat-select name="asset" [(ngModel)]="selectedAssetId"
                          (ngModelChange)="onSelectedAssetIdChangedAsync($event)">
                <mat-option [value]="null">-- {{ 'Common.All' | translate }} --</mat-option>

                <mat-option *ngFor="let asset of assets" [value]="asset.id">
                  {{ asset.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="flex-grow-1 text-right">
            <button mat-raised-button color="primary" [disabled]="!selectedAssetId" (click)="addNewCheckpointAsync()">
              <fa-icon icon="plus" class="mr-1"></fa-icon>

              {{ 'Dashboard.AssetControlRooms.AddCheckpoint' | translate }}
            </button>
          </div>
        </div>

        <div id="checkpoint-holder">
          <mat-accordion>
            <mat-expansion-panel *ngFor="let checkpoint of checkpoints; trackBy: trackCheckpoint"
                                 (afterExpand)="loadCheckpointEventsAsync(checkpoint)"
                                 togglePosition="before">
              <mat-expansion-panel-header>
                <div class="w-100">
                  <span>{{ checkpoint.createdAt | date:'shortDate' }}</span>
                  <span class="ml-3"
                        [class.text-secondary]="checkpoint.state === AssetRoomCheckpointState.InPreparation"
                        [class.text-warning]="checkpoint.state === AssetRoomCheckpointState.Prepared"
                        [class.text-success]="checkpoint.state === AssetRoomCheckpointState.Checked">
                    {{ ('Dashboard.AssetControlRooms.CheckpointStates.' + checkpoint.state) | translate }}
                  </span>
                  <span class="ml-3" *ngIf="!selectedAssetId">
                    {{ checkpoint.asset?.name }}
                  </span>
                </div>
              </mat-expansion-panel-header>

              <ng-template matExpansionPanelContent>
                <div>
                  <div *ngIf="!checkpoint.events" class="text-center">
                    <app-loading-indicator></app-loading-indicator>
                  </div>

                  <div class="table-responsive">
                    <table *ngIf="checkpoint.events && checkpoint.events.length" class="table table-hover table-sm events-table">
                      <tbody>
                      <tr *ngFor="let event of checkpoint.events">
                        <td class="text-center">
                          {{ event.createdAt | date:'shortDate' }}
                        </td>

                        <td (click)="editCheckpointEventAsync(checkpoint.id, event.id)">
                          <span class="mr-2" [attr.title]="('Dashboard.AssetControlRooms.' + (event.type === AssetRoomCheckpointEventType.Preparation ? 'PreparationEventName' : 'CheckEventName')) | translate">
                            <fa-icon *ngIf="event.type === AssetRoomCheckpointEventType.Preparation"
                                     icon="spray-can"></fa-icon>
                            <fa-icon *ngIf="event.type === AssetRoomCheckpointEventType.CheckResult"
                                     icon="vial"></fa-icon>
                          </span>

                          <span class="cursor-pointer d-none d-xl-inline-block">
                            {{ ('Dashboard.AssetControlRooms.' + (event.type === AssetRoomCheckpointEventType.Preparation ? 'PreparationEventName' : 'CheckEventName')) | translate }}
                          </span>
                        </td>

                        <td>
                          <app-asset-control-result-image [result]="event.result"></app-asset-control-result-image>

                          <span class="ml-2" *ngIf="event.createdBy">
                            {{ event.createdBy.name }}
                          </span>
                        </td>

                        <td class="text-right">
                          <button mat-raised-button
                                  title="{{ 'Common.Edit' | translate }}"
                                  (click)="editCheckpointEventAsync(checkpoint.id, event.id)">
                            <fa-icon icon="pencil-alt"></fa-icon>
                          </button>
                        </td>
                      </tr>
                      </tbody>
                    </table>

                    <div *ngIf="checkpoint.events && !checkpoint.events.length" class="text-center mb-3">
                      {{ 'Dashboard.AssetControlRooms.NoEventsYet' | translate }}
                    </div>
                  </div>

                  <div class="text-right">
                    <button mat-raised-button color="primary" (click)="addNewCheckpointEventAsync(checkpoint.id)">
                      <fa-icon icon="plus" class="mr-1"></fa-icon>

                      {{ 'Dashboard.AssetControlRooms.AddCheckpointEvent' | translate }}
                    </button>
                  </div>
                </div>
              </ng-template>
            </mat-expansion-panel>
          </mat-accordion>

          <div class="mt-3 text-center">
            <div *ngIf="loadingInProgress" class="mb-2">
              <app-loading-indicator></app-loading-indicator>
            </div>

            <div *ngIf="!loadingInProgress && checkpoints && !checkpoints.length" class="text-center mb-2">
              {{ 'Dashboard.AssetControlRooms.NoEventsYet' | translate }}
            </div>

            <div>
              <button mat-raised-button *ngIf="!noMorePagesToLoad" (click)="loadMoreCheckpointsAsync()">
                {{ 'Common.LoadMore' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
