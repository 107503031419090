import { Component } from '@angular/core';

@Component({
  selector: 'app-control-reports',
  templateUrl: './control-reports.component.html',
  styleUrls: ['./control-reports.component.scss'],
})
export class ControlReportsComponent {

}
