<div>
  <div class="p-5 image-faded-background request-demo">
    <mat-card class="mat-elevation-z8 pt-4">
      <mat-card-header>
        <mat-card-title class="text-center">
          {{ 'RequestDemo.ScheduleADemo' | translate }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="p-4 pb-2">
        <form #requestForm="ngForm">
          <mat-form-field class="w-100">
            <input matInput [(ngModel)]="requestDemoData.email" name="email"
                   placeholder="{{ 'Common.Email' | translate }}" type="email"
                   required>
          </mat-form-field>

          <mat-form-field class="mt-2 w-100">
            <input matInput [(ngModel)]="requestDemoData.name" name="email"
                   placeholder="{{ 'Common.Name' | translate }}"
                   required>
          </mat-form-field>

          <mat-form-field class="mt-2 w-100">
            <input matInput [(ngModel)]="requestDemoData.companyName" name="company-name"
                   placeholder="{{ 'RequestDemo.CompanyName' | translate }}"
                   required>
          </mat-form-field>

          <mat-form-field class="mt-2 w-100">
            <input matInput [(ngModel)]="requestDemoData.phoneNumber" name="phone"
                   placeholder="{{ 'Common.PhoneNumber' | translate }}"
                   required>
          </mat-form-field>

          <mat-form-field class="mt-2 w-100">
            <input matInput [(ngModel)]="requestDemoData.numberOfEmployees" name="employee-count"
                   placeholder="{{ 'RequestDemo.EmployeeCount' | translate }}" type="number"
                   required>
          </mat-form-field>

          <div class="mt-4 text-center">
            <app-button color="primary"
                        [loading]="inProgress"
                        (clicked)="onFormSubmitAsync(requestForm)">
              {{ 'RequestDemo.ScheduleADemo' | translate }}
            </app-button>

            <div *ngIf="showError">
              {{ 'Common.ErrorSaving' | translate }}
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>