<div id="login-container" class="text-center h-75 image-faded-background pt-5">
  <div id="login-box" class="d-inline-block">
    <mat-card class="mat-elevation-z4">
      <mat-tab-group [(selectedIndex)]="selectedTabIndex">
        <mat-tab label="{{ 'Common.Login' | translate }}">
          <app-login-box></app-login-box>
        </mat-tab>

        <mat-tab label="{{ 'Common.Register' | translate }}">
          <app-register-box></app-register-box>
        </mat-tab>
      </mat-tab-group>
    </mat-card>
  </div>
</div>
