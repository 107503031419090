import { Injectable } from '@angular/core';
import { CoolHttp } from '@angular-cool/http';
import { NotificationSettings } from './notification-settings.interface';

@Injectable()
export class NotificationSettingsService {
  constructor(private _http: CoolHttp) {
  }

  public async getNotificationSettingsAsync(): Promise<NotificationSettings> {
    return await this._http.getAsync('api/settings/notifications');
  }

  public async setNotificationSettingsAsync(notificationSettings: NotificationSettings) {
    return await this._http.postAsync('api/settings/notifications', notificationSettings);
  }
}
