import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {

  constructor() {
  }

  public isLoading: boolean;

  @Input()
  public set loading(value: boolean) {
    this.isLoading = value;
  }

  @Input()
  public color: ThemePalette;

  @Input()
  public disabled: boolean;

  @Output()
  public clicked: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  ngOnInit() {
  }

  public onClick($event: MouseEvent) {
    if (this.disabled || this.isLoading) {
      return;
    }

    this.clicked.emit($event);
  }
}
