<div class="position-relative" *ngIf="permissionRole">
  <h4 class="mb-3">
    "{{ permissionRole.name }}" {{ 'Common.Permissions' | translate }}
    <sub *ngIf="permissionRole.isBuiltIn"
         class="font-italic text-danger">
      {{ 'Dashboard.PermissionRoles.BuiltIn' | translate }}
    </sub>
  </h4>

  <div *ngIf="!permissionRole.isBuiltIn">
    <mat-form-field class="w-75">
      <input matInput name="name" [(ngModel)]="permissionRole.name"
             placeholder="{{ 'Common.Name' | translate }}" required>
    </mat-form-field>
  </div>

  <div class="p-3">
    <ul class="permissions-container">
      <li *ngFor="let featurePermission of availableFeaturePermissions" class="mb-2 text-left">
        <mat-checkbox name="check-{{ featurePermission }}"
                      [disabled]="permissionRole.isBuiltIn"
                      [(ngModel)]="permissionRoleAvailableFeaturePermissionsMap[featurePermission]">
          {{ 'Dashboard.PermissionRoles.FeaturePermissions.' + featurePermission | translate }}
        </mat-checkbox>
      </li>
    </ul>
  </div>

  <div class="mt-1 text-right">
    <button mat-raised-button
            *ngIf="!permissionRole.isBuiltIn"
            (click)="removeButtonClickAsync()"
            color="warn" class="float-left mr-2">
      {{ 'Common.Remove' | translate }}
    </button>

    <button mat-raised-button (click)="closeDialog()">
      {{ 'Common.Cancel' | translate }}
    </button>

    <app-button *ngIf="!permissionRole.isBuiltIn"
                class="ml-3" color="primary"
                [loading]="saveInProgress"
                (clicked)="saveChangesAsync()">
      {{ 'Common.Save' | translate }}
    </app-button>
  </div>
</div>
