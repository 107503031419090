import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FeaturePermissions } from '../../../server/src/models/feature-permissions.enum';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { CompanyJoinComponent } from './company-join/company-join.component';
import { AssetControlAssetTypesComponent } from './dashboard/asset-control/asset-control-asset-types/asset-control-asset-types.component';
import { AssetControlReportListComponent } from './dashboard/asset-control/asset-control-reports/asset-control-report-list/asset-control-report-list.component';
import { AssetControlReportViewComponent } from './dashboard/asset-control/asset-control-reports/asset-control-report-view/asset-control-report-view.component';
import { AssetControlReportsComponent } from './dashboard/asset-control/asset-control-reports/asset-control-reports.component';
import { AssetControlRoomCategoriesComponent } from './dashboard/asset-control/asset-control-room-categories/asset-control-room-categories.component';
import { AssetControlRoomsAssetsComponent } from './dashboard/asset-control/asset-control-rooms/asset-control-rooms-assets/asset-control-rooms-assets.component';
import { AssetControlRoomsEditComponent } from './dashboard/asset-control/asset-control-rooms/asset-control-rooms-edit/asset-control-rooms-edit.component';
import { AssetControlRoomsEventsComponent } from './dashboard/asset-control/asset-control-rooms/asset-control-rooms-events/asset-control-rooms-events.component';
import { AssetControlRoomsListComponent } from './dashboard/asset-control/asset-control-rooms/asset-control-rooms-list/asset-control-rooms-list.component';
import { AssetControlRoomsComponent } from './dashboard/asset-control/asset-control-rooms/asset-control-rooms.component';
import { AssetControlComponent } from './dashboard/asset-control/asset-control.component';
import { ControlCheckEventsEditComponent } from './dashboard/control/control-check-events/control-check-events-edit/control-check-events-edit.component';
import { ControlCheckEventsListComponent } from './dashboard/control/control-check-events/control-check-events-list/control-check-events-list.component';
import { ControlCheckEventsComponent } from './dashboard/control/control-check-events/control-check-events.component';
import { ControlReportViewComponent } from './dashboard/control/control-reports/control-report-view/control-report-view.component';
import { ControlReportsListComponent } from './dashboard/control/control-reports/control-reports-list/control-reports-list.component';
import { ControlReportsComponent } from './dashboard/control/control-reports/control-reports.component';
import { ControlComponent } from './dashboard/control/control.component';
import { DashboardHomeComponent } from './dashboard/dashboard-home/dashboard-home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EditKnowledgeBaseArticleComponent } from './dashboard/knowledge-base/edit-knowledge-base-article/edit-knowledge-base-article.component';
import { KnowledgeBaseArticleListComponent } from './dashboard/knowledge-base/knowledge-base-article-list/knowledge-base-article-list.component';
import { KnowledgeBaseComponent } from './dashboard/knowledge-base/knowledge-base.component';
import { ViewKnowledgeBaseArticleComponent } from './dashboard/knowledge-base/view-knowledge-base-article/view-knowledge-base-article.component';
import { CompleteLearningCampaignComponent } from './dashboard/learning/complete-learning-campaign/complete-learning-campaign.component';
import { EditLearningCampaignComponent } from './dashboard/learning/learning-campaigns/edit-learning-campaign/edit-learning-campaign.component';
import { LearningCampaignListComponent } from './dashboard/learning/learning-campaigns/learning-campaign-list/learning-campaign-list.component';
import { LearningCampaignsComponent } from './dashboard/learning/learning-campaigns/learning-campaigns.component';
import { LearningCertificateTemplatesComponent } from './dashboard/learning/learning-certificate-templates/learning-certificate-templates.component';
import { LearningCertificatesComponent } from './dashboard/learning/learning-certificates/learning-certificates.component';
import { LearningReportViewComponent } from './dashboard/learning/learning-reports/learning-report-view/learning-report-view.component';
import { LearningReportsListComponent } from './dashboard/learning/learning-reports/learning-reports-list/learning-reports-list.component';
import { LearningReportsComponent } from './dashboard/learning/learning-reports/learning-reports.component';
import { LearningComponent } from './dashboard/learning/learning.component';
import { OrganizationDepartmentsComponent } from './dashboard/organization/organization-departments/organization-departments.component';
import { OrganizationPermissionRolesComponent } from './dashboard/organization/organization-permission-roles/organization-permission-roles.component';
import { OrganizationRolesComponent } from './dashboard/organization/organization-roles/organization-roles.component';
import { OrganizationUsersComponent } from './dashboard/organization/organization-users/organization-users.component';
import { OrganizationComponent } from './dashboard/organization/organization.component';
import { EditProcedureGeneralComponent } from './dashboard/procedures/edit-procedure/edit-procedure-general/edit-procedure-general.component';
import { EditProcedureStepsComponent } from './dashboard/procedures/edit-procedure/edit-procedure-steps/edit-procedure-steps.component';
import { EditProcedureTestComponent } from './dashboard/procedures/edit-procedure/edit-procedure-test/edit-procedure-test.component';
import { EditProcedureComponent } from './dashboard/procedures/edit-procedure/edit-procedure.component';
import { ProcedureCategoriesComponent } from './dashboard/procedures/procedure-categories/procedure-categories.component';
import { ProcedureListComponent } from './dashboard/procedures/procedure-list/procedure-list.component';
import { ProceduresComponent } from './dashboard/procedures/procedures.component';
import { ViewProcedureComponent } from './dashboard/procedures/view-procedure/view-procedure.component';
import { CompanySettingsComponent } from './dashboard/settings/company-settings.component';
import { HomeComponent } from './home/home.component';
import { ActivateEmailComponent } from './login/activate-email/activate-email.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { LoginSuccessComponent } from './login/login-success/login-success.component';
import { LoginComponent } from './login/login.component';
import { RestorePasswordComponent } from './login/restore-password/restore-password.component';
import { SSOLoginComponent } from './login/sso-login/sso-login.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { RequestDemoComponent } from './request-demo/request-demo.component';
import { FeaturePermissionRouteGuard } from './shared/routing/route-guards/feature-permission.guard';
import { HasCompanyRouteGuard } from './shared/routing/route-guards/has-company.guard';
import { LoggedInRouteGuard } from './shared/routing/route-guards/logged-in.guard';
import { UserActiveRouteGuard } from './shared/routing/route-guards/user-active.guard';
import { RouteLocations } from './shared/routing/route-locations.enum';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';

const routes: Routes = [
  {
    path: RouteLocations.Home,
    component: HomeComponent,
    pathMatch: 'full',
  },
  {
    path: RouteLocations.RequestDemo,
    component: RequestDemoComponent,
  },
  {
    path: RouteLocations.Login,
    component: LoginComponent,
  },
  {
    path: RouteLocations.ActivateEmail,
    component: ActivateEmailComponent,
    canActivate: [
      LoggedInRouteGuard,
    ],
  },
  {
    path: RouteLocations.ForgotPassword,
    component: ForgotPasswordComponent,
  },
  {
    path: RouteLocations.RestorePassword,
    component: RestorePasswordComponent,
  },
  {
    path: RouteLocations.SSOLogin,
    component: SSOLoginComponent,
  },
  {
    path: RouteLocations.LoginSuccess,
    component: LoginSuccessComponent,
  },
  {
    path: RouteLocations.CompanyJoin,
    component: CompanyJoinComponent,
    canActivate: [
      LoggedInRouteGuard,
      UserActiveRouteGuard,
    ],
  },
  {
    path: RouteLocations.Dashboard,
    component: DashboardComponent,
    canActivate: [
      LoggedInRouteGuard,
      UserActiveRouteGuard,
      HasCompanyRouteGuard,
    ],
    children: [
      {
        path: RouteLocations.DashboardChildren.DashboardHome,
        component: DashboardHomeComponent,
        pathMatch: 'full',
      },
      {
        path: RouteLocations.DashboardChildren.ProcedureCategories,
        component: ProcedureCategoriesComponent,
        data: {
          requiredFeaturePermissions: [
            FeaturePermissions.EditProcedures,
          ],
        },
      },
      {
        path: RouteLocations.DashboardChildren.Procedures,
        component: ProceduresComponent,
        canActivate: [
          FeaturePermissionRouteGuard,
        ],
        data: {
          requiredFeaturePermissions: [
            FeaturePermissions.ViewProcedures,
          ],
        },
        children: [
          {
            path: RouteLocations.DashboardChildren.ProceduresChildren.ProcedureList,
            component: ProcedureListComponent,
            pathMatch: 'full',
          },
          {
            path: RouteLocations.DashboardChildren.ProceduresChildren.EditProcedure,
            component: EditProcedureComponent,
            children: [
              {
                path: RouteLocations.DashboardChildren.ProceduresChildren.EditProcedureChildren.General,
                component: EditProcedureGeneralComponent,
                pathMatch: 'full',
              },
              {
                path: RouteLocations.DashboardChildren.ProceduresChildren.EditProcedureChildren.Steps,
                component: EditProcedureStepsComponent,
              },
              {
                path: RouteLocations.DashboardChildren.ProceduresChildren.EditProcedureChildren.Test,
                component: EditProcedureTestComponent,
              },
              {
                path: '**',
                redirectTo: RouteLocations.DashboardChildren.ProceduresChildren.EditProcedureChildren.General,
              },
            ],
          },
          {
            path: RouteLocations.DashboardChildren.ProceduresChildren.ViewProcedure,
            component: ViewProcedureComponent,
            pathMatch: 'full',
          },
          {
            path: '**',
            redirectTo: RouteLocations.DashboardChildren.ProceduresChildren.ProcedureList,
          },
        ],
      },
      {
        path: RouteLocations.DashboardChildren.KnowledgeBaseArticles,
        component: KnowledgeBaseComponent,
        canActivate: [
          FeaturePermissionRouteGuard,
        ],
        data: {
          requiredFeaturePermissions: [
            FeaturePermissions.ViewKnowledgeBaseArticles,
          ],
        },
        children: [
          {
            path: RouteLocations.DashboardChildren.KnowledgeBaseArticlesChildren.ArticlesList,
            component: KnowledgeBaseArticleListComponent,
          },
          {
            path: RouteLocations.DashboardChildren.KnowledgeBaseArticlesChildren.ViewArticle,
            component: ViewKnowledgeBaseArticleComponent,
            pathMatch: 'full',
          },
          {
            path: RouteLocations.DashboardChildren.KnowledgeBaseArticlesChildren.EditArticle,
            component: EditKnowledgeBaseArticleComponent,
            canActivate: [
              FeaturePermissionRouteGuard,
            ],
            data: {
              requiredFeaturePermissions: [
                FeaturePermissions.EditKnowledgeBaseArticles,
              ],
            },
          },
        ],
      },
      {
        path: RouteLocations.DashboardChildren.Organization,
        component: OrganizationComponent,
        canActivate: [
          FeaturePermissionRouteGuard,
        ],
        data: {
          requiredFeaturePermissions: [
            FeaturePermissions.ManageCompanyUsers,
          ],
        },
        children: [
          {
            path: RouteLocations.DashboardChildren.OrganizationChildren.Departments,
            component: OrganizationDepartmentsComponent,
          },
          {
            path: RouteLocations.DashboardChildren.OrganizationChildren.Roles,
            component: OrganizationRolesComponent,
          },
          {
            path: RouteLocations.DashboardChildren.OrganizationChildren.Permissions,
            component: OrganizationPermissionRolesComponent,
          },
          {
            path: RouteLocations.DashboardChildren.OrganizationChildren.Users,
            component: OrganizationUsersComponent,
          },
          {
            path: '**',
            redirectTo: RouteLocations.DashboardChildren.OrganizationChildren.Users,
          },
        ],
      },
      {
        path: RouteLocations.DashboardChildren.Learning,
        component: LearningComponent,
        children: [
          {
            path: RouteLocations.DashboardChildren.LearningChildren.CompleteCampaign,
            component: CompleteLearningCampaignComponent,
          },
          {
            path: RouteLocations.DashboardChildren.LearningChildren.Reports,
            component: LearningReportsComponent,
            canActivate: [
              FeaturePermissionRouteGuard,
            ],
            data: {
              requiredFeaturePermissions: [
                FeaturePermissions.ViewLearningReports,
              ],
            },
            children: [
              {
                path: RouteLocations.DashboardChildren.LearningChildren.ReportsChildren.List,
                component: LearningReportsListComponent,
              },
              {
                path: RouteLocations.DashboardChildren.LearningChildren.ReportsChildren.View,
                component: LearningReportViewComponent,
              },
              {
                path: '**',
                redirectTo: RouteLocations.DashboardChildren.LearningChildren.ReportsChildren.List,
              },
            ],
          },
          {
            path: RouteLocations.DashboardChildren.LearningChildren.Campaigns,
            component: LearningCampaignsComponent,
            canActivate: [
              FeaturePermissionRouteGuard,
            ],
            data: {
              requiredFeaturePermissions: [
                FeaturePermissions.ManageLearningCampaigns,
              ],
            },
            children: [
              {
                path: RouteLocations.DashboardChildren.LearningChildren.CampaignsChildren.CampaignList,
                component: LearningCampaignListComponent,
              },
              {
                path: RouteLocations.DashboardChildren.LearningChildren.CampaignsChildren.EditCampaign,
                component: EditLearningCampaignComponent,
              },
              {
                path: '**',
                redirectTo: RouteLocations.DashboardChildren.LearningChildren.CampaignsChildren.CampaignList,
              },
            ],
          },
          {
            path: RouteLocations.DashboardChildren.LearningChildren.Certificates,
            component: LearningCertificatesComponent,
            canActivate: [
              FeaturePermissionRouteGuard,
            ],
            data: {
              requiredFeaturePermissions: [
                FeaturePermissions.ManageLearningCertificates,
              ],
            },
          },
          {
            path: RouteLocations.DashboardChildren.LearningChildren.CertificateTemplates,
            component: LearningCertificateTemplatesComponent,
            canActivate: [
              FeaturePermissionRouteGuard,
            ],
            data: {
              requiredFeaturePermissions: [
                FeaturePermissions.ManageLearningCertificates,
              ],
            },
          },
          {
            path: '**',
            redirectTo: RouteLocations.DashboardChildren.LearningChildren.Campaigns,
          },
        ],
      },
      {
        path: RouteLocations.DashboardChildren.Control,
        component: ControlComponent,
        children: [
          {
            path: RouteLocations.DashboardChildren.ControlChildren.ControlCheckEvents,
            component: ControlCheckEventsComponent,
            canActivate: [
              FeaturePermissionRouteGuard,
            ],
            data: {
              requiredFeaturePermissions: [
                FeaturePermissions.ManageControlCheckEvents,
              ],
            },
            children: [
              {
                path: RouteLocations.DashboardChildren.ControlChildren.ControlCheckEventsChildren.ListEvents,
                component: ControlCheckEventsListComponent,
              },
              {
                path: RouteLocations.DashboardChildren.ControlChildren.ControlCheckEventsChildren.EditEvent,
                component: ControlCheckEventsEditComponent,
              },
              {
                path: '**',
                redirectTo: RouteLocations.DashboardChildren.ControlChildren.ControlCheckEventsChildren.ListEvents,
              },
            ],
          },
          {
            path: RouteLocations.DashboardChildren.ControlChildren.ControlReports,
            component: ControlReportsComponent,
            canActivate: [
              FeaturePermissionRouteGuard,
            ],
            data: {
              requiredFeaturePermissions: [
                FeaturePermissions.ViewControlReports,
              ],
            },
            children: [
              {
                path: RouteLocations.DashboardChildren.ControlChildren.ControlReportsChildren.List,
                component: ControlReportsListComponent,
              },
              {
                path: RouteLocations.DashboardChildren.ControlChildren.ControlReportsChildren.View,
                component: ControlReportViewComponent,
              },
              {
                path: '**',
                redirectTo: RouteLocations.DashboardChildren.ControlChildren.ControlReportsChildren.List,
              },
            ],
          },
          {
            path: '**',
            redirectTo: RouteLocations.DashboardChildren.ControlChildren.ControlCheckEvents,
          },
        ],
      },
      {
        path: RouteLocations.DashboardChildren.AssetControl,
        component: AssetControlComponent,
        children: [
          {
            path: RouteLocations.DashboardChildren.AssetControlChildren.RoomCategories,
            component: AssetControlRoomCategoriesComponent,
            canActivate: [
              FeaturePermissionRouteGuard,
            ],
            data: {
              requiredFeaturePermissions: [
                FeaturePermissions.ManageAssetControl,
              ],
            },
          },
          {
            path: RouteLocations.DashboardChildren.AssetControlChildren.Rooms,
            component: AssetControlRoomsComponent,
            canActivate: [
              FeaturePermissionRouteGuard,
            ],
            data: {
              requiredFeaturePermissions: [
                FeaturePermissions.ManageAssetControl,
              ],
            },
            children: [
              {
                path: RouteLocations.DashboardChildren.AssetControlChildren.RoomsChildren.List,
                component: AssetControlRoomsListComponent,
                pathMatch: 'full',
              },
              {
                path: RouteLocations.DashboardChildren.AssetControlChildren.RoomsChildren.Assets,
                component: AssetControlRoomsAssetsComponent,
              },
              {
                path: RouteLocations.DashboardChildren.AssetControlChildren.RoomsChildren.Events,
                component: AssetControlRoomsEventsComponent,
              },
              {
                path: RouteLocations.DashboardChildren.AssetControlChildren.RoomsChildren.Edit,
                component: AssetControlRoomsEditComponent,
              },
              {
                path: '**',
                redirectTo: RouteLocations.DashboardChildren.AssetControlChildren.RoomsChildren.List,
              },
            ],
          },
          {
            path: RouteLocations.DashboardChildren.AssetControlChildren.AssetTypes,
            component: AssetControlAssetTypesComponent,
            canActivate: [
              FeaturePermissionRouteGuard,
            ],
            data: {
              requiredFeaturePermissions: [
                FeaturePermissions.ManageAssetControl,
              ],
            },
          },
          {
            path: RouteLocations.DashboardChildren.AssetControlChildren.Reports,
            component: AssetControlReportsComponent,
            canActivate: [
              FeaturePermissionRouteGuard,
            ],
            data: {
              requiredFeaturePermissions: [
                FeaturePermissions.ViewAssetControlReports,
              ],
            },
            children: [
              {
                path: RouteLocations.DashboardChildren.AssetControlChildren.ReportsChildren.List,
                component: AssetControlReportListComponent,
              },
              {
                path: RouteLocations.DashboardChildren.AssetControlChildren.ReportsChildren.View,
                component: AssetControlReportViewComponent,
              },
              {
                path: '**',
                redirectTo: RouteLocations.DashboardChildren.AssetControlChildren.ReportsChildren.List,
              },
            ],
          },
          {
            path: '**',
            redirectTo: RouteLocations.DashboardChildren.AssetControlChildren.Reports,
          },
        ],
      },
      {
        path: RouteLocations.DashboardChildren.Settings,
        component: CompanySettingsComponent,
        canActivate: [
          FeaturePermissionRouteGuard,
        ],
        data: {
          requiredFeaturePermissions: [
            FeaturePermissions.ManageCompanySettings,
          ],
        },
      },
      {
        path: '**',
        redirectTo: RouteLocations.DashboardChildren.DashboardHome,
      },
    ],
  },
  {
    path: RouteLocations.Settings,
    component: AccountSettingsComponent,
    canActivate: [
      LoggedInRouteGuard,
      UserActiveRouteGuard,
    ],
  },
  {
    path: RouteLocations.TermsAndConditions,
    component: TermsAndConditionsComponent,
  },
  {
    path: RouteLocations.PrivacyPolicy,
    component: PrivacyPolicyComponent,
  },
  {
    path: '**',
    redirectTo: RouteLocations.Home,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [
    LoggedInRouteGuard,
    UserActiveRouteGuard,
    HasCompanyRouteGuard,
    FeaturePermissionRouteGuard,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {

}
