import { CoolDialogService } from '@angular-cool/dialogs';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { interval } from 'rxjs';
import { filter } from 'rxjs/operators';
import { RouteLocations } from './shared/routing/route-locations.enum';
import { WINDOW } from './shared/services/injection-tokens';
import { LanguageService } from './shared/services/language/language.service';
import { LoggerService } from './shared/services/logger/logger.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private _pwaUpdateDialogIsShown: boolean;

  constructor(angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
              router: Router,
              @Inject(WINDOW) private _window: Window,
              private _changeDetector: ChangeDetectorRef,
              private _swUpdate: SwUpdate,
              private _loggerService: LoggerService,
              private _languageService: LanguageService,
              private _dialogService: CoolDialogService) {
    angulartics2GoogleAnalytics.startTracking();

    router.events
      .pipe(
        untilDestroyed(this),
        filter(event => event instanceof NavigationStart),
      )
      .subscribe((event: NavigationStart) => {
        this.isLeftNavShown = event.url.startsWith('/' + RouteLocations.Dashboard);

        this._changeDetector.markForCheck();
      });
  }

  public isLeftNavShown: boolean;

  public async ngOnInit() {
    await this._subscribeToAndSchedulePWAUpdateAsync();
  }

  public ngOnDestroy(): void {
  }

  private async _subscribeToAndSchedulePWAUpdateAsync() {
    this._swUpdate.available
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(async () => {
        if (this._pwaUpdateDialogIsShown) {
          return;
        }

        this._pwaUpdateDialogIsShown = true;

        const updateDialogResult = await this._dialogService.showDialog({
          titleText: await this._languageService.getTranslationAsync('SoftwareUpdateDialog.Title'),
          questionText: await this._languageService.getTranslationAsync('SoftwareUpdateDialog.Description'),
          confirmActionButtonText: await this._languageService.getTranslationAsync('Common.Update'),
          cancelActionButtonText: await this._languageService.getTranslationAsync('Common.Cancel'),
          confirmActionButtonColor: 'primary',
        });

        this._pwaUpdateDialogIsShown = false;

        if (updateDialogResult.isConfirmed) {
          this._window.location.reload();
        }
      });

    // check for PWA update every 30 seconds
    interval(1000 * 30)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(async () => {
        await this._tryCheckingForPWAUpdate();
      });

    await this._tryCheckingForPWAUpdate();
  }

  private async _tryCheckingForPWAUpdate() {
    try {
      await this._swUpdate.checkForUpdate();
    } catch(e) {
      this._loggerService.captureBreadcrumb('Failed to check for PWA update');

      this._loggerService.error(e);
    }
  }
}
