import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
  AssetControlReportDateResolution,
  AssetControlReportGenerateRequestDTO,
} from '../../../../../../../../server/src/dto/asset-control-report.dto';
import { DateUtility } from '../../../../../../../../server/src/utils/date-utility';
import { DateProviderService } from '../../../../../shared/utils/date-provider.service';
import { AssetControlReportsService } from '../../../shared/services/asset-control-reports.service';

@Component({
  selector: 'app-asset-control-report-generate-dialog',
  templateUrl: './asset-control-report-generate-dialog.component.html',
  styleUrls: ['./asset-control-report-generate-dialog.component.scss'],
})
export class AssetControlReportGenerateDialogComponent implements OnInit {

  constructor(private _assetControlReportsService: AssetControlReportsService,
              private _dateProviderService: DateProviderService,
              private _dialog: MatDialogRef<AssetControlReportGenerateDialogComponent>) {

  }

  public isLoading: boolean;
  public hasError: boolean;

  public filters: AssetControlReportGenerateRequestDTO = {
    startDate: null,
    endDate: null,
    dateResolution: AssetControlReportDateResolution.Days,
    roomCategory: null,
    responsibleUser: null,
    responsibleRole: null,
    assetType: null,
    room: null,
  };

  public AssetControlReportDateResolution = AssetControlReportDateResolution;

  public ngOnInit() {
    this.filters.startDate = DateUtility.addDays(this._dateProviderService.now(), -20);
    this.filters.endDate = DateUtility.addDays(this._dateProviderService.now(), 20);
  }

  public onCancel() {
    this._dialog.close();
  }

  public async onGenerateReportClickAsync(filterForm: NgForm) {
    filterForm.form.markAllAsTouched();

    if (!filterForm.valid) {
      return;
    }

    try {
      this.hasError = false;
      this.isLoading = true;

      await this._assetControlReportsService.generateReportAsync(this.filters);

      this._dialog.close();
    } catch {
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  }
}
