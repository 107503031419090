import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CompanyService } from '../../../../shared/services/company/company.service';

@Component({
  selector: 'app-add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.scss'],
})
export class AddUserDialogComponent implements OnInit {

  constructor(private _dialog: MatDialogRef<AddUserDialogComponent>,
              private _companyService: CompanyService) {
  }

  public inviteLink: string;
  public inProgress: boolean;
  public hasError: boolean;
  public isDone: boolean;

  public emailAddresses: string;

  public async ngOnInit() {
    this.inviteLink = await this._companyService.getJoinLinkAsync();
  }

  public closeDialog() {
    this._dialog.close();
  }

  public async sendInviteEmailsAsync() {
    try {
      this.inProgress = true;
      this.hasError = false;
      this.isDone = false;

      await this._companyService.sendInviteToEmailAddressesAsync(this.emailAddresses.replace(/\s/g, '').split(','));

      this.emailAddresses = '';
      this.isDone = true;
    } catch(e) {
      this.hasError = true;
    } finally {
      this.inProgress = false;
    }
  }

  public copyClick(inviteLinkInput: HTMLInputElement) {
    inviteLinkInput.select();

    document.execCommand('copy');
  }
}
