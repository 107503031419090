<div class="canvas-controls-container"
     (mouseup)="onMouseUp()"
     (mouseleave)="onMouseUp()"
     (touchcancel)="onMouseUp()"
     (touchend)="onMouseUp()">
  <div>
    <div class="unit"></div>

    <div class="unit hover-strong cursor-pointer"
         (mousedown)="moveLeft()"
         (touchstart)="moveLeft()"
         (mouseleave)="onMouseUp()"
         (touchcancel)="onMouseUp()"
         (touchend)="onMouseUp()">
      <fa-icon icon="chevron-left"></fa-icon>
    </div>

    <div class="unit"></div>
  </div>
  <div>
    <div class="unit hover-strong cursor-pointer"
         (mousedown)="moveUp()"
         (touchstart)="moveUp()"
         (mouseleave)="onMouseUp()"
         (touchcancel)="onMouseUp()"
         (touchend)="onMouseUp()">
      <fa-icon icon="chevron-up"></fa-icon>
    </div>

    <div class="unit center d-flex flex-row">
      <div class="hover-strong cursor-pointer"
           (mousedown)="zoomIn()"
           (touchstart)="zoomIn()"
           (mouseleave)="onMouseUp()"
           (touchcancel)="onMouseUp()"
           (touchend)="onMouseUp()">
        <fa-icon icon="plus"></fa-icon>
      </div>

      <div class="hover-strong cursor-pointer ml-2"
           (mousedown)="zoomOut()"
           (touchstart)="zoomOut()"
           (mouseleave)="onMouseUp()"
           (touchcancel)="onMouseUp()"
           (touchend)="onMouseUp()">
        <fa-icon icon="minus"></fa-icon>
      </div>
    </div>

    <div class="unit hover-strong cursor-pointer"
         (mousedown)="moveDown()"
         (touchstart)="moveDown()"
         (mouseleave)="onMouseUp()"
         (touchcancel)="onMouseUp()"
         (touchend)="onMouseUp()">
      <fa-icon icon="chevron-down"></fa-icon>
    </div>
  </div>
  <div>
    <div class="unit"></div>

    <div class="unit hover-strong cursor-pointer"
         (mousedown)="moveRight()"
         (touchstart)="moveRight()"
         (mouseleave)="onMouseUp()"
         (touchcancel)="onMouseUp()"
         (touchend)="onMouseUp()">
      <fa-icon icon="chevron-right"></fa-icon>
    </div>

    <div class="unit"></div>
  </div>
</div>