import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { LearningReportGenerateRequestDTO } from '../../../../../../../../server/src/dto/learning-reports.dto';
import { LearningCampaignSelectComponent } from '../../../../../shared/components/learning-campaign-select/learning-campaign-select.component';
import { LearningReportService } from '../../shared/services/learning-report.service';

@Component({
  selector: 'app-learning-report-generate-dialog',
  templateUrl: './learning-report-generate-dialog.component.html',
  styleUrls: ['./learning-report-generate-dialog.component.scss'],
})
export class LearningReportGenerateDialogComponent implements OnInit {
  constructor(private _learningReportService: LearningReportService,
              private _dialog: MatDialogRef<LearningReportGenerateDialogComponent>) {

  }

  public isLoading: boolean;
  public hasError: boolean;

  public filters: LearningReportGenerateRequestDTO = {
    startDate: null,
    endDate: null,
    departments: [],
    learningCampaign: null,
  };

  @ViewChild(LearningCampaignSelectComponent, { static: true })
  public learningCampaignSelect: LearningCampaignSelectComponent;

  ngOnInit() {
  }

  public onCancel() {
    this._dialog.close();
  }

  public async onGenerateReportClickAsync(filterForm: NgForm) {
    filterForm.form.markAllAsTouched();

    this.learningCampaignSelect.markAsTouched();

    if (!filterForm.valid) {
      return;
    }

    try {
      this.isLoading = true;
      this.hasError = false;

      await this._learningReportService.generateReportAsync(this.filters);

      this._dialog.close();
    } catch {
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  }
}
