import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PermissionRoleDTO } from '../../../../../../server/src/dto/permission-role.dto';
import { LanguageService } from '../../../shared/services/language/language.service';
import { OrganizationService } from '../../../shared/services/organization/organization.service';
import { EditPermissionRoleDialogConfig } from './edit-permission-role-dialog/edit-permission-role-dialog-config.interface';
import { EditPermissionRoleDialogComponent } from './edit-permission-role-dialog/edit-permission-role-dialog.component';

@Component({
  selector: 'app-organization-permission-roles',
  templateUrl: './organization-permission-roles.component.html',
  styleUrls: ['./organization-permission-roles.component.scss'],
})
export class OrganizationPermissionRolesComponent implements OnInit {
  constructor(private _organizationService: OrganizationService,
              private _dialogOpenService: MatDialog,
              private _languageService: LanguageService) {

  }

  public displayedColumns = ['name', 'featurePermissions', 'actions'];
  public isLoading: boolean;

  public dataSource: MatTableDataSource<PermissionRoleDTO>;

  @ViewChild(MatSort, { static: true })
  public sort: MatSort;

  public async ngOnInit() {
    try {
      this.isLoading = true;

      await this._loadDataAsync();
    } finally {
      this.isLoading = false;
    }
  }

  public async addNewPermissionRoleAsync() {
    await this._organizationService.upsertPermissionRoleAsync({
      name: await this._languageService.getTranslationAsync('Dashboard.PermissionRoles.NewPermissionRoleName'),
    });

    await this._loadDataAsync();
  }

  public async editPermissionRoleAsync(permissionRole: PermissionRoleDTO) {
    const dialog = this._dialogOpenService.open<EditPermissionRoleDialogComponent, EditPermissionRoleDialogConfig>(EditPermissionRoleDialogComponent, {
      data: {
        permissionRole: permissionRole,
      },
    });

    await dialog.afterClosed().toPromise();

    await this._loadDataAsync();
  }

  public filterPermissionRoles(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  private async _loadDataAsync() {
    const permissionRoles = await this._organizationService.getPermissionRolesAsync();

    this.dataSource = new MatTableDataSource(permissionRoles);
    this.dataSource.sort = this.sort;
  }
}
