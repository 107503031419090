import { CoolDialogService } from '@angular-cool/dialogs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ProcedureDTO } from '../../../../../../../server/src/dto/procedure.dto';
import { RouteNavigationService } from '../../../../shared/routing/route-navigation.service';
import { LanguageService } from '../../../../shared/services/language/language.service';
import { ProceduresService } from '../../../../shared/services/procedures/procedures.service';

@Component({
  selector: 'app-edit-procedure-general',
  templateUrl: './edit-procedure-general.component.html',
  styleUrls: ['./edit-procedure-general.component.scss'],
})
export class EditProcedureGeneralComponent implements OnInit, OnDestroy {
  constructor(private _proceduresService: ProceduresService,
              private _activatedRoute: ActivatedRoute,
              private _dialogService: CoolDialogService,
              private _languageService: LanguageService,
              private _routeNavigationService: RouteNavigationService) {

  }

  public procedure: ProcedureDTO;

  public hasDepartmentFilter: boolean;

  public hasErrorInSave: boolean;
  public hasErrorInRemove: boolean;
  public inProgress: boolean;

  public ngOnInit() {
    this._activatedRoute.parent.params
      .pipe(untilDestroyed(this))
      .subscribe(async (params: Params) => {
        const procedureId = params.procedureId;

        if (!procedureId) {
          return;
        }

        this.procedure = await this._proceduresService.getProcedureByIdAsync(procedureId);
        this.hasDepartmentFilter = this.procedure.departments && !!this.procedure.departments.length;
      });
  }

  public ngOnDestroy(): void {
  }

  public async removeProcedureAsync() {
    const removeDialogResult = await this._dialogService.showDialog({
      titleText: await this._languageService.getTranslationAsync('Dashboard.Procedures.RemoveProcedureTitle', { name: this.procedure.name }),
      questionText: await this._languageService.getTranslationAsync('Dashboard.Procedures.RemoveProcedureDescription', { name: this.procedure.name }),
      confirmActionButtonText: await this._languageService.getTranslationAsync('Common.Remove'),
      cancelActionButtonText: await this._languageService.getTranslationAsync('Common.Cancel'),
      confirmActionButtonColor: 'warn',
    });

    if (!removeDialogResult.isConfirmed) {
      return;
    }

    this.hasErrorInRemove = false;

    try {
      await this._proceduresService.removeProcedureByIdAsync(this.procedure.id);
    } catch(e) {
      this.hasErrorInRemove = true;
    }

    if (!this.hasErrorInRemove) {
      await this._routeNavigationService.goToProceduresListAsync();
    }
  }

  public async saveChangesAsync(editProcedureForm: NgForm) {
    if (editProcedureForm.invalid) {
      return;
    }

    try {
      this.inProgress = true;
      this.hasErrorInSave = false;

      if (!this.hasDepartmentFilter) {
        this.procedure.departments = [];
      }

      await this._proceduresService.updateProcedureAsync(this.procedure);

      editProcedureForm.form.markAsUntouched();
      editProcedureForm.form.markAsPristine();
    } catch {
      this.hasErrorInSave = true;
    } finally {
      this.inProgress = false;
    }
  }

  public async cancelClickAsync() {
    await this._routeNavigationService.goToViewProcedureAsync(this.procedure.id);
  }
}
