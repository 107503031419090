<div cdkDropList class="ordering-container" (cdkDropListDropped)="onDropMoveItem($event)">
  <div class="ordering-item" *ngFor="let item of items; let i = index" cdkDrag>
    <div class="mr-2 d-flex align-items-center">
      {{ i + 1 }}.
    </div>

    <ng-container
        *ngIf="itemTemplateRef"
        [ngTemplateOutlet]="itemTemplateRef"
        [ngTemplateOutletContext]="{ $implicit: item }">
    </ng-container>
  </div>
</div>