import { Component, OnInit } from '@angular/core';
import { UserContextService } from '../../shared/services/user-context/user-context.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(private _userContextService: UserContextService) {

  }

  public inProgress: boolean;
  public isDone: boolean;

  public email: string;

  ngOnInit() {
  }

  public async onSendEmailAsync() {
    try {
      this.inProgress = true;

      await this._userContextService.sendForgotPasswordEmailAsync(this.email);

      this.isDone = true;
    } finally {
      this.inProgress = false;
    }
  }
}
