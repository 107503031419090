import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { RouteNavigationService } from '../../shared/routing/route-navigation.service';
import { UserContextService } from '../../shared/services/user-context/user-context.service';

@Component({
  selector: 'app-login-success',
  templateUrl: './login-success.component.html',
  styleUrls: ['./login-success.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginSuccessComponent implements OnInit {

  constructor(private _userContextService: UserContextService,
              private _activatedRoute: ActivatedRoute,
              private _routeNavigationService: RouteNavigationService,
              private _router: Router) {
  }

  public async ngOnInit() {
    await this._userContextService.isUserLoggedInObservable
      .pipe(
        filter((isLoggedIn: boolean) => isLoggedIn),
        take(1),
      )
      .toPromise();

    if (this._activatedRoute.snapshot.queryParams.mode === 'sso') {
      this._userContextService.promoteSSOAutoLoginIdentifierCandidate();
    }

    const urlToReload = this._userContextService.getAndClearRouteToNavigateAfterLogin();

    if (urlToReload) {
      await this._router.navigateByUrl(urlToReload);
    } else {
      await this._routeNavigationService.goToDashboardAsync();
    }
  }
}