import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ContentChild, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-ordering-list',
  templateUrl: './ordering-list.component.html',
  styleUrls: ['./ordering-list.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: OrderingListComponent,
    multi: true,
  }],
})
export class OrderingListComponent implements OnInit, OnDestroy, ControlValueAccessor {
  private _onChange: (items: any[]) => void;
  private _onTouched: (items: any[]) => void;

  constructor() {
  }

  public items: any[];

  @ContentChild(TemplateRef)
  public itemTemplateRef: TemplateRef<any>;

  ngOnInit(): void {
  }

  public ngOnDestroy(): void {
  }

  public onDropMoveItem(dragEvent: CdkDragDrop<any[]>) {
    moveItemInArray(this.items, dragEvent.previousIndex, dragEvent.currentIndex);

    this._onTouched(this.items);
    this._onChange(this.items);
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  public writeValue(items: any[]): void {
    this.items = items;
  }
}
