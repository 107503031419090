export class Deferred<T = void> {
  private _innerPromiseResolve;
  private _innerPromiseReject;

  private readonly _promise: Promise<T>;

  constructor() {
    this._promise = new Promise((resolve, reject) => {
      this._innerPromiseResolve = resolve;
      this._innerPromiseReject = reject;
    });
  }

  public getPromise(): Promise<T> {
    return this._promise;
  }

  public resolve(data?: T) {
    this._innerPromiseResolve(data);
  }

  public reject(error?: Error) {
    this._innerPromiseReject(error);
  }
}