import { CoolLocalStorage } from '@angular-cool/storage';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, ReplaySubject } from 'rxjs';
import { Language } from '../../../../../../server/src/models/language.enum';
import { SettingsService } from '../settings/settings.service';
import { UserContextService } from '../user-context/user-context.service';

export const LANGUAGE_STORAGE_KEY = 'language';

@Injectable()
export class LanguageService {
  private _currentLanguageSubject: ReplaySubject<Language> = new ReplaySubject<Language>();

  constructor(private _translate: TranslateService,
              private _cookieService: CookieService,
              private _userContextService: UserContextService,
              private _userSettingsService: SettingsService,
              private _storageService: CoolLocalStorage) {

  }

  public getCurrentLanguageObservable(): Observable<Language> {
    return this._currentLanguageSubject.asObservable();
  }

  public async initializeAsync() {
    this._translate.setDefaultLang(Language.English);

    const savedLanguage = this._storageService.getItem(LANGUAGE_STORAGE_KEY);

    await this.setLanguageAsync(<Language>savedLanguage || Language.English);

    this._userContextService.userObservable
      .subscribe(async user => {
        if (user.language !== this.getCurrentLanguage()) {
          await this.setLanguageAsync(user.language);
        }
      });
  }

  public async setLanguageAsync(language: Language) {
    this._cookieService.set(
      'language',
      language,
      new Date(3000, 1, 1),
      '/',
      window.location.hostname.substring(window.location.hostname.indexOf('.')),
      true,
      'None',
    );

    this._storageService.setItem(LANGUAGE_STORAGE_KEY, language);

    if (this._userContextService.user && this._userContextService.user.language !== language) {
      await this._userSettingsService.setUserSettingsAsync({
        language: language,
      });
    }

    this._translate.use(language);

    this._currentLanguageSubject.next(language);
  }

  public getCurrentLanguage(): Language {
    return <Language>this._translate.currentLang;
  }

  public getTranslationAsync(key: string, params: any = undefined): Promise<string> {
    return this._translate.get(key, params).toPromise();
  }
}