import { CoolHttp } from '@angular-cool/http';
import { Injectable } from '@angular/core';
import { LearningCertificatesGenerateRequestDTO } from '../../../../../../server/src/dto/learning-certificates.dto';
import { ReportDTO } from '../../../../../../server/src/dto/report.dto';

@Injectable()
export class LearningCertificatesService {
  constructor(private _http: CoolHttp) {

  }

  public async getCertificatesAsync(): Promise<ReportDTO<LearningCertificatesGenerateRequestDTO>[]> {
    return await this._http.getAsync('api/companies/active/learning/certificates');
  }

  public async generateCertificateAsync(generateRequest: LearningCertificatesGenerateRequestDTO): Promise<void> {
    await this._http.postAsync('api/companies/active/learning/certificates', generateRequest);
  }

  public async deleteCertificateByIdAsync(certificateId: string): Promise<void> {
    await this._http.deleteAsync(`api/companies/active/learning/certificates/${ certificateId }`);
  }
}