<app-page-header
    pageTitle="{{ (isEditing ? 'Dashboard.Control.EditControlCheck' : 'Dashboard.Control.RecordControlCheck') | translate }}"></app-page-header>

<div *ngIf="controlCheckEvent">
  <mat-card class="mat-elevation-z4">
    <mat-card-content class="px-3">
      <form #saveForm="ngForm" class="selector-row">
        <div>
          <div class="d-flex flex-row">
            <app-procedure-select class="flex-grow-1 mr-3"
                                  [(ngModel)]="controlCheckEvent.procedure"
                                  (ngModelChange)="onSelectedProcedureChange($event)"
                                  name="procedure" required>
            </app-procedure-select>

            <app-department-select class="flex-grow-1 ml-3"
                                   [(ngModel)]="controlCheckEvent.department" name="department"
                                   [multiple]="false" required>
            </app-department-select>
          </div>
          <div>
            <app-user-selector [(ngModel)]="controlCheckEvent.targetUserIds" name="target-users">
            </app-user-selector>
          </div>
          <div>
            <mat-form-field class="w-100">
              <textarea matInput
                        class="w-100"
                        name="notes"
                        placeholder="{{ 'Common.Notes' | translate }}"
                        [(ngModel)]="controlCheckEvent.notes">
              </textarea>
            </mat-form-field>
          </div>
        </div>

        <div class="text-right justify-self-end">
          <span *ngIf="hasError" class="text-danger mr-2">
            {{ 'Dashboard.Control.FailedToSaveControlCheckEvent' | translate }}
          </span>

          <app-button color="primary" [loading]="saveInProgress"
                      [disabled]="!controlCheckEvent.procedure || !controlCheckEvent.department"
                      (clicked)="saveControlCheckAsync(saveForm)">
            {{ 'Common.Save' | translate }}
          </app-button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="procedure" class="mat-elevation-z4 mt-3">
    <mat-card-header>
      <mat-card-title>
        {{ procedure.name }} - {{ 'Common.Steps' | translate }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="px-3">
        <div *ngFor="let procedureStep of procedure.steps; let i = index"
             class="step p-4 d-flex flex-row align-items-center cursor-pointer"
             [class.is-ok]="procedureStepChecks[procedureStep.id]"
             (click)="toggleStepCheck(procedureStep)">
          <div>
            <fa-icon [icon]="procedureStepChecks[procedureStep.id] ? 'check-circle' : 'times-circle'"
                     [class.color-warn]="!procedureStepChecks[procedureStep.id]"
                     [class.color-success]="procedureStepChecks[procedureStep.id]"
                     size="2x">
            </fa-icon>
          </div>

          <div class="step-image-container ml-3">
            <app-procedure-step-image [step]="procedureStep" [index]="i + 1" size="small">
            </app-procedure-step-image>
          </div>

          <h6 class="ml-2 mb-0">{{ procedureStep.name }}?</h6>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>