import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LearningCertificateTemplateDTO } from '../../../../../../../server/src/dto/learning-certificates.dto';
import { LearningCertificateTemplatesService } from '../../../../shared/services/learning-certificates/learning-certificate-templates.service';
import { EditLearningCertificateTemplateDialogConfig } from './edit-learning-certificate-template-dialog-config.interface';

@Component({
  selector: 'app-edit-learning-certificate-template-dialog',
  templateUrl: './edit-learning-certificate-template-dialog.component.html',
  styleUrls: ['./edit-learning-certificate-template-dialog.component.scss'],
})
export class EditLearningCertificateTemplateDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) private _dialogConfig: EditLearningCertificateTemplateDialogConfig,
              private _dialog: MatDialogRef<EditLearningCertificateTemplateDialogComponent>,
              private _learningCertificateTemplatesService: LearningCertificateTemplatesService) {

  }

  public saveInProgress: boolean;
  public showError: boolean;

  public template: LearningCertificateTemplateDTO;

  ngOnInit() {
    this.template = JSON.parse(JSON.stringify(this._dialogConfig.template));
  }

  public async saveChangesAsync(templateSaveForm: NgForm) {
    if (!templateSaveForm.valid) {
      return;
    }

    try {
      this.saveInProgress = true;
      this.showError = false;

      await this._learningCertificateTemplatesService.upsertLearningCertificateTemplateByIdAsync(this.template);

      this.closeDialog();
    } catch {
      this.showError = true;
    } finally {
      this.saveInProgress = false;
    }
  }

  public closeDialog() {
    this._dialog.close();
  }
}
