import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LimitedCompanyUserDTO } from '../../../../../../server/src/dto/company-user.dto';
import { LanguageService } from '../../services/language/language.service';
import { OrganizationService } from '../../services/organization/organization.service';

@Component({
  selector: 'app-single-user-select',
  templateUrl: './single-user-select.component.html',
  styleUrls: ['./single-user-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: SingleUserSelectComponent,
    multi: true,
  }],
})
export class SingleUserSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {
  private _onChange: (users: LimitedCompanyUserDTO) => void;
  private _onTouched: (users: LimitedCompanyUserDTO) => void;

  private _inputChangedSubject = new Subject<string>();

  constructor(private _organizationService: OrganizationService,
              private _changeDetector: ChangeDetectorRef,
              private _languageService: LanguageService) {
  }

  public filteredUsers: LimitedCompanyUserDTO[] = [];

  public selectedUser: LimitedCompanyUserDTO;

  public innerInputValue: string;

  @Input()
  public displayOnly: boolean = false;

  @Input()
  public disabled: boolean = false;

  @Input()
  public placeholder: string;

  @ViewChild('auto')
  public matAutocomplete: MatAutocomplete;

  public async ngOnInit() {
    this.filteredUsers = await this._organizationService.getCompanyUsersAsync('0', 20, '');

    this._inputChangedSubject
      .pipe(
        untilDestroyed(this),
        debounceTime(250),
      )
      .subscribe(async (inputValue: string) => {
        this.filteredUsers = await this._organizationService.getCompanyUsersAsync('0', 20, inputValue);

        let matchingUser = this.filteredUsers.find(user => user.name.toLowerCase() === inputValue.toLowerCase());

        if (matchingUser || !inputValue) {
          this._setUser(matchingUser, true);
        }

        this._changeDetector.detectChanges();
      });

    this.placeholder = this.placeholder || await this._languageService.getTranslationAsync('Common.Users');
  }

  public ngOnDestroy(): void {
  }

  public async writeValue(user: LimitedCompanyUserDTO): Promise<void> {
    this._setUser(user, false);
  }

  public selected(event: MatAutocompleteSelectedEvent): void {
    this._setUser(event.option.value, true);
  }

  public async onInputChangeAsync(inputValue: string) {
    this._inputChangedSubject.next(inputValue);
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private _setUser(user: LimitedCompanyUserDTO, sendOutsideNotification: boolean) {
    this.selectedUser = user;

    this.innerInputValue = this.selectedUser?.name;

    if (sendOutsideNotification) {
      this._onTouched && this._onTouched(this.selectedUser);
      this._onChange && this._onChange(this.selectedUser);
    }
  }
}
