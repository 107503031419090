import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { take } from 'rxjs/operators';
import { UserContextService } from '../../services/user-context/user-context.service';
import { RouteNavigationService } from '../route-navigation.service';

@Injectable()
export class LoggedInRouteGuard implements CanActivate {
  constructor(private _userContextService: UserContextService,
              private _routeNavigationService: RouteNavigationService) {

  }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this._userContextService.isUserLoggedInObservable
        .pipe(take(1))
        .subscribe((isUserLoggedIn: boolean) => {
          if (!isUserLoggedIn) {
            this._userContextService.saveRouteToNavigateAfterLogin(state.url);

            const devKeyMatch = state.url.match(/dev-key=([^&]*)/);
            const joinTokenMatch = state.url.match(/joinToken=([^&]*)/);

            if (joinTokenMatch) {
              this._routeNavigationService.goToRegistrationAsync(
                devKeyMatch && devKeyMatch[1],
                joinTokenMatch && joinTokenMatch[1],
              );
            } else {
              this._routeNavigationService.goToLoginAsync(devKeyMatch && devKeyMatch[1]);
            }
          }

          resolve(isUserLoggedIn);
        });
    });
  }
}
