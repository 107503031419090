<app-page-header pageTitle="{{ 'Dashboard.CompanySettings.Title' | translate }}"></app-page-header>

<mat-accordion>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ 'Dashboard.CompanySettings.GeneralSettings' | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <app-company-general-settings></app-company-general-settings>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ 'Dashboard.CompanySettings.WhiteLabel' | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <app-white-labeling-settings></app-white-labeling-settings>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ 'Dashboard.CompanySettings.SSOSettings' | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <app-sso-settings></app-sso-settings>
  </mat-expansion-panel>
</mat-accordion>