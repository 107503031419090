import { Component, OnInit } from '@angular/core';
import { CompanyWhiteLabelSettingsDTO } from '../../../../../../server/src/dto/company-settings.dto';
import { UserContextService } from '../../../shared/services/user-context/user-context.service';
import { CompanySettingsService } from '../shared/services/company-settings.service';

@Component({
  selector: 'app-white-labeling-settings',
  templateUrl: './white-labeling-settings.component.html',
  styleUrls: ['./white-labeling-settings.component.scss'],
})
export class WhiteLabelingSettingsComponent implements OnInit {

  constructor(private _companySettingsService: CompanySettingsService,
              private _userContextService: UserContextService) {

  }

  public settings: CompanyWhiteLabelSettingsDTO;

  public isLoading: boolean;
  public inProgress: boolean;

  public async ngOnInit() {
    try {
      this.isLoading = true;

      this.settings = await this._companySettingsService.getWhiteLabelSettingsAsync();
    } finally {
      this.isLoading = false;
    }
  }

  public async onSaveAsync() {
    try {
      this.inProgress = true;

      await this._companySettingsService.setWhiteLabelSettingsAsync(this.settings);

      await this._userContextService.refreshUserSettingsAsync();
    } finally {
      this.inProgress = false;
    }
  }
}
