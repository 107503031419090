import { CoolHttp } from '@angular-cool/http';
import { AssetDTO, AssetTypeDTO } from '../../../../../../../server/src/dto/asset-control.dto';
import { Injectable } from '@angular/core';

@Injectable()
export class AssetsService {
  constructor(private _http: CoolHttp) {

  }

  public async getAssetTypesAsync() {
    return await this._http.getAsync(`api/companies/active/asset-control/asset-types`);
  }

  public async createAssetTypeAsync(name: string) {
    await this._http.postAsync(`api/companies/active/asset-control/asset-types`, {
      name: name,
    });
  }

  public async updateAssetTypeAsync(assetType: AssetTypeDTO) {
    await this._http.postAsync(`api/companies/active/asset-control/asset-types/${ assetType.id }`, {
      id: assetType.id,
      name: assetType.name,
    });
  }

  public async removeAssetTypeAsync(assetTypeId: string) {
    await this._http.deleteAsync(`api/companies/active/asset-control/asset-types/${ assetTypeId }`);
  }

  public async getAssets(filter: string): Promise<AssetDTO[]> {
    return this._http.getAsync(`api/companies/active/asset-control/assets`, {
      params: {
        filter: filter,
      },
    });
  }
}
