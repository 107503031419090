<div id="subscription-upgrade-dialog" class="container">
  <h1>{{ 'SubscriptionDialog.Title' | translate }}</h1>

  <div class="mt-2" [innerHTML]="'SubscriptionDialog.Description' | translate"></div>

  <div class="text-center mt-3">
    <button mat-raised-button (click)="closeDialog()">
      {{ 'Common.Close' | translate }}
    </button>
  </div>
</div>
