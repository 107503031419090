import { CoolDialogService } from '@angular-cool/dialogs';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { v4 as uuid } from 'uuid';
import { ProcedureStepDTO } from '../../../../../../../server/src/dto/procedure.dto';
import { ListUtils } from '../../../../../../../server/src/utils/list-utils';
import { RouteNavigationService } from '../../../../shared/routing/route-navigation.service';
import { LanguageService } from '../../../../shared/services/language/language.service';
import { ProceduresService } from '../../../../shared/services/procedures/procedures.service';

@Component({
  selector: 'app-edit-procedure-steps',
  templateUrl: './edit-procedure-steps.component.html',
  styleUrls: ['./edit-procedure-steps.component.scss'],
})
export class EditProcedureStepsComponent implements OnInit, OnDestroy {
  private _procedureId: string;

  constructor(private _proceduresService: ProceduresService,
              private _activatedRoute: ActivatedRoute,
              private _languageService: LanguageService,
              private _dialogService: CoolDialogService,
              private _routeNavigationService: RouteNavigationService) {

  }

  public steps: ProcedureStepDTO[];

  public hasErrorInSave: boolean;
  public inProgress: boolean;
  public isLoading: boolean;

  @ViewChild('procedureStepsEditForm', { static: true })
  public procedureStepsEditForm: NgForm;

  public ngOnInit() {
    this._activatedRoute.parent.params
      .pipe(untilDestroyed(this))
      .subscribe(async (params: Params) => {
        this._procedureId = params.procedureId;

        if (!this._procedureId) {
          return;
        }

        try {
          this.isLoading = true;

          this.steps = await this._proceduresService.getProcedureStepsByProcedureIdAsync(this._procedureId);
        } finally {
          this.isLoading = false;
        }
      });
  }

  public ngOnDestroy(): void {
  }

  public async addStepAsync(editProcedureStepsForm: NgForm) {
    this.steps.push({
      id: uuid(),
      name: await this._languageService.getTranslationAsync('Dashboard.Procedures.NewProcedureStepName'),
      description: await this._languageService.getTranslationAsync('Dashboard.Procedures.NewProcedureStepDescription'),
      rank: null,
      featuredImage: null,
      files: [],
    });

    ListUtils.setRankForListItems(this.steps);

    editProcedureStepsForm.form.markAsTouched();
  }

  public async removeStepAsync(step: ProcedureStepDTO) {
    const removeDialogResult = await this._dialogService.showDialog({
      titleText: await this._languageService.getTranslationAsync('Dashboard.Procedures.RemoveStepTitle', { stepName: step.name }),
      questionText: await this._languageService.getTranslationAsync('Dashboard.Procedures.RemoveStepDescription', { stepName: step.name }),
      confirmActionButtonText: await this._languageService.getTranslationAsync('Common.Remove'),
      cancelActionButtonText: await this._languageService.getTranslationAsync('Common.Cancel'),
      confirmActionButtonColor: 'warn',
    });

    if (!removeDialogResult.isConfirmed) {
      return;
    }

    this.steps.splice(this.steps.indexOf(step), 1);
  }

  public moveStepDown(step: ProcedureStepDTO, index: number) {
    if (index >= (this.steps.length - 1)) {
      return;
    }

    this.steps.splice(index, 1);

    this.steps.splice(index + 1, 0, step);

    ListUtils.setRankForListItems(this.steps);

    this.procedureStepsEditForm.form.markAsDirty();
    this.procedureStepsEditForm.form.markAsTouched();
  }

  public moveStepUp(step: ProcedureStepDTO, index: number) {
    if (index <= 0) {
      return;
    }

    this.steps.splice(index, 1);

    this.steps.splice(index - 1, 0, step);

    ListUtils.setRankForListItems(this.steps);

    this.procedureStepsEditForm.form.markAsDirty();
    this.procedureStepsEditForm.form.markAsTouched();
  }

  public async saveChangesAsync(editProcedureStepsForm: NgForm) {
    if (editProcedureStepsForm.invalid) {
      return;
    }

    try {
      this.inProgress = true;
      this.hasErrorInSave = false;

      await this._proceduresService.updateProcedureStepsAsync(this._procedureId, this.steps);

      editProcedureStepsForm.form.markAsUntouched();
      editProcedureStepsForm.form.markAsPristine();
    } catch {
      this.hasErrorInSave = true;
    } finally {
      this.inProgress = false;
    }
  }

  public async cancelClickAsync() {
    await this._routeNavigationService.goToViewProcedureAsync(this._procedureId);
  }
}
