export class DateUtility {
  public static getFirstDayOfMonth(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  private static getStartOfDay(date: Date): Date {
    return new Date(date.setHours(0, 0, 0, 0));
  }

  public static getNumberOfMonthsBetweenDates(startDate: Date, endDate: Date): number {
    const normalizedStartDate = DateUtility.getFirstDayOfMonth(startDate);
    const normalizedEndDate = DateUtility.getFirstDayOfMonth(endDate);

    return (normalizedEndDate.getFullYear() - normalizedStartDate.getFullYear()) * 12 + (normalizedEndDate.getMonth() - normalizedStartDate.getMonth() + 1);
  }

  public static addMonths(startDate: Date, numberOfMonths: number): Date {
    return new Date(new Date(startDate).setMonth(startDate.getMonth() + numberOfMonths));
  }

  public static addDays(startDate: Date, numberOfDays: number): Date {
    return new Date(new Date(startDate).setDate(startDate.getDate() + numberOfDays));
  }

  public static addMinutes(startDate: Date, numberOfMinutes: number): Date {
    return new Date(new Date(startDate).setMinutes(startDate.getMinutes() + numberOfMinutes));
  }

  public static getMonthsBetweenDates(startDate: Date, endDate: Date): Date[] {
    const result = [];

    const normalizedStartDate = DateUtility.getFirstDayOfMonth(startDate);
    const normalizedEndDate = DateUtility.getFirstDayOfMonth(endDate);

    const numberOfMonths = DateUtility.getNumberOfMonthsBetweenDates(normalizedStartDate, normalizedEndDate);

    for (let i = 0; i < numberOfMonths; i++) {
      result.push(DateUtility.addMonths(normalizedStartDate, i));
    }

    return result;
  }

  public static getNumberOfDaysBetweenDates(startDate: Date, endDate: Date): number {
    const normalizedStartDate = DateUtility.getStartOfDay(startDate);
    const normalizedEndDate = DateUtility.getStartOfDay(endDate);

    return Math.ceil((normalizedEndDate.getTime() - normalizedStartDate.getTime()) / 86400000);
  }

  public static getDaysBetweenDates(startDate: Date, endDate: Date) {
    const result = [];

    const numberOfDays = DateUtility.getNumberOfDaysBetweenDates(startDate, endDate);

    for (let i = 0; i < numberOfDays; i++) {
      result.push(DateUtility.addDays(startDate, i));
    }

    return result;
  }

  public static isSameYear(a: Date, b: Date): boolean {
    return a.getFullYear() === b.getFullYear();
  }

  public static isSameMonth(a: Date, b: Date): boolean {
    return DateUtility.isSameYear(a, b) && a.getMonth() === b.getMonth();
  }

  public static isSameDay(a: Date, b: Date): boolean {
    return DateUtility.isSameMonth(a, b) && a.getDate() === b.getDate();
  }
}