import { CoolHttp } from '@angular-cool/http';
import { Injectable } from '@angular/core';
import {
  CompanyGeneralSettingsDTO, CompanySSOSettingsDTO,
  CompanyWhiteLabelSettingsDTO,
} from '../../../../../../../server/src/dto/company-settings.dto';
import { FileUploadItem } from '../../../../shared/components/file-upload-selector/file-upload-item.interface';

@Injectable()
export class CompanySettingsService {
  constructor(private _http: CoolHttp) {

  }

  public async getWhiteLabelSettingsAsync(): Promise<CompanyWhiteLabelSettingsDTO> {
    return this._http.getAsync('api/companies/active/settings/white-label');
  }

  public async setWhiteLabelSettingsAsync(settings: CompanyWhiteLabelSettingsDTO) {
    const formData = new FormData();

    if (settings.logo && (<FileUploadItem>settings.logo).file) {
      formData.append(`logo`, (<FileUploadItem>settings.logo).file);
    }

    const settingData = <CompanyWhiteLabelSettingsDTO>JSON.parse(JSON.stringify(settings));

    if (settingData.logo && settingData.logo.id.includes('-')) {
      (<FileUploadItem>settingData.logo).file = null;
      settingData.logo.url = '';
    }

    formData.append('settings', JSON.stringify(settingData));

    return this._http.postAsync('api/companies/active/settings/white-label', formData);
  }

  public async getGeneralSettingsAsync(): Promise<CompanyGeneralSettingsDTO> {
    return this._http.getAsync('api/companies/active/settings/general');
  }

  public async setGeneralSettingsAsync(settings: CompanyGeneralSettingsDTO): Promise<CompanyGeneralSettingsDTO> {
    return this._http.postAsync('api/companies/active/settings/general', settings);
  }

  public async getSSOSettingsAsync(): Promise<CompanySSOSettingsDTO> {
    return await this._http.getAsync('api/companies/active/settings/sso');
  }

  public async setSSOSettingsAsync(settings: CompanySSOSettingsDTO): Promise<void> {
    await this._http.postAsync('api/companies/active/settings/sso', settings);
  }
}