export const MIN_RANK_CHARACTER_CODE = 97;
export const MAX_RANK_CHARACTER_CODE = 122;
export const MAX_ENTROPY = MAX_RANK_CHARACTER_CODE - MIN_RANK_CHARACTER_CODE;


export class ListUtils {
  public static getChangeStrategyForList<T extends any>(originalList: T[], newList: T[], idParameter: string = 'id'): {
    itemsToDelete: T[];
    itemsToAdd: T[];
    itemsToUpdate: T[]
  } {
    return {
      itemsToDelete: originalList.filter((originalItem: T) => !newList.find((newItem: T) => newItem[idParameter] === originalItem[idParameter])),
      itemsToAdd: newList.filter((newItem: T) => !originalList.find((oldItem: T) => oldItem[idParameter] === newItem[idParameter])),
      itemsToUpdate: newList.filter((newItem: T) => !!newItem[idParameter]).filter((newItem: T) => {
        const originalItem: T = originalList.find((originalItem: T) => originalItem[idParameter] === newItem[idParameter]);

        const allProperties: string[] = Object.keys(newItem).concat(Object.keys(originalItem));
        const uniqueProperties = [...Array.from(new Set(allProperties))];

        for (const prop of uniqueProperties) {
          if (newItem[prop] !== originalItem[prop]) {
            return true;
          }
        }

        return false;
      }),
    };
  }

  public static compareByRank<T extends any>(a: T, b: T): number {
    if (a.rank > b.rank) {
      return 1;
    }

    if (b.rank > a.rank) {
      return -1;
    }

    return 0;
  }

  public static generateCompareByCustomPropertyFunction<T extends any>(propertyName: string): (a: T, b: T) => number {
    return (a: T, b: T) => {
      if (a[propertyName] > b[propertyName]) {
        return 1;
      }

      if (b[propertyName] > a[propertyName]) {
        return -1;
      }

      return 0;
    };
  }

  public static setRankForListItems<T extends { rank: string }>(items: T[]): void {
    const numberOfDigits = Math.ceil(items.length / MAX_ENTROPY);

    let currentRank = new RankNumber(numberOfDigits);

    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      item.rank = currentRank.toString();

      currentRank = currentRank.increase();
    }
  }

  public static randomlySortList(items: any[]): void {
    items.sort(() => ((0.5 - Math.random()) > 0) ? 1 : -1);

    if (.5 - Math.random() > 0) {
      items.reverse();
    }
  }

  public static flattenObject(ob: any): any {
    const toReturn: any = {};

    for (const i in ob) {
      if (!ob.hasOwnProperty(i)) continue;

      if ((typeof ob[i]) == 'object' && ob[i] !== null) {
        const flatObject = ListUtils.flattenObject(ob[i]);
        for (const x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) continue;

          toReturn[i + '.' + x] = flatObject[x];
        }
      } else {
        toReturn[i] = ob[i];
      }
    }
    return toReturn;
  }
}

export class RankNumber {
  private _digits: number[];

  constructor(numberOfWidgets: number) {
    this._digits = new Array(numberOfWidgets).fill(MIN_RANK_CHARACTER_CODE);
  }

  public increase(): RankNumber {
    const newNumber = this.clone();

    newNumber._tryIncreaseDigit(this._digits.length - 1);

    return newNumber;
  }

  public setDigits(digits: number[]): void {
    this._digits = digits.slice();
  }

  public clone(): RankNumber {
    const newNumber = new RankNumber(this._digits.length);

    newNumber.setDigits(this._digits);

    return newNumber;
  }

  public toString(): string {
    return this._digits.map(digit => String.fromCharCode(digit)).join('');
  }

  private _tryIncreaseDigit(digitIndex: number) {
    const digit = this._digits[digitIndex];

    if (digit === MAX_RANK_CHARACTER_CODE) {
      if (digitIndex === 0) {
        throw new Error('No more digits to increase');
      }

      this._tryIncreaseDigit(digitIndex - 1);

      this._digits[digitIndex] = MIN_RANK_CHARACTER_CODE;
    } else {
      this._digits[digitIndex]++;
    }
  }
}