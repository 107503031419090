import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { RouteNavigationService } from '../../shared/routing/route-navigation.service';
import { UserContextService } from '../../shared/services/user-context/user-context.service';
import { TimeUtils } from '../../shared/utils/time-utils';

@Component({
  selector: 'app-activate-email',
  templateUrl: './activate-email.component.html',
  styleUrls: ['./activate-email.component.scss'],
})
export class ActivateEmailComponent implements OnInit, OnDestroy {

  constructor(private _activatedRoute: ActivatedRoute,
              private _router: Router,
              private _routeNavigationService: RouteNavigationService,
              private _userContextService: UserContextService) {
  }

  public showError: boolean;

  public resendInProgress: boolean;
  public showValidationEmailResent: boolean;

  public showActivationMessage: boolean;

  public async ngOnInit() {
    if (this._userContextService.user.isActivated) {
      await this._routeNavigationService.goToDashboardAsync();

      return;
    }

    this._activatedRoute.queryParams
      .pipe(untilDestroyed(this))
      .subscribe(async queryParams => {
        const token = queryParams.token;

        if (token) {
          this.showActivationMessage = true;

          try {
            await this._userContextService.activateUserAsync(token);
          } catch {
            this.showError = true;
          }

          await TimeUtils.waitForTimeAsync(2 * 1000);

          let urlToReload = this._userContextService.getAndClearRouteToNavigateAfterActivateAccount();
          const loginUrlToReload = this._userContextService.getAndClearRouteToNavigateAfterLogin();

          if (!urlToReload) {
            urlToReload = loginUrlToReload;
          }

          if (urlToReload) {
            await this._router.navigateByUrl(urlToReload);
          } else {
            await this._routeNavigationService.goToDashboardAsync();
          }
        }
      });
  }

  public ngOnDestroy(): void {
  }

  public async trySendEmailValidationEmailAsync() {
    this.resendInProgress = true;

    await this._userContextService.tryResendEmailValidationEmailAsync();

    this.resendInProgress = false;
    this.showValidationEmailResent = true;
  }
}
