import { CoolHttp, HttpHeader } from '@angular-cool/http';
import { Inject, Injectable } from '@angular/core';
import { Environment } from '../../../../environments/environment.interface';
import { ENVIRONMENT, WINDOW } from '../injection-tokens';
import { LanguageService } from '../language/language.service';
import { LoggerService } from '../logger/logger.service';
import { UserContextService } from '../user-context/user-context.service';
import { UnauthenticatedInterceptor } from './unauthenticated.interceptor';

export function startupServiceFactory(provider: StartupService) {
  return () => provider.setupAsync();
}

@Injectable()
export class StartupService {
  constructor(private _http: CoolHttp,
              private _loggerService: LoggerService,
              private _languageService: LanguageService,
              private _unauthenticatedInterceptor: UnauthenticatedInterceptor,
              @Inject(WINDOW) private _window: Window,
              @Inject(ENVIRONMENT) private _environment: Environment,
              private _userContextService: UserContextService) {

  }

  public async setupAsync() {
    this._loggerService.initialize();

    this._setupHttp();

    this._languageService.initializeAsync();

    // Do not wait for this to load
    // noinspection ES6MissingAwait
    this._tryInitializeUserAsync();
  }

  private _setupHttp() {
    // bypass Service Worker
    this._http.registerGlobalHeader(new HttpHeader('ngsw-bypass', 'true'));
    this._http.setWithCredentials(true);
    this._http.registerBaseUrl(this._environment.backendUrl);

    this._http.registerResponseInterceptor(this._unauthenticatedInterceptor);
  }

  private async _tryInitializeUserAsync() {
    await this._tryGetXSRFTokenAsync();

    await this._userContextService.initializeAsync();
  }

  private async _tryGetXSRFTokenAsync() {
    const response = <{ token: string; }>await this._http.getAsync('api/settings/xsrf-token');

    if (this._window.location.hostname === 'localhost') {
      this._http.registerGlobalHeader(new HttpHeader('X-XSRF-TOKEN', response.token));
    } else {
      this._http.sendCookieValueInCustomHeader('XSRF-TOKEN', 'X-XSRF-TOKEN');
    }
  }
}