export enum ReportType {
  Learning = 'learning',

  Control = 'control',

  AssetControl = 'asset-control',

  LearningCertificates = 'learning-certificate',
}

export enum ReportStatus {
  ToDo = 'to-do',

  InProgress = 'in-progress',

  Failed = 'failed',

  Done = 'done',
}