import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SubscriptionUpgradeDialogConfig } from './subscription-upgrade-dialog-config.interface';
import { SubscriptionUpgradeDialogComponent } from './subscription-upgrade-dialog.component';
import { SubscriptionUpgradeReason } from './subscription-upgrade-reason.enum';

@Injectable()
export class SubscriptionUpgradeService {
  constructor(private _dialog: MatDialog) {

  }

  public showSubscriptionUpgradeDialog(reason: SubscriptionUpgradeReason) {
    this._dialog.open(SubscriptionUpgradeDialogComponent, {
      data: <SubscriptionUpgradeDialogConfig>{
        reason: reason,
      },
    });
  }
}
