import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AssetTypeDTO } from '../../../../../../../../server/src/dto/asset-control.dto';
import { LanguageService } from '../../../../../shared/services/language/language.service';
import { AssetsService } from '../../services/assets.service';

@Component({
  selector: 'app-asset-type-select',
  templateUrl: './asset-type-select.component.html',
  styleUrls: ['./asset-type-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: AssetTypeSelectComponent,
    multi: true,
  }],
})
export class AssetTypeSelectComponent implements OnInit, ControlValueAccessor {
  private _onChange: (type: AssetTypeDTO) => void;
  private _onTouched: (type: AssetTypeDTO) => void;

  constructor(private _assetsService: AssetsService,
              private _languageService: LanguageService) {
  }

  @Input()
  public displayOnly: boolean;

  @Input()
  public name: string;

  @Input()
  public placeholder: string;

  @Input()
  public assetTypes: AssetTypeDTO[];

  @Input()
  public required: boolean;

  @Input()
  public unselectable: boolean;

  public isDisabled: boolean;

  public selectedAssetType: AssetTypeDTO;

  async ngOnInit() {
    this.assetTypes = this.assetTypes || await this._assetsService.getAssetTypesAsync();

    this.placeholder = this.placeholder || await this._languageService.getTranslationAsync('Common.Type');
  }

  public onInnerChange(change: string) {
    this.selectedAssetType = this.assetTypes.find(type => type.id === change);

    this._onTouched && this._onTouched(this.selectedAssetType);
    this._onChange && this._onChange(this.selectedAssetType);
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public writeValue(assetType: AssetTypeDTO): void {
    this.selectedAssetType = assetType;
  }
}
