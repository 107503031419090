import { CoolDialogService } from '@angular-cool/dialogs';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AssetTypeDTO } from '../../../../../../server/src/dto/asset-control.dto';
import { LanguageService } from '../../../shared/services/language/language.service';
import { Editable } from '../../../shared/utils/editable';
import { AssetsService } from '../shared/services/assets.service';

@Component({
  selector: 'app-asset-control-asset-types',
  templateUrl: './asset-control-asset-types.component.html',
  styleUrls: ['./asset-control-asset-types.component.scss'],
})
export class AssetControlAssetTypesComponent implements OnInit {

  constructor(private _assetsService: AssetsService,
              private _dialogService: CoolDialogService,
              private _languageService: LanguageService) {

  }

  public displayedColumns = ['name', 'actions'];
  public isLoading: boolean;

  public updateAssetTypeInProgress: boolean;

  public dataSource: MatTableDataSource<Editable<AssetTypeDTO>>;

  @ViewChild(MatSort, { static: true })
  public sort: MatSort;

  public async ngOnInit() {
    try {
      this.isLoading = true;

      await this._loadDataAsync();
    } finally {
      this.isLoading = false;
    }
  }

  public async addNewAssetTypeAsync() {
    await this._assetsService.createAssetTypeAsync(
      await this._languageService.getTranslationAsync('Dashboard.AssetControlAssetType.NewAssetTypeName'),
    );

    await this._loadDataAsync();
  }

  public async editAssetTypeAsync(assetType: Editable<AssetTypeDTO>) {
    if (!assetType.value.name) {
      return;
    }

    try {
      await this._assetsService.updateAssetTypeAsync(assetType.value);
    } finally {
      this.updateAssetTypeInProgress = false;
    }

    assetType.stopEditing(true);

    await this._loadDataAsync();
  }

  public async startEditingAssetTypeAsync(assetType: Editable<AssetTypeDTO>) {
    assetType.startEditing();
  }

  public async cancelEditingAssetTypeAsync(assetType: Editable<AssetTypeDTO>) {
    assetType.stopEditing(false);
  }

  public async removeAssetTypeAsync(assetType: Editable<AssetTypeDTO>) {
    const removeDialogResult = await this._dialogService.showDialog({
      titleText: await this._languageService.getTranslationAsync('Dashboard.AssetControlAssetType.RemoveAssetTypeTitle', { assetTypeName: assetType.value.name }),
      questionText: await this._languageService.getTranslationAsync('Dashboard.AssetControlAssetType.RemoveAssetTypeDescription', { assetTypeName: assetType.value.name }),
      confirmActionButtonText: await this._languageService.getTranslationAsync('Common.Remove'),
      cancelActionButtonText: await this._languageService.getTranslationAsync('Common.Cancel'),
      confirmActionButtonColor: 'warn',
    });

    if (!removeDialogResult.isConfirmed) {
      return;
    }

    await this._assetsService.removeAssetTypeAsync(assetType.value.id);

    await this._loadDataAsync();
  }

  public filterAssetTypes(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  private async _loadDataAsync() {
    const assetTypes = await this._assetsService.getAssetTypesAsync();

    this.dataSource = new MatTableDataSource(assetTypes.map(assetType => new Editable(assetType)));

    this.dataSource.filterPredicate = (item: Editable<AssetTypeDTO>, filter: string) => !filter || !item.value || item.value.name.toLowerCase().includes(filter);

    this.dataSource.sortingDataAccessor = (item: Editable<AssetTypeDTO>, property: string) => {
      switch (property) {
        case 'name':
          return item.value.name;

        default:
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
  }
}
