<mat-card [class.mat-elevation-z4]="!weakBackground"
          [class.mat-elevation-z0]="weakBackground">
  <mat-card-content>
    <div class="d-flex">
      <h4 class="flex-grow-1 text-truncate mb-0 pt-1" [attr.title]="learningCampaign?.name">
        {{ showCampaignName ? learningCampaign?.name : '' }}
      </h4>

      <div class="mx-3 d-flex flex-row"
           *ngIf="session?.progress && session?.currentStep?.type !== LearningCampaignCompletionSessionStepType.Results">
        <div *ngIf="showTimeProgress && learningCampaign?.enforceMinutesRequiredToComplete && session"
             class="align-self-center">
          <app-countdown-timer [targetTime]="session?.enforcedFinishTime?.toISOString()"
                               [warnFromSeconds]="60"
                               (finished)="onSessionTimeoutEvent()">
          </app-countdown-timer>
        </div>
        <div *ngIf="showStepProgress" class="ml-3 align-self-center">
          <mat-progress-spinner
              [diameter]="30"
              [strokeWidth]="5"
              mode="determinate"
              [value]="(session.progress.currentStepIndex / session.progress.allStepCount) * 100">
          </mat-progress-spinner>
        </div>
        <div *ngIf="showStepProgress" class="ml-3 align-self-center">
            <span [class.text-success]="session.progress.currentStepIndex >= session.progress.allStepCount">
              {{ session.progress.currentStepIndex }} / {{ session.progress.allStepCount }}
            </span>
          {{ 'Common.Step' | translate }}
        </div>
      </div>

      <div class="ml-4">
        <app-button *ngIf="showPreviousButton"
                    [loading]="isPreviousLoading"
                    (clicked)="previousStepClicked()">
          {{ 'Common.Previous' | translate }}
        </app-button>

        <app-button *ngIf="showNextButton"
                    [loading]="isNextLoading"
                    [class.ml-2]="showPreviousButton"
                    color="primary"
                    (clicked)="nextStepClicked()">
          {{ 'Common.Next' | translate }}
        </app-button>

        <app-button *ngIf="showStartButton"
                    [loading]="isStartLoading"
                    color="primary"
                    (clicked)="startClicked()">
          {{ 'Common.Start' | translate }}
        </app-button>

        <app-button *ngIf="showSubmitButton"
                    [loading]="isSubmitLoading"
                    [class.ml-2]="showPreviousButton"
                    color="primary"
                    (clicked)="submitClicked()">
          {{ 'Common.Submit' | translate }}
        </app-button>
      </div>
    </div>
  </mat-card-content>
</mat-card>