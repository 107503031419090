import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { fabric } from 'fabric';
import { interval, Subject } from 'rxjs';
import { debounceTime, map, mergeMap, takeUntil } from 'rxjs/operators';

export const ZOOM_RATE = 0.005;
export const MOVE_RATE = 5;

@Component({
  selector: 'app-canvas-controls',
  templateUrl: './canvas-controls.component.html',
  styleUrls: ['./canvas-controls.component.scss'],
})
export class CanvasControlsComponent implements OnInit, OnChanges {
  private _controlsMouseUpSubject = new Subject();
  private _canvasMouseWheelSubject = new Subject<{ delta: number; offsetX: number; offsetY: number; }>();
  private _canvasMouseDownSubject = new Subject<void>();
  private _canvasMouseUpSubject = new Subject<void>();
  private _canvasMouseMoveSubject = new Subject<{ clientX: number; clientY: number; }>();

  private _lastMousePosition = { x: 0, y: 0 };

  constructor() {
  }

  @Input()
  public canvas: fabric.Canvas;

  @Input()
  public ignorePanOnSelection: boolean;

  @Output()
  public zoomChange = new EventEmitter<void>();

  public ngOnInit(): void {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.canvas) {
      return;
    }

    this.canvas.on('mouse:down', (opt: { target: fabric.Object; e: MouseEvent | TouchEvent }) => {
      if (this.ignorePanOnSelection && opt.target) {
        return;
      }

      this._canvasMouseDownSubject.next();

      let event: MouseEvent | Touch = <MouseEvent>opt.e;

      if (event.clientX === undefined) {
        event = (<TouchEvent> opt.e).touches.item(0);
      }

      this._lastMousePosition.x = event.clientX;
      this._lastMousePosition.y = event.clientY;
    });

    this.canvas.on('mouse:up', () => {
      this._canvasMouseUpSubject.next();
    });

    this.canvas.on('mouse:move', (opt: { e: MouseEvent | TouchEvent }) => {
      let event: MouseEvent | Touch = <MouseEvent>opt.e;

      if (event.clientX === undefined) {
        event = (<TouchEvent> opt.e).touches.item(0);
      }

      this._canvasMouseMoveSubject.next({
        clientX: event.clientX,
        clientY: event.clientY,
      });
    });

    this.canvas.selection = false;

    this._canvasMouseDownSubject
      .pipe(
        mergeMap(() => this._canvasMouseMoveSubject.pipe(takeUntil(this._canvasMouseUpSubject))),
        map((e: { clientX: number; clientY: number; }) => {
          this.canvas.viewportTransform[4] += e.clientX - this._lastMousePosition.x;
          this.canvas.viewportTransform[5] += e.clientY - this._lastMousePosition.y;

          this.canvas.requestRenderAll();

          this._lastMousePosition.x = e.clientX;
          this._lastMousePosition.y = e.clientY;
        }),
        debounceTime(200),
      )
      .subscribe(() => this._fixSelection());

    this.canvas.on('mouse:wheel', (opt: { e: WheelEvent }) => {
      this._canvasMouseWheelSubject.next({
        delta: opt.e.deltaY / 3,
        offsetX: opt.e.offsetX,
        offsetY: opt.e.offsetY,
      });

      opt.e.preventDefault();
      opt.e.stopPropagation();
    });

    this._canvasMouseWheelSubject
      .pipe(
        map((event) => {
          const currentZoom = this.canvas.getZoom();

          this.canvas.zoomToPoint(new fabric.Point(event.offsetX, event.offsetY), currentZoom + (event.delta / 600));

          this.canvas.requestRenderAll();
        }),
        debounceTime(20),
      )
      .subscribe(() => {
        this.zoomChange.emit();
      });
  }

  public onMouseUp() {
    this._controlsMouseUpSubject.next();
  }

  public zoomIn() {
    this._doUntilMouseUp(() => {
      const currentZoom = this.canvas.getZoom();

      this.canvas.setZoom(currentZoom + ZOOM_RATE);

      this.canvas.requestRenderAll();
    }, () => this.zoomChange.emit());
  }

  public zoomOut() {
    this._doUntilMouseUp(() => {
      const currentZoom = this.canvas.getZoom();

      this.canvas.setZoom(currentZoom - ZOOM_RATE);

      this.canvas.requestRenderAll();
    }, () => this.zoomChange.emit());
  }

  public moveUp() {
    this._doUntilMouseUp(() => {
      this.canvas.viewportTransform[5] += MOVE_RATE;

      this.canvas.requestRenderAll();
    }, () => this._fixSelection());
  }

  public moveDown() {
    this._doUntilMouseUp(() => {
      this.canvas.viewportTransform[5] -= MOVE_RATE;

      this.canvas.requestRenderAll();
    }, () => this._fixSelection());
  }

  public moveLeft() {
    this._doUntilMouseUp(() => {
      this.canvas.viewportTransform[4] += MOVE_RATE;

      this.canvas.requestRenderAll();
    }, () => this._fixSelection());
  }

  public moveRight() {
    this._doUntilMouseUp(() => {
      this.canvas.viewportTransform[4] -= MOVE_RATE;

      this.canvas.requestRenderAll();
    }, () => this._fixSelection());
  }

  private _fixSelection() {
    // Fix selection
    this.canvas.setZoom(this.canvas.getZoom() * 1.000001);

    this.canvas.requestRenderAll();
  }

  private _doUntilMouseUp(intervalAction: () => void, debouncedAction?: () => void) {
    interval(20)
      .pipe(
        takeUntil(this._controlsMouseUpSubject),
        map(() => intervalAction()),
        debounceTime(300),
      )
      .subscribe(() => {
        debouncedAction && debouncedAction();
      });
  }
}
