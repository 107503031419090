<div *ngIf="displayOnly">
  <span *ngFor="let userId of selectedUserIds; let first = first">
    {{ first ? '' : ', ' }}{{ userNamesDictionary[userId] }}
  </span>
</div>

<mat-form-field *ngIf="!displayOnly" class="w-100">
  <mat-chip-list #chipList>
    <mat-chip
        *ngFor="let userId of selectedUserIds"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(userId)">

      {{ userNamesDictionary[userId] }}

      <fa-icon matChipRemove *ngIf="removable" icon="times"></fa-icon>
    </mat-chip>
    <input
        placeholder="{{ placeholder }}"
        #userInput
        [disabled]="disabled"
        (keyup)="onInputChangeAsync(userInput.value)"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="add($event)">
  </mat-chip-list>

  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let user of filteredUsers" [value]="user">
      {{ user.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>