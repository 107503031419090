<form #generalSettingsForm="ngForm">
  <mat-form-field>
    <input matInput name="name"
           [(ngModel)]="user.name"
           placeholder="{{ 'Common.Name' | translate }}"
           required>
  </mat-form-field>

  <div class="mt-3 mb-2 text-right">
    <app-button color="primary"
                [disabled]="generalSettingsForm.invalid || generalSettingsForm.pristine"
                [loading]="saveInProgress"
                (clicked)="saveGeneralSettingsAsync(generalSettingsForm)">
      {{ 'Common.SaveChanges' | translate }}
    </app-button>
  </div>
</form>