import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CompanyDTO } from '../../../../../../server/src/dto/company.dto';
import { SubCompanyService } from '../../services/company/sub-company.service';
import { LanguageService } from '../../services/language/language.service';

@Component({
  selector: 'app-sub-company-select',
  templateUrl: './sub-company-select.component.html',
  styleUrls: ['./sub-company-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: SubCompanySelectComponent,
    multi: true,
  }],
})
export class SubCompanySelectComponent implements OnInit, ControlValueAccessor {
  private _onChange: (company: CompanyDTO) => void;
  private _onTouched: (company: CompanyDTO) => void;

  constructor(private _subCompanyService: SubCompanyService,
              private _languageService: LanguageService) {
  }

  @Input()
  public name: string;

  @Input()
  public placeholder: string;

  @Input()
  public subCompanies: CompanyDTO[];

  @Input()
  public required: boolean;

  public isDisabled: boolean;

  public selectedCompany: CompanyDTO;

  public async ngOnInit() {
    this.subCompanies = this.subCompanies || await this._subCompanyService.getSubCompaniesAsync();

    this.placeholder = this.placeholder || await this._languageService.getTranslationAsync('Common.Company');
  }

  public onInnerChange(selectedCompanyId: string) {
    this.selectedCompany = this.subCompanies.find(_ => _.id === selectedCompanyId);

    this._onTouched && this._onTouched(this.selectedCompany);
    this._onChange && this._onChange(this.selectedCompany);
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public writeValue(company: CompanyDTO): void {
    this.selectedCompany = this.subCompanies?.find(_ => _.id === company?.id);
  }
}
