import { CoolDialogService } from '@angular-cool/dialogs';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CompanyRoleDTO } from '../../../../../../server/src/dto/company-role.dto';
import { LanguageService } from '../../../shared/services/language/language.service';
import { OrganizationService } from '../../../shared/services/organization/organization.service';
import { Editable } from '../../../shared/utils/editable';

@Component({
  selector: 'app-organization-roles',
  templateUrl: './organization-roles.component.html',
  styleUrls: ['./organization-roles.component.scss'],
})
export class OrganizationRolesComponent implements OnInit {

  constructor(private _organizationService: OrganizationService,
              private _dialogService: CoolDialogService,
              private _languageService: LanguageService) {

  }

  public displayedColumns = ['name', 'actions'];
  public isLoading: boolean;

  public updateCompanyRoleInProgress: boolean;

  public dataSource: MatTableDataSource<Editable<CompanyRoleDTO>>;

  @ViewChild(MatSort, { static: true })
  public sort: MatSort;

  public async ngOnInit() {
    try {
      this.isLoading = true;

      await this._loadDataAsync();
    } finally {
      this.isLoading = false;
    }
  }

  public async addNewCompanyRoleAsync() {
    await this._organizationService.createCompanyRoleAsync(
      await this._languageService.getTranslationAsync('Dashboard.Roles.NewCompanyRoleName'),
    );

    await this._loadDataAsync();
  }

  public async editCompanyRoleAsync(companyRole: Editable<CompanyRoleDTO>) {
    if (!companyRole.value.name) {
      return;
    }

    try {
      await this._organizationService.updateCompanyRoleAsync(companyRole.value);
    } finally {
      this.updateCompanyRoleInProgress = false;
    }

    companyRole.stopEditing(true);

    await this._loadDataAsync();
  }

  public async startEditingCompanyRoleAsync(companyRole: Editable<CompanyRoleDTO>) {
    companyRole.startEditing();
  }

  public async cancelEditingCompanyRoleAsync(companyRole: Editable<CompanyRoleDTO>) {
    companyRole.stopEditing(false);
  }

  public async removeCompanyRoleAsync(companyRole: Editable<CompanyRoleDTO>) {
    const removeDialogResult = await this._dialogService.showDialog({
      titleText: await this._languageService.getTranslationAsync('Dashboard.Roles.RemoveCompanyRoleTitle', { companyRoleName: companyRole.value.name }),
      questionText: await this._languageService.getTranslationAsync('Dashboard.Roles.RemoveCompanyRoleDescription', { companyRoleName: companyRole.value.name }),
      confirmActionButtonText: await this._languageService.getTranslationAsync('Common.Remove'),
      cancelActionButtonText: await this._languageService.getTranslationAsync('Common.Cancel'),
      confirmActionButtonColor: 'warn',
    });

    if (!removeDialogResult.isConfirmed) {
      return;
    }

    await this._organizationService.removeCompanyRoleAsync(companyRole.value.id);

    await this._loadDataAsync();
  }

  public filterCompanyRoles(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  private async _loadDataAsync() {
    const companyRoles = await this._organizationService.getCompanyRolesAsync();

    this.dataSource = new MatTableDataSource(companyRoles.map(companyRole => new Editable(companyRole)));

    this.dataSource.filterPredicate = (item: Editable<CompanyRoleDTO>, filter: string) => !filter || !item.value || item.value.name.toLowerCase().includes(filter);

    this.dataSource.sortingDataAccessor = (item: Editable<CompanyRoleDTO>, property: string) => {
      switch (property) {
        case 'name':
          return item.value.name;

        default:
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
  }
}
