import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CompanySSOSettingsDTO } from '../../../../../../server/src/dto/company-settings.dto';
import { CompanySettingsService } from '../shared/services/company-settings.service';

@Component({
  selector: 'app-sso-settings',
  templateUrl: './sso-settings.component.html',
  styleUrls: ['./sso-settings.component.scss'],
})
export class SsoSettingsComponent implements OnInit {

  constructor(private _companySettingsService: CompanySettingsService) {
  }

  public isLoading: boolean;
  public inProgress: boolean;
  public hasError: boolean;

  public settings: CompanySSOSettingsDTO;

  public async ngOnInit() {
    try {
      this.isLoading = true;

      this.settings = await this._companySettingsService.getSSOSettingsAsync();
    } finally {
      this.isLoading = false;
    }
  }

  public async onSaveAsync(ssoForm: NgForm) {
    if (!ssoForm.valid) {
      return;
    }

    try {
      this.inProgress = true;
      this.hasError = false;

      await this._companySettingsService.setSSOSettingsAsync(this.settings);
    } catch {
      this.hasError = true;
    } finally {
      this.inProgress = false;
    }
  }
}
