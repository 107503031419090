import { CoolHttp } from '@angular-cool/http';
import { Injectable } from '@angular/core';
import { PCacheable, PCacheBuster } from 'ngx-cacheable';
import { Subject } from 'rxjs';
import {
  ProcedureCategoryDTO,
  ProcedureCategoryWithProceduresDTO,
} from '../../../../../../server/src/dto/procedure.dto';

const procedureCategoriesCacheInvalidator = new Subject<void>();

@Injectable()
export class ProcedureCategoriesService {
  constructor(private _http: CoolHttp) {

  }

  @PCacheable({
    cacheBusterObserver: procedureCategoriesCacheInvalidator,
    maxAge: 1000 * 60,
  })
  public async getProcedureCategoriesAsync() {
    return await this._http.getAsync('api/companies/active/procedures/categories');
  }

  @PCacheBuster({
    cacheBusterNotifier: procedureCategoriesCacheInvalidator,
  })
  public async upsertProcedureCategoriesAsync(category: ProcedureCategoryDTO) {
    return await this._http.postAsync('api/companies/active/procedures/categories', category);
  }

  @PCacheBuster({
    cacheBusterNotifier: procedureCategoriesCacheInvalidator,
  })
  public async removeProcedureCategoriesAsync(categoryId: string) {
    return await this._http.deleteAsync(`api/companies/active/procedures/categories/${ categoryId }`);
  }

  public async getProcedureCategoriesWithProceduresAsync(): Promise<ProcedureCategoryWithProceduresDTO[]> {
    return await this._http.getAsync('api/companies/active/procedures/categories', {
      params: {
        includeProcedures: '1',
      }
    });
  }
}