<div class="d-inline-block">
  <app-file-display [files]="files"
                    [deleteFileButton]="true"
                    [forceDisplay]="true"
                    [centered]="centered"
                    [displayFormat]="displayFormat"
                    (deleteFile)="removeFile($event)">
    <div>
      <form #dropFileForm class="w-100 h-100">
        <input id="file-upload-{{ name }}"
               class="file-upload"
               #fileUploadInput
               type="file"
               (change)="onFileInputChange(fileUploadInput)">

        <label id="file-upload-label"
               [class.file-dragged-over]="fileDraggedOver"
               [class.small]="displayFormat === 'small'"
               for="file-upload-{{ name }}">
          <div class="total-center">
            <div class="mb-3">
              <fa-icon icon="plus" size="2x"></fa-icon>
            </div>

            {{ 'Common.SelectOrDropFiles' | translate }}
          </div>
        </label>
      </form>
    </div>
  </app-file-display>
</div>