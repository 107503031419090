import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AssetRoomDTO } from '../../../../../../../server/src/dto/asset-control.dto';
import { RouteNavigationService } from '../../../../shared/routing/route-navigation.service';
import { LanguageService } from '../../../../shared/services/language/language.service';
import { AssetRoomService } from '../../shared/services/asset-room.service';

@Component({
  selector: 'app-asset-control-rooms-list',
  templateUrl: './asset-control-rooms-list.component.html',
  styleUrls: ['./asset-control-rooms-list.component.scss'],
})
export class AssetControlRoomsListComponent implements OnInit {
  constructor(private _assetRoomService: AssetRoomService,
              private _routeNavigationService: RouteNavigationService,
              private _languageService: LanguageService) {

  }

  public displayedColumns = ['name', 'category', 'actions'];
  public isLoading: boolean;

  public dataSource: MatTableDataSource<AssetRoomDTO>;

  public async ngOnInit() {
    await this._loadDataAsync();
  }

  public async addNewRoomAsync() {
    const createdRoom = await this._assetRoomService.upsertRoomAsync({
      id: null,
      name: await this._languageService.getTranslationAsync('Dashboard.AssetControlRooms.AddNewRoom'),
      category: null,
      backgroundJson: null,
      backgroundJsonSettings: null,
    });

    await this.editRoomAsync(createdRoom);
  }

  public async editRoomAsync(room: AssetRoomDTO) {
    await this._routeNavigationService.goToEditAssetRoomAsync(room.id);
  }

  public async goToRoomEventsAsync(room: AssetRoomDTO) {
    await this._routeNavigationService.goToAssetRoomEventsAsync(room.id);
  }

  public async goToRoomAssetsAsync(room: AssetRoomDTO) {
    await this._routeNavigationService.goToAssetRoomAssetsAsync(room.id);
  }

  public filterRooms(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  private async _loadDataAsync() {
    try {
      this.isLoading = true;

      const rooms = await this._assetRoomService.getRoomsAsync();

      this.dataSource = new MatTableDataSource(rooms);
    } finally {
      this.isLoading = false;
    }
  }
}
