<div *ngIf="procedure">
  <mat-card class="mat-elevation-z4">
    <mat-card-content>
      <div class="p-2">
        <h4 class="mb-3" *ngIf="!hideName">{{ procedure.name }}</h4>

        <div *ngIf="procedure.categories?.length" class="mt-2 mb-4">
          <app-procedure-category-select [displayOnly]="true"
                                         [ngModel]="procedure.categories"
                                         name="categories">
          </app-procedure-category-select>
        </div>

        <app-rich-display *ngIf="procedure.description"
                          [contentHTML]="procedure.description">
        </app-rich-display>

        <div class="mt-3">
          <app-file-display [files]="procedure.files">
          </app-file-display>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <div class="mt-3" *ngIf="!hideSteps">
    <div *ngFor="let step of procedure.steps | orderByRank; let i = index" class="mb-3 w-100">
      <mat-card class="mat-elevation-z4">
        <mat-card-content>
          <div class="px-4">
            <div class="row">
              <div class="col-2">
                <app-procedure-step-image [step]="step" [index]="i + 1"></app-procedure-step-image>
              </div>
              <div class="col-10 d-flex flex-column justify-content-center">
                <h5>{{ step.name }}</h5>

                <app-rich-display *ngIf="step.description" [contentHTML]="step.description">
                </app-rich-display>

                <div class="mt-3" *ngIf="step.files?.length">
                  <app-file-display [files]="step.files" displayFormat="small">
                  </app-file-display>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>