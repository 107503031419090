import { AssetRoomCheckpointEventResult } from '../models/asset-room-checkpoint.enum';
import { AssetRoomCategoryDTO, AssetTypeDTO, LimitedAssetRoomDTO } from './asset-control.dto';
import { CompanyRoleDTO } from './company-role.dto';
import { LimitedCompanyUserDTO } from './company-user.dto';

export interface AssetControlReportDTO {
  filters: AssetControlReportGenerateRequestDTO;

  dateResolutionTicks: Date[];

  rooms: {
    room: LimitedAssetRoomDTO,

    resultsByTimeTick: number[];
  }[];

  assetTypes: {
    assetType: AssetTypeDTO,

    resultsByTimeTick: number[];
  }[];

  rawResultDictionary: AssetControlReportDTODictionary;

  rawResults: AssetControlReportDTORawResult[];
}

export interface AssetControlReportDTORawResult {
  date: Date;

  result: AssetRoomCheckpointEventResult;

  assetId: string;
}

export interface AssetControlReportDTODictionary {
  rooms: {
    [id: string]: {
      name: string;

      categoryId: string;
    }
  };

  roomCategories: {
    [id: string]: {
      name: string;
    }
  };

  assets: {
    [id: string]: {
      name: string;

      typeId: string;

      roomId: string;
    };
  };

  assetTypes: {
    [id: string]: {
      name: string;
    }
  };
}

export enum AssetControlReportDateResolution {
  Days = 'days',

  Months = 'months',
}

export interface AssetControlReportGenerateRequestDTO {
  startDate: Date | null;

  endDate: Date | null;

  dateResolution: AssetControlReportDateResolution;

  roomCategory: AssetRoomCategoryDTO | null;

  responsibleUser: LimitedCompanyUserDTO | null;

  responsibleRole: CompanyRoleDTO | null;

  assetType: AssetTypeDTO | null;

  room: LimitedAssetRoomDTO | null;
}
