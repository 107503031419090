<app-page-header pageTitle="{{ 'Dashboard.KnowledgeBase.EditArticle' | translate }} - {{ article?.name }}"></app-page-header>

<div *ngIf="article">
  <mat-card class="p-4">
    <div>
      <form #articleEditForm="ngForm">
        <div>
          <mat-form-field class="w-100">
            <input matInput placeholder="{{ 'Common.Name' | translate }}"
                   name="name" [(ngModel)]="article.name"
                   required>
          </mat-form-field>
        </div>
        <div class="mt-2">
          <app-rich-editor placeholder="{{ 'Common.Description' | translate }}"
                           name="description"
                           [(ngModel)]="article.description"
                           required>
          </app-rich-editor>
        </div>
        <div class="mt-3">
          <mat-slide-toggle name="published"
                            color="primary"
                            (ngModelChange)="articleEditForm.form.markAsTouched()"
                            [(ngModel)]="article.isPublished"
                            #publishToggle>
            <fa-icon [icon]="publishToggle.checked ? 'eye' : 'eye-slash'" class="mr-1"></fa-icon>

            {{ (publishToggle.checked ? 'Dashboard.KnowledgeBase.ArticleIsPublished' : 'Dashboard.KnowledgeBase.ArticleIsDraft') | translate }}
          </mat-slide-toggle>
        </div>
        <div class="mt-3">
          <app-file-upload-selector name="files" [(ngModel)]="article.files">
          </app-file-upload-selector>
        </div>
      </form>
    </div>

    <div class="text-right mt-3">
      <button mat-raised-button
              color="warn"
              class="mr-3 float-left"
              (click)="removeArticleAsync()">
        {{ 'Common.Remove' | translate }}
      </button>

      <span class="text-danger mr-4" *ngIf="hasErrorInSave">
      {{ 'Common.ErrorSaving' | translate }}
    </span>

      <button mat-raised-button class="mr-3" (click)="cancelClickAsync()">
        {{ 'Common.Cancel' | translate }}
      </button>

      <app-button color="primary" [loading]="inProgress"
                  [disabled]="articleEditForm.untouched || !articleEditForm.valid"
                  (clicked)="saveChangesAsync(articleEditForm)">
        {{ 'Common.SaveChanges' | translate }}
      </app-button>
    </div>
  </mat-card>
</div>