import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[scoreColor]'
})
export class ScoreColorDirective {
  constructor(private _elementRef: ElementRef<HTMLElement>) {

  }

  @Input()
  public set scoreColor(value: number) {
    this._elementRef.nativeElement.style.color = ScoreColorDirective.getScoreColor(value);
  }

  public static getScoreColor(score: number): string {
    if (score > 85) {
      return '#28a745';
    } else if (score > 60) {
      return '#17a2b8';
    } else {
      return '#dc3545';
    }
  }
}