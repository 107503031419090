<div>
  <h5>{{ 'Dashboard.LearningCertificates.CreateCertificate' | translate }}</h5>

  <div class="dialog-container">
    <form class="filter-row" #filterForm="ngForm">
      <app-learning-campaign-select [(ngModel)]="filters.learningCampaign"
                                    name="learning-campaign" [required]="true">
      </app-learning-campaign-select>

      <div>
        <mat-form-field class="w-100">
          <mat-label>{{ 'Common.Template' | translate }}</mat-label>

          <mat-select name="template" [(ngModel)]="filters.template" required>
            <mat-option *ngFor="let template of templates" [value]="template">
              {{ template.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="text-right" *ngIf="templates && !templates.length">
          <button class="btn btn-link p-0" (click)="goToCertificateTemplatesAsync()">
            {{ 'Dashboard.LearningCertificates.AddCertificateTemplate' | translate }}
          </button>
        </div>
      </div>

      <div>
        <mat-form-field class="w-100">
          <input matInput [matDatepicker]="startDatePicker"
                 name="start-date"
                 [(ngModel)]="filters.startDate"
                 (click)="startDatePicker.open()"
                 placeholder="{{ 'Common.StartDate' | translate }}">
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field class="w-100">
          <input matInput [matDatepicker]="endDatePicker"
                 name="end-date"
                 [(ngModel)]="filters.endDate"
                 (click)="endDatePicker.open()"
                 placeholder="{{ 'Common.EndDate' | translate }}">
          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div>
        <mat-slide-toggle name="user-filter"
                          color="primary"
                          (ngModelChange)="filterForm.form.markAsTouched()"
                          [(ngModel)]="filters.generateForAllUsers"
                          #userFilterToggle>
          {{ (userFilterToggle.checked ? 'Dashboard.LearningCertificates.AllUsers' : 'Dashboard.LearningCertificates.SpecificUsers') | translate }}
        </mat-slide-toggle>
      </div>

      <div *ngIf="!filters.generateForAllUsers">
        <app-user-selector name="users" [(ngModel)]="filters.generateForSpecificUserIds">
        </app-user-selector>
      </div>
    </form>

    <div class="mt-3 text-right">
      <span *ngIf="hasError" class="color-warn mr-4">
        {{ 'Common.FailedToGenerateReport' | translate }}
      </span>

      <button mat-raised-button (click)="onCancel()" class="mr-2">
        {{ 'Common.Cancel' | translate }}
      </button>

      <app-button color="primary"
                  [loading]="isLoading"
                  (clicked)="onGenerateCertificateClickAsync(filterForm)">
        {{ 'Common.Generate' | translate }}
      </app-button>
    </div>
  </div>
</div>