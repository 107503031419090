import { CoolDialogService } from '@angular-cool/dialogs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { isNumber } from 'util';
import { LearningCampaignDTO } from '../../../../../../../server/src/dto/learning-campaign.dto';
import { ProcedureCategoryWithProceduresDTO, ProcedureDTO } from '../../../../../../../server/src/dto/procedure.dto';
import { RouteNavigationService } from '../../../../shared/routing/route-navigation.service';
import { LanguageService } from '../../../../shared/services/language/language.service';
import { LearningCampaignsService } from '../../../../shared/services/learning-campaigns/learning-campaigns.service';
import { ProcedureCategoriesService } from '../../../../shared/services/procedures/procedure-categories.service';
import { ProceduresService } from '../../../../shared/services/procedures/procedures.service';

export interface ProcedureDTOWithSelected extends ProcedureDTO {
  isSelected: boolean;
}

@Component({
  selector: 'app-edit-learning-campaign',
  templateUrl: './edit-learning-campaign.component.html',
  styleUrls: ['./edit-learning-campaign.component.scss'],
})
export class EditLearningCampaignComponent implements OnInit, OnDestroy {
  constructor(private _learningCampaignsService: LearningCampaignsService,
              private _activatedRoute: ActivatedRoute,
              private _proceduresService: ProceduresService,
              private _procedureCategoriesService: ProcedureCategoriesService,
              private _dialogService: CoolDialogService,
              private _languageService: LanguageService,
              private _routeNavigationService: RouteNavigationService) {

  }

  public learningCampaign: LearningCampaignDTO;
  public procedures: ProcedureDTOWithSelected[];
  public procedureCategories: ProcedureCategoryWithProceduresDTO[];

  public hasDepartmentFilter: boolean;
  public enforceSuccessPercentage: boolean;

  public hasErrorInSave: boolean;
  public inProgress: boolean;

  public ngOnInit() {
    this._activatedRoute.params
      .pipe(untilDestroyed(this))
      .subscribe(async (params: Params) => {
        const campaignId = params.campaignId;

        if (!campaignId) {
          return;
        }

        this.learningCampaign = await this._learningCampaignsService.getLearningCampaignByIdAsync(campaignId);
        this.hasDepartmentFilter = this.learningCampaign.departments && !!this.learningCampaign.departments.length;
        this.enforceSuccessPercentage = this.learningCampaign.forceRetakeUnderSuccessPercentage !== null;

        this.procedureCategories = await this._procedureCategoriesService.getProcedureCategoriesWithProceduresAsync();

        const procedures = await this._proceduresService.getProceduresAsync(true);
        this.procedures = procedures.map(procedure => {
          const withSelected = <ProcedureDTOWithSelected>procedure;

          withSelected.isSelected = this.learningCampaign.procedures.some(p => p.id === withSelected.id);

          withSelected.categories = this.procedureCategories.filter(category => category.procedures.some(proc => proc.id === procedure.id));

          return withSelected;
        });

        this.procedures.sort((a, b) => (+b.isPublished) - (+a.isPublished));
      });
  }

  public ngOnDestroy(): void {
  }

  public async removeLearningCampaignAsync() {
    const removeDialogResult = await this._dialogService.showDialog({
      titleText: await this._languageService.getTranslationAsync('Dashboard.LearningCampaigns.RemoveCampaignTitle', { name: this.learningCampaign.name }),
      questionText: await this._languageService.getTranslationAsync('Dashboard.LearningCampaigns.RemoveCampaignDescription', { name: this.learningCampaign.name }),
      confirmActionButtonText: await this._languageService.getTranslationAsync('Common.Remove'),
      cancelActionButtonText: await this._languageService.getTranslationAsync('Common.Cancel'),
      confirmActionButtonColor: 'warn',
    });

    if (!removeDialogResult.isConfirmed) {
      return;
    }

    await this._learningCampaignsService.removeLearningCampaignAsync(this.learningCampaign.id);

    await this._routeNavigationService.goToLearningCampaignListAsync();
  }

  public async saveChangesAsync(editCampaignForm: NgForm) {
    if (editCampaignForm.invalid || !this.learningCampaign.procedures.length) {
      return;
    }

    try {
      this.inProgress = true;
      this.hasErrorInSave = false;

      if (!this.hasDepartmentFilter) {
        this.learningCampaign.departments = [];
      }

      if (!this.enforceSuccessPercentage) {
        this.learningCampaign.forceRetakeUnderSuccessPercentage = null;
      } else if (!isNumber(this.learningCampaign.forceRetakeUnderSuccessPercentage)) {
        this.learningCampaign.forceRetakeUnderSuccessPercentage = 50;
      }

      await this._learningCampaignsService.updateLearningCampaignAsync(this.learningCampaign);

      await this._routeNavigationService.goToLearningCampaignListAsync();
    } catch {
      this.hasErrorInSave = true;
    } finally {
      this.inProgress = false;
    }
  }

  public async cancelClickAsync() {
    await this._routeNavigationService.goToLearningCampaignListAsync();
  }

  public onProcedureSelectClick(procedure: ProcedureDTOWithSelected, campaignEditForm: NgForm = null) {
    if (!procedure.isPublished) {
      return;
    }

    procedure.isSelected = !procedure.isSelected;

    if (procedure.isSelected) {
      this.learningCampaign.procedures.push(procedure);
    } else {
      this.learningCampaign.procedures.splice(this.learningCampaign.procedures.findIndex(p => p.id === procedure.id), 1);
    }

    if (campaignEditForm) {
      campaignEditForm.form.markAsTouched();
    }
  }

  public selectProceduresInCategory(category: ProcedureCategoryWithProceduresDTO, campaignEditForm: NgForm) {
    for (const procedure of category.procedures) {
      const procedureInList = this.procedures.find(proc => proc.id === procedure.id);

      if (!procedureInList) {
        continue;
      }

      if (procedureInList.isPublished && !procedureInList.isSelected) {
        this.onProcedureSelectClick(procedureInList);
      }
    }

    campaignEditForm.form.markAsTouched();
  }
}
