import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { take } from 'rxjs/operators';
import { UserContextService } from '../../services/user-context/user-context.service';
import { RouteNavigationService } from '../route-navigation.service';

@Injectable()
export class UserActiveRouteGuard implements CanActivate {
  constructor(private _userContextService: UserContextService,
              private _routeNavigationService: RouteNavigationService) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this._userContextService.isUserLoggedInObservable
        .pipe(take(1))
        .subscribe((isLoggedIn) => {
          if (!isLoggedIn) {
            resolve(false);

            return;
          }

          if (!this._userContextService.user.isActivated) {
            this._userContextService.saveRouteToNavigateAfterActivateAccount(state.url);

            this._routeNavigationService.goToValidateEmailAsync();

            resolve(false);

            return;
          }

          resolve(true);
        });
    });
  }
}
