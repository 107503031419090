import { CoolHttp } from '@angular-cool/http';
import { Injectable } from '@angular/core';
import { PCacheable, PCacheBuster } from 'ngx-cacheable';
import { Subject } from 'rxjs';
import { AssetDTO, AssetRoomCategoryDTO, AssetRoomDTO } from '../../../../../../../server/src/dto/asset-control.dto';

const roomsCacheInvalidator = new Subject<void>();
const roomCategoryCacheInvalidator = new Subject<void>();

@Injectable()
export class AssetRoomService {
  constructor(private _http: CoolHttp) {

  }

  @PCacheable({
    cacheBusterObserver: roomsCacheInvalidator,
    maxAge: 1000 * 60,
  })
  public async getRoomsAsync(): Promise<AssetRoomDTO[]> {
    return await this._http.getAsync('api/companies/active/asset-control/rooms');
  }

  public async getRoomByIdAsync(roomId: string): Promise<AssetRoomDTO> {
    return await this._http.getAsync(`api/companies/active/asset-control/rooms/${ roomId }`);
  }

  @PCacheBuster({
    cacheBusterNotifier: roomsCacheInvalidator,
  })
  public async upsertRoomAsync(room: AssetRoomDTO): Promise<AssetRoomDTO> {
    return await this._http.postAsync('api/companies/active/asset-control/rooms', room);
  }

  @PCacheBuster({
    cacheBusterNotifier: roomsCacheInvalidator,
  })
  public async removeRoomByIdAsync(roomId: string) {
    await this._http.deleteAsync(`api/companies/active/asset-control/rooms/${ roomId }`);
  }

  public async getRoomAssetsAsync(roomId: string): Promise<AssetDTO[]> {
    return await this._http.getAsync(`api/companies/active/asset-control/rooms/${ roomId }/assets`);
  }

  public async addAssetToRoomAsync(roomId: string, asset: AssetDTO): Promise<AssetDTO> {
    return await this._http.postAsync(`api/companies/active/asset-control/rooms/${ roomId }/assets`, asset);
  }

  public async updateAssetAsync(roomId: string, asset: AssetDTO) {
    return await this._http.postAsync(`api/companies/active/asset-control/rooms/${ roomId }/assets/${ asset.id }`, asset);
  }

  public async removeAssetAsync(roomId: string, assetId: string) {
    return await this._http.deleteAsync(`api/companies/active/asset-control/rooms/${ roomId }/assets/${ assetId }`);
  }

  @PCacheable({
    cacheBusterObserver: roomCategoryCacheInvalidator,
    maxAge: 1000 * 60,
  })
  public async getRoomCategoriesAsync(): Promise<AssetRoomCategoryDTO[]> {
    return await this._http.getAsync(`api/companies/active/asset-control/rooms/categories`);
  }

  @PCacheBuster({
    cacheBusterNotifier: roomCategoryCacheInvalidator,
  })
  public async removeRoomCategoryAsync(categoryId: string): Promise<void> {
    await this._http.deleteAsync(`api/companies/active/asset-control/rooms/categories/${ categoryId }`);
  }

  @PCacheBuster({
    cacheBusterNotifier: roomCategoryCacheInvalidator,
  })
  public async upsertCategoryAsync(roomCategory: AssetRoomCategoryDTO): Promise<AssetRoomCategoryDTO> {
    return await this._http.postAsync('api/companies/active/asset-control/rooms/categories', roomCategory);
  }
}
