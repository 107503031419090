import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyDTO } from '../../../../../../../server/src/dto/company.dto';
import { SubCompanyService } from '../../../../shared/services/company/sub-company.service';
import { CopyProcedureToCompanyDialogConfig } from './copy-procedure-to-company-dialog-config.interface';

@Component({
  selector: 'app-copy-procedure-to-company-dialog',
  templateUrl: './copy-procedure-to-company-dialog.component.html',
})
export class CopyProcedureToCompanyDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) private _dialogConfig: CopyProcedureToCompanyDialogConfig,
              private _dialog: MatDialogRef<CopyProcedureToCompanyDialogComponent>,
              private _subCompanyService: SubCompanyService) {

  }

  public procedureName: string;

  public showError = false;
  public saveInProgress = false;

  public ngOnInit() {
    if (!this._dialogConfig.procedure) {
      throw new Error('Dialog config "procedure" is mandatory!');
    }

    this.procedureName = this._dialogConfig.procedure.name;
  }

  public async copyProcedureToCompany(company: CompanyDTO) {
    if (!company) {
      return;
    }

    try {
      this.saveInProgress = true;
      this.showError = false;

      await this._subCompanyService.copyProcedureToCompanyAsync(company.id, this._dialogConfig.procedure.id);

      this._dialog.close();
    } catch {
      this.showError = true;
    } finally {
      this.saveInProgress = false;
    }
  }

  public cancel() {
    this._dialog.close();
  }
}