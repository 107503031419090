<div *ngIf="displayOnly">{{ selectedRoom?.name }}</div>

<mat-form-field *ngIf="!displayOnly" class="w-100">
  <mat-label>{{ placeholder }}</mat-label>
  <mat-select name="{{ name }}-inner-select"
              [ngModel]="selectedRoom?.id"
              (ngModelChange)="onInnerChange($event)"
              [disabled]="isDisabled"
              [required]="required">
    <mat-option [value]="null">{{ 'Common.None' | translate }}</mat-option>

    <mat-option *ngFor="let room of rooms" [value]="room.id">
      {{ room.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
