import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ControlCheckEventDTO } from '../../../../../../../server/src/dto/control-check-event.dto';
import { LimitedProcedureDTO, ProcedureDTO, ProcedureStepDTO } from '../../../../../../../server/src/dto/procedure.dto';
import { ListUtils } from '../../../../../../../server/src/utils/list-utils';
import { RouteNavigationService } from '../../../../shared/routing/route-navigation.service';
import { ProceduresService } from '../../../../shared/services/procedures/procedures.service';
import { ControlChecksService } from '../../shared/services/control-checks.service';

@Component({
  selector: 'app-control-check-events-edit',
  templateUrl: './control-check-events-edit.component.html',
  styleUrls: ['./control-check-events-edit.component.scss'],
})
export class ControlCheckEventsEditComponent implements OnInit, OnDestroy {

  constructor(private _activatedRoute: ActivatedRoute,
              private _routeNavigationService: RouteNavigationService,
              private _proceduresService: ProceduresService,
              private _controlCheckEventsService: ControlChecksService) {
  }

  public isEditing: boolean;
  public saveInProgress: boolean;
  public hasError: boolean;

  public procedure: ProcedureDTO;
  public procedureStepChecks: {
    [id: string]: boolean;
  };

  public controlCheckEvent: ControlCheckEventDTO;

  public ngOnInit() {
    this._activatedRoute.params
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(async params => {
        const controlCheckEventId = params.controlCheckEventId;

        if (controlCheckEventId !== 'new') {
          this.isEditing = true;

          this.controlCheckEvent = await this._controlCheckEventsService.getControlCheckEventByIdAsync(controlCheckEventId);

          await this.onSelectedProcedureChange(this.controlCheckEvent.procedure);
        } else {
          this.isEditing = false;

          this.controlCheckEvent = {
            id: null,
            procedure: null,
            department: null,
            recordedBy: null,
            targetUserIds: null,
            successPercentage: null,
            successfulStepIds: [],
            failedStepIds: [],
            notes: null,
            createdAt: null,
          };
        }
      });
  }

  public ngOnDestroy(): void {
  }

  public async saveControlCheckAsync(saveForm: NgForm) {
    saveForm.form.markAllAsTouched();

    if (!saveForm.valid || !this.controlCheckEvent.procedure || !this.controlCheckEvent.department) {
      return;
    }

    try {
      this.saveInProgress = true;
      this.hasError = false;

      this.controlCheckEvent.successfulStepIds = Object.keys(this.procedureStepChecks).filter(id => this.procedureStepChecks[id]);
      this.controlCheckEvent.failedStepIds = Object.keys(this.procedureStepChecks).filter(id => !this.procedureStepChecks[id]);

      await this._controlCheckEventsService.saveControlCheckEventAsync(this.controlCheckEvent);

      await this._routeNavigationService.goToControlCheckEventListAsync();
    } catch {
      this.hasError = true;
    } finally {
      this.saveInProgress = false;
    }
  }

  public async onSelectedProcedureChange(procedure: LimitedProcedureDTO) {
    this.procedure = await this._proceduresService.getProcedureByIdAsync(procedure.id);

    this.procedureStepChecks = {};
    for (const step of this.procedure.steps) {
      this.procedureStepChecks[step.id] = this.controlCheckEvent.successfulStepIds.includes(step.id);
    }

    this.procedure.steps.sort(ListUtils.compareByRank);
  }

  public toggleStepCheck(step: ProcedureStepDTO) {
    this.procedureStepChecks[step.id] = !this.procedureStepChecks[step.id];
  }
}
