import { Inject, Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { Environment } from '../../../../environments/environment.interface';
import { ENVIRONMENT } from '../injection-tokens';

@Injectable()
export class TrackingService {
  constructor(private _angulartics2: Angulartics2,
              @Inject(ENVIRONMENT) private _environment: Environment) {

  }

  public trackEvent(eventName: string, eventCategory: string, eventProperties: any = undefined): void {
    if (!this._environment.analytics.trackingEnabled) {
      return;
    }

    try {
      eventProperties = eventProperties || {};
      eventProperties.category = eventProperties.category || eventCategory;

      this._angulartics2.eventTrack.next({
        action: eventName,
        properties: eventProperties,
      });
    } catch (e) {
      console.log('Event tracking failed');
    }
  }
}
