import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable()
export class DateProviderService {
  constructor(private _datePipe: DatePipe) {

  }

  public now(): Date {
    return new Date();
  }

  public nowISOString(): string {
    return this.now().toISOString();
  }

  public formatDate(date: Date, format?: string, timezone?: string, locale?: string) {
    return this._datePipe.transform(date, format, timezone, locale);
  }
}