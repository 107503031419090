import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Environment } from '../../environments/environment.interface';
import { RouteNavigationService } from '../shared/routing/route-navigation.service';
import { ENVIRONMENT } from '../shared/services/injection-tokens';
import { LanguageService } from '../shared/services/language/language.service';
import { TrackingService } from '../shared/services/tracking/tracking.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {

  constructor(@Inject(ENVIRONMENT) private _environment: Environment,
              private _languageService: LanguageService,
              private _routeNavigationService: RouteNavigationService,
              private _trackingService: TrackingService) {
  }

  public thisYear: string = (new Date()).getFullYear().toString();

  public contactUrl: string = `mailto:${ this._environment.contactEmail }`;

  public helpUrl: string;

  ngOnInit() {
    this._languageService.getCurrentLanguageObservable()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.helpUrl = `${ this._environment.helpUrl }/${ this._languageService.getCurrentLanguage() }`;
      });
  }

  public ngOnDestroy(): void {
  }

  public async termsClick() {
    this._trackingService.trackEvent('Terms Click', 'Footer');

    await this._routeNavigationService.goToTermsAndConditionsAsync();
  }

  public async privacyClick() {
    this._trackingService.trackEvent('Privacy Click', 'Footer');

    await this._routeNavigationService.goToPrivacyPolicyAsync();
  }

  public async mailClick() {
    this._trackingService.trackEvent('Mail Click', 'Footer');
  }

  public async helpClick() {
    this._trackingService.trackEvent('Help Click', 'Footer');
  }
}
