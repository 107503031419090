import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { QuillEditorComponent } from 'ngx-quill';
import { ImageUploader } from './shared/quill-plugins/image-uploader.quill-plugin';
import { EditorImageUploadService } from './shared/services/editor-image-upload.service';

import Quill from 'quill';

import MagicUrl from 'quill-magic-url';
import BlotFormatter from 'quill-blot-formatter';

import { FontStyle } from 'quill/formats/font';

FontStyle.whitelist = ['proxima'];
Quill.register(FontStyle, true);

Quill.register('modules/magicUrl', MagicUrl);
Quill.register('modules/blotFormatter', BlotFormatter);
Quill.register('modules/imageUploader', ImageUploader);

@Component({
  selector: 'app-rich-editor',
  templateUrl: './rich-editor.component.html',
  styleUrls: ['./rich-editor.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: RichEditorComponent,
    multi: true,
  }],
})
export class RichEditorComponent implements OnInit, ControlValueAccessor {
  private _controlInitiated: boolean;

  private _onChange: any;
  private _onTouched: any;

  private _imagesInEditor: string[];

  constructor(private _editorImageUploadService: EditorImageUploadService) {
  }

  @Input()
  public placeholder: string;

  @ViewChild(QuillEditorComponent, { static: true })
  public editor: QuillEditorComponent;

  public value: string;

  public modules: { magicUrl: boolean, imageUploader: any, blotFormatter: any, toolbar: any } = {
    magicUrl: true,
    imageUploader: {
      upload: async file => {
        const createdImage = await this._editorImageUploadService.uploadImageAsync(file);

        this._imagesInEditor = this._imagesInEditor || [];
        this._imagesInEditor.push(createdImage.url);

        return createdImage.url;
      },
    },
    blotFormatter: {},
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'align': [] }],

      [{ 'header': [1, 2, 3, 4, 5, false] }],

      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],

      ['blockquote'],

      [{ 'color': [] }, { 'background': [] }],

      ['link', 'image', 'video'],

      ['clean'],
    ],
  };

  ngOnInit() {

  }

  public onInnerValueChanged(value: string) {
    if (!this._controlInitiated) {
      return;
    }

    this.value = value;

    this._updateImagesInTextAsync();

    this._onTouched(value);
    this._onChange(value);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(value: string): void {
    this.value = value;

    this._updateImagesInTextAsync();

    setTimeout(() => {
      this._controlInitiated = true;
    }, 10);
  }

  private async _updateImagesInTextAsync() {
    const originalImagesInEditor = this._imagesInEditor || [];

    this.value = this.value || '';

    const imageMatchesInText = this.value.match(/src="(https:\/\/files\.proceduremanager\.(work|com)\/files\/[^"]*)"/gi);

    if (!imageMatchesInText) {
      this._imagesInEditor = [];
    } else {
      this._imagesInEditor = imageMatchesInText.map(m => m.slice(5, -1));
    }

    const imagesRemoved = originalImagesInEditor.filter(image => !this._imagesInEditor.includes(image));

    if (imagesRemoved.length) {
      await Promise.all(imagesRemoved.map(imageUrl => this.tryRemoveImageAsync(imageUrl)));
    }
  }

  private async tryRemoveImageAsync(imageUrl: string) {
    try {
      await this._editorImageUploadService.removeImageAsync(imageUrl);
    } catch {
      console.log('Failed to remove image');
    }
  }
}
