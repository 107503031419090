import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
  constructor(private _activatedRoute: ActivatedRoute) {

  }

  public selectedTabIndex: number;

  public ngOnInit() {
    if (this._activatedRoute.snapshot.queryParams['registration'] === '1') {
      this.selectedTabIndex = 1;
    }
  }
}