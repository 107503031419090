import { HttpHeader } from '@angular-cool/http';
import { IResponseInterceptor } from '@angular-cool/http/lib/response-interceptor.interface';
import { Injectable } from '@angular/core';
import * as HTTP_STATUS from 'http-status-codes';
import { UserContextService } from '../user-context/user-context.service';

export const API_EXCEPTIONS: RegExp[] = [
  /\/api\/settings$/,
];

@Injectable()
export class UnauthenticatedInterceptor implements IResponseInterceptor {
  constructor(private _userContextService: UserContextService) {
  }

  public async afterResponseAsync(response: any, url: string, method: string, data: any, headers: HttpHeader[]): Promise<boolean> {
    if (response.status === HTTP_STATUS.UNAUTHORIZED && !API_EXCEPTIONS.some(exception => exception.test(response.url))) {
      this._userContextService.goToLogout();

      return true;
    }

    return false;
  }
}
