<div>
  <app-page-header pageTitle="{{ 'Dashboard.Navigation.AssetControlRooms' | translate }}">
    <div>
      <div class="d-inline-block">
        <mat-form-field>
          <input matInput (keyup)="filterRooms($event.target.value)"
                 placeholder="{{ 'Common.Filter' | translate }}">
        </mat-form-field>
      </div>

      <button class="ml-5"
              mat-raised-button color="primary"
              (click)="addNewRoomAsync()">
        <fa-icon icon="plus" class="mr-1"></fa-icon>

        {{ 'Dashboard.AssetControlRooms.AddNewRoom' | translate }}
      </button>
    </div>
  </app-page-header>

  <div class="w-100">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z4 w-100">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Common.Name' | translate }}</th>
        <td mat-cell *matCellDef="let room">
          <a (click)="goToRoomEventsAsync(room); $event.preventDefault();" href="#" class="text-decoration-none">
            {{ room.name }}
          </a>
        </td>
      </ng-container>

      <!-- Category Column -->
      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Common.Category' | translate }}</th>
        <td mat-cell *matCellDef="let room">
            {{ room.category?.name }}
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ 'Common.Actions' | translate }}</th>
        <td mat-cell *matCellDef="let room">
          <button mat-raised-button color="primary"
                  [attr.title]="'Dashboard.AssetControlRooms.Events' | translate"
                  (click)="goToRoomEventsAsync(room)">
            <fa-icon [icon]="['fab', 'searchengin']" size="lg" class="mr-2"></fa-icon>

            {{ 'Dashboard.AssetControlRooms.Events' | translate }}
          </button>

          <button mat-raised-button color="accent" class="ml-3"
                  [attr.title]="'Dashboard.AssetControlRooms.Assets' | translate"
                  (click)="goToRoomAssetsAsync(room)">
            <fa-icon icon="cube" class="mr-2"></fa-icon>

            {{ 'Dashboard.AssetControlRooms.Assets' | translate  }}
          </button>

          <button mat-raised-button color="accent" class="ml-3"
                  [attr.title]="'Common.Edit' | translate"
                  (click)="editRoomAsync(room)">
            <fa-icon icon="pencil-alt" class="mr-2"></fa-icon>

            {{ 'Common.Edit' | translate }}
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div class="text-center mt-3" *ngIf="isLoading">
      <app-loading-indicator></app-loading-indicator>
    </div>
  </div>
</div>
