import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { filter } from 'rxjs/operators';
import { ControlCheckEventDTO } from '../../../../../../../server/src/dto/control-check-event.dto';
import { RouteNavigationService } from '../../../../shared/routing/route-navigation.service';
import { ControlChecksService } from '../../shared/services/control-checks.service';

export const PAGE_SIZE = 10;

@Component({
  selector: 'app-control-check-events-list',
  templateUrl: './control-check-events-list.component.html',
  styleUrls: ['./control-check-events-list.component.scss'],
})
export class ControlCheckEventsListComponent implements OnInit, OnDestroy {
  private _maxNumberOfItems: number;
  private _lastLoadedPageIndex: number;
  private _loadedAllEvents: boolean;

  constructor(private _controlChecksService: ControlChecksService,
              private _changeDetector: ChangeDetectorRef,
              private _routeNavigationService: RouteNavigationService) {

  }

  @ViewChild(MatPaginator, { static: true })
  public paginator: MatPaginator;

  public displayedColumns = ['procedure', 'department', 'createdAt', 'recordedBy', 'successPercentage', 'notes', 'actions'];

  public isLoading: boolean;

  public data: ControlCheckEventDTO[];
  public dataSource: MatTableDataSource<ControlCheckEventDTO>;
  public isPaginatorDisabled: boolean;

  public pageSize = PAGE_SIZE;

  public async ngOnInit() {
    this._maxNumberOfItems = (await this._controlChecksService.getNumberOfControlCheckEventsAsync()).eventCount;

    this.data = new Array(this._maxNumberOfItems);
    await this._loadNextBatchOfDataIntoDataSourceAsync(0, 2 * PAGE_SIZE);

    this.dataSource.paginator.page
      .pipe(
        filter(page => page.pageIndex > this._lastLoadedPageIndex),
        untilDestroyed(this),
      )
      .subscribe(async page => {
        this.isPaginatorDisabled = true;
        this._changeDetector.markForCheck();

        await this._loadNextBatchOfDataIntoDataSourceAsync(page.pageIndex, PAGE_SIZE);

        this.isPaginatorDisabled = false;
        this._changeDetector.markForCheck();
      });
  }

  public ngOnDestroy(): void {
  }

  public async addNewControlCheckEventAsync() {
    await this._routeNavigationService.goToEditControlCheckEventAsync('new');
  }

  public async editControlCheckEventAsync(controlEvent: ControlCheckEventDTO) {
    await this._routeNavigationService.goToEditControlCheckEventAsync(controlEvent.id);
  }

  private async _loadNextBatchOfDataIntoDataSourceAsync(pageIndex: number, numberOfItemsToLoad: number) {
    if (this._loadedAllEvents) {
      return;
    }

    try {
      this.isLoading = true;

      const lastItemIndex = (pageIndex * PAGE_SIZE) - 1;

      const lastLoadedEventId = this.data[lastItemIndex] ? this.data[lastItemIndex].id : '9223372036854775807';

      const controlCheckEvents = await this._controlChecksService.getControlCheckEventsAsync(lastLoadedEventId, numberOfItemsToLoad);

      if (!controlCheckEvents.length || controlCheckEvents.length < numberOfItemsToLoad) {
        this._loadedAllEvents = true;
      }

      for (let i = 0; i < controlCheckEvents.length; i++) {
        this.data[lastItemIndex + 1 + i] = controlCheckEvents[i];
      }

      this._lastLoadedPageIndex = pageIndex;

      this.dataSource = new MatTableDataSource(this.data);
      this.dataSource.paginator = this.paginator;
    } finally {
      this.isLoading = false;
    }
  }
}
