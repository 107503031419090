<mat-card class="mat-elevation-z4">
  <mat-card-header>
    <mat-card-title>{{ 'Dashboard.Home.MyActiveLearningCampaigns' | translate }}</mat-card-title>
  </mat-card-header>
  <mat-card-content class="px-3">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0 w-100">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ 'Common.Name' | translate }}</th>
        <td mat-cell *matCellDef="let campaign">{{ campaign.name }}</td>
      </ng-container>

      <!-- End Date Column -->
      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef>{{ 'Common.Deadline' | translate }}</th>
        <td mat-cell *matCellDef="let campaign">{{ campaign.endDate | date }}</td>
      </ng-container>

      <!-- Minutes Required To Complete Column -->
      <ng-container matColumnDef="minutesRequiredToComplete">
        <th mat-header-cell *matHeaderCellDef>{{ 'Common.Length' | translate }}</th>
        <td mat-cell *matCellDef="let campaign">
          <span>{{ campaign.minutesRequiredToComplete }}</span>
          <span class="ml-2">{{ 'Common.Time.Minutes' | translate }}</span>
        </td>
      </ng-container>

      <!-- Result Column -->
      <ng-container matColumnDef="result">
        <th mat-header-cell *matHeaderCellDef>{{ 'Common.Score' | translate }}</th>
        <td mat-cell *matCellDef="let campaign">
          <span *ngIf="campaign.result"
                [class.text-danger]="campaign.result.score < 60"
                [class.text-info]="campaign.result.score >= 60 && campaign.result.score < 85"
                [class.text-success]="campaign.result.score >= 85">
            {{ campaign.result.score }}%
          </span>

          <span *ngIf="campaign.result && !campaign.result.isSuccessful" class="ml-2 color-warn">
            ({{ 'Common.Failed' | translate }})
          </span>
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ 'Common.Actions' | translate }}</th>
        <td mat-cell *matCellDef="let campaign">
          <button mat-raised-button color="primary"
                  *ngIf="!campaign.result || !campaign.result.isSuccessful"
                  [attr.title]="'Common.Start' | translate"
                  (click)="startCampaignAsync(campaign)">
            <fa-icon icon="book" class="mr-2"></fa-icon>
            {{ 'Common.Start' | translate }}
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div class="text-center mt-3" *ngIf="!isLoading && !dataSource?.data.length">
      {{ 'Dashboard.Home.NoActiveLearningCampaigns' | translate }}
    </div>

    <div class="text-center mt-3" *ngIf="isLoading">
      <app-loading-indicator></app-loading-indicator>
    </div>

    <div class="mt-4 text-right">
      <div class="hover-strong cursor-pointer" *ngIf="!completedAreShownAsWell" (click)="loadCompletedAsWellAsync()">
        {{ 'Dashboard.Home.ShowCompleted' | translate }}
      </div>
    </div>
  </mat-card-content>
</mat-card>