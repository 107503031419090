import { CoolHttp } from '@angular-cool/http';
import { Injectable } from '@angular/core';
import { ProcedureTestDTO } from '../../../../../../server/src/dto/procedure-test.dto';

@Injectable()
export class ProcedureTestService {
  constructor(private _http: CoolHttp) {

  }

  public async getProcedureTestAsync(procedureId: string): Promise<ProcedureTestDTO> {
    return await this._http.getAsync(`api/companies/active/procedures/${ procedureId }/test`);
  }

  public async updateProcedureTestAsync(procedureId: string, procedureTest: ProcedureTestDTO): Promise<ProcedureTestDTO> {
    return await this._http.postAsync(`api/companies/active/procedures/${ procedureId }/test`, procedureTest);
  }
}