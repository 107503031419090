<div *ngIf="displayOnly">{{ selectedRoomCategory?.name }}</div>

<mat-form-field *ngIf="!displayOnly" class="w-100">
  <mat-label>{{ placeholder }}</mat-label>
  <mat-select name="{{ name }}-inner-select"
              [ngModel]="selectedRoomCategory?.id"
              (ngModelChange)="onInnerChange($event)"
              [disabled]="isDisabled"
              [required]="required">
    <mat-option [value]="null">{{ 'Common.None' | translate }}</mat-option>

    <mat-option *ngFor="let category of roomCategories" [value]="category.id">
      {{ category.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
