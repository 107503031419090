import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { LimitedProcedureDTO } from '../../../../../../server/src/dto/procedure.dto';
import { ProceduresService } from '../../services/procedures/procedures.service';
import { LanguageService } from '../../services/language/language.service';

@Component({
  selector: 'app-procedure-select',
  templateUrl: './procedure-select.component.html',
  styleUrls: ['./procedure-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: ProcedureSelectComponent,
    multi: true,
  }],
})
export class ProcedureSelectComponent implements OnInit, ControlValueAccessor {
  private _onChange: (procedures: LimitedProcedureDTO) => void;
  private _onTouched: (procedures: LimitedProcedureDTO) => void;

  constructor(private _proceduresService: ProceduresService,
              private _languageService: LanguageService) {
  }

  @Input()
  public name: string;

  @Input()
  public placeholder: string;

  @Input()
  public procedures: LimitedProcedureDTO[];

  @Input()
  public required: boolean;

  @ViewChild(MatSelect)
  public procedureSelect: MatSelect;

  public isDisabled: boolean;
  public selectedProcedure: LimitedProcedureDTO;

  public async ngOnInit() {
    this.procedures = this.procedures || await this._proceduresService.getProceduresAsync(false);

    this.placeholder = this.placeholder || await this._languageService.getTranslationAsync('Common.Procedure');
  }

  public onInnerChange(procedureId: string) {
    this.selectedProcedure = this.procedures.find(procedure => procedure.id === procedureId);

    this._onTouched && this._onTouched(this.selectedProcedure);
    this._onChange && this._onChange(this.selectedProcedure);
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public writeValue(procedure: LimitedProcedureDTO): void {
    this.selectedProcedure = procedure || null;
  }

  public markAsTouched() {
    this.procedureSelect.ngControl.control.markAllAsTouched();
  }
}
