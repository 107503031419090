export const RouteLocations = {
  Home: '',

  RequestDemo: 'request-demo',

  OnInstall: 'installed',

  Login: 'login',
  LoginSuccess: 'login-success',
  ActivateEmail: 'activate-email',
  ForgotPassword: 'forgot-password',
  RestorePassword: 'restore-password',
  SSOLogin: 'login/sso/:identifier',

  Settings: 'settings',

  CompanyJoin: 'company/join',

  Dashboard: 'dashboard',

  DashboardChildren: {
    DashboardHome: '',

    ProcedureCategories: 'procedure-categories',

    Procedures: 'procedures',
    ProceduresChildren: {
      ProcedureList: '',

      ViewProcedure: ':procedureId',

      EditProcedure: ':procedureId/edit',

      EditProcedureChildren: {
        General: 'general',

        Steps: 'steps',

        Test: 'test',
      },
    },

    KnowledgeBaseArticles: 'knowledge-base/articles',
    KnowledgeBaseArticlesChildren: {
      ArticlesList: '',

      ViewArticle: ':articleId',

      EditArticle: ':articleId/edit',
    },

    Organization: 'organization',
    OrganizationChildren: {
      Departments: 'departments',

      Roles: 'roles',

      Permissions: 'permissions',

      Users: 'users',
    },

    Learning: 'learning',
    LearningChildren: {
      Campaigns: 'campaigns',
      CampaignsChildren: {
        CampaignList: 'list',
        EditCampaign: ':campaignId/edit',
      },

      Reports: 'reports',
      ReportsChildren: {
        List: 'list',
        View: ':reportId/view',
      },

      Certificates: 'certificates',
      CertificateTemplates: 'certificate-templates',

      CompleteCampaign: 'complete-campaign/:campaignId',
    },

    Control: 'control',
    ControlChildren: {
      ControlCheckEvents: 'control-checks-events',
      ControlCheckEventsChildren: {
        ListEvents: 'list',
        EditEvent: 'edit/:controlCheckEventId',
      },

      ControlReports: 'reports',
      ControlReportsChildren: {
        List: 'list',
        View: ':reportId/view',
      },
    },

    AssetControl: 'asset-control',
    AssetControlChildren: {
      RoomCategories: 'room-categories',

      Rooms: 'rooms',
      RoomsChildren: {
        List: '',
        Edit: ':roomId',
        Assets: ':roomId/assets',
        Events: ':roomId/events',
      },

      AssetTypes: 'asset-types',

      Reports: 'reports',
      ReportsChildren: {
        List: 'list',
        View: ':reportId/view',
      },
    },

    Settings: 'settings',
  },

  TermsAndConditions: 'terms',
  PrivacyPolicy: 'privacy',
};
