import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PermissionRoleDTO } from '../../../../../../../../server/src/dto/permission-role.dto';
import { OrganizationService } from '../../../../../shared/services/organization/organization.service';

@Component({
  selector: 'app-permission-role-select',
  templateUrl: './permission-role-select.component.html',
  styleUrls: ['./permission-role-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: PermissionRoleSelectComponent,
    multi: true,
  }],
})
export class PermissionRoleSelectComponent implements OnInit, ControlValueAccessor {
  private _onChange: (role: PermissionRoleDTO) => void;
  private _onTouched: (role: PermissionRoleDTO) => void;

  constructor(private _organizationService: OrganizationService) {
  }

  @Input()
  public displayOnly: boolean;

  @Input()
  public name: string;

  @Input()
  public placeholder: string;

  public isDisabled: boolean;

  public selectedPermissionRole: PermissionRoleDTO;

  public permissionRoles: PermissionRoleDTO[];

  async ngOnInit() {
    this.permissionRoles = await this._organizationService.getPermissionRolesAsync();
  }

  public onInnerChange(change: string) {
    this.selectedPermissionRole = this.permissionRoles.find(role => role.id === change);

    this._onTouched && this._onTouched(this.selectedPermissionRole);
    this._onChange && this._onChange(this.selectedPermissionRole);
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public writeValue(permissionRole: PermissionRoleDTO): void {
    this.selectedPermissionRole = permissionRole;
  }
}
