<div *ngIf="displayOnly">{{ displayOnlyFormat() }}</div>

<mat-form-field *ngIf="!displayOnly" class="w-100">
  <mat-label>{{ placeholder }}</mat-label>
  <mat-select name="{{ name }}-inner-select"
              [ngModel]="selectedDepartmentIds"
              (ngModelChange)="onInnerChange($event)"
              [disabled]="isDisabled"
              [required]="required"
              [multiple]="multiple">
    <mat-option *ngFor="let department of departments" [value]="department.id">
      {{ department.name }}
    </mat-option>
  </mat-select>
</mat-form-field>