<div>
  <app-page-header [pageTitle]="article?.name">
    <div>
      <button *ngIf="canUserEditArticles && article"
              mat-raised-button color="primary"
              (click)="editArticleAsync()">
        <fa-icon icon="pencil-alt" class="mr-1"></fa-icon>

        {{ 'Dashboard.KnowledgeBase.EditArticle' | translate }}
      </button>
    </div>
  </app-page-header>

  <div *ngIf="article">
    <mat-card class="mat-elevation-z4">
      <mat-card-content>
        <app-rich-display *ngIf="article.description"
                          [contentHTML]="article.description">
        </app-rich-display>

        <div class="mt-3">
          <app-file-display [files]="article.files">
          </app-file-display>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div *ngIf="isLoading" class="text-center mt-3">
    <app-loading-indicator></app-loading-indicator>
  </div>

  <div *ngIf="showNotFound" class="text-center mt-3">
    <app-not-found></app-not-found>
  </div>
</div>