import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { UserContextService } from '../../shared/services/user-context/user-context.service';

@Component({
  selector: 'app-sso-login',
  templateUrl: './sso-login.component.html',
  styleUrls: ['./sso-login.component.scss'],
})
export class SSOLoginComponent implements OnInit, OnDestroy {

  constructor(private _activatedRoute: ActivatedRoute,
              private _userContextService: UserContextService) {
  }

  public redirecting: boolean;

  public identifier: string;

  ngOnInit() {
    this._userContextService.tryAutomaticallyLogInToSSO();

    this._activatedRoute.params
      .pipe(untilDestroyed(this))
      .subscribe((params: Params) => {
        if (params.identifier) {
          this.identifier = params.identifier;

          this.ssoLogin();
        } else {
          this.identifier = this._userContextService.getSSOAutoLoginIdentifier();
        }
      });
  }

  public ngOnDestroy(): void {
  }

  public ssoLogin() {
    if (!this.identifier) {
      return;
    }

    this.redirecting = true;

    this._userContextService.goToSSOLogin(this.identifier);
  }
}
