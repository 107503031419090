<div id="app">
  <div id="header-container">
    <app-header></app-header>
  </div>

  <div id="content-container">
    <router-outlet></router-outlet>
  </div>

  <div id="footer-container" [class.with-left-nav]="isLeftNavShown">
    <app-footer></app-footer>
  </div>
</div>