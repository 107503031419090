import { CompanySubscriptionTypes } from './company-subscription-types.enum';

export enum FeaturePermissions {
  ManageCompanyUsers = 'manage-company-users',
  ManageCompanySettings = 'manage-company-settings',

  ViewProcedures = 'view-procedures',
  EditProcedures = 'edit-procedures',

  ParticipateInLearningCampaigns = 'participate-in-learning-campaigns',
  ManageLearningCampaigns = 'manage-learning-campaigns',
  ViewLearningReports = 'view-learning-reports',
  EditProcedureTests = 'edit-procedure-tests',
  ManageLearningCertificates = 'manage-learning-certificates',

  ManageControlCheckEvents = 'manage-control-check-events',
  ViewControlReports = 'view-control-reports',

  EditKnowledgeBaseArticles = 'edit-knowledge-base-articles',
  ViewKnowledgeBaseArticles = 'view-knowledge-base-articles',

  ManageAssetControl = 'manage-asset-control',
  ViewAssetControlReports = 'view-asset-control-reports',

  CopyProcedureToOtherCompany = 'copy-procedure-to-other-company'
}

export const SubscriptionBasedFeaturePermissions: Readonly<{
  [sub in CompanySubscriptionTypes]: FeaturePermissions[];
}> = {
  [CompanySubscriptionTypes.Platform]: [
    FeaturePermissions.ViewProcedures,
    FeaturePermissions.EditProcedures,
    FeaturePermissions.ManageCompanyUsers,
    FeaturePermissions.ManageCompanySettings,
    FeaturePermissions.ViewKnowledgeBaseArticles,
    FeaturePermissions.EditKnowledgeBaseArticles,
  ],
  [CompanySubscriptionTypes.Learn]: [
    FeaturePermissions.EditProcedureTests,
    FeaturePermissions.ParticipateInLearningCampaigns,
    FeaturePermissions.ManageLearningCampaigns,
    FeaturePermissions.ViewLearningReports,
    FeaturePermissions.ManageLearningCertificates,
  ],
  [CompanySubscriptionTypes.Control]: [
    FeaturePermissions.ManageControlCheckEvents,
    FeaturePermissions.ViewControlReports,
  ],
  [CompanySubscriptionTypes.AssetControl]: [
    FeaturePermissions.ManageAssetControl,
    FeaturePermissions.ViewAssetControlReports,
  ],
  [CompanySubscriptionTypes.ManagedServiceProvider]: [
    FeaturePermissions.CopyProcedureToOtherCompany,
  ],
};