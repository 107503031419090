import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LimitedCompanyUserDTO } from '../../../../../../server/src/dto/company-user.dto';
import { LanguageService } from '../../services/language/language.service';
import { OrganizationService } from '../../services/organization/organization.service';

@Component({
  selector: 'app-user-selector',
  templateUrl: './user-selector.component.html',
  styleUrls: ['./user-selector.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: UserSelectorComponent,
    multi: true,
  }],
})
export class UserSelectorComponent implements OnInit, OnDestroy, ControlValueAccessor {
  private _onChange: (users: string[]) => void;
  private _onTouched: (users: string[]) => void;

  private _inputChangedSubject = new Subject<string>();

  constructor(private _organizationService: OrganizationService,
              private _changeDetector: ChangeDetectorRef,
              private _languageService: LanguageService) {
  }

  public filteredUsers: LimitedCompanyUserDTO[] = [];

  public selectedUserIds: string[];

  public userNamesDictionary: {
    [id: string]: string;
  } = {};

  @Input()
  public displayOnly: boolean = false;

  @Input()
  public selectable: boolean = true;

  @Input()
  public removable: boolean = true;

  @Input()
  public addOnBlur: boolean = true;

  @Input()
  public disabled: boolean = false;

  @Input()
  public placeholder: string;

  @Input()
  public separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('userInput')
  public userInput: ElementRef<HTMLInputElement>;

  @ViewChild('auto')
  public matAutocomplete: MatAutocomplete;

  public async ngOnInit() {
    this._inputChangedSubject
      .pipe(
        untilDestroyed(this),
        debounceTime(250),
      )
      .subscribe(async (inputValue: string) => {
        this.filteredUsers = await this._organizationService.getCompanyUsersAsync('0', 20, inputValue);

        for (const user of this.filteredUsers) {
          this.userNamesDictionary[user.id] = user.name;
        }

        this._changeDetector.detectChanges();
      });

    this._inputChangedSubject.next('');

    this.placeholder = this.placeholder || await this._languageService.getTranslationAsync('Common.Users');
  }

  public ngOnDestroy(): void {
  }

  public async onInputChangeAsync(inputValue: string) {
    this._inputChangedSubject.next(inputValue);
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public async writeValue(userIds: string[]): Promise<void> {
    if (!userIds || !userIds.length) {
      this.selectedUserIds = [];
    } else {
      this.selectedUserIds = userIds;

      const usersIdsWithoutNames: string[] = this.selectedUserIds.filter(userId => !this.userNamesDictionary[userId]);

      if (usersIdsWithoutNames.length) {
        const resolvedUsers = await this._organizationService.getCompanyUsersAsync('0', 20, null, usersIdsWithoutNames);

        for (const user of resolvedUsers) {
          this.userNamesDictionary[user.id] = user.name;
        }
      }
    }
  }

  public add(event: MatChipInputEvent): void {
    if (this.matAutocomplete.isOpen) {
      return;
    }

    const input = event.input;
    const value = (event.value || '').trim().toLowerCase();
    const user = this.filteredUsers.find(user => user.name.toLowerCase() === value);

    if (input) {
      input.value = '';
    }

    if (user) {
      this.selectedUserIds.push(user.id);
      this._inputChangedSubject.next('');

      this._updateModelView();
    }
  }

  public selected(event: MatAutocompleteSelectedEvent): void {
    const userId = event.option.value.id;

    const userInSelection = this.selectedUserIds.includes(userId);

    if (userInSelection) {
      return;
    }

    const user = this.filteredUsers.find(user => user.id === userId);

    if (!user) {
      return;
    }

    this.selectedUserIds.push(user.id);

    this.userInput.nativeElement.value = '';
    this._inputChangedSubject.next('');

    this._updateModelView();
  }

  public remove(userIdToRemove: string): void {
    this.selectedUserIds = this.selectedUserIds.filter(userId => userId !== userIdToRemove);

    this._updateModelView();
  }

  private _updateModelView() {
    this._onTouched && this._onTouched(this.selectedUserIds);
    this._onChange && this._onChange(this.selectedUserIds);
  }
}
