import { CoolHttp } from '@angular-cool/http';
import { Injectable } from '@angular/core';
import { PCacheable, PCacheBuster } from 'ngx-cacheable';
import { Subject } from 'rxjs';
import { DepartmentDTO } from '../../../../../../server/src/dto/department.dto';
import { ProcedureCategoryDTO, ProcedureDTO, ProcedureStepDTO } from '../../../../../../server/src/dto/procedure.dto';
import { FileUploadItem } from '../../components/file-upload-selector/file-upload-item.interface';

const procedureCacheInvalidator = new Subject<void>();

@Injectable()
export class ProceduresService {
  constructor(private _http: CoolHttp) {
  }

  @PCacheable({
    cacheBusterObserver: procedureCacheInvalidator,
    maxAge: 1000 * 60
  })
  public async getProceduresAsync(includeNonPublished: boolean, departments: DepartmentDTO[] = null, categories: ProcedureCategoryDTO[] = null): Promise<ProcedureDTO[]> {
    let params;

    if (departments || categories) {
      params = {};

      if (departments) {
        params.departments = departments.map(d => d.id).join(',');
      }

      if (categories) {
        params.categories = categories.map(d => d.id).join(',');
      }

      if (includeNonPublished) {
        params.includeNonPublished = '1';
      }
    }

    return await this._http.getAsync('api/companies/active/procedures', {
      params: params,
    });
  }

  @PCacheBuster({
    cacheBusterNotifier: procedureCacheInvalidator,
  })
  public async createProcedureAsync(name: string, description: string): Promise<ProcedureDTO> {
    return await this._http.postAsync('api/companies/active/procedures', {
      name: name,
      description: description,
    });
  }

  public async getProcedureByIdAsync(procedureId: string): Promise<ProcedureDTO> {
    return await this._http.getAsync(`api/companies/active/procedures/${ procedureId }`);
  }

  @PCacheBuster({
    cacheBusterNotifier: procedureCacheInvalidator,
  })
  public async removeProcedureByIdAsync(procedureId: string) {
    return await this._http.deleteAsync(`api/companies/active/procedures/${ procedureId }`);
  }

  @PCacheBuster({
    cacheBusterNotifier: procedureCacheInvalidator,
  })
  public async updateProcedureAsync(procedure: ProcedureDTO) {
    const formData = new FormData();

    const filesToUpload = (<FileUploadItem[]>procedure.files).filter(file => !!file.file);

    for (const file of filesToUpload) {
      formData.append(`file-${ file.id }`, file.file);
    }

    const featureImageUploadItem = <FileUploadItem> procedure.featuredImage;

    if (featureImageUploadItem?.file) {
      formData.append(`feature-image-file`, featureImageUploadItem.file);
    }

    const procedureData = <ProcedureDTO>JSON.parse(JSON.stringify(procedure));

    // remove unnecessary base64 image in url
    if (featureImageUploadItem?.file) {
      featureImageUploadItem.file = null;
      featureImageUploadItem.url = '';
    }

    // remove unnecessary base64 image in url
    for (const file of <FileUploadItem[]>procedureData.files) {
      file.file = null;

      if (file.id && file.id.includes('-')) {
        file.url = '';
      }
    }

    formData.append('procedure', JSON.stringify(procedureData));

    await this._http.postAsync(`api/companies/active/procedures/${ procedure.id }`, formData);
  }

  public async getProcedureStepsByProcedureIdAsync(procedureId: string): Promise<ProcedureStepDTO[]> {
    return await this._http.getAsync(`api/companies/active/procedures/${ procedureId }/steps`);
  }

  @PCacheBuster({
    cacheBusterNotifier: procedureCacheInvalidator,
  })
  public async updateProcedureStepsAsync(procedureId: string, steps: ProcedureStepDTO[]) {
    const formData = new FormData();

    for (const step of steps) {
      const featuredImage = (<FileUploadItem>step.featuredImage);

      if (featuredImage?.file) {
        formData.append(`file-${ step.id }-featured-image`, featuredImage.file);
      }

      const filesToUpload = (<FileUploadItem[]>step.files).filter(file => !!file.file);

      for (const file of filesToUpload) {
        formData.append(`file-${ step.id }-${ file.id }`, file.file);
      }
    }

    const stepsData = <ProcedureStepDTO[]>JSON.parse(JSON.stringify(steps));

    // remove unnecessary base64 image in url
    for (const step of stepsData) {
      const featuredImage = (<FileUploadItem>step.featuredImage);

      if (featuredImage) {
        featuredImage.file = null;

        featuredImage.url = '';
      }

      for (const file of <FileUploadItem[]>step.files) {
        file.file = null;

        if (file.id && file.id.includes('-')) {
          file.url = '';
        }
      }
    }

    formData.append('steps', JSON.stringify(stepsData));

    await this._http.postAsync(`api/companies/active/procedures/${ procedureId }/steps`, formData);
  }
}