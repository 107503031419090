import { CoolDialogService } from '@angular-cool/dialogs';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  AssetControlReportDateResolution,
  AssetControlReportGenerateRequestDTO,
} from '../../../../../../../server/src/dto/asset-control-report.dto';
import { ControlReportGenerateRequestDTO } from '../../../../../../../server/src/dto/control-report.dto';
import { ReportDTO } from '../../../../../../../server/src/dto/report.dto';
import { ReportStatus } from '../../../../../../../server/src/models/report-type.enum';
import { RouteNavigationService } from '../../../../shared/routing/route-navigation.service';
import { WINDOW } from '../../../../shared/services/injection-tokens';
import { LanguageService } from '../../../../shared/services/language/language.service';
import { AssetControlReportsService } from '../../shared/services/asset-control-reports.service';
import { AssetControlReportGenerateDialogComponent } from './asset-control-report-generate-dialog/asset-control-report-generate-dialog.component';

@Component({
  selector: 'app-asset-control-report-list',
  templateUrl: './asset-control-report-list.component.html',
  styleUrls: ['./asset-control-report-list.component.scss'],
})
export class AssetControlReportListComponent implements OnInit {
  constructor(private _assetControlReportsService: AssetControlReportsService,
              private _routeNavigationService: RouteNavigationService,
              private _languageService: LanguageService,
              private _coolDialogService: CoolDialogService,
              @Inject(WINDOW) private _window: Window,
              private _dialogService: MatDialog) {
  }

  public displayedColumns = [
    'dateResolution',
    'startDate',
    'endDate',
    'roomCategory',
    'room',
    'assetType',
    'role',
    'user',
    'status',
    'actions',
  ];
  public isLoading: boolean;

  public dataSource: MatTableDataSource<ReportDTO<AssetControlReportGenerateRequestDTO>>;

  @ViewChild(MatSort, { static: true })
  public sort: MatSort;

  public ReportStatus = ReportStatus;
  public AssetControlReportDateResolution = AssetControlReportDateResolution;

  public async ngOnInit() {
    await this.loadDataAsync();
  }

  public async addReportAsync() {
    const dialog = this._dialogService.open(AssetControlReportGenerateDialogComponent);

    await dialog.afterClosed().toPromise();

    await this.loadDataAsync();

    setTimeout(async () => {
      await this.loadDataAsync();
    }, 1000 * 30);

    setTimeout(async () => {
      await this.loadDataAsync();
    }, 1000 * 60);

    setTimeout(async () => {
      await this.loadDataAsync();
    }, 1000 * 60 * 1.5);
  }

  public downloadReport(report: ReportDTO<ControlReportGenerateRequestDTO>) {
    this._window.open(report.reportFileUrl, '_blank');
  }

  public async deleteReportAsync(report: ReportDTO<ControlReportGenerateRequestDTO>) {
    const removeDialogResult = await this._coolDialogService.showDialog({
      titleText: await this._languageService.getTranslationAsync('Dashboard.ReportsCommon.RemoveReportTitle'),
      questionText: await this._languageService.getTranslationAsync('Dashboard.ReportsCommon.RemoveReportDescription'),
      confirmActionButtonText: await this._languageService.getTranslationAsync('Common.Remove'),
      cancelActionButtonText: await this._languageService.getTranslationAsync('Common.Cancel'),
      confirmActionButtonColor: 'warn',
    });

    if (!removeDialogResult.isConfirmed) {
      return;
    }

    await this._assetControlReportsService.deleteReportByIdAsync(report.id);

    await this.loadDataAsync();
  }

  public async viewReportAsync(report: ReportDTO<AssetControlReportGenerateRequestDTO>) {
    await this._routeNavigationService.goToViewAssetControlReportAsync(report.id);
  }

  public async loadDataAsync() {
    try {
      this.isLoading = true;

      const reports = await this._assetControlReportsService.getReportsAsync();

      this.dataSource = new MatTableDataSource(reports);
      this.dataSource.sort = this.sort;
    } finally {
      this.isLoading = false;
    }
  }
}
