import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { filter } from 'rxjs/operators';
import { UserSettingsDTO } from '../../../../server/src/dto/user-settings.dto';
import { RouteLocations } from '../shared/routing/route-locations.enum';
import { RouteNavigationService } from '../shared/routing/route-navigation.service';
import { UserContextService } from '../shared/services/user-context/user-context.service';
import { AppStateActions } from '../shared/state/app.state';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

  constructor(private _routeNavigationService: RouteNavigationService,
              private _router: Router,
              private _store: Store,
              private _changeDetector: ChangeDetectorRef,
              private _userContextService: UserContextService) {
  }

  public isInitialized: boolean;

  public isUserLoggedIn: boolean;
  public user: UserSettingsDTO;

  public isOnDashboard: boolean;

  ngOnInit() {
    this._userContextService.isUserLoggedInObservable
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((userLoggedIn: boolean) => {
        this.isInitialized = true;

        this.isUserLoggedIn = userLoggedIn;

        this.user = this._userContextService.user;
      });

    this._router.events
      .pipe(
        untilDestroyed(this),
        filter(ev => ev instanceof NavigationStart),
      )
      .subscribe((event: NavigationStart) => {
        this.isOnDashboard = event.url.startsWith(`/${ RouteLocations.Dashboard }`);

        this._changeDetector.markForCheck();
      });
  }

  ngOnDestroy(): void {
  }

  public async loginClickAsync() {
    await this._routeNavigationService.goToLoginAsync();
  }

  public async settingsClickAsync() {
    await this._routeNavigationService.goToSettingsAsync();
  }

  public logoutClick() {
    this._userContextService.goToLogout();
  }

  public async goToDashboardClickAsync() {
    await this._routeNavigationService.goToDashboardAsync();
  }

  public toggleNavigationBar() {
    this._store.dispatch(new AppStateActions.ToggleNavbar());
  }
}
