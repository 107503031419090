<div id="company-selector" *ngIf="dataLoaded">
  <div [matMenuTriggerFor]="profilesMenu" class="d-flex flex-row cursor-pointer">
    <div class="logo">
      <fa-icon icon="user"></fa-icon>
    </div>

    <div>{{ activeCompanyProfile.companyName }}</div>

    <fa-icon *ngIf="hasMultipleCompanyProfiles"
             icon="chevron-down"
             size="xs"
             class="ml-2"
             style="position: relative; top: -2px;"></fa-icon>
  </div>

  <mat-menu #profilesMenu="matMenu">
    <button mat-menu-item
            *ngFor="let profile of companyProfiles"
            (click)="selectCompanyProfileAsync(profile)">
      {{ profile.companyName }}
    </button>
  </mat-menu>
</div>