<div class="alert alert-info mat-elevation-z2">
  <div class="d-flex flex-row py-1 position-relative">
    <div class="pr-2">
      <fa-icon icon="exclamation-triangle"></fa-icon>
    </div>

    <div class="px-2 flex-grow-1">
      <h6 class="mt-1 mb-0 font-weight-bold cursor-pointer"
          (click)="toggleIsOpened()">
        {{ title }}
      </h6>

      <div *ngIf="state.isOpened">
        <ng-content></ng-content>
      </div>
    </div>

    <div class="open-toggler" (click)="toggleIsOpened()">
      <fa-icon [icon]="state.isOpened ? 'chevron-down' : 'chevron-left'" size="xs"></fa-icon>
    </div>
  </div>
</div>