import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
  LearningCertificatesGenerateRequestDTO,
  LearningCertificateTemplateDTO,
} from '../../../../../../../server/src/dto/learning-certificates.dto';
import { LearningCampaignSelectComponent } from '../../../../shared/components/learning-campaign-select/learning-campaign-select.component';
import { RouteNavigationService } from '../../../../shared/routing/route-navigation.service';
import { LearningCertificateTemplatesService } from '../../../../shared/services/learning-certificates/learning-certificate-templates.service';
import { LearningCertificatesService } from '../../../../shared/services/learning-certificates/learning-certificates.service';

@Component({
  selector: 'app-learning-certificate-generate-dialog',
  templateUrl: './learning-certificate-generate-dialog.component.html',
  styleUrls: ['./learning-certificate-generate-dialog.component.scss'],
})
export class LearningCertificateGenerateDialogComponent implements OnInit {
  constructor(private _learningCertificatesService: LearningCertificatesService,
              private _routeNavigationService: RouteNavigationService,
              private _learningCertificateTemplatesService: LearningCertificateTemplatesService,
              private _dialog: MatDialogRef<LearningCertificateGenerateDialogComponent>) {

  }

  public isLoading: boolean;
  public hasError: boolean;

  public templates: LearningCertificateTemplateDTO[];

  public filters: LearningCertificatesGenerateRequestDTO = {
    learningCampaign: null,
    startDate: null,
    endDate: null,
    template: null,
    generateForAllUsers: true,
    generateForSpecificUserIds: [],
  };

  @ViewChild(LearningCampaignSelectComponent, { static: true })
  public learningCampaignSelect: LearningCampaignSelectComponent;

  public async ngOnInit() {
    this.templates = await this._learningCertificateTemplatesService.getLearningCertificateTemplatesAsync();
  }

  public onCancel() {
    this._dialog.close();
  }

  public async onGenerateCertificateClickAsync(filterForm: NgForm) {
    filterForm.form.markAllAsTouched();

    this.learningCampaignSelect.markAsTouched();

    if (!filterForm.valid) {
      return;
    }

    try {
      this.isLoading = true;
      this.hasError = false;

      await this._learningCertificatesService.generateCertificateAsync(this.filters);

      this._dialog.close();
    } catch {
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  }

  public async goToCertificateTemplatesAsync() {
    await this._routeNavigationService.goToLearningCertificateTemplatesAsync();

    this._dialog.close();
  }
}
