import { CoolDialogService } from '@angular-cool/dialogs';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AssetRoomCategoryDTO } from '../../../../../../server/src/dto/asset-control.dto';
import { LanguageService } from '../../../shared/services/language/language.service';
import { Editable } from '../../../shared/utils/editable';
import { AssetRoomService } from '../shared/services/asset-room.service';

@Component({
  selector: 'app-asset-control-room-categories',
  templateUrl: './asset-control-room-categories.component.html',
  styleUrls: ['./asset-control-room-categories.component.scss']
})
export class AssetControlRoomCategoriesComponent implements OnInit {
  constructor(private _assetRoomsService: AssetRoomService,
              private _dialogService: CoolDialogService,
              private _languageService: LanguageService) {

  }

  public displayedColumns = ['name', 'actions'];
  public isLoading: boolean;

  public updateRoomCategoryInProgress: boolean;

  public dataSource: MatTableDataSource<Editable<AssetRoomCategoryDTO>>;

  @ViewChild(MatSort, { static: true })
  public sort: MatSort;

  public async ngOnInit() {
    try {
      this.isLoading = true;

      await this._loadDataAsync();
    } finally {
      this.isLoading = false;
    }
  }

  public async addNewRoomCategoryAsync() {
    await this._assetRoomsService.upsertCategoryAsync({
      id: null,
      name: await this._languageService.getTranslationAsync('Dashboard.AssetControlRoomCategories.NewRoomCategoryName'),
    });

    await this._loadDataAsync();
  }

  public async editRoomCategoryAsync(roomCategory: Editable<AssetRoomCategoryDTO>) {
    if (!roomCategory.value.name) {
      return;
    }

    try {
      await this._assetRoomsService.upsertCategoryAsync(roomCategory.value);
    } finally {
      this.updateRoomCategoryInProgress = false;
    }

    roomCategory.stopEditing(true);

    await this._loadDataAsync();
  }

  public async startEditingRoomCategoryAsync(roomCategory: Editable<AssetRoomCategoryDTO>) {
    roomCategory.startEditing();
  }

  public async cancelEditingRoomCategoryAsync(roomCategory: Editable<AssetRoomCategoryDTO>) {
    roomCategory.stopEditing(false);
  }

  public async removeRoomCategoryAsync(roomCategory: Editable<AssetRoomCategoryDTO>) {
    const removeDialogResult = await this._dialogService.showDialog({
      titleText: await this._languageService.getTranslationAsync('Dashboard.AssetControlRoomCategories.RemoveRoomCategoryTitle', { roomCategoryName: roomCategory.value.name }),
      questionText: await this._languageService.getTranslationAsync('Dashboard.AssetControlRoomCategories.RemoveRoomCategoryDescription', { roomCategoryName: roomCategory.value.name }),
      confirmActionButtonText: await this._languageService.getTranslationAsync('Common.Remove'),
      cancelActionButtonText: await this._languageService.getTranslationAsync('Common.Cancel'),
      confirmActionButtonColor: 'warn',
    });

    if (!removeDialogResult.isConfirmed) {
      return;
    }

    await this._assetRoomsService.removeRoomCategoryAsync(roomCategory.value.id);

    await this._loadDataAsync();
  }

  public filterRoomCategories(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  private async _loadDataAsync() {
    const roomCategories = await this._assetRoomsService.getRoomCategoriesAsync();

    this.dataSource = new MatTableDataSource(roomCategories.map(roomCategory => new Editable(roomCategory)));

    this.dataSource.filterPredicate = (item: Editable<AssetRoomCategoryDTO>, filter: string) => !filter || !item.value || item.value.name.toLowerCase().includes(filter);

    this.dataSource.sortingDataAccessor = (item: Editable<AssetRoomCategoryDTO>, property: string) => {
      switch (property) {
        case 'name':
          return item.value.name;

        default:
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
  }
}
