import { CoolHttp } from '@angular-cool/http';
import { Injectable } from '@angular/core';
import { PCacheable, PCacheBuster } from 'ngx-cacheable';
import { Subject } from 'rxjs';
import {
  AssetRoomCheckpointDTO,
  AssetRoomCheckpointEventDTO,
} from '../../../../../../../server/src/dto/asset-room-checkpoint.dto';
import { ProcedureDTO } from '../../../../../../../server/src/dto/procedure.dto';
import { FileUploadItem } from '../../../../shared/components/file-upload-selector/file-upload-item.interface';

const checkpointsCacheInvalidator = new Subject<void>();

@Injectable()
export class AssetRoomCheckpointsService {
  constructor(private _http: CoolHttp) {

  }

  @PCacheable({
    cacheBusterObserver: checkpointsCacheInvalidator,
    maxAge: 1000 * 10,
  })
  public async getCheckpointsAsync(roomId: string, assetId: string, fromId: string, limit: number): Promise<AssetRoomCheckpointDTO[]> {
    const queryParams: any = {
      fromId: fromId,
      limit: limit.toString(),
    };

    if (assetId) {
      queryParams.assetId = assetId;
    }

    return await this._http.getAsync(`api/companies/active/asset-control/rooms/${ roomId }/checkpoints`, {
      params: queryParams,
    });
  }

  public async getCheckpointByIdAsync(roomId: string, checkpointId: string): Promise<AssetRoomCheckpointDTO> {
    return this._http.getAsync(`api/companies/active/asset-control/rooms/${ roomId }/checkpoints/${ checkpointId }`);
  }

  @PCacheBuster({
    cacheBusterNotifier: checkpointsCacheInvalidator,
  })
  public async upsertCheckpointAsync(roomId: string, checkpoint: AssetRoomCheckpointDTO): Promise<AssetRoomCheckpointDTO> {
    return await this._http.postAsync(`api/companies/active/asset-control/rooms/${ roomId }/checkpoints`, checkpoint);
  }

  @PCacheBuster({
    cacheBusterNotifier: checkpointsCacheInvalidator,
  })
  public async upsertCheckpointEventAsync(roomId: string, checkpointId: string, event: AssetRoomCheckpointEventDTO): Promise<AssetRoomCheckpointEventDTO> {
    const formData = new FormData();

    const filesToUpload = (<FileUploadItem[]>event.files).filter(file => !!file.file);

    for (const file of filesToUpload) {
      formData.append(`file-${ file.id }`, file.file);
    }

    const checkpointEventData = <ProcedureDTO>JSON.parse(JSON.stringify(event));

    // remove unnecessary base64 image in url
    for (const file of <FileUploadItem[]>event.files) {
      file.file = null;

      if (file.id && file.id.includes('-')) {
        file.url = '';
      }
    }

    formData.append('checkpointEvent', JSON.stringify(checkpointEventData));

    return await this._http.postAsync(`api/companies/active/asset-control/rooms/${ roomId }/checkpoints/${ checkpointId }/events`, formData);
  }

  public async getCheckpointEventAsync(roomId: string, checkpointId: string, checkpointEventId: string): Promise<AssetRoomCheckpointEventDTO> {
    return await this._http.getAsync(`api/companies/active/asset-control/rooms/${ roomId }/checkpoints/${ checkpointId }/events/${ checkpointEventId }`);
  }

  @PCacheBuster({
    cacheBusterNotifier: checkpointsCacheInvalidator,
  })
  public async removeCheckpointEventAsync(roomId: string, checkpointId: string, checkpointEventId: string): Promise<AssetRoomCheckpointEventDTO> {
    return await this._http.deleteAsync(`api/companies/active/asset-control/rooms/${ roomId }/checkpoints/${ checkpointId }/events/${ checkpointEventId }`);
  }

  @PCacheable({
    cacheBusterObserver: checkpointsCacheInvalidator,
    maxAge: 1000 * 10,
  })
  public async getCheckpointEventsAsync(roomId: string, checkpointId: string): Promise<AssetRoomCheckpointEventDTO[]> {
    return await this._http.getAsync(`api/companies/active/asset-control/rooms/${ roomId }/checkpoints/${ checkpointId }/events`);
  }
}