import { CoolHttp } from '@angular-cool/http';
import { Injectable } from '@angular/core';
import {
  LearningReportDTO,
  LearningReportGenerateRequestDTO,
} from '../../../../../../../../server/src/dto/learning-reports.dto';
import { ReportDTO } from '../../../../../../../../server/src/dto/report.dto';

@Injectable()
export class LearningReportService {
  constructor(private _http: CoolHttp) {

  }

  public async getReportByIdAsync(reportId: string): Promise<LearningReportDTO> {
    return await this._http.getAsync(`api/companies/active/learning/reports/${ reportId }`);
  }

  public async deleteReportByIdAsync(reportId: string): Promise<LearningReportDTO> {
    return await this._http.deleteAsync(`api/companies/active/learning/reports/${ reportId }`);
  }

  public async getReportsAsync(): Promise<ReportDTO<LearningReportGenerateRequestDTO>[]> {
    return await this._http.getAsync('api/companies/active/learning/reports');
  }

  public async generateReportAsync(generateRequest: LearningReportGenerateRequestDTO): Promise<void> {
    await this._http.postAsync('api/companies/active/learning/reports', generateRequest);
  }
}