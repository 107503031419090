<div>
  <app-page-header pageTitle="{{ 'Dashboard.Navigation.Procedures' | translate }}">
    <div>
      <mat-form-field>
        <input matInput (keyup)="filterAndSelectProceduresToShow($event.target.value)"
               placeholder="{{ 'Common.Filter' | translate }}">
      </mat-form-field>

      <app-department-select name="departments"
                             [(ngModel)]="selectedDepartments"
                             (ngModelChange)="onDepartmentFilterChangeAsync()"
                             class="ml-4 d-inline-block">
      </app-department-select>

      <app-procedure-category-select name="categories"
                                     [(ngModel)]="selectedCategories"
                                     (ngModelChange)="onCategoryFilterChangeAsync()"
                                     class="ml-4 d-inline-block">
      </app-procedure-category-select>

      <button *ngIf="canUserEditProcedures"
              class="ml-5"
              mat-raised-button color="primary"
              (click)="addNewProcedureAsync()">
        <fa-icon icon="plus" class="mr-1"></fa-icon>

        {{ 'Dashboard.Procedures.AddNewProcedure' | translate }}
      </button>
    </div>
  </app-page-header>

  <div class="procedure-card-container">
    <div *ngFor="let procedure of proceduresToShow; trackBy: trackProcedure"
         class="procedure-card"
         (click)="viewProcedureAsync(procedure)">
      <mat-card class="mat-elevation-z4 h-100">
        <div class="h-100 w-100 d-flex flex-column align-items-center">
          <div class="procedure-image-container">
            <div *ngIf="procedure.featuredImage"
                 class="feature-image"
                 [style.background-image]="'url(' + procedure.featuredImage.url + ')'">
            </div>

            <fa-icon *ngIf="!procedure.featuredImage" icon="cogs" size="4x"></fa-icon>
          </div>
          <div class="procedure-name">
            {{ procedure.name }}
          </div>
        </div>
      </mat-card>
    </div>
  </div>

  <div class="text-center mt-3" *ngIf="isLoading">
    <app-loading-indicator></app-loading-indicator>
  </div>
</div>