import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UserContextService } from '../../shared/services/user-context/user-context.service';

@Component({
  selector: 'app-password-settings',
  templateUrl: './password-settings.component.html',
  styleUrls: ['./password-settings.component.scss']
})
export class PasswordSettingsComponent implements OnInit {

  constructor(private _userContextService: UserContextService,
              private _changeDetector: ChangeDetectorRef) {
  }

  public saveInProgress: boolean;
  public showError: boolean;

  public oldPassword: string;
  public newPassword: string;

  ngOnInit() {
  }

  public async changePasswordAsync(changeForm: NgForm) {
    if (!changeForm.valid) {
      return;
    }

    try {
      this.showError = false;
      this.saveInProgress = true;

      await this._userContextService.updatePasswordAsync(this.oldPassword, this.newPassword);

      this.oldPassword = '';
      this.newPassword = '';

      changeForm.form.markAsUntouched();
      changeForm.form.markAsPristine();
    } catch {
      this.showError = true;
    } finally {
      this.saveInProgress = false;
    }

    this._changeDetector.markForCheck();
  }
}
