import { CoolDialogService } from '@angular-cool/dialogs';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PermissionRoleDTO } from '../../../../../../../server/src/dto/permission-role.dto';
import { FeaturePermissions } from '../../../../../../../server/src/models/feature-permissions.enum';
import { LanguageService } from '../../../../shared/services/language/language.service';
import { OrganizationService } from '../../../../shared/services/organization/organization.service';
import { EditPermissionRoleDialogConfig } from './edit-permission-role-dialog-config.interface';

@Component({
  selector: 'app-edit-permission-role-dialog',
  templateUrl: './edit-permission-role-dialog.component.html',
  styleUrls: ['./edit-permission-role-dialog.component.scss'],
})
export class EditPermissionRoleDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) private _dialogConfig: EditPermissionRoleDialogConfig,
              private _dialog: MatDialogRef<EditPermissionRoleDialogComponent>,
              private _dialogService: CoolDialogService,
              private _languageService: LanguageService,
              private _organizationService: OrganizationService) {

  }

  public saveInProgress: boolean;

  public permissionRole: PermissionRoleDTO;
  public permissionRoleAvailableFeaturePermissionsMap: {
    [perm: string]: boolean;
  };

  public availableFeaturePermissions: FeaturePermissions[];

  public async ngOnInit() {
    this.availableFeaturePermissions = await this._organizationService.getAvailableFeaturePermissionsAsync();
    this.permissionRole = JSON.parse(JSON.stringify(this._dialogConfig.permissionRole));

    this.permissionRoleAvailableFeaturePermissionsMap = {};

    for (const featurePerm of this.availableFeaturePermissions) {
      this.permissionRoleAvailableFeaturePermissionsMap[featurePerm] = this.permissionRole.featurePermissions.includes(featurePerm);
    }
  }

  public async saveChangesAsync() {
    if (this.permissionRole.isBuiltIn) {
      return;
    }

    if (!this.permissionRole.name) {
      return;
    }

    this.saveInProgress = true;

    try {
      this.permissionRole.featurePermissions = [];

      for(const featurePerm of this.availableFeaturePermissions) {
        const val = this.permissionRoleAvailableFeaturePermissionsMap[featurePerm];

        if (val) {
          this.permissionRole.featurePermissions.push(featurePerm);
        }
      }

      await this._organizationService.upsertPermissionRoleAsync(this.permissionRole);

      this.closeDialog();
    } finally {
      this.saveInProgress = false;
    }
  }

  public async removeButtonClickAsync() {
    const removeDialogResult = await this._dialogService.showDialog({
      titleText: await this._languageService.getTranslationAsync('Dashboard.PermissionRoles.RemovePermissionRoleTitle', { roleName: this.permissionRole.name }),
      questionText: await this._languageService.getTranslationAsync('Dashboard.PermissionRoles.RemovePermissionRoleDescription', { roleName: this.permissionRole.name }),
      confirmActionButtonText: await this._languageService.getTranslationAsync('Common.Remove'),
      cancelActionButtonText: await this._languageService.getTranslationAsync('Common.Cancel'),
      confirmActionButtonColor: 'warn',
    });

    if (!removeDialogResult.isConfirmed) {
      return;
    }

    await this._organizationService.removePermissionRoleAsync(this.permissionRole.id);

    this.closeDialog();
  }

  public closeDialog() {
    this._dialog.close();
  }
}
