import { Component, Inject, Input, OnInit } from '@angular/core';
import { FileDTO } from '../../../../../../../../../server/src/dto/file.dto';
import { FileType } from '../../../../../../../../../server/src/models/file-type.enum';
import { WINDOW } from '../../../../../services/injection-tokens';

@Component({
  selector: 'app-file-item-display',
  templateUrl: './file-item-display.component.html',
  styleUrls: ['./file-item-display.component.scss'],
})
export class FileItemDisplayComponent implements OnInit {

  constructor(@Inject(WINDOW) private _window: Window) {

  }

  public FileType = FileType;

  @Input()
  public file: FileDTO;

  @Input()
  public size: 'big' | 'normal' = 'normal';

  public extension: string;

  ngOnInit() {
    if (this.file) {
      const matches = this.file.url.match(/\.([^.]*)$/i);

      this.extension = matches ? matches[1] : '';
    }
  }

  public openOtherFileClick() {
    this._window.open(this.file.url, '_blank');
  }
}
