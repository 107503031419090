import { CoolHttp } from '@angular-cool/http';
import { Injectable } from '@angular/core';
import { FileDTO } from '../../../../../../../../server/src/dto/file.dto';

@Injectable()
export class EditorImageUploadService {
  constructor(private _http: CoolHttp) {

  }

  public async uploadImageAsync(file: File): Promise<FileDTO> {
    const formData = new FormData();

    formData.append('image', file);

    return await this._http.postAsync(`api/companies/active/images`, formData);
  }

  public async removeImageAsync(imageUrlToRemove: string) {
    await this._http.deleteAsync(`api/companies/active/images`, {
      params: {
        url: imageUrlToRemove,
      },
    });
  }
}