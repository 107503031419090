<div *ngIf="displayOnly">{{ selectedUser?.name }}</div>

<mat-form-field *ngIf="!displayOnly" class="w-100">
  <input matInput
         placeholder="{{ placeholder }}"
         #userInput
         [disabled]="disabled"
         (keyup)="onInputChangeAsync(userInput.value)"
         name="user"
         [(ngModel)]="innerInputValue"
         [matAutocomplete]="auto">

  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let user of filteredUsers" [value]="user">
      {{ user.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
