import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-learning-campaigns',
  templateUrl: './learning-campaigns.component.html',
  styleUrls: ['./learning-campaigns.component.scss']
})
export class LearningCampaignsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
