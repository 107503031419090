import { CoolDialogService } from '@angular-cool/dialogs';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LearningCertificatesGenerateRequestDTO } from '../../../../../../server/src/dto/learning-certificates.dto';
import { ReportDTO } from '../../../../../../server/src/dto/report.dto';
import { ReportStatus } from '../../../../../../server/src/models/report-type.enum';
import { WINDOW } from '../../../shared/services/injection-tokens';
import { LanguageService } from '../../../shared/services/language/language.service';
import { LearningCertificatesService } from '../../../shared/services/learning-certificates/learning-certificates.service';
import { LearningCertificateGenerateDialogComponent } from './learning-certificate-generate-dialog/learning-certificate-generate-dialog.component';

@Component({
  selector: 'app-learning-certificates',
  templateUrl: './learning-certificates.component.html',
  styleUrls: ['./learning-certificates.component.scss'],
})
export class LearningCertificatesComponent implements OnInit {
  constructor(private _learningCertificatesService: LearningCertificatesService,
              private _languageService: LanguageService,
              private _coolDialogService: CoolDialogService,
              @Inject(WINDOW) private _window: Window,
              private _dialogService: MatDialog) {
  }

  public displayedColumns = ['learningCampaign', 'template', 'startDate', 'endDate', 'users', 'status', 'actions'];
  public isLoading: boolean;

  public dataSource: MatTableDataSource<ReportDTO<LearningCertificatesGenerateRequestDTO>>;

  @ViewChild(MatSort, { static: true })
  public sort: MatSort;

  public ReportStatus = ReportStatus;

  public async ngOnInit() {
    await this.loadDataAsync();
  }

  public async addCertificateAsync() {
    const dialog = this._dialogService.open(LearningCertificateGenerateDialogComponent);

    await dialog.afterClosed().toPromise();

    await this.loadDataAsync();

    setTimeout(async () => {
      await this.loadDataAsync();
    }, 1000 * 30);

    setTimeout(async () => {
      await this.loadDataAsync();
    }, 1000 * 60);

    setTimeout(async () => {
      await this.loadDataAsync();
    }, 1000 * 60 * 1.5);
  }

  public downloadReport(report: ReportDTO<LearningCertificatesGenerateRequestDTO>) {
    this._window.open(report.reportFileUrl, '_blank');
  }

  public async deleteReportAsync(certificate: ReportDTO<LearningCertificatesGenerateRequestDTO>) {
    const removeDialogResult = await this._coolDialogService.showDialog({
      titleText: await this._languageService.getTranslationAsync('Dashboard.ReportsCommon.RemoveReportTitle'),
      questionText: await this._languageService.getTranslationAsync('Dashboard.ReportsCommon.RemoveReportDescription'),
      confirmActionButtonText: await this._languageService.getTranslationAsync('Common.Remove'),
      cancelActionButtonText: await this._languageService.getTranslationAsync('Common.Cancel'),
      confirmActionButtonColor: 'warn',
    });

    if (!removeDialogResult.isConfirmed) {
      return;
    }

    await this._learningCertificatesService.deleteCertificateByIdAsync(certificate.id);

    await this.loadDataAsync();
  }

  public async loadDataAsync() {
    try {
      this.isLoading = true;

      const reports = await this._learningCertificatesService.getCertificatesAsync();

      this.dataSource = new MatTableDataSource(reports);
      this.dataSource.sort = this.sort;
    } finally {
      this.isLoading = false;
    }
  }
}
