import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  LearningCampaignCompletionSessionDTO,
  LearningCampaignCompletionSessionStepType,
} from '../../../../../../../server/src/dto/learning-campaign-completion-session.dto';
import { LimitedLearningCampaignWithTestDTO } from '../../../../../../../server/src/dto/learning-campaign.dto';

@Component({
  selector: 'app-complete-learning-campaign-header',
  templateUrl: './complete-learning-campaign-header.component.html',
  styleUrls: ['./complete-learning-campaign-header.component.scss'],
})
export class CompleteLearningCampaignHeaderComponent implements OnInit {

  constructor() {
  }

  @Input()
  public learningCampaign: LimitedLearningCampaignWithTestDTO;

  @Input()
  public session: LearningCampaignCompletionSessionDTO;

  @Input()
  public isStartLoading: boolean;
  @Input()
  public showStartButton: boolean;

  @Input()
  public isNextLoading: boolean;
  @Input()
  public showNextButton: boolean;

  @Input()
  public isPreviousLoading: boolean;
  @Input()
  public showPreviousButton: boolean;

  @Input()
  public isSubmitLoading: boolean;
  @Input()
  public showSubmitButton: boolean;

  @Input()
  public showCampaignName: boolean;

  @Input()
  public weakBackground: boolean;

  @Input()
  public showStepProgress: boolean;

  @Input()
  public showTimeProgress: boolean;

  @Output()
  public nextClick = new EventEmitter<void>();

  @Output()
  public previousClick = new EventEmitter<void>();

  @Output()
  public startClick = new EventEmitter<void>();

  @Output()
  public submitClick = new EventEmitter<void>();

  @Output()
  public onSessionTimeout = new EventEmitter<void>();

  public LearningCampaignCompletionSessionStepType = LearningCampaignCompletionSessionStepType;

  ngOnInit(): void {
  }

  public previousStepClicked() {
    this.previousClick.emit();
  }

  public nextStepClicked() {
    this.nextClick.emit();
  }

  public startClicked() {
    this.startClick.emit();
  }

  public submitClicked() {
    this.submitClick.emit();
  }

  public onSessionTimeoutEvent() {
    this.onSessionTimeout.emit();
  }
}
