import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color } from 'ng2-charts';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { LearningReportDTO } from '../../../../../../../server/src/dto/learning-reports.dto';
import { LearningCampaignSelectComponent } from '../../../../shared/components/learning-campaign-select/learning-campaign-select.component';
import { LanguageService } from '../../../../shared/services/language/language.service';
import { ScoreColorDirective } from '../../../shared/directives/score-color.directive';
import { LearningReportService } from '../shared/services/learning-report.service';

@Component({
  selector: 'app-learning-report-view',
  templateUrl: './learning-report-view.component.html',
  styleUrls: ['./learning-report-view.component.scss'],
})
export class LearningReportViewComponent implements OnInit {
  private _reportId: string;

  constructor(private _learningReportService: LearningReportService,
              private _languageService: LanguageService,
              private _activatedRoute: ActivatedRoute) {
  }

  public proceduresDisplayedColumns = ['name', 'successRate'];

  public report: LearningReportDTO;
  public isLoading: boolean;
  public hasError: boolean;

  public completionRateChartLabels: string[];
  public completionRateChartData: number[][];
  public completionRateChartColors: Color[];

  public procedureSuccessRateChartLabels: string[];
  public procedureSuccessRateChartData: ChartDataSets[];
  public procedureSuccessRateChartOptions: ChartOptions = {
    scales: {
      yAxes: [{ ticks: { beginAtZero: true, max: 100 } }],
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value) => {
          return `${ value }%`;
        },
      },
    },
  };

  public companyUserResultsDataSource: MatTableDataSource<any>;
  public companyUsersWithoutResultDataSource: MatTableDataSource<any>;

  @ViewChild(LearningCampaignSelectComponent, { static: true })
  public learningCampaignSelect: LearningCampaignSelectComponent;

  public async ngOnInit() {
    this._activatedRoute.params
      .pipe(untilDestroyed(this))
      .subscribe(async params => {
        this._reportId = params.reportId;

        if (this._reportId) {
          await this._loadReportAsync();
        }
      });


    this.completionRateChartLabels = [
      await this._languageService.getTranslationAsync('Dashboard.LearningReports.NumberOfCompleted'),
      await this._languageService.getTranslationAsync('Dashboard.LearningReports.NumberOfNotCompleted'),
    ];

    this.completionRateChartColors = [
      { backgroundColor: ['#28a745', '#ed5565'] },
    ];
  }

  public ngOnDestroy(): void {
  }

  public getDepartmentNamesFromFilter() {
    if (!this.report) {
      return '';
    }

    return this.report.filters.departments.map(dep => dep.name).join(', ');
  }

  private async _loadReportAsync() {
    try {
      this.isLoading = true;
      this.hasError = false;

      this.report = await this._learningReportService.getReportByIdAsync(this._reportId);

      this.report.procedureSuccessRates.sort((a, b) => a.successRate - b.successRate);

      this.completionRateChartData = [
        [
          this.report.completionRates.completed,
          this.report.completionRates.allEligible - this.report.completionRates.completed,
        ],
      ];

      this.procedureSuccessRateChartLabels = this.report.procedureSuccessRates.map(rate => rate.name);
      this.procedureSuccessRateChartData = [
        {
          data: this.report.procedureSuccessRates.map(rate => rate.successRate),
          backgroundColor: this.report.procedureSuccessRates.map(rate => ScoreColorDirective.getScoreColor(rate.successRate)),
        },
      ];

      this.companyUserResultsDataSource = new MatTableDataSource<any>(this.report.companyUserResults);
      this.companyUsersWithoutResultDataSource = new MatTableDataSource<any>(this.report.companyUsersWithoutResult);
    } catch {
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  }

  public filterCompanyUserResults(value: string) {
    if (!this.companyUserResultsDataSource) {
      return;
    }

    this.companyUserResultsDataSource.filter = value.trim().toLowerCase();
  }

  public filterCompanyUsersWithoutResult(value: string) {
    if (!this.companyUsersWithoutResultDataSource) {
      return;
    }

    this.companyUsersWithoutResultDataSource.filter = value.trim().toLowerCase();
  }
}
