<div>
  <div class="mb-4">
    <app-notification-message id="certificateTemplateInformation"
                              title="{{ 'Dashboard.LearningCertificates.CertificateHowToTitle' | translate }}">
      <div>
        <p class="mt-1 mb-0">
          {{ 'Dashboard.LearningCertificates.CertificateHowToDescription' | translate }}
        </p>
        <ul class="my-1">
          <li>
            <span class="inline-code">{{ '{' }}name{{ '}' }}</span>:
            <span>{{ 'Dashboard.LearningCertificates.CertificateHowToUserName' | translate }}</span>
          </li>
          <li>
            <span class="inline-code">{{ '{' }}date{{ '}' }}</span>:
            <span>{{ 'Dashboard.LearningCertificates.CertificateHowToDate' | translate }}</span>
          </li>
          <li>
            <span class="inline-code">{{ '{' }}completion_date{{ '}' }}</span>:
            <span>{{ 'Dashboard.LearningCertificates.CertificateHowToCompletionDate' | translate }}</span>
          </li>
          <li>
            <span class="inline-code">{{ '{' }}result{{ '}' }}</span>:
            <span>{{ 'Dashboard.LearningCertificates.CertificateHowToResult' | translate }}</span>
          </li>
          <li>
            <span class="inline-code">{{ '{' }}campaign{{ '}' }}</span>:
            <span>{{ 'Dashboard.LearningCertificates.CertificateHowToCampaign' | translate }}</span>
          </li>
        </ul>
      </div>
    </app-notification-message>
  </div>

  <app-page-header pageTitle="{{ 'Dashboard.Navigation.CertificateTemplates' | translate }}">
    <div>
      <div class="d-inline-block">
        <mat-form-field>
          <input matInput (keyup)="filterTemplates($event.target.value)"
                 placeholder="{{ 'Common.Filter' | translate }}">
        </mat-form-field>
      </div>

      <button class="ml-5"
              mat-raised-button color="primary"
              (click)="addNewPermissionRoleAsync()">
        <fa-icon icon="plus" class="mr-1"></fa-icon>

        {{ 'Dashboard.LearningCertificateTemplates.AddNewTemplate' | translate }}
      </button>
    </div>
  </app-page-header>

  <div class="w-100">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z4 w-100">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Common.Name' | translate }}</th>
        <td mat-cell *matCellDef="let template">
          {{ template.name || 'N/A' }}
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ 'Common.Actions' | translate }}</th>
        <td mat-cell *matCellDef="let template">
          <button mat-raised-button color="primary"
                  (click)="editTemplateAsync(template)">
            <fa-icon icon="pencil-alt"></fa-icon>
          </button>

          <button mat-raised-button color="warn" class="ml-2"
                  (click)="removeTemplateAsync(template)">
            <fa-icon icon="trash"></fa-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div class="text-center mt-3" *ngIf="isLoading">
      <app-loading-indicator></app-loading-indicator>
    </div>
  </div>
</div>