import { CoolHttp } from '@angular-cool/http';
import { Injectable } from '@angular/core';
import { LearningCertificateTemplateDTO } from '../../../../../../server/src/dto/learning-certificates.dto';
import { FileUploadItem } from '../../components/file-upload-selector/file-upload-item.interface';

@Injectable()
export class LearningCertificateTemplatesService {
  constructor(private _http: CoolHttp) {

  }

  public async getLearningCertificateTemplatesAsync(): Promise<LearningCertificateTemplateDTO[]> {
    return await this._http.getAsync(`api/companies/active/learning/certificates/templates`);
  }

  public async getLearningCertificateTemplateByIdAsync(templateId: string): Promise<LearningCertificateTemplateDTO> {
    return await this._http.getAsync(`api/companies/active/learning/certificates/templates/${ templateId }`);
  }

  public async removeLearningCertificateTemplateByIdAsync(templateId: string): Promise<void> {
    return await this._http.deleteAsync(`api/companies/active/learning/certificates/templates/${ templateId }`);
  }

  public async upsertLearningCertificateTemplateByIdAsync(template: LearningCertificateTemplateDTO): Promise<void> {
    const formData = new FormData();

    if (template.templateFile && (<FileUploadItem>template.templateFile).file) {
      formData.append(`templateFile`, (<FileUploadItem>template.templateFile).file);
    }

    const templateData = <LearningCertificateTemplateDTO>JSON.parse(JSON.stringify(template));

    if (templateData.templateFile && templateData.templateFile.id.includes('-')) {
      (<FileUploadItem>templateData.templateFile).file = null;
      templateData.templateFile.url = '';
    }

    formData.append('template', JSON.stringify(templateData));

    return await this._http.postAsync(`api/companies/active/learning/certificates/templates`, formData);
  }
}