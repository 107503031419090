import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FileDTO } from '../../../../../../server/src/dto/file.dto';
import {
  LearningCampaignCompletionSessionDTO,
  LearningCampaignCompletionSessionProcedureTestQuestionAnswersDTO,
  LearningCampaignCompletionSessionStepType,
} from '../../../../../../server/src/dto/learning-campaign-completion-session.dto';
import { LimitedLearningCampaignWithTestDTO } from '../../../../../../server/src/dto/learning-campaign.dto';
import {
  LimitedProcedureTestQuestionAnswerDTO,
  LimitedProcedureTestQuestionDTO,
} from '../../../../../../server/src/dto/procedure-test.dto';
import { LimitedProcedureWithContentAndTestDTO } from '../../../../../../server/src/dto/procedure.dto';
import { ProcedureTestQuestionType } from '../../../../../../server/src/models/procedure-test-question-type.enum';
import { ListUtils } from '../../../../../../server/src/utils/list-utils';
import { RouteNavigationService } from '../../../shared/routing/route-navigation.service';
import { CompleteCampaignService } from './shared/services/complete-campaign.service';

@Component({
  selector: 'app-complete-learning-campaign',
  templateUrl: './complete-learning-campaign.component.html',
  styleUrls: ['./complete-learning-campaign.component.scss'],
})
export class CompleteLearningCampaignComponent implements OnInit, OnDestroy {
  private _campaignId: string;

  private _sessionTimeoutHappened = new Subject<void>();

  constructor(private _activatedRoute: ActivatedRoute,
              private _completeCampaignService: CompleteCampaignService,
              private _routeNavigationService: RouteNavigationService) {

  }

  public learningCampaign: LimitedLearningCampaignWithTestDTO;

  public initialLoaded: boolean;
  public session: LearningCampaignCompletionSessionDTO;

  public currentProcedure: LimitedProcedureWithContentAndTestDTO;
  public currentVideo: FileDTO;
  public currentQuestion: LimitedProcedureTestQuestionDTO;
  public currentlySelectedAnswerId: string;
  public currentlySelectedAnswerIds: string[] = [];

  public failedProcedureQuestions: {
    id: string;
    name: string;
    failingQuestions: {
      id: string;
      type: ProcedureTestQuestionType;
      questionText: string;
      answers: {
        id: string;
        answerText: string;
        rank: string;
        selectedRank: string;
        correctRank: string;
        isCorrect: boolean;
        isSelected: boolean;
      }[];
    }[];
  }[];

  public showAtLeastOneAnswerNeeded: boolean;

  public isStartLoading: boolean;
  public showStartButton: boolean = true;

  public isNextLoading: boolean;
  public showNextButton: boolean;

  public isPreviousLoading: boolean;
  public showPreviousButton: boolean;

  public isSubmitLoading: boolean;
  public showSubmitButton: boolean;

  public LearningCampaignCompletionSessionStepType = LearningCampaignCompletionSessionStepType;
  public ProcedureTestQuestionType = ProcedureTestQuestionType;

  public ngOnInit() {
    this._activatedRoute.params
      .pipe(untilDestroyed(this))
      .subscribe(async (params: Params) => {
        this._campaignId = params.campaignId;

        if (!this._campaignId) {
          return;
        }

        await this._reloadCampaignAsync();

        this.initialLoaded = true;
      });

    this._sessionTimeoutHappened
      .pipe(
        untilDestroyed(this),
        debounceTime(100),
      )
      .subscribe(async () => {
        if (this.session.isCompleted) {
          return;
        }

        await this._reloadCampaignAsync();
      });
  }

  public ngOnDestroy(): void {
  }

  public async startCompletingCampaignAsync() {
    try {
      this.isStartLoading = true;

      this._setSession(await this._completeCampaignService.createMyActiveLearningCampaignSessionAsync(this._campaignId));
    } finally {
      this.isStartLoading = false;
    }
  }

  public async submitCampaignAsync() {
    try {
      this.isSubmitLoading = true;

      this._setSession(await this._completeCampaignService.nextMyActiveLearningCampaignSessionStepAsync(this._campaignId, null));
    } finally {
      this.isSubmitLoading = false;
    }
  }

  public async nextStepAsync() {
    try {
      this.isNextLoading = true;

      let answers: LearningCampaignCompletionSessionProcedureTestQuestionAnswersDTO = null;

      if (this.session.currentStep.type === LearningCampaignCompletionSessionStepType.ProcedureTest) {
        if (this.currentQuestion.type === ProcedureTestQuestionType.SingleChoice) {
          if (!this.currentlySelectedAnswerId) {
            this.showAtLeastOneAnswerNeeded = true;
            this.isNextLoading = false;

            return;
          } else {
            answers = {
              id: this.currentQuestion.id,
              selectedAnswers: [
                {
                  id: this.currentlySelectedAnswerId,
                  rank: undefined,
                },
              ],
            };
          }
        } else if (this.currentQuestion.type === ProcedureTestQuestionType.MultipleChoice) {
          if (!this.currentlySelectedAnswerIds.length) {
            this.showAtLeastOneAnswerNeeded = true;
            this.isNextLoading = false;

            return;
          } else {
            answers = {
              id: this.currentQuestion.id,
              selectedAnswers: this.currentlySelectedAnswerIds.map(id => {
                return {
                  id: id,
                  rank: undefined,
                };
              }),
            };
          }
        } else if (this.currentQuestion.type === ProcedureTestQuestionType.Ordering) {
          ListUtils.setRankForListItems(this.currentQuestion.answers);

          answers = {
            id: this.currentQuestion.id,
            selectedAnswers: this.currentQuestion.answers.map(answer => {
              return {
                id: answer.id,
                rank: answer.rank,
              };
            }),
          };
        } else {
          throw new Error('Unknown procedure test question type');
        }
      }

      this.showAtLeastOneAnswerNeeded = false;

      this._setSession(await this._completeCampaignService.nextMyActiveLearningCampaignSessionStepAsync(this._campaignId, answers));
    } finally {
      this.isNextLoading = false;
    }
  }

  public async previousStepAsync() {
    try {
      this.isPreviousLoading = true;

      this._setSession(await this._completeCampaignService.previousMyActiveLearningCampaignSessionStepAsync(this._campaignId));
    } finally {
      this.isPreviousLoading = false;
    }
  }

  public async restartCampaignAsync() {
    await this._completeCampaignService.removeMyActiveLearningCampaignSessionAsync(this._campaignId);

    this.showStartButton = true;

    await this._reloadCampaignAsync();
  }

  private async _reloadCampaignAsync() {
    this.session = null;

    this.learningCampaign = await this._completeCampaignService.getMyActiveLearningCampaignByIdAsync(this._campaignId);

    try {
      this._setSession(await this._completeCampaignService.getMyActiveLearningCampaignSessionAsync(this._campaignId));
    } catch {

    }
  }

  private _setSession(session: LearningCampaignCompletionSessionDTO) {
    this.session = session;

    this.currentProcedure = session.currentStep.procedureId ? this.learningCampaign.procedures.find(procedure => procedure.id === session.currentStep.procedureId) : null;
    this.currentVideo = session.currentStep.procedureVideoId ? this.currentProcedure.files.find(video => video.id === session.currentStep.procedureVideoId) : null;
    this.currentQuestion = session.currentStep.procedureTestQuestionId ? this.currentProcedure.test.questions.find(question => question.id === session.currentStep.procedureTestQuestionId) : null;

    const currentSessionProcedure = session.procedures.find(procedure => procedure.id === session.currentStep.procedureId);
    const currentSessionQuestion = currentSessionProcedure ? currentSessionProcedure.questions.find(question => question.id === session.currentStep.procedureTestQuestionId) : null;

    this.currentlySelectedAnswerId = currentSessionProcedure && currentSessionQuestion && currentSessionQuestion.selectedAnswers.length ? currentSessionQuestion.selectedAnswers[0].id : null;
    this.currentlySelectedAnswerIds = currentSessionProcedure && currentSessionQuestion ? currentSessionQuestion.selectedAnswers.map(answer => answer.id) : [];

    this.showStartButton = session.currentStep.type === LearningCampaignCompletionSessionStepType.Start;
    this.showSubmitButton = session.currentStep.type === LearningCampaignCompletionSessionStepType.BeforeResults;

    this.showNextButton = (<LearningCampaignCompletionSessionStepType[]> [
      LearningCampaignCompletionSessionStepType.ProcedureContent,
      LearningCampaignCompletionSessionStepType.ProcedureVideos,
      LearningCampaignCompletionSessionStepType.ProcedureTest,
    ]).includes(session.currentStep.type);

    this.showPreviousButton = (<LearningCampaignCompletionSessionStepType[]> [
      LearningCampaignCompletionSessionStepType.ProcedureContent,
      LearningCampaignCompletionSessionStepType.ProcedureVideos,
      LearningCampaignCompletionSessionStepType.ProcedureTest,
      LearningCampaignCompletionSessionStepType.BeforeResults,
    ]).includes(session.currentStep.type);

    // Ordering question answers
    for (const procedure of session.procedures) {
      const correspondingCampaignProcedure = this.learningCampaign.procedures.find(p => p.id === procedure.id);

      if (!procedure.questions) {
        continue;
      }

      for (const question of procedure.questions) {
        const correspondingCampaignProcedureQuestion = correspondingCampaignProcedure.test.questions.find(q => q.id === question.id);

        if (correspondingCampaignProcedureQuestion?.type !== ProcedureTestQuestionType.Ordering || !question.selectedAnswers) {
          continue;
        }

        for (const answer of question.selectedAnswers) {
          const correspondingCampaignProcedureQuestionAnswer = correspondingCampaignProcedureQuestion.answers.find(a => a.id === answer.id);

          correspondingCampaignProcedureQuestionAnswer.rank = answer.rank;
        }

        correspondingCampaignProcedureQuestion.answers.sort(ListUtils.compareByRank);
      }
    }

    if (session.currentStep.type === LearningCampaignCompletionSessionStepType.Results) {
      this.failedProcedureQuestions = [];

      for (const procedure of session.procedures) {
        const procedureInstance = this.learningCampaign.procedures.find(p => p.id === procedure.id);
        const failingQuestions = procedureInstance.test.questions.filter(question => session.results.incorrectlyAnsweredQuestions.some(q => q.id === question.id));

        if (!failingQuestions.length) {
          continue;
        }

        this.failedProcedureQuestions.push({
          id: procedure.id,
          name: procedureInstance.name,
          failingQuestions: failingQuestions.map(question => {
            const sessionResultQuestion = session.results.incorrectlyAnsweredQuestions.find(q => q.id === question.id);

            return {
              id: question.id,
              type: question.type,
              questionText: question.questionText,
              rank: question.rank,
              answers: question.answers.map(answer => {
                const selectedRank = sessionResultQuestion.selectedAnswers.find(a => a.id === answer.id)?.rank;
                const correctRank = sessionResultQuestion.correctAnswers.find(a => a.id === answer.id)?.rank;

                let isCorrect: boolean;

                if (question.type === ProcedureTestQuestionType.Ordering) {
                  isCorrect = selectedRank === correctRank;
                } else {
                  isCorrect = sessionResultQuestion.correctAnswers.some(a => a.id === answer.id);
                }

                return {
                  id: answer.id,
                  answerText: answer.answerText,
                  rank: answer.rank,
                  selectedRank: selectedRank,
                  correctRank: correctRank,
                  isCorrect: isCorrect,
                  isSelected: sessionResultQuestion.selectedAnswers.some(a => a.id === answer.id),
                };
              }).sort(ListUtils.compareByRank),
            };
          }).sort(ListUtils.compareByRank),
        });
      }
    } else {
      this.failedProcedureQuestions = null;
    }
  }

  public onMultipleChoiceAnswerClick(answer: LimitedProcedureTestQuestionAnswerDTO) {
    const indexOfAnswer = this.currentlySelectedAnswerIds.indexOf(answer.id);

    if (indexOfAnswer === -1) {
      this.currentlySelectedAnswerIds.push(answer.id);
    } else {
      this.currentlySelectedAnswerIds.splice(indexOfAnswer, 1);
    }

    this.showAtLeastOneAnswerNeeded = !this.currentlySelectedAnswerIds.length;
  }

  public onSingleChoiceAnswerClick() {
    this.showAtLeastOneAnswerNeeded = !this.currentlySelectedAnswerId;
  }

  public async learnMoreAboutProcedureAsync(procedureId: string) {
    //TODO: new tab
    await this._routeNavigationService.goToViewProcedureAsync(procedureId);
  }

  public getSelectedAnswerId(answers: { id: string; isSelected: boolean; }[]): string {
    return answers.find(a => a.isSelected).id;
  }

  public onSessionTimeout() {
    this._sessionTimeoutHappened.next();
  }
}
