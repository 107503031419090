import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { fabric } from 'fabric';
import { Image } from 'fabric/fabric-impl';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AssetDTO } from '../../../../../../../../server/src/dto/asset-control.dto';
import { LoggerService } from '../../../../../shared/services/logger/logger.service';

@Component({
  selector: 'app-room-canvas',
  templateUrl: './room-canvas.component.html',
  styleUrls: ['./room-canvas.component.scss'],
})
export class RoomCanvasComponent implements OnInit, OnChanges, OnDestroy {
  private _assets: AssetDTO[];
  private _handledAssetCanvasElements: { [id: string]: fabric.Object } = {};

  private _canvasZoomChanged = new Subject();

  private _clearAndAssetsRequest = new Subject();

  constructor(private _changeDetector: ChangeDetectorRef,
              private _logger: LoggerService) {
  }

  @Input()
  public backgroundJson: any;

  @Input()
  public backgroundJsonSettings: any;

  @Input()
  public assets: Observable<AssetDTO[]>;

  @Input()
  public assetSelectionDisabled: boolean;

  @Input()
  public selectedAssetId: string;

  @Output()
  public selectedAssetIdChanged = new EventEmitter<string>();

  public roomCanvas: fabric.Canvas;

  @ViewChild('roomCanvasElement')
  public roomCanvasElement: ElementRef<HTMLCanvasElement>;

  public async ngOnInit() {
    if (!this.backgroundJson || !this.backgroundJsonSettings) {
      return;
    }

    // load canvas element
    this._changeDetector.detectChanges();

    await this._buildRoomCanvasAsync();

    this.assets
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((assets: AssetDTO[]) => {
        this._assets = assets;

        this._registerClearAndAddAssetsFromCanvas();
      });

    this._canvasZoomChanged
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(() => this._registerClearAndAddAssetsFromCanvas());

    this._clearAndAssetsRequest
      .pipe(
        debounceTime(100),
        untilDestroyed(this),
      )
      .subscribe(async () => {
        await this._clearAndAddAssetsFromCanvasAsync();
      });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedAssetId) {
      this._registerClearAndAddAssetsFromCanvas();
    }
  }

  public ngOnDestroy(): void {
  }

  public async onCanvasZoomChangeAsync() {
    this._canvasZoomChanged.next();
  }

  public setAllAssetProperties(options: Partial<{ visible: boolean; selectable: boolean }>) {
    for (const assetId of Object.keys(this._handledAssetCanvasElements)) {
      this.setAssetProperties(assetId, options);
    }
  }

  public setAssetProperties(assetId: string, options: Partial<{ visible: boolean; selectable: boolean }>) {
    const assetElement = this._handledAssetCanvasElements[assetId];

    if (!assetElement) {
      return;
    }

    assetElement.set(options);
  }

  public requestRenderAll() {
    this.roomCanvas.requestRenderAll();
  }

  public getAssetPosition(assetId: string) {
    const assetElement = this._handledAssetCanvasElements[assetId];

    if (!assetElement) {
      return null;
    }

    return assetElement.getPointByOrigin('left', 'top');
  }

  private _registerClearAndAddAssetsFromCanvas() {
    this._clearAndAssetsRequest.next();
  }

  private async _clearAndAddAssetsFromCanvasAsync() {
    if (!this._assets) {
      return;
    }

    for (const assetElement of Object.values(this._handledAssetCanvasElements)) {
      this.roomCanvas.remove(assetElement);
    }

    this._handledAssetCanvasElements = {};

    await Promise.all(this._assets.map(async (asset) => {
      const assetElement = await this._loadAssetFabricImage(this.selectedAssetId === asset.id);

      assetElement.set({
        top: asset.positionY,
        left: asset.positionX,
        selectable: false,
        hasControls: false,
      });

      assetElement.on('mousedown', () => {
        if (this.assetSelectionDisabled) {
          return;
        }

        this.selectedAssetId = asset.id;

        this.selectedAssetIdChanged.next(this.selectedAssetId);

        this._registerClearAndAddAssetsFromCanvas();
      });

      if (this._handledAssetCanvasElements[asset.id]) {
        this.roomCanvas.remove(this._handledAssetCanvasElements[asset.id]);
      }

      this._handledAssetCanvasElements[asset.id] = assetElement;

      this.roomCanvas.add(assetElement);
    }));

    this.roomCanvas.requestRenderAll();
  }

  private _loadAssetFabricImage(isRedCrosshair: boolean): Promise<fabric.Image> {
    return new Promise(resolve => {
      fabric.Image.fromURL(`/assets/images/editor/markers/crosshair${ isRedCrosshair ? '-red' : '' }.svg`, (img: Image) => {
        img.scale((1 / this.roomCanvas.getZoom()) * 0.07);

        resolve(img);
      }, {
        width: 512,
        height: 512,
      });
    });
  }

  private _buildRoomCanvasAsync() {
    return new Promise(async resolve => {
      this.roomCanvas = new fabric.Canvas(this.roomCanvasElement.nativeElement, {
        renderOnAddRemove: false,
      });

      if (this.backgroundJson) {
        this.roomCanvas.loadFromJSON(this.backgroundJson, () => {
          if (this.backgroundJsonSettings) {
            this.roomCanvas.setZoom(this.backgroundJsonSettings.zoom);

            this.roomCanvas.viewportTransform[4] = this.backgroundJsonSettings.translateX;
            this.roomCanvas.viewportTransform[5] = this.backgroundJsonSettings.translateY;
          }

          this.roomCanvas.requestRenderAll();

          resolve();
        }, (o, obj) => {
          if (!obj) {
            this._logger.error(new Error('Failed to load something'));

            return;
          }

          obj.set({
            selectable: false,
          });
        });
      }
    });
  }
}
