import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CompanyRoleDTO } from '../../../../server/src/dto/company-role.dto';
import { CompanyDTO } from '../../../../server/src/dto/company.dto';
import { DepartmentDTO } from '../../../../server/src/dto/department.dto';
import { UserSettingsDTO } from '../../../../server/src/dto/user-settings.dto';
import { RouteNavigationService } from '../shared/routing/route-navigation.service';
import { CompanyService } from '../shared/services/company/company.service';
import { SettingsService } from '../shared/services/settings/settings.service';
import { TrackingService } from '../shared/services/tracking/tracking.service';
import { UserContextService } from '../shared/services/user-context/user-context.service';

@Component({
  selector: 'app-company-join',
  templateUrl: './company-join.component.html',
  styleUrls: ['./company-join.component.scss'],
})
export class CompanyJoinComponent implements OnInit {
  private _joinToken: string;

  constructor(private _routeNavigationService: RouteNavigationService,
              private _companyService: CompanyService,
              private _trackingService: TrackingService,
              private _userContextService: UserContextService,
              private _settingsService: SettingsService,
              private _activatedRoute: ActivatedRoute) {
  }

  public company: CompanyDTO;

  public departments: DepartmentDTO[];
  public companyRoles: CompanyRoleDTO[];

  public inProgress: boolean;
  public error: boolean;
  public missingTokenError: boolean;
  public invalidTokenError: boolean;

  public userAlreadyInCompany: boolean;

  public userName: string;
  public user: UserSettingsDTO;

  public async ngOnInit() {
    this._joinToken = <string | null>this._activatedRoute.snapshot.queryParams.joinToken;

    if (!this._joinToken) {
      this.missingTokenError = true;

      return;
    }

    this.inProgress = true;

    this.user = this._userContextService.user;
    this.userName = this.user.name;

    try {
      const companyData = await this._companyService.getCompanyDataFromJoinTokenAsync(this._joinToken);

      this.company = companyData.company;
      this.departments = companyData.departments;
      this.companyRoles = companyData.companyRoles;

      if (this._userContextService.user.activeCompanyUserId === this.company.id) {
        this.userAlreadyInCompany = true;
      }
    } catch (e) {
      this.invalidTokenError = true;

      this._trackingService.trackEvent('Invalid company join token', 'Join Company');
    } finally {
      this.inProgress = false;
    }
  }

  public async joinCompanyAsync(joinForm: NgForm, selectedDepartments: DepartmentDTO[], selectedCompanyRole: CompanyRoleDTO) {
    if (!joinForm.valid ||
      !this.company ||
      this.invalidTokenError ||
      this.userAlreadyInCompany ||
      !this.userName ||
      !selectedCompanyRole ||
      !selectedDepartments ||
      !selectedDepartments.length) {
      return;
    }

    this.error = false;

    try {
      if (this.user.name !== this.userName) {
        this.user.name = this.userName;

        await this._settingsService.setUserSettingsAsync(this.user);
      }

      await this._companyService.joinCompanyAsync(this._joinToken, selectedDepartments, selectedCompanyRole);
    } catch (e) {
      this._trackingService.trackEvent('Failed to join company', 'Join Company', {
        name: this.company.name,
      });

      this.error = true;
    }

    if (this.error) {
      return;
    }

    this._trackingService.trackEvent('Join company', 'Company');

    await this._userContextService.refreshUserSettingsAsync();

    await this._routeNavigationService.goToDashboardAsync();
  }
}
