<div>
  <app-page-header pageTitle="{{ 'Dashboard.PermissionRoles.Title' | translate }}">
    <div>
      <div class="d-inline-block">
        <mat-form-field>
          <input matInput (keyup)="filterPermissionRoles($event.target.value)"
                 placeholder="{{ 'Common.Filter' | translate }}">
        </mat-form-field>
      </div>

      <button class="ml-5"
              mat-raised-button color="primary"
              (click)="addNewPermissionRoleAsync()">
        <fa-icon icon="plus" class="mr-1"></fa-icon>

        {{ 'Dashboard.PermissionRoles.AddNewPermissionRole' | translate }}
      </button>
    </div>
  </app-page-header>

  <div class="w-100">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z4 w-100">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Common.Name' | translate }}</th>
        <td mat-cell *matCellDef="let permissionRole">
          {{ permissionRole.name || 'N/A' }}
        </td>
      </ng-container>

      <!-- Feature permissions Column -->
      <ng-container matColumnDef="featurePermissions">
        <th mat-header-cell *matHeaderCellDef>{{ 'Common.Permission' | translate }}</th>
        <td mat-cell *matCellDef="let permissionRole">
          <div *ngIf="permissionRole.featurePermissions.length < 3" class="text-truncate">
            <span *ngFor="let featurePermission of permissionRole.featurePermissions; let i = index">
              {{ i !== 0 ? ', ' : '' }}
              {{ 'Dashboard.PermissionRoles.FeaturePermissions.' + featurePermission | translate }}
            </span>
          </div>

          <div *ngIf="permissionRole.featurePermissions.length > 2" class="font-italic">
            {{ 'Dashboard.PermissionRoles.MultiplePermissions' | translate: { permissionCount: permissionRole.featurePermissions.length } }}
          </div>
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ 'Common.Actions' | translate }}</th>
        <td mat-cell *matCellDef="let permissionRole">
          <button mat-raised-button color="primary"
                  [attr.title]=" (permissionRole.isBuiltIn ? 'Common.View' : 'Common.Edit') | translate"
                  (click)="editPermissionRoleAsync(permissionRole)">
            <fa-icon [icon]="permissionRole.isBuiltIn ? 'eye' : 'pencil-alt'"></fa-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div class="text-center mt-3" *ngIf="isLoading">
      <app-loading-indicator></app-loading-indicator>
    </div>
  </div>
</div>