import { CoolHttp } from '@angular-cool/http';
import { Injectable } from '@angular/core';
import { CompanyProfileDTO } from '../../../../../../server/src/dto/company-profile.dto';

@Injectable()
export class CompanyProfileService {
  constructor(private _http: CoolHttp) {

  }

  public async getCompanyProfilesOfUserAsync(): Promise<CompanyProfileDTO[]> {
    return await this._http.getAsync<CompanyProfileDTO[]>('api/company-profiles');
  }

  public async setActiveCompanyProfileAsync(companyUserId: string) {
    await this._http.postAsync('api/company-profiles/active', {
      companyUserId: companyUserId,
    });
  }
}