import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TrackingService } from '../../shared/services/tracking/tracking.service';
import { NotificationSettings } from './shared/services/notification-settings.interface';
import { NotificationSettingsService } from './shared/services/notification-settings.service';

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss'],
})
export class NotificationSettingsComponent implements OnInit {

  constructor(private _notificationSettingsService: NotificationSettingsService,
              private _changeDetector: ChangeDetectorRef,
              private _trackingService: TrackingService) {
  }

  public saveInProgress: boolean;

  public notificationSettings: NotificationSettings;

  public get pageLoaded(): boolean {
    return !!this.notificationSettings;
  }

  public async ngOnInit() {
    this.notificationSettings = await this._notificationSettingsService.getNotificationSettingsAsync();

    this._changeDetector.markForCheck();
  }

  public async saveNotificationsSettingsAsync(notificationSettingsForm: NgForm) {
    if (notificationSettingsForm.invalid) {
      return;
    }

    this.saveInProgress = true;

    try {
      await this._notificationSettingsService.setNotificationSettingsAsync(this.notificationSettings);

      this._trackingService.trackEvent('Save notification settings', 'Account settings');

      notificationSettingsForm.form.markAsPristine();
      notificationSettingsForm.form.markAsUntouched();

      this._changeDetector.markForCheck();
    } finally {
      this.saveInProgress = false;
    }
  }
}
