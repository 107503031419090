<div class="h-75">
  <div class="p-5 image-faded-background">
    <mat-card class="mat-elevation-z8 pt-4">
      <mat-card-header>
        <mat-card-title class="text-center">
          {{ 'Login.RestorePasswordTitle' | translate }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="p-4 pb-2">
        <div>
          <mat-form-field>
            <input matInput #passwordInput
                   name="password"
                   type="password"
                   [(ngModel)]="password"
                   placeholder="{{ 'Login.ForgotPasswordNewPassword' | translate }}" required>
          </mat-form-field>
        </div>

        <div class="text-center mt-3">
          <app-button (clicked)="restorePasswordAsync()"
                      color="primary"
                      [loading]="inProgress"
                      [disabled]="!passwordInput.value">
            {{ 'Login.RestorePassword' | translate }}
          </app-button>
        </div>

        <div *ngIf="showError" class="mt-3 color-warn">
          {{ 'Login.FailedToRestorePassword' | translate }}
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>