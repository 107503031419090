<form #passwordChangeFrom="ngForm">
  <div>
    <div>
      <mat-form-field class="field">
        <input matInput name="old-password"
               [(ngModel)]="oldPassword"
               placeholder="{{ 'AccountSettings.PasswordSettings.CurrentPassword' | translate }}"
               type="password"
               required>
      </mat-form-field>
    </div>

    <div class="mt-3">
      <mat-form-field class="field">
        <input matInput name="new-password"
               [(ngModel)]="newPassword"
               placeholder="{{ 'AccountSettings.PasswordSettings.NewPassword' | translate }}"
               type="password"
               required>
      </mat-form-field>
    </div>
  </div>
  <div class="mt-3 mb-2 text-right">
    <div *ngIf="showError" class="d-inline-block color-warn mr-2">
      {{ 'AccountSettings.PasswordSettings.FailedToChange' | translate }}
    </div>

    <app-button color="primary"
                [disabled]="passwordChangeFrom.invalid || passwordChangeFrom.pristine"
                [loading]="saveInProgress"
                (clicked)="changePasswordAsync(passwordChangeFrom)">
      {{ 'AccountSettings.PasswordSettings.ChangePassword' | translate }}
    </app-button>
  </div>
</form>