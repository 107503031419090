import { Component, OnInit } from '@angular/core';
import { RouteNavigationService } from '../shared/routing/route-navigation.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  constructor(private _routeNavigationService: RouteNavigationService) {

  }

  ngOnInit() {
  }

  public async onRequestDemoClickAsync() {
    await this._routeNavigationService.goToRequestDemoAsync();
  }
}
