import { CoolHttp } from '@angular-cool/http';
import { Injectable } from '@angular/core';
import { CompanyDTO } from '../../../../../../server/src/dto/company.dto';

@Injectable()
export class SubCompanyService {
  constructor(private _http: CoolHttp) {

  }

  public async getSubCompaniesAsync(): Promise<CompanyDTO[]> {
    return await this._http.getAsync(`api/companies/active/sub-companies`);
  }

  public async copyProcedureToCompanyAsync(companyId: string, procedureId: string) {
    return await this._http.postAsync(`api/companies/active/sub-companies/${ companyId }/procedures`, {
      id: procedureId,
    });
  }
}
