import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.scss']
})
export class CompanySettingsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
