import { Inject, Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { WINDOW } from '../services/injection-tokens';
import { RouteLocations } from './route-locations.enum';

@Injectable()
export class RouteNavigationService {
  constructor(@Inject(WINDOW) private _window: Window,
              private _router: Router) {

  }

  public async goToHomeAsync() {
    await this._router.navigate([RouteLocations.Home]);
  }

  public async goToRequestDemoAsync() {
    await this._router.navigate([RouteLocations.RequestDemo]);
  }

  public async goToTermsAndConditionsAsync() {
    await this._router.navigate([RouteLocations.TermsAndConditions]);
  }

  public async goToPrivacyPolicyAsync() {
    await this._router.navigate([RouteLocations.PrivacyPolicy]);
  }

  public openTermsInNewWindow() {
    this._window.open(RouteLocations.TermsAndConditions, '_blank');
  }

  public async goToLoginAsync(devKey?: string) {
    const extras: NavigationExtras = {};

    if (devKey) {
      extras.queryParams = {
        'dev-key': devKey,
      };
    }

    await this._router.navigate([RouteLocations.Login], extras);
  }

  public async goToRegistrationAsync(devKey?: string, joinToken?: string) {
    const extras: NavigationExtras = {
      queryParams: {
        'dev-key': devKey,
        joinToken: joinToken,
        registration: '1',
      },
    };

    await this._router.navigate([RouteLocations.Login], extras);
  }

  public async goToLoginSuccessAsync() {
    await this._router.navigate([RouteLocations.LoginSuccess]);
  }

  public async goToForgotPasswordAsync() {
    await this._router.navigate([RouteLocations.ForgotPassword]);
  }

  public async goToSSOLoginAsync(identifier: string = null) {
    await this._router.navigate([
      ...RouteLocations.SSOLogin.replace(':identifier', identifier || '').split('/'),
    ]);
  }

  public async goToValidateEmailAsync() {
    await this._router.navigate([RouteLocations.ActivateEmail]);
  }

  public async goToSettingsAsync() {
    await this._router.navigate([RouteLocations.Settings]);
  }

  public async goToCompanyJoinAsync() {
    await this._router.navigate([RouteLocations.CompanyJoin]);
  }

  public async goToDashboardAsync() {
    await this._router.navigate([RouteLocations.Dashboard]);
  }

  public async goToDashboardHomeAsync() {
    await this._router.navigate([
      RouteLocations.Dashboard,
      RouteLocations.DashboardChildren.DashboardHome,
    ]);
  }

  public async goToProceduresListAsync() {
    await this._router.navigate([
      RouteLocations.Dashboard,
      RouteLocations.DashboardChildren.Procedures,
    ]);
  }

  public async goToEditProcedureAsync(procedureId: string) {
    await this._router.navigate([
      RouteLocations.Dashboard,
      RouteLocations.DashboardChildren.Procedures,
      ...RouteLocations.DashboardChildren.ProceduresChildren.EditProcedure.replace(':procedureId', procedureId).split('/'),
    ]);
  }

  public async goToViewProcedureAsync(procedureId: string) {
    await this._router.navigate([
      RouteLocations.Dashboard,
      RouteLocations.DashboardChildren.Procedures,
      ...RouteLocations.DashboardChildren.ProceduresChildren.ViewProcedure.replace(':procedureId', procedureId).split('/'),
    ]);
  }

  public async goToArticleListAsync() {
    await this._router.navigate([
      RouteLocations.Dashboard,
      ...RouteLocations.DashboardChildren.KnowledgeBaseArticles.split('/'),
    ]);
  }

  public async goToViewArticleAsync(articleId: string) {
    await this._router.navigate([
      RouteLocations.Dashboard,
      ...RouteLocations.DashboardChildren.KnowledgeBaseArticles.split('/'),
      ...RouteLocations.DashboardChildren.KnowledgeBaseArticlesChildren.ViewArticle.replace(':articleId', articleId).split('/'),
    ]);
  }

  public async goToEditArticleAsync(articleId: string) {
    await this._router.navigate([
      RouteLocations.Dashboard,
      ...RouteLocations.DashboardChildren.KnowledgeBaseArticles.split('/'),
      ...RouteLocations.DashboardChildren.KnowledgeBaseArticlesChildren.EditArticle.replace(':articleId', articleId).split('/'),
    ]);
  }

  public async goToLearningCampaignListAsync() {
    await this._router.navigate([
      RouteLocations.Dashboard,
      RouteLocations.DashboardChildren.Learning,
      RouteLocations.DashboardChildren.LearningChildren.Campaigns,
      RouteLocations.DashboardChildren.LearningChildren.CampaignsChildren.CampaignList,
    ]);
  }

  public async goToEditLearningCampaignAsync(learningCampaignId: string) {
    await this._router.navigate([
      RouteLocations.Dashboard,
      RouteLocations.DashboardChildren.Learning,
      RouteLocations.DashboardChildren.LearningChildren.Campaigns,
      ...RouteLocations.DashboardChildren.LearningChildren.CampaignsChildren.EditCampaign.replace(':campaignId', learningCampaignId).split('/'),
    ]);
  }

  public async goToViewLearningReportAsync(reportId: string) {
    await this._router.navigate([
      RouteLocations.Dashboard,
      RouteLocations.DashboardChildren.Learning,
      RouteLocations.DashboardChildren.LearningChildren.Reports,
      ...RouteLocations.DashboardChildren.LearningChildren.ReportsChildren.View.replace(':reportId', reportId).split('/'),
    ]);
  }

  public async goToLearningCampaignReportAsync(learningCampaignId: string) {
    await this._router.navigate([
      RouteLocations.Dashboard,
      RouteLocations.DashboardChildren.Learning,
      RouteLocations.DashboardChildren.LearningChildren.Reports,
    ], {
      queryParams: {
        campaignId: learningCampaignId,
      },
    });
  }

  public async goToLearningCertificateTemplatesAsync() {
    await this._router.navigate([
      RouteLocations.Dashboard,
      RouteLocations.DashboardChildren.Learning,
      RouteLocations.DashboardChildren.LearningChildren.CertificateTemplates,
    ]);
  }

  public async goToViewControlReportAsync(reportId: string) {
    await this._router.navigate([
      RouteLocations.Dashboard,
      RouteLocations.DashboardChildren.Control,
      RouteLocations.DashboardChildren.ControlChildren.ControlReports,
      ...RouteLocations.DashboardChildren.ControlChildren.ControlReportsChildren.View.replace(':reportId', reportId).split('/'),
    ]);
  }

  public async goToViewAssetControlReportAsync(reportId: string) {
    await this._router.navigate([
      RouteLocations.Dashboard,
      RouteLocations.DashboardChildren.AssetControl,
      RouteLocations.DashboardChildren.AssetControlChildren.Reports,
      ...RouteLocations.DashboardChildren.AssetControlChildren.ReportsChildren.View.replace(':reportId', reportId).split('/'),
    ]);
  }

  public async goToControlCampaignReportAsync(learningCampaignId: string) {
    await this._router.navigate([
      RouteLocations.Dashboard,
      RouteLocations.DashboardChildren.Control,
      RouteLocations.DashboardChildren.ControlChildren.ControlReports,
    ], {
      queryParams: {
        campaignId: learningCampaignId,
      },
    });
  }

  public async goToCompleteCampaignAsync(learningCampaignId: string) {
    await this._router.navigate([
      RouteLocations.Dashboard,
      RouteLocations.DashboardChildren.Learning,
      ...RouteLocations.DashboardChildren.LearningChildren.CompleteCampaign.replace(':campaignId', learningCampaignId).split('/'),
    ]);
  }

  public async goToControlCheckEventListAsync() {
    await this._router.navigate([
      RouteLocations.Dashboard,
      RouteLocations.DashboardChildren.Control,
      RouteLocations.DashboardChildren.ControlChildren.ControlCheckEvents,
      RouteLocations.DashboardChildren.ControlChildren.ControlCheckEventsChildren.ListEvents,
    ]);
  }

  public async goToEditControlCheckEventAsync(controlCheckEventId: string) {
    await this._router.navigate([
      RouteLocations.Dashboard,
      RouteLocations.DashboardChildren.Control,
      RouteLocations.DashboardChildren.ControlChildren.ControlCheckEvents,
      ...RouteLocations.DashboardChildren.ControlChildren.ControlCheckEventsChildren.EditEvent.replace(':controlCheckEventId', controlCheckEventId).split('/'),
    ]);
  }

  public async goToAssetRoomListAsync() {
    await this._router.navigate([
      RouteLocations.Dashboard,
      RouteLocations.DashboardChildren.AssetControl,
      RouteLocations.DashboardChildren.AssetControlChildren.Rooms,
    ]);
  }

  public async goToEditAssetRoomAsync(roomId: string) {
    await this._router.navigate([
      RouteLocations.Dashboard,
      RouteLocations.DashboardChildren.AssetControl,
      RouteLocations.DashboardChildren.AssetControlChildren.Rooms,
      ...RouteLocations.DashboardChildren.AssetControlChildren.RoomsChildren.Edit.replace(':roomId', roomId).split('/'),
    ]);
  }

  public async goToAssetRoomEventsAsync(roomId: string) {
    await this._router.navigate([
      RouteLocations.Dashboard,
      RouteLocations.DashboardChildren.AssetControl,
      RouteLocations.DashboardChildren.AssetControlChildren.Rooms,
      ...RouteLocations.DashboardChildren.AssetControlChildren.RoomsChildren.Events.replace(':roomId', roomId).split('/'),
    ]);
  }

  public async goToAssetRoomAssetsAsync(roomId: string) {
    await this._router.navigate([
      RouteLocations.Dashboard,
      RouteLocations.DashboardChildren.AssetControl,
      RouteLocations.DashboardChildren.AssetControlChildren.Rooms,
      ...RouteLocations.DashboardChildren.AssetControlChildren.RoomsChildren.Assets.replace(':roomId', roomId).split('/'),
    ]);
  }

  public reloadPage() {
    this._window.location.reload();
  }
}