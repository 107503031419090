export enum JWTTypes {
  JoinCompanySimple = 'join-company-simple',

  JoinCompanyWithEmail = 'join-company-with-email',

  ForgotPassword = 'forgot-password',
}

export interface JoinCompanySimple {
  type: JWTTypes;
  companyId: string;
}

export interface JoinCompanyWithEmail {
  type: JWTTypes;
  companyId: string;
  emailHash: string;
  email: string;
}

export interface ForgotPassword {
  type: JWTTypes;
  userId: string;
}