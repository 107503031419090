<div>
  <app-page-header pageTitle="{{ 'Dashboard.ControlReports.Title' | translate }} - {{ report?.filters.procedure.name }}"></app-page-header>

  <mat-card *ngIf="report" class="mat-elevation-z4 mt-4">
    <mat-card-header>
      <mat-card-title>
        <div>{{ report.filters.procedure.name }}</div>
        <div class="d-flex flex-row second-row">
          <div *ngIf="report.filters.departments?.length">
            {{ getDepartmentNamesFromFilter() }}
          </div>
          <div *ngIf="report.filters.userIds?.length" class="ml-4">
            <app-user-selector [(ngModel)]="report.filters.userIds"
                               [displayOnly]="true"
                               name="users">
            </app-user-selector>
          </div>
          <div *ngIf="report.filters.startDate || report.filters.endDate" class="ml-4 d-flex flex-row">
            <div *ngIf="report.filters.startDate">{{ report.filters.startDate | date }}</div>
            <div class="mx-2">&nbsp;-&nbsp;</div>
            <div *ngIf="report.filters.endDate">{{ report.filters.endDate | date }}</div>
          </div>
        </div>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="p-3">
      <div *ngIf="successPercentagesByDepartmentChartData">
        <canvas baseChart
                [datasets]="successPercentagesByDepartmentChartData"
                [labels]="successPercentagesByDepartmentChartLabels"
                [options]="successPercentagesByDepartmentChartOptions"
                [legend]="true"
                chartType="line">
        </canvas>
      </div>

      <div *ngIf="!successPercentagesByDepartmentChartData" class="text-center">{{ 'Common.NoData' | translate }}</div>

      <div class="mt-4" *ngIf="procedureStepSuccessPercentagesChartData">
        <h5 class="text-center mb-3">{{ 'Common.Steps' | translate }}</h5>
        <canvas baseChart
                [datasets]="procedureStepSuccessPercentagesChartData"
                [labels]="procedureStepSuccessPercentagesChartLabels"
                [options]="procedureStepSuccessPercentagesChartOptions"
                [legend]="false"
                chartType="bar">
        </canvas>
      </div>
    </mat-card-content>
  </mat-card>

  <div class="text-center" *ngIf="isLoading">
    <app-loading-indicator></app-loading-indicator>
  </div>

  <mat-card *ngIf="hasError">
    <h6 class="color-warn text-center">{{ 'Common.FailedToGenerateReport' | translate }}</h6>
  </mat-card>
</div>
