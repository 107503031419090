import { CoolHttp } from '@angular-cool/http';
import { Injectable } from '@angular/core';
import { LearningCampaignDTO } from '../../../../../../server/src/dto/learning-campaign.dto';

@Injectable()
export class LearningCampaignsService {
  constructor(private _http: CoolHttp) {

  }

  public async getLearningCampaignsAsync(): Promise<LearningCampaignDTO[]> {
    return await this._http.getAsync('api/companies/active/learning/campaigns');
  }

  public async getLearningCampaignByIdAsync(learningCampaignId: string): Promise<LearningCampaignDTO> {
    return await this._http.getAsync(`api/companies/active/learning/campaigns/${ learningCampaignId }`);
  }

  public async createLearningCampaignAsync(learningCampaign: LearningCampaignDTO): Promise<LearningCampaignDTO> {
    return await this._http.postAsync('api/companies/active/learning/campaigns', learningCampaign);
  }

  public async updateLearningCampaignAsync(learningCampaign: LearningCampaignDTO) {
    await this._http.postAsync(`api/companies/active/learning/campaigns/${ learningCampaign.id }`, learningCampaign);
  }

  public async removeLearningCampaignAsync(learningCampaignId: string) {
    await this._http.deleteAsync(`api/companies/active/learning/campaigns/${ learningCampaignId }`);
  }
}