import { CoolDialogService } from '@angular-cool/dialogs';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DepartmentDTO } from '../../../../../../server/src/dto/department.dto';
import { LanguageService } from '../../../shared/services/language/language.service';
import { OrganizationService } from '../../../shared/services/organization/organization.service';
import { Editable } from '../../../shared/utils/editable';

@Component({
  selector: 'app-organization-departments',
  templateUrl: './organization-departments.component.html',
  styleUrls: ['./organization-departments.component.scss'],
})
export class OrganizationDepartmentsComponent implements OnInit {

  constructor(private _organizationService: OrganizationService,
              private _dialogService: CoolDialogService,
              private _languageService: LanguageService) {

  }

  public displayedColumns = ['name', 'actions'];
  public isLoading: boolean;

  public updateDepartmentInProgress: boolean;

  public dataSource: MatTableDataSource<Editable<DepartmentDTO>>;

  @ViewChild(MatSort, { static: true })
  public sort: MatSort;

  public async ngOnInit() {
    try {
      this.isLoading = true;

      await this._loadDataAsync();
    } finally {
      this.isLoading = false;
    }
  }

  public async addNewDepartmentAsync() {
    await this._organizationService.createDepartmentAsync(
      await this._languageService.getTranslationAsync('Dashboard.Departments.NewDepartmentName'),
    );

    await this._loadDataAsync();
  }

  public async editDepartmentAsync(department: Editable<DepartmentDTO>) {
    if (!department.value.name) {
      return;
    }

    try {
      await this._organizationService.updateDepartmentAsync(department.value);
    } finally {
      this.updateDepartmentInProgress = false;
    }

    department.stopEditing(true);

    await this._loadDataAsync();
  }

  public async startEditingDepartmentAsync(department: Editable<DepartmentDTO>) {
    department.startEditing();
  }

  public async cancelEditingDepartmentAsync(department: Editable<DepartmentDTO>) {
    department.stopEditing(false);
  }

  public async removeDepartmentAsync(department: Editable<DepartmentDTO>) {
    const removeDialogResult = await this._dialogService.showDialog({
      titleText: await this._languageService.getTranslationAsync('Dashboard.Departments.RemoveDepartmentTitle', { departmentName: department.value.name }),
      questionText: await this._languageService.getTranslationAsync('Dashboard.Departments.RemoveDepartmentDescription', { departmentName: department.value.name }),
      confirmActionButtonText: await this._languageService.getTranslationAsync('Common.Remove'),
      cancelActionButtonText: await this._languageService.getTranslationAsync('Common.Cancel'),
      confirmActionButtonColor: 'warn',
    });

    if (!removeDialogResult.isConfirmed) {
      return;
    }

    await this._organizationService.removeDepartmentAsync(department.value.id);

    await this._loadDataAsync();
  }

  public filterDepartments(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  private async _loadDataAsync() {
    const departments = await this._organizationService.getDepartmentsAsync();

    this.dataSource = new MatTableDataSource(departments.map(department => new Editable(department)));

    this.dataSource.filterPredicate = (item: Editable<DepartmentDTO>, filter: string) => !filter || !item.value || item.value.name.toLowerCase().includes(filter);

    this.dataSource.sortingDataAccessor = (item: Editable<DepartmentDTO>, property: string) => {
      switch (property) {
        case 'name':
          return item.value.name;

        default:
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
  }
}
