<form #questionForm="ngForm">
  <div class="d-flex">
    <mat-form-field class="flex-grow-1">
      <input matInput
             [(ngModel)]="question.questionText"
             name="question-{{ question.rank }}"
             placeholder="{{ 'Dashboard.Procedures.TestQuestionText' | translate }}"
             required>
    </mat-form-field>

    <mat-form-field class="ml-3">
      <mat-select name="question-{{ question.rank }}-type"
                  [(ngModel)]="question.type"
                  (valueChange)="onQuestionTypeChange()"
                  required>
        <mat-option [value]="ProcedureTestQuestionType.MultipleChoice">
          {{ 'Dashboard.Procedures.TestQuestionTypes.MultipleChoice' | translate }}
        </mat-option>
        <mat-option [value]="ProcedureTestQuestionType.SingleChoice">
          {{ 'Dashboard.Procedures.TestQuestionTypes.SingleChoice' | translate }}
        </mat-option>
        <mat-option [value]="ProcedureTestQuestionType.Ordering">
          {{ 'Dashboard.Procedures.TestQuestionTypes.Ordering' | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="mt-3">
    <div *ngIf="question.type === ProcedureTestQuestionType.MultipleChoice">
      <mat-selection-list>
        <mat-list-option *ngFor="let answer of question.answers; let i = index"
                         (click)="onMultipleChoiceAnswerClick(answer)"
                         [selected]="answer.isCorrectAnswer"
                         checkboxPosition="before"
                         class="mb-2">
          <div class="d-flex">
            <mat-form-field class="flex-grow-1" floatLabel="never">
              <input matInput
                     name="question-{{ question.rank }}-answer-{{ i }}-text"
                     (click)="$event.stopPropagation()"
                     (keydown)="$event.stopPropagation()"
                     [(ngModel)]="answer.answerText"
                     placeholder="{{ 'Dashboard.Procedures.TestAnswerText' | translate }}"
                     required>
            </mat-form-field>

            <div class="ml-3 hover-strong cursor-pointer d-flex align-items-center"
                 *ngIf="question.answers.length > 2"
                 (click)="removeAnswer(answer)"
                 title="{{ 'Common.Remove' | translate }}">
              <fa-icon icon="trash"></fa-icon>
            </div>
          </div>
        </mat-list-option>
      </mat-selection-list>
    </div>

    <div *ngIf="question.type === ProcedureTestQuestionType.SingleChoice">
      <mat-radio-group [(ngModel)]="singleChoiceCorrectAnswer"
                       name="question-{{ question.rank }}-answer"
                       (change)="onSingleChoiceValueChange($event)">
        <mat-list>
          <mat-list-item *ngFor="let answer of question.answers; let i = index">
            <div matLine>
              <mat-radio-button class="w-100" [value]="answer">
                <div class="d-flex">
                  <mat-form-field class="flex-grow-1" floatLabel="never">
                    <input matInput
                           name="question-{{ question.rank }}-answer-{{ i }}-text"
                           (click)="$event.stopPropagation()"
                           (keydown)="$event.stopPropagation()"
                           [(ngModel)]="answer.answerText"
                           placeholder="{{ 'Dashboard.Procedures.TestAnswerText' | translate }}"
                           required>
                  </mat-form-field>

                  <div class="ml-3 hover-strong cursor-pointer d-flex align-items-center"
                       *ngIf="question.answers.length > 2"
                       (click)="removeAnswer(answer)"
                       title="{{ 'Common.Remove' | translate }}">
                    <fa-icon icon="trash"></fa-icon>
                  </div>
                </div>
              </mat-radio-button>
            </div>
          </mat-list-item>
        </mat-list>
      </mat-radio-group>
    </div>

    <div *ngIf="question.type === ProcedureTestQuestionType.Ordering">
      <app-ordering-list [(ngModel)]="question.answers" name="ordering-question-{{ question.id }}">
        <ng-template let-item>
          <div class="d-flex flex-row px-2">
            <div class="d-flex align-items-center cursor-grab">
              <fa-icon icon="bars"></fa-icon>
            </div>

            <mat-form-field class="flex-grow-1 ml-3" floatLabel="never">
              <input matInput
                     (click)="$event.stopPropagation()"
                     (keydown)="$event.stopPropagation()"
                     [(ngModel)]="item.answerText"
                     name="ordering-answer-{{ question.id }}-{{ item.id }}"
                     placeholder="{{ 'Dashboard.Procedures.TestAnswerText' | translate }}"
                     required>
            </mat-form-field>

            <div class="ml-3 hover-strong cursor-pointer d-flex align-items-center"
                 *ngIf="question.answers.length > 2"
                 (click)="removeAnswer(item)"
                 title="{{ 'Common.Remove' | translate }}">
              <fa-icon icon="trash"></fa-icon>
            </div>
          </div>
        </ng-template>
      </app-ordering-list>
    </div>
  </div>

  <div class="mt-3">
    <button mat-raised-button color="primary" (click)="addAnswerAsync()">
      <fa-icon icon="plus" class="mr-1"></fa-icon>

      {{ 'Dashboard.Procedures.TestAddAnswer' | translate }}
    </button>
  </div>
</form>