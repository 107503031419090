import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { LearningCampaignDTO } from '../../../../../../server/src/dto/learning-campaign.dto';
import { LanguageService } from '../../services/language/language.service';
import { LearningCampaignsService } from '../../services/learning-campaigns/learning-campaigns.service';
import { Deferred } from '../../utils/deferred';

@Component({
  selector: 'app-learning-campaign-select',
  templateUrl: './learning-campaign-select.component.html',
  styleUrls: ['./learning-campaign-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: LearningCampaignSelectComponent,
    multi: true,
  }],
})
export class LearningCampaignSelectComponent implements OnInit, ControlValueAccessor {
  private _onChange: (campaign: LearningCampaignDTO) => void;
  private _onTouched: (campaign: LearningCampaignDTO) => void;

  private _isComponentLoaded = new Deferred();

  constructor(private _learningCampaignsService: LearningCampaignsService,
              private _languageService: LanguageService) {
  }

  @Input()
  public displayOnly: boolean;

  @Input()
  public name: string;

  @Input()
  public placeholder: string;

  @Input()
  public learningCampaigns: LearningCampaignDTO[];

  @Input()
  public required: boolean;

  @ViewChild(MatSelect)
  public learningCampaignSelect: MatSelect;

  public isDisabled: boolean;

  public selectedLearningCampaignId: string;
  public selectedLearningCampaign: LearningCampaignDTO;

  public async ngOnInit() {
    this.learningCampaigns = this.learningCampaigns || await this._learningCampaignsService.getLearningCampaignsAsync();

    this.placeholder = this.placeholder || await this._languageService.getTranslationAsync('Common.LearningCampaigns');

    this._isComponentLoaded.resolve();
  }

  public onInnerChange(change: string) {
    this.selectedLearningCampaignId = change;

    this.selectedLearningCampaign = this.learningCampaigns.find(campaign => campaign.id === this.selectedLearningCampaignId);

    this._onTouched && this._onTouched(this.selectedLearningCampaign);
    this._onChange && this._onChange(this.selectedLearningCampaign);
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public writeValue(learningCampaigns: LearningCampaignDTO): void {
    this.selectedLearningCampaign = learningCampaigns;
    this.selectedLearningCampaignId = this.selectedLearningCampaign ? this.selectedLearningCampaign.id : null;
  }

  public markAsTouched() {
    this.learningCampaignSelect.ngControl.control.markAllAsTouched();
  }

  public getIsComponentLoaded(): Promise<void> {
    return this._isComponentLoaded.getPromise();
  }
}
