<div *ngIf="displayOnly">{{ selectedPermissionRole?.name }}</div>

<mat-form-field *ngIf="!displayOnly" class="w-100">
  <mat-label>{{ placeholder }}</mat-label>
  <mat-select name="{{ name }}-inner-select"
              [ngModel]="selectedPermissionRole?.id"
              (ngModelChange)="onInnerChange($event)"
              [disabled]="isDisabled">
    <mat-option *ngFor="let role of permissionRoles" [value]="role.id">
      {{ role.name }}
    </mat-option>
  </mat-select>
</mat-form-field>