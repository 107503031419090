<div id="page-header" class="mb-3">
  <div class="title-container">
    <h2 class="title">
      <span *ngIf="showBack" class="hover-strong cursor-pointer mr-2" [attr.title]="'Common.Back' | translate" (click)="goBack()">
        <fa-icon icon="chevron-left" size="xs"></fa-icon>
      </span>

      {{ pageTitle }}
    </h2>
  </div>
  <div class="actions-container">
    <ng-content></ng-content>
  </div>
</div>
