<div>
  <app-page-header pageTitle="{{ 'Dashboard.Navigation.Users' | translate }}">
    <div>
      <div class="d-inline-block">
        <mat-form-field>
          <input matInput (keyup)="filterCompanyUsers($event.target.value)"
                 placeholder="{{ 'Common.Filter' | translate }}">
        </mat-form-field>
      </div>

      <div class="d-inline-block mr-4 ml-5">
        {{ currentNumberOfUsers }} / {{ maxNumberOfUsers }} {{ 'Common.User' | translate }}
      </div>

      <button mat-raised-button color="primary"
              (click)="addNewCompanyUserAsync()">
        <fa-icon icon="plus" class="mr-1"></fa-icon>

        {{ 'Dashboard.Users.AddNewCompanyUser' | translate }}
      </button>
    </div>
  </app-page-header>

  <div class="w-100">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z4 w-100">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ 'Common.Name' | translate }}</th>
        <td mat-cell *matCellDef="let companyUser">
          {{ companyUser?.name || 'N/A' }}
        </td>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>{{ 'Common.Email' | translate }}</th>
        <td mat-cell *matCellDef="let companyUser">
          {{ companyUser?.email || 'N/A' }}
        </td>
      </ng-container>

      <!-- Company Role Column -->
      <ng-container matColumnDef="companyRole">
        <th mat-header-cell *matHeaderCellDef>{{ 'Common.Role' | translate }}</th>
        <td mat-cell *matCellDef="let companyUser">
          {{ companyUser?.companyRole?.name || 'N/A' }}
        </td>
      </ng-container>

      <!-- Departments Column -->
      <ng-container matColumnDef="departments">
        <th mat-header-cell *matHeaderCellDef>{{ 'Common.Departments' | translate }}</th>
        <td mat-cell *matCellDef="let companyUser">
          <div *ngIf="!companyUser?.departments.length">{{ 'Dashboard.Users.NoDepartments' | translate }}</div>

          <div *ngIf="companyUser?.departments.length && companyUser.departments.length < 3">
            <span *ngFor="let department of companyUser.departments; let i = index">
              {{ i !== 0 ? ', ' : '' }}
              {{ department.name }}
            </span>
          </div>

          <div *ngIf="companyUser?.departments.length > 2" class="font-italic">
            {{ 'Common.MultipleDepartments' | translate: { departmentCount: companyUser?.departments.length } }}
          </div>
        </td>
      </ng-container>

      <!-- Permission Role Column -->
      <ng-container matColumnDef="permissionRole">
        <th mat-header-cell *matHeaderCellDef>{{ 'Common.Permission' | translate }}</th>
        <td mat-cell *matCellDef="let companyUser">
          <app-permission-role-select name="permission-role-{{ companyUser?.id }}"
                                      [ngModel]="companyUser?.permissionRole"
                                      [displayOnly]="true">
          </app-permission-role-select>
        </td>
      </ng-container>

      <!-- Notes -->
      <ng-container matColumnDef="notes">
        <th mat-header-cell *matHeaderCellDef>{{ 'Common.Notes' | translate }}</th>
        <td mat-cell *matCellDef="let companyUser">
          <div class="notes-container" [attr.title]="companyUser?.notes">
            {{ companyUser?.notes }}
          </div>
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ 'Common.Actions' | translate }}</th>
        <td mat-cell *matCellDef="let companyUser">
          <button mat-raised-button color="primary"
                  [attr.title]="'Common.Edit' | translate"
                  (click)="editingCompanyUserAsync(companyUser)">
            <fa-icon icon="pencil-alt"></fa-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator class="mat-elevation-z4"
                   [disabled]="isPaginatorDisabled"
                   [showFirstLastButtons]="false"
                   [pageSize]="pageSize"
                   [hidePageSize]="true">
    </mat-paginator>

    <div class="text-center mt-3" *ngIf="isLoading">
      <app-loading-indicator></app-loading-indicator>
    </div>
  </div>
</div>