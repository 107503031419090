import { CoolHttp } from '@angular-cool/http';
import { Injectable } from '@angular/core';
import {
  LearningCampaignCompletionSessionDTO,
  LearningCampaignCompletionSessionProcedureTestQuestionAnswersDTO,
} from '../../../../../../../../server/src/dto/learning-campaign-completion-session.dto';
import { LimitedLearningCampaignWithTestDTO } from '../../../../../../../../server/src/dto/learning-campaign.dto';

@Injectable()
export class CompleteCampaignService {
  constructor(private _http: CoolHttp) {

  }

  public async getMyActiveLearningCampaignByIdAsync(learningCampaignId: string): Promise<LimitedLearningCampaignWithTestDTO> {
    return await this._http.getAsync(`api/companies/active/learning/campaigns/my/active/${ learningCampaignId }`);
  }

  public async getMyActiveLearningCampaignSessionAsync(learningCampaignId: string): Promise<LearningCampaignCompletionSessionDTO> {
    return await this._http.getAsync(`api/companies/active/learning/campaigns/my/active/${ learningCampaignId }/session`);
  }

  public async createMyActiveLearningCampaignSessionAsync(learningCampaignId: string): Promise<LearningCampaignCompletionSessionDTO> {
    return await this._http.postAsync(`api/companies/active/learning/campaigns/my/active/${ learningCampaignId }/session`);
  }

  public async removeMyActiveLearningCampaignSessionAsync(learningCampaignId: string): Promise<LearningCampaignCompletionSessionDTO> {
    return await this._http.deleteAsync(`api/companies/active/learning/campaigns/my/active/${ learningCampaignId }/session`);
  }

  public async nextMyActiveLearningCampaignSessionStepAsync(learningCampaignId: string, questionAnswers: LearningCampaignCompletionSessionProcedureTestQuestionAnswersDTO): Promise<LearningCampaignCompletionSessionDTO> {
    return await this._http.postAsync(`api/companies/active/learning/campaigns/my/active/${ learningCampaignId }/session/next`, questionAnswers);
  }

  public async previousMyActiveLearningCampaignSessionStepAsync(learningCampaignId: string): Promise<LearningCampaignCompletionSessionDTO> {
    return await this._http.postAsync(`api/companies/active/learning/campaigns/my/active/${ learningCampaignId }/session/previous`);
  }
}