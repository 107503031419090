import { Pipe, PipeTransform } from '@angular/core';
import { ListUtils } from '../../../../../server/src/utils/list-utils';

@Pipe({
  name: 'orderByRank',
})
export class OrderByRankPipe implements PipeTransform {
  transform(value: any[], propertyName: string = 'rank'): any[] {
    return [].concat(value)
      .sort(propertyName === 'rank' ? ListUtils.compareByRank : ListUtils.generateCompareByCustomPropertyFunction(propertyName));
  }
}
