<div style="min-width: 400px">
  <h2>{{ 'Dashboard.Procedures.CopyProcedureToOtherCompany' | translate }}</h2>

  <div class="mt-3">
    <div>{{ 'Common.Procedure' | translate }}: {{ procedureName }}</div>

    <div class="mt-2">
      <app-sub-company-select #companySelect name="sub-comp" required></app-sub-company-select>
    </div>
  </div>

  <div class="mt-3 text-right">
    <span *ngIf="showError" class="mr-2 text-danger">{{ 'Common.ErrorSaving' | translate }}</span>

    <button mat-raised-button (click)="cancel()" class="mr-2">{{ 'Common.Cancel' | translate }}</button>

    <app-button color="primary"
                [loading]="saveInProgress"
                [disabled]="!companySelect.selectedCompany"
                (clicked)="copyProcedureToCompany(companySelect.selectedCompany)">
      {{ 'Dashboard.Procedures.CopyProcedureToOtherCompany' | translate }}
    </app-button>
  </div>
</div>