import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ProcedureDTO } from '../../../../../../server/src/dto/procedure.dto';
import { FeaturePermissions } from '../../../../../../server/src/models/feature-permissions.enum';
import { RouteNavigationService } from '../../../shared/routing/route-navigation.service';
import { ProceduresService } from '../../../shared/services/procedures/procedures.service';
import { UserContextService } from '../../../shared/services/user-context/user-context.service';
import { CopyProcedureToCompanyDialogConfig } from '../shared/copy-procedure-to-company-dialog/copy-procedure-to-company-dialog-config.interface';
import { CopyProcedureToCompanyDialogComponent } from '../shared/copy-procedure-to-company-dialog/copy-procedure-to-company-dialog.component';

@Component({
  selector: 'app-view-procedure',
  templateUrl: './view-procedure.component.html',
  styleUrls: ['./view-procedure.component.scss'],
})
export class ViewProcedureComponent implements OnInit, OnDestroy {

  constructor(private _userContextService: UserContextService,
              private _proceduresService: ProceduresService,
              private _activatedRoute: ActivatedRoute,
              private _dialogService: MatDialog,
              private _routeNavigationService: RouteNavigationService) {

  }

  public canUserEditProcedures: boolean;
  public canCopyToOtherCompany: boolean;
  public isLoading: boolean;
  public showProcedureNotFound: boolean;

  public procedure: ProcedureDTO;

  public ngOnInit() {
    this._activatedRoute.params
      .pipe(untilDestroyed(this))
      .subscribe(async (params: Params) => {
        const procedureId = params.procedureId;

        if (!procedureId) {
          return;
        }

        try {
          this.isLoading = true;
          this.showProcedureNotFound = false;

          this.procedure = await this._proceduresService.getProcedureByIdAsync(procedureId);
        } catch {
          this.showProcedureNotFound = true;
        } finally {
          this.isLoading = false;
        }

        this.canUserEditProcedures = this._userContextService.user.featurePermissions.includes(FeaturePermissions.EditProcedures);
        this.canCopyToOtherCompany = this._userContextService.user.featurePermissions.includes(FeaturePermissions.CopyProcedureToOtherCompany);
      });
  }

  public ngOnDestroy(): void {
  }

  public async editProcedureAsync() {
    await this._routeNavigationService.goToEditProcedureAsync(this.procedure.id);
  }

  public async copyProcedureToOtherCompanyAsync() {
    if (!this.canCopyToOtherCompany || !this.procedure) {
      return;
    }

    this._dialogService.open<CopyProcedureToCompanyDialogComponent, CopyProcedureToCompanyDialogConfig>(CopyProcedureToCompanyDialogComponent, {
      data: {
        procedure: this.procedure,
      },
    });
  }
}
