<div>
  <h5>{{ 'Dashboard.LearningReports.Title' | translate }} - {{ 'Common.Filter' | translate }}</h5>

  <div class="dialog-container">
    <form #filterForm="ngForm">
      <div class="row">
        <div class="col-12">
          <app-learning-campaign-select [(ngModel)]="filters.learningCampaign"
                                        name="learning-campaign" [required]="true">
          </app-learning-campaign-select>
        </div>
      </div>

      <div class="row">
        <div class="col-6 pr-2">
          <mat-form-field class="w-100">
            <input matInput [matDatepicker]="startDatePicker"
                   name="start-date"
                   [(ngModel)]="filters.startDate"
                   (click)="startDatePicker.open()"
                   placeholder="{{ 'Common.StartDate' | translate }}"
                   required>
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-6 pl-2">
          <mat-form-field class="w-100">
            <input matInput [matDatepicker]="endDatePicker"
                   name="end-date"
                   [(ngModel)]="filters.endDate"
                   (click)="endDatePicker.open()"
                   placeholder="{{ 'Common.EndDate' | translate }}"
                   required>
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <app-department-select [(ngModel)]="filters.departments" name="departments">
          </app-department-select>
        </div>
      </div>
    </form>

    <div class="mt-3 text-right">
      <span *ngIf="hasError" class="color-warn mr-4">
        {{ 'Common.FailedToGenerateReport' | translate }}
      </span>

      <button mat-raised-button (click)="onCancel()" class="mr-2">
        {{ 'Common.Cancel' | translate }}
      </button>

      <app-button color="primary"
                  [loading]="isLoading"
                  (clicked)="onGenerateReportClickAsync(filterForm)">
        {{ 'Common.CreateReport' | translate }}
      </app-button>
    </div>
  </div>
</div>