import { CoolLocalStorage } from '@angular-cool/storage';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { FeaturePermissions } from '../../../../../server/src/models/feature-permissions.enum';
import { RouteLocations } from '../../shared/routing/route-locations.enum';
import { UserContextService } from '../../shared/services/user-context/user-context.service';
import { AppStateActions } from '../../shared/state/app.state';

const SIDE_NAV_OPENED_STATE_STORAGE_KEY = 'side-nav-opened-state';

export interface SideNavOpenedState {
  organizationOpened: boolean;
  learningOpened: boolean;
  controlOpened: boolean;
  assetControlOpened: boolean;
  knowledgeBaseOpened: boolean;
}

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {

  constructor(private _userContextService: UserContextService,
              private _store: Store,
              private _localStorage: CoolLocalStorage) {
  }

  public RouteLocations = RouteLocations;

  public showOrganization: boolean;

  public showLearning: boolean;

  public showLearningCampaigns: boolean;
  public showLearningCampaignReports: boolean;

  public showLearningCertificates: boolean;

  public showControl: boolean;

  public showControlCheckEvents: boolean;
  public showControlCheckReports: boolean;

  public showAssetControl: boolean;
  public showAssetControlReports: boolean;
  public showAssetControlRoomCategories: boolean;
  public showAssetControlRooms: boolean;
  public showAssetControlAssetTypes: boolean;

  public showProcedureCategories: boolean;

  public showSettings: boolean;

  public openedState: SideNavOpenedState;

  public ngOnInit() {
    this.openedState = this._localStorage.getObject(SIDE_NAV_OPENED_STATE_STORAGE_KEY) || {
      organizationOpened: true,
      learningOpened: true,
      controlOpened: true,
      assetControlOpened: true,
      knowledgeBaseOpened: true,
    };

    this.showProcedureCategories = this._userContextService.user.featurePermissions.includes(FeaturePermissions.EditProcedures);

    this.showOrganization = this._userContextService.user.featurePermissions.includes(FeaturePermissions.ManageCompanyUsers);

    this.showLearningCampaigns = this._userContextService.user.featurePermissions.includes(FeaturePermissions.ManageLearningCampaigns);
    this.showLearningCampaignReports = this._userContextService.user.featurePermissions.includes(FeaturePermissions.ViewLearningReports);
    this.showLearningCertificates = this._userContextService.user.featurePermissions.includes(FeaturePermissions.ManageLearningCertificates);

    this.showLearning = this.showLearningCampaigns || this.showLearningCampaignReports || this.showLearningCertificates;

    this.showControlCheckEvents = this._userContextService.user.featurePermissions.includes(FeaturePermissions.ManageControlCheckEvents);
    this.showControlCheckReports = this._userContextService.user.featurePermissions.includes(FeaturePermissions.ViewControlReports);
    this.showControl = this.showControlCheckEvents || this.showControlCheckReports;

    this.showAssetControlRoomCategories = this._userContextService.user.featurePermissions.includes(FeaturePermissions.ManageAssetControl);
    this.showAssetControlRooms = this._userContextService.user.featurePermissions.includes(FeaturePermissions.ManageAssetControl);
    this.showAssetControlReports = this._userContextService.user.featurePermissions.includes(FeaturePermissions.ViewAssetControlReports);
    this.showAssetControlAssetTypes = this._userContextService.user.featurePermissions.includes(FeaturePermissions.ManageAssetControl);
    this.showAssetControl = this.showAssetControlRoomCategories || this.showAssetControlRooms || this.showAssetControlReports || this.showAssetControlAssetTypes;

    this.showSettings = this._userContextService.user.featurePermissions.includes(FeaturePermissions.ManageCompanySettings);
  }

  public toggleKnowledgeBase() {
    this.openedState.knowledgeBaseOpened = !this.openedState.knowledgeBaseOpened;

    this._saveOpenedStateToStorage();
  }

  public toggleOrganization() {
    this.openedState.organizationOpened = !this.openedState.organizationOpened;

    this._saveOpenedStateToStorage();
  }

  public toggleLearning() {
    this.openedState.learningOpened = !this.openedState.learningOpened;

    this._saveOpenedStateToStorage();
  }

  public toggleControl() {
    this.openedState.controlOpened = !this.openedState.controlOpened;

    this._saveOpenedStateToStorage();
  }

  public toggleAssetControl() {
    this.openedState.assetControlOpened = !this.openedState.assetControlOpened;

    this._saveOpenedStateToStorage();
  }

  public closeNavbar() {
    this._store.dispatch(new AppStateActions.ToggleNavbar());
  }

  private _saveOpenedStateToStorage() {
    this._localStorage.setObject(SIDE_NAV_OPENED_STATE_STORAGE_KEY, this.openedState);
  }
}
