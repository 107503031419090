import { CoolHttp } from '@angular-cool/http';
import { Injectable } from '@angular/core';
import {
  KnowledgeBaseArticleDTO,
  LimitedKnowledgeBaseArticleDTO,
} from '../../../../../../../server/src/dto/knowledge-base-article.dto';
import { FileUploadItem } from '../../../../shared/components/file-upload-selector/file-upload-item.interface';

@Injectable()
export class KnowledgeBasesService {
  constructor(private _http: CoolHttp) {

  }

  public async getLimitedKnowledgeBaseArticlesAsync(): Promise<LimitedKnowledgeBaseArticleDTO[]> {
    return await this._http.getAsync('api/companies/active/knowledge-base/articles');
  }

  public async createArticleAsync(name: string, description: string): Promise<LimitedKnowledgeBaseArticleDTO> {
    return await this._http.postAsync('api/companies/active/knowledge-base/articles', {
      name: name,
      description: description,
    });
  }

  public async getArticleByIdAsync(articleId: string): Promise<KnowledgeBaseArticleDTO> {
    return await this._http.getAsync(`api/companies/active/knowledge-base/articles/${ articleId }`);
  }

  public async removeArticleByIdAsync(articleId: string) {
    await this._http.deleteAsync(`api/companies/active/knowledge-base/articles/${ articleId }`);
  }

  public async updateArticleAsync(article: KnowledgeBaseArticleDTO) {
    const formData = new FormData();

    const filesToUpload = (<FileUploadItem[]>article.files).filter(file => !!file.file);

    for (const file of filesToUpload) {
      formData.append(`file-${ file.id }`, file.file);
    }

    const articleData = <KnowledgeBaseArticleDTO>JSON.parse(JSON.stringify(article));

    // remove unnecessary base64 image in url
    for (const file of <FileUploadItem[]>articleData.files) {
      file.file = null;

      if (file.id && file.id.includes('-')) {
        file.url = '';
      }
    }

    formData.append('article', JSON.stringify(articleData));

    await this._http.postAsync(`api/companies/active/knowledge-base/articles/${ article.id }`, formData);
  }
}