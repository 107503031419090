import { Component, OnInit } from '@angular/core';
import { CompanyGeneralSettingsDTO } from '../../../../../../server/src/dto/company-settings.dto';
import { Language, LANGUAGE_NAMES } from '../../../../../../server/src/models/language.enum';
import { CompanySettingsService } from '../shared/services/company-settings.service';

@Component({
  selector: 'app-company-general-settings',
  templateUrl: './company-general-settings.component.html',
  styleUrls: ['./company-general-settings.component.scss'],
})
export class CompanyGeneralSettingsComponent implements OnInit {
  constructor(private _companySettingsService: CompanySettingsService) {

  }

  public isLoading: boolean;
  public inProgress: boolean;
  public hasError: boolean;

  public companySettings: CompanyGeneralSettingsDTO;

  public languages: { key: Language; name: string; }[] = Object.keys(LANGUAGE_NAMES).map((langKey: Language) => {
    return {
      key: langKey,
      name: LANGUAGE_NAMES[langKey],
    };
  });

  public async ngOnInit() {
    try {
      this.isLoading = true;

      this.companySettings = await this._companySettingsService.getGeneralSettingsAsync();
    } finally {
      this.isLoading = false;
    }
  }

  public async onSaveAsync() {
    try {
      this.inProgress = true;
      this.hasError = false;

      await this._companySettingsService.setGeneralSettingsAsync(this.companySettings);
    } catch {
      this.hasError = true;
    } finally {
      this.inProgress = false;
    }
  }
}
