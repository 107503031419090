import { Component, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-rich-display',
  templateUrl: './rich-display.component.html',
  styleUrls: ['./rich-display.component.scss'],
})
export class RichDisplayComponent implements OnDestroy {
  constructor() {
  }

  @Input()
  public contentHTML: string;

  public ngOnDestroy(): void {
  }
}
