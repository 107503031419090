import { Component, OnInit } from '@angular/core';
import { CompanyProfileDTO } from '../../../../../../server/src/dto/company-profile.dto';
import { RouteNavigationService } from '../../../shared/routing/route-navigation.service';
import { CompanyProfileService } from '../../../shared/services/company/company-profile.service';

@Component({
  selector: 'app-company-selector',
  templateUrl: './company-selector.component.html',
  styleUrls: ['./company-selector.component.scss'],
})
export class CompanySelectorComponent implements OnInit {
  constructor(private _companyProfileService: CompanyProfileService,
              private _routeNavigationService: RouteNavigationService) {

  }

  public dataLoaded: boolean;

  public activeCompanyProfile: CompanyProfileDTO;

  public hasMultipleCompanyProfiles: boolean;

  public companyProfiles: CompanyProfileDTO[];

  public async ngOnInit() {
    this.companyProfiles = await this._companyProfileService.getCompanyProfilesOfUserAsync();

    this.activeCompanyProfile = this.companyProfiles.find(profile => profile.isActive);
    this.hasMultipleCompanyProfiles = this.companyProfiles.length > 1;

    this.dataLoaded = true;
  }

  public async selectCompanyProfileAsync(companyProfile: CompanyProfileDTO) {
    await this._companyProfileService.setActiveCompanyProfileAsync(companyProfile.id);

    this._routeNavigationService.reloadPage();
  }
}
