<div>
  <h5>{{ 'Dashboard.AssetControlReports.Title' | translate }} - {{ 'Common.Filter' | translate }}</h5>

  <div class="dialog-container">
    <form #filterForm="ngForm">
      <div class="row">
        <div class="col-6 pr-2">
          <app-asset-room-category-select name="category"
                                          placeholder="{{ 'Dashboard.AssetControlReports.RoomCategory' | translate }}"
                                          [(ngModel)]="filters.roomCategory">
          </app-asset-room-category-select>
        </div>
        <div class="col-6 pl-2">
          <app-asset-room-select name="room"
                                 placeholder="{{ 'Dashboard.AssetControlReports.AssetRoom' | translate }}"
                                 [(ngModel)]="filters.room">
          </app-asset-room-select>
        </div>
      </div>

      <div class="row">
        <div class="col-6 pr-2">
          <app-asset-type-select name="assetType"
                                 placeholder="{{ 'Dashboard.AssetControlReports.AssetType' | translate }}"
                                 [(ngModel)]="filters.assetType"
                                 [unselectable]="true">
          </app-asset-type-select>
        </div>
        <div class="col-6 pl-2">
          <mat-form-field class="w-100">
            <mat-label>{{ 'Dashboard.ReportsCommon.DateResolution' | translate }}</mat-label>
            <mat-select name="date-resolution" [(ngModel)]="filters.dateResolution" required>
              <mat-option [value]="AssetControlReportDateResolution.Days">
                {{ 'Common.Time.Days' | translate }} - {{ 'Dashboard.ReportsCommon.MaxDays' | translate }}
              </mat-option>
              <mat-option [value]="AssetControlReportDateResolution.Months">
                {{ 'Common.Time.Months' | translate }} - {{ 'Dashboard.ReportsCommon.MaxMonths' | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-6 pr-2">
          <mat-form-field class="w-100">
            <input matInput [matDatepicker]="startDatePicker"
                   name="start-date"
                   [(ngModel)]="filters.startDate"
                   (click)="startDatePicker.open()"
                   placeholder="{{ 'Common.StartDate' | translate }}"
                   required>
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-6 pl-2">
          <mat-form-field class="w-100">
            <input matInput [matDatepicker]="endDatePicker"
                   name="end-date"
                   [(ngModel)]="filters.endDate"
                   (click)="endDatePicker.open()"
                   placeholder="{{ 'Common.EndDate' | translate }}"
                   required>
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-6 pr-2">
          <app-company-role-select name="role"
                                   placeholder="{{ 'Dashboard.AssetControlReports.ResponsibleRole' | translate }}"
                                   [(ngModel)]="filters.responsibleUser"
                                   [unselectable]="true">
          </app-company-role-select>
        </div>
        <div class="col-6 pl-2">
          <app-single-user-select name="user"
                                  placeholder="{{ 'Dashboard.AssetControlReports.ResponsibleUser' | translate }}"
                                  [(ngModel)]="filters.responsibleUser">
          </app-single-user-select>
        </div>
      </div>
    </form>

    <div class="mt-3 text-right">
      <span *ngIf="hasError" class="color-warn mr-4">
        {{ 'Common.FailedToGenerateReport' | translate }}
      </span>

      <button mat-raised-button (click)="onCancel()" class="mr-2">
        {{ 'Common.Cancel' | translate }}
      </button>

      <app-button color="primary"
                  [loading]="isLoading"
                  (clicked)="onGenerateReportClickAsync(filterForm)">
        {{ 'Common.CreateReport' | translate }}
      </app-button>
    </div>
  </div>
</div>
