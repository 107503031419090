<div class="h-75">
  <div class="p-5 image-faded-background">
    <mat-card class="mat-elevation-z8 pt-4">
      <mat-card-header>
        <mat-card-title class="text-center">
          {{ 'Login.ForgotPasswordTitle' | translate }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="p-4 pb-2 text-center">
        <div>
          <mat-form-field class="w-75">
            <input matInput #emailInput name="email" type="email" [(ngModel)]="email" [disabled]="isDone"
                   placeholder="{{ 'Common.Email' | translate }}" required>
          </mat-form-field>
        </div>
        <div class="text-center mt-3">
          <div>
            <app-button *ngIf="!isDone"
                        color="primary"
                        [loading]="inProgress"
                        [disabled]="!emailInput.value"
                        (clicked)="onSendEmailAsync()">
              {{ 'Login.ForgotPasswordSendEmail' | translate }}
            </app-button>
          </div>

          <div *ngIf="isDone" class="text-success mt-3">
            {{ 'Login.ForgotPasswordEmailSent' | translate }}
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>