import { ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ActivatedRoute, Params } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AssetDTO, AssetRoomDTO } from '../../../../../../../server/src/dto/asset-control.dto';
import { AssetRoomCheckpointDTO } from '../../../../../../../server/src/dto/asset-room-checkpoint.dto';
import {
  AssetRoomCheckpointEventType,
  AssetRoomCheckpointState,
} from '../../../../../../../server/src/models/asset-room-checkpoint.enum';
import { AssetRoomCheckpointsService } from '../../shared/services/asset-room-checkpoints.service';
import { AssetRoomService } from '../../shared/services/asset-room.service';
import { AssetControlRoomEventEditorDialogConfig } from './asset-control-room-event-editor/asset-control-room-event-editor-dialog.config';
import { AssetControlRoomEventEditorComponent } from './asset-control-room-event-editor/asset-control-room-event-editor.component';

export const CHECKPOINT_PAGE_SIZE = 30;

@Component({
  selector: 'app-asset-control-rooms-events',
  templateUrl: './asset-control-rooms-events.component.html',
  styleUrls: ['./asset-control-rooms-events.component.scss'],
})
export class AssetControlRoomsEventsComponent implements OnInit, OnDestroy {
  private _roomId: string;
  private _maxLoadedPageIndex: number = -1;

  private _selectedAssetIdChanged = new Subject<string>();

  constructor(private _assetRoomService: AssetRoomService,
              private _dialogService: MatDialog,
              private _changeDetector: ChangeDetectorRef,
              private _assetRoomCheckpointsService: AssetRoomCheckpointsService,
              private _activatedRoute: ActivatedRoute) {

  }

  public room: AssetRoomDTO;
  public assets: AssetDTO[];

  public assetsSubject = new Subject<AssetDTO[]>();

  public selectedAssetId: string;

  public checkpoints: AssetRoomCheckpointDTO[] = [];

  public loadingInProgress: boolean;

  public noMorePagesToLoad: boolean;

  public AssetRoomCheckpointEventType = AssetRoomCheckpointEventType;
  public AssetRoomCheckpointState = AssetRoomCheckpointState;

  @ViewChildren(MatExpansionPanel)
  public checkpointPanels: QueryList<MatExpansionPanel>;

  public ngOnInit() {
    this._activatedRoute.params
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(async (params: Params) => {
        this._roomId = params.roomId;

        if (!this._roomId) {
          return;
        }

        await this._loadDataAsync();

        await this._loadCheckpointsAsync(0);
      });

    this._selectedAssetIdChanged
      .pipe(
        untilDestroyed(this),
        debounceTime(200),
      )
      .subscribe(async (assetId: string) => {
        this.selectedAssetId = assetId;

        this._resetCheckpointList();

        await this._loadCheckpointsAsync(0);
      });
  }

  public ngOnDestroy(): void {
  }

  public trackCheckpoint(index: number, checkpoint: AssetRoomCheckpointDTO) {
    return checkpoint?.id;
  }

  public async onSelectedAssetIdChangedAsync(assetId: string) {
    this._selectedAssetIdChanged.next(assetId);
  }

  public async loadMoreCheckpointsAsync() {
    await this._loadCheckpointsAsync(this._maxLoadedPageIndex + 1);
  }

  public async addNewCheckpointEventAsync(checkpointId: string) {
    await this.editCheckpointEventAsync(checkpointId, null);
  }

  public async editCheckpointEventAsync(checkpointId: string, checkpointEventId: string) {
    const dialog = this._dialogService.open<AssetControlRoomEventEditorComponent, AssetControlRoomEventEditorDialogConfig>(
      AssetControlRoomEventEditorComponent,
      {
        data: {
          roomId: this.room.id,
          checkpointId: checkpointId,
          checkpointEventId: checkpointEventId,
        },
      },
    );

    await dialog.afterClosed().toPromise();

    const checkpoint = this.checkpoints.find(checkpoint => checkpoint.id === checkpointId);

    await this.loadCheckpointEventsAsync(checkpoint);

    const updatedCheckpoint = await this._assetRoomCheckpointsService.getCheckpointByIdAsync(this._roomId, checkpointId);

    checkpoint.state = updatedCheckpoint.state;
  }

  public async addNewCheckpointAsync() {
    if (!this.selectedAssetId) {
      return;
    }

    const createdCheckpoint = await this._assetRoomCheckpointsService.upsertCheckpointAsync(this._roomId, {
      id: null,
      state: AssetRoomCheckpointState.InPreparation,
      asset: this.assets.find(asset => asset.id === this.selectedAssetId),
      events: undefined,
      createdAt: undefined,
    });

    this.checkpoints.unshift(createdCheckpoint);

    this._changeDetector.detectChanges();

    this.checkpointPanels.first.open();
  }

  public async loadCheckpointEventsAsync(checkpoint: AssetRoomCheckpointDTO) {
    checkpoint.events = await this._assetRoomCheckpointsService.getCheckpointEventsAsync(this._roomId, checkpoint.id);
  }

  private _resetCheckpointList() {
    this._maxLoadedPageIndex = -1;
    this.noMorePagesToLoad = false;

    this.checkpoints.length = 0;
  }

  private async _loadCheckpointsAsync(desiredPageIndex: number) {
    if (this.noMorePagesToLoad) {
      return;
    }

    if (desiredPageIndex <= this._maxLoadedPageIndex) {
      return;
    }

    let lastCheckpointId = '9223372036854775807';

    if (this.checkpoints.length) {
      lastCheckpointId = this.checkpoints[this.checkpoints.length - 1].id;
    }

    try {
      this.loadingInProgress = true;

      const loadedCheckpoints = await this._assetRoomCheckpointsService.getCheckpointsAsync(
        this.room.id,
        this.selectedAssetId || undefined,
        lastCheckpointId,
        CHECKPOINT_PAGE_SIZE,
      );

      this.checkpoints.push(...loadedCheckpoints);

      if (loadedCheckpoints.length < CHECKPOINT_PAGE_SIZE) {
        this.noMorePagesToLoad = true;
      }
    } finally {
      this.loadingInProgress = false;
    }
  }

  private async _loadDataAsync() {
    this.room = await this._assetRoomService.getRoomByIdAsync(this._roomId);

    this.assets = await this._assetRoomService.getRoomAssetsAsync(this._roomId);

    this.assetsSubject.next(this.assets);
  }
}
