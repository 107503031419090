import { FileType } from '../models/file-type.enum';

export class FileUtils {
  public static getTypeOfFile(fileName: string): FileType {
    if (fileName.match(/\.(jpeg|jpg|png|gif)$/i)) {
      return FileType.Image;
    }

    if (fileName.match(/\.(mp4|webm|ogg)$/i)) {
      return FileType.Video;
    }

    return FileType.Other;
  }

  public static getExtensionFromFileName(fileName: string): string {
    const extensionMatch = fileName.match(/\.([^.]*)$/i);

    return extensionMatch && extensionMatch[1];
  }

  public static getMimeType(fileType: FileType, fileName: string): string {
    const extensionMatch = fileName.match(/\.([^.]*)$/i);

    const extension = extensionMatch && extensionMatch[1];

    if (fileType === FileType.Image) {
      return `image/${ extension }`;
    }

    if (fileType === FileType.Video) {
      return `video/${ extension }`;
    }

    if (extension === 'pptx') {
      return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
    }

    if (extension === 'docx') {
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    }

    if (extension === 'xlsx') {
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }

    if (extension === 'doc') {
      return 'application/msword';
    }

    if (extension === 'ppt') {
      return 'application/vnd.ms-powerpoint';
    }

    if (extension === 'xls') {
      return 'application/vnd.ms-excel';
    }

    return 'application/octet-stream';
  }
}