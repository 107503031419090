import { CoolDialogService } from '@angular-cool/dialogs';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProcedureCategoryDTO } from '../../../../../../server/src/dto/procedure.dto';
import { LanguageService } from '../../../shared/services/language/language.service';
import { Editable } from '../../../shared/utils/editable';
import { ProcedureCategoriesService } from '../../../shared/services/procedures/procedure-categories.service';

@Component({
  selector: 'app-procedure-categories',
  templateUrl: './procedure-categories.component.html',
  styleUrls: ['./procedure-categories.component.scss'],
})
export class ProcedureCategoriesComponent implements OnInit {
  constructor(private _procedureCategoriesService: ProcedureCategoriesService,
              private _dialogService: CoolDialogService,
              private _languageService: LanguageService) {

  }

  public displayedColumns = ['name', 'actions'];
  public isLoading: boolean;

  public updateCategoryInProgress: boolean;

  public dataSource: MatTableDataSource<Editable<ProcedureCategoryDTO>>;

  @ViewChild(MatSort, { static: true })
  public sort: MatSort;

  public async ngOnInit() {
    try {
      this.isLoading = true;

      await this._loadDataAsync();
    } finally {
      this.isLoading = false;
    }
  }

  public async addNewCategoryAsync() {
    await this._procedureCategoriesService.upsertProcedureCategoriesAsync({
      id: null,
      name: await this._languageService.getTranslationAsync('Dashboard.ProcedureCategories.NewCategoryName'),
    });

    await this._loadDataAsync();
  }

  public async editCategoryAsync(procedureCategory: Editable<ProcedureCategoryDTO>) {
    if (!procedureCategory.value.name) {
      return;
    }

    try {
      await this._procedureCategoriesService.upsertProcedureCategoriesAsync(procedureCategory.value);
    } finally {
      this.updateCategoryInProgress = false;
    }

    procedureCategory.stopEditing(true);

    await this._loadDataAsync();
  }

  public async startEditingCategoryAsync(procedureCategory: Editable<ProcedureCategoryDTO>) {
    procedureCategory.startEditing();
  }

  public async cancelEditingCategoryAsync(procedureCategory: Editable<ProcedureCategoryDTO>) {
    procedureCategory.stopEditing(false);
  }

  public async removeCategoryAsync(procedureCategory: Editable<ProcedureCategoryDTO>) {
    const removeDialogResult = await this._dialogService.showDialog({
      titleText: await this._languageService.getTranslationAsync('Dashboard.ProcedureCategories.RemoveCategoryTitle', { categoryName: procedureCategory.value.name }),
      questionText: await this._languageService.getTranslationAsync('Dashboard.ProcedureCategories.RemoveCategoryDescription', { categoryName: procedureCategory.value.name }),
      confirmActionButtonText: await this._languageService.getTranslationAsync('Common.Remove'),
      cancelActionButtonText: await this._languageService.getTranslationAsync('Common.Cancel'),
      confirmActionButtonColor: 'warn',
    });

    if (!removeDialogResult.isConfirmed) {
      return;
    }

    await this._procedureCategoriesService.removeProcedureCategoriesAsync(procedureCategory.value.id);

    await this._loadDataAsync();
  }

  public filterCategories(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  private async _loadDataAsync() {
    const procedureCategories = await this._procedureCategoriesService.getProcedureCategoriesAsync();

    this.dataSource = new MatTableDataSource(procedureCategories.map(procedureCategory => new Editable(procedureCategory)));

    this.dataSource.filterPredicate = (item: Editable<ProcedureCategoryDTO>, filter: string) => !filter || !item.value || item.value.name.toLowerCase().includes(filter);

    this.dataSource.sortingDataAccessor = (item: Editable<ProcedureCategoryDTO>, property: string) => {
      switch (property) {
        case 'name':
          return item.value.name;

        default:
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
  }
}
