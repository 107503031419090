import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RequestDemoDTO } from '../../../../server/src/dto/request-demo.dto';
import { RouteNavigationService } from '../shared/routing/route-navigation.service';
import { RequestDemoService } from './shared/services/request-demo.service';

@Component({
  selector: 'app-request-demo',
  templateUrl: './request-demo.component.html',
  styleUrls: ['./request-demo.component.scss'],
})
export class RequestDemoComponent implements OnInit {

  constructor(private _requestDemoService: RequestDemoService,
              private _routeNavigationService: RouteNavigationService) {

  }

  public requestDemoData: RequestDemoDTO;

  public inProgress: boolean;
  public showError: boolean;

  ngOnInit() {
    this.requestDemoData = {
      name: '',
      companyName: '',
      email: '',
      phoneNumber: '',
      numberOfEmployees: 200,
    };
  }

  public async onFormSubmitAsync(requestForm: NgForm) {
    if (!requestForm.valid) {
      return;
    }

    try {
      this.inProgress = true;
      this.showError = false;

      await this._requestDemoService.requestDemoAsync(this.requestDemoData);

      await this._routeNavigationService.goToHomeAsync();
    } catch {
      this.showError = true;
    } finally {
      this.inProgress = false;
    }
  }
}
