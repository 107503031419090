import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { FeaturePermissions } from '../../../../../../server/src/models/feature-permissions.enum';
import { SubscriptionUpgradeReason } from '../../components/subscription-upgrade/subscription-upgrade-reason.enum';
import { SubscriptionUpgradeService } from '../../components/subscription-upgrade/subscription-upgrade.service';
import { UserContextService } from '../../services/user-context/user-context.service';
import { RouteNavigationService } from '../route-navigation.service';

@Injectable()
export class FeaturePermissionRouteGuard implements CanActivate {
  constructor(private _userContextService: UserContextService,
              private _subscriptionUpgradeService: SubscriptionUpgradeService,
              private _routeNavigationService: RouteNavigationService) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const requiredPermissions: FeaturePermissions[] = route.data.requiredFeaturePermissions;

    const incompatibleFeaturePermission = requiredPermissions.find((permission: FeaturePermissions) => !this._userContextService.user.featurePermissions.includes(permission));

    if (incompatibleFeaturePermission) {
      this._routeNavigationService.goToDashboardHomeAsync();

      this._subscriptionUpgradeService.showSubscriptionUpgradeDialog(SubscriptionUpgradeReason.MissingFeature);

      return false;
    }

    return true;
  }
}
