export enum AssetRoomCheckpointState {
  InPreparation = 'in-preparation',

  Prepared = 'prepared',

  Checked = 'checked',
}

export enum AssetRoomCheckpointEventType {
  Preparation = 'preparation',

  CheckResult = 'check-result',
}

export enum AssetRoomCheckpointEventResult {
  None = 'none',

  Bad = 'bad',

  Neutral = 'neutral',

  Good = 'good',

  Great = 'great',
}

export const ASSET_ROOM_CHECKPOINT_EVENT_RESULT_NUMERIC_MAP = {
  [AssetRoomCheckpointEventResult.None]: 0,
  [AssetRoomCheckpointEventResult.Bad]: -3,
  [AssetRoomCheckpointEventResult.Neutral]: -1,
  [AssetRoomCheckpointEventResult.Good]: 1,
  [AssetRoomCheckpointEventResult.Great]: 3,
};

export const ASSET_ROOM_CHECKPOINT_NUMERIC_MAP_EVENT_RESULT = {
  [ASSET_ROOM_CHECKPOINT_EVENT_RESULT_NUMERIC_MAP[AssetRoomCheckpointEventResult.None]]: AssetRoomCheckpointEventResult.None,
  [ASSET_ROOM_CHECKPOINT_EVENT_RESULT_NUMERIC_MAP[AssetRoomCheckpointEventResult.Bad]]: AssetRoomCheckpointEventResult.Bad,
  [ASSET_ROOM_CHECKPOINT_EVENT_RESULT_NUMERIC_MAP[AssetRoomCheckpointEventResult.Neutral]]: AssetRoomCheckpointEventResult.Neutral,
  [ASSET_ROOM_CHECKPOINT_EVENT_RESULT_NUMERIC_MAP[AssetRoomCheckpointEventResult.Good]]: AssetRoomCheckpointEventResult.Good,
  [ASSET_ROOM_CHECKPOINT_EVENT_RESULT_NUMERIC_MAP[AssetRoomCheckpointEventResult.Great]]: AssetRoomCheckpointEventResult.Great,
};