import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ControlReportDateResolution, ControlReportDTO } from '../../../../../../../server/src/dto/control-report.dto';
import { DateProviderService } from '../../../../shared/utils/date-provider.service';
import { ScoreColorDirective } from '../../../shared/directives/score-color.directive';
import { ControlReportsService } from '../shared/services/control-reports.service';

@Component({
  selector: 'app-control-report-view',
  templateUrl: './control-report-view.component.html',
  styleUrls: ['./control-report-view.component.scss'],
})
export class ControlReportViewComponent implements OnInit, OnDestroy {
  private _reportId: string;

  constructor(private _controlReportsService: ControlReportsService,
              private _activatedRoute: ActivatedRoute,
              private _dateProviderService: DateProviderService) {

  }

  public report: ControlReportDTO;
  public isLoading: boolean;
  public hasError: boolean;

  public successPercentagesByDepartmentChartData: ChartDataSets[];
  public successPercentagesByDepartmentChartLabels: Label[];
  public successPercentagesByDepartmentChartOptions: ChartOptions = {
    responsive: true,
    spanGaps: true,
    elements: {
      line: {
        tension: 0,
      },
    },
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          max: 100,
        },
      }],
    },
    plugins: {
      datalabels: {
        anchor: 'start',
        align: 'start',
        formatter: (value: number) => {
          if (!value && value !== 0) {
            return null;
          }

          return `${ value.toFixed(2).replace(/\.00$/, '') }%`;
        },
      },
    },
  };

  public procedureStepSuccessPercentagesChartLabels: string[];
  public procedureStepSuccessPercentagesChartData: ChartDataSets[];
  public procedureStepSuccessPercentagesChartOptions: ChartOptions = {
    scales: {
      yAxes: [{ ticks: { beginAtZero: true, max: 100 } }],
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value) => {
          return `${ value }%`;
        },
      },
    },
  };

  public ngOnInit() {
    this._activatedRoute.params
      .pipe(untilDestroyed(this))
      .subscribe(async params => {
        this._reportId = params.reportId;

        if (this._reportId) {
          await this._loadReportAsync();
        }
      });
  }

  public ngOnDestroy(): void {
  }

  public getDepartmentNamesFromFilter() {
    if (!this.report) {
      return '';
    }

    return this.report.filters.departments.map(dep => dep.name).join(', ');
  }

  private async _loadReportAsync() {
    try {
      this.isLoading = true;
      this.hasError = false;

      this.report = await this._controlReportsService.getReportByIdAsync(this._reportId);

      const labelFormat = this.report.filters.dateResolution === ControlReportDateResolution.Days ? 'shortDate' : 'yyyy. MMM';

      this.successPercentagesByDepartmentChartLabels = this.report.dateResolutionTicks.map(month => this._dateProviderService.formatDate(new Date(month), labelFormat));
      this.successPercentagesByDepartmentChartData = this.report.departmentReports.length ? this.report.departmentReports.map(departmentData => {
        return {
          data: departmentData.successPercentagesByTimeTick,
          label: departmentData.department.name,
          fill: false,
        };
      }) : null;

      this.procedureStepSuccessPercentagesChartLabels = this.report.procedureStepSuccessPercentages.map(step => step.name);
      this.procedureStepSuccessPercentagesChartData = this.report.procedureStepSuccessPercentages.length ? [
        {
          data: this.report.procedureStepSuccessPercentages.map(step => step.successRate),
          backgroundColor: this.report.procedureStepSuccessPercentages.map(rate => ScoreColorDirective.getScoreColor(rate.successRate)),
        },
      ] : null;
    } catch {
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  }
}
