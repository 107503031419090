<div id="file-viewer" class="p-1 p-md-4">
  <div id="close-button" (click)="closeClick()" title="{{ 'Common.Close' | translate }}">
    <fa-icon icon="times"></fa-icon>
  </div>

  <div id="file-container" *ngIf="currentFileItem">
    <app-file-item-display [file]="currentFileItem" size="big"></app-file-item-display>

    <div id="next-button" *ngIf="files.length > 1" (click)="nextFileItemClick()" title="{{ 'Common.Next' | translate }}">
      <fa-icon icon="chevron-right"></fa-icon>
    </div>
    <div id="previous-button" *ngIf="files.length > 1" (click)="previousFileItemClick()" title="{{ 'Common.Previous' | translate }}">
      <fa-icon icon="chevron-left"></fa-icon>
    </div>
  </div>
</div>
