import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DepartmentDTO } from '../../../../../../server/src/dto/department.dto';
import { LanguageService } from '../../services/language/language.service';
import { OrganizationService } from '../../services/organization/organization.service';

@Component({
  selector: 'app-department-select',
  templateUrl: './department-select.component.html',
  styleUrls: ['./department-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: DepartmentSelectComponent,
    multi: true,
  }],
})
export class DepartmentSelectComponent implements OnInit, ControlValueAccessor {
  private _onChange: (departments: DepartmentDTO | DepartmentDTO[]) => void;
  private _onTouched: (departments: DepartmentDTO | DepartmentDTO[]) => void;

  constructor(private _organizationService: OrganizationService,
              private _languageService: LanguageService) {
  }

  @Input()
  public displayOnly: boolean;

  @Input()
  public name: string;

  @Input()
  public placeholder: string;

  @Input()
  public multiple: boolean = true;

  @Input()
  public departments: DepartmentDTO[];

  @Input()
  public required: boolean;

  public isDisabled: boolean;
  public selectedDepartmentIds: string | string[];

  public selectedDepartments: DepartmentDTO | DepartmentDTO[];

  public async ngOnInit() {
    this.departments = this.departments || await this._organizationService.getDepartmentsAsync();

    this.placeholder = this.placeholder || await this._languageService.getTranslationAsync(this.multiple ? 'Common.Departments' : 'Common.Department');
  }

  public onInnerChange(change: string[]) {
    this.selectedDepartmentIds = change;

    this.selectedDepartments = this.departments.filter(department => this.selectedDepartmentIds.includes(department.id));

    const valueToWrite = this.multiple ? this.selectedDepartments : this.selectedDepartments[0];

    this._onTouched && this._onTouched(valueToWrite);
    this._onChange && this._onChange(valueToWrite);
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public writeValue(departments: DepartmentDTO | DepartmentDTO[]): void {
    if (this.multiple) {
      this.selectedDepartments = departments || [];
      this.selectedDepartmentIds = (<DepartmentDTO[]>this.selectedDepartments).map(dep => dep.id);
    } else {
      this.selectedDepartments = departments || null;
      this.selectedDepartmentIds = departments ? (<DepartmentDTO>departments).id : null;
    }
  }

  public displayOnlyFormat(): string {
    if (this.multiple) {
      return (<DepartmentDTO[]>this.selectedDepartments).map(dep => dep.name).join(', ');
    } else {
      return (<DepartmentDTO>this.selectedDepartments).name;
    }
  }
}
