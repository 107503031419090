<div class="d-flex flex-column edit-procedure-container">
  <app-page-header pageTitle="{{ 'Dashboard.Procedures.EditProcedure' | translate }} - {{ procedure?.name }}" [showBack]="true"></app-page-header>

  <mat-drawer-container>
    <mat-drawer mode="side" opened>
      <mat-nav-list class="pt-0">
        <a mat-list-item [routerLink]="RouteLocations.DashboardChildren.ProceduresChildren.EditProcedureChildren.General"
           routerLinkActive="active">
          {{ 'Dashboard.Procedures.General' | translate }}
        </a>
        <a mat-list-item [routerLink]="RouteLocations.DashboardChildren.ProceduresChildren.EditProcedureChildren.Steps"
           routerLinkActive="active">
          {{ 'Dashboard.Procedures.Steps' | translate }}
        </a>
        <a mat-list-item *ngIf="showTestMenu"
           [routerLink]="RouteLocations.DashboardChildren.ProceduresChildren.EditProcedureChildren.Test"
           routerLinkActive="active">
          {{ 'Dashboard.Procedures.Test' | translate }}
        </a>
      </mat-nav-list>
    </mat-drawer>
    <mat-drawer-content>
      <div class="px-3 pb-3">
        <router-outlet></router-outlet>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>