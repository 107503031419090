<div id="privacy" class="page">
  <div *ngIf="currentLanguage === Language.English" class="box">
    <h2 class="text-center mb-4">Privacy Policy</h2>

    <p>
      This privacy policy has been compiled to better serve those who are concerned with how their 'Personally
      Identifiable Information' (PII) is being used
      online. PII, as described in US and EU privacy law and information security, is information that can be used on
      its own
      or with other information to identify,
      contact, or locate a single person, or to identify an individual in context. Please read our privacy policy
      carefully to get a clear understanding of how we
      collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.
    </p>

    <h3>What personal information do we collect from the people that visit our blog, website or app?</h3>

    <p>
      When ordering, registering or filling out a form on our site or in our app, as appropriate, you may be asked to
      enter your name, email address, mailing
      address, billing address, phone number, credit card
      information or other details to help you with your experience.
    </p>

    <h3>When do we collect information?</h3>

    <p>
      We collect information from you when you register on our site or in our app, place an order, subscribe to a
      newsletter or enter information on our site or
      in our app.
    </p>

    <h3>How do we use your information?</h3>

    <div>
      We may use the information we collect from you when you register, make a purchase, sign up for our newsletter,
      respond to a survey or marketing
      communication, surf the website or app, or use certain other site or app features in the following ways:

      <ul>
        <li>To personalize your experience and to allow us to deliver the type of content and product offerings in which
          you are most interested.
        </li>
        <li>To improve our website in order to better serve you.</li>
        <li>To allow us to better service you in responding to your customer service requests.</li>
        <li>To quickly process your transactions.</li>
        <li>To send periodic emails regarding your order or other products and services.</li>
      </ul>
    </div>

    <h3>How do we protect your information?</h3>

    <p>
      Your personal information is contained behind secured networks and is only accessible by a limited number of
      persons who have special access rights to such
      systems, and are required to keep the information confidential. In addition, all sensitive/credit information you
      supply is encrypted via Secure Socket
      Layer (SSL) technology.
    </p>
    <p>
      We implement a variety of security measures when a user places an order enters, submits, or accesses their
      information to maintain the safety of your
      personal information.
    </p>
    <p>
      Data collected by our application are processed and safely stored within the borders of the EU or US based on the
      account's geographical location on servers provided by Heroku and AWS cloud services.<br>
      All data collected on accounts are stored while the account has an active paid subscription plus 90 days.
    </p>
    <p>
      All credit transactions are processed through a gateway provider and are not stored or processed on our servers.

      We do not use vulnerability scanning and/or scanning to PCI standards.
      An external PCI compliant payment gateway handles all CC transactions.
      We do not use Malware Scanning.
    </p>

    <h3>Do we use 'cookies'?</h3>

    <p>
      Yes. Cookies are small files that a site or its service provider transfers to your computer's hard drive through
      your Web browser (if you allow) that
      enables the site's or service provider's systems to recognize your browser and capture and remember certain
      information. For instance, we use cookies to
      help us remember and process the items in your shopping cart. They are also used to help us understand your
      preferences based on previous or current site
      activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate
      data about site traffic and site
      interaction so that we can offer better site experiences and tools in the future.
    </p>

    <h3>We use cookies to:</h3>

    <div>
      <ul>
        <li>Help remember and process the items in the shopping cart.</li>
        <li>Understand and save user's preferences for future visits.</li>
        <li>Keep track of advertisements.</li>
        <li>Compile aggregate data about site traffic and site interactions in order to offer better site experiences
          and tools in the future. We may also use
          trusted third-party services that track this information on our behalf.
        </li>
      </ul>
      <p>
        You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off
        all cookies. You do this through your
        browser
        settings. Since browser is a little different, look at your browser's Help Menu to learn the correct way to
        modify your cookies.
      </p>
    </div>


    <h3>If users disable cookies in their browser:</h3>

    <p>
      If you turn cookies off, Some of the features that make your site experience more efficient may not function
      properly. Some of the features that make your site experience more efficient and may not function properly.
    </p>

    <h3>Third-party disclosure</h3>

    <p>
      We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information unless we
      provide users with advance notice. This
      does not include website hosting partners and other parties who assist us in operating our website, conducting our
      business, or serving our users, so long
      as those parties agree to keep this information confidential. We may also release information when it's release is
      appropriate to comply with the law,
      enforce our site policies, or protect ours or others' rights, property or safety. Data shared with outside parties
      may be transferred outside EU but only under the EU.-US. and Swiss privacy shield.
      <br>
      However, non-personally identifiable visitor information may be provided to other parties for marketing,
      advertising, or other uses.
    </p>

    <h3>Third-party links</h3>

    <p>
      We do not include or offer third-party products or services on our website.
    </p>

    <h3>Third-party services we use</h3>

    <h4>Google</h4>

    <p>
      Google's advertising requirements can be summed up by Google's Advertising Principles. They are put in place to
      provide a positive experience for users.
      https://support.google.com/adwordspolicy/answer/1316548?hl=en

      We use Google AdSense Advertising on our website and in our app.

      Google, as a third-party vendor, uses cookies to serve ads on our site. Google's use of the DART cookie enables it
      to serve ads to our users based on
      previous visits to our site and other sites on the Internet. Users may opt-out of the use of the DART cookie by
      visiting the Google Ad and Content Network
      privacy policy.
    </p>

    <h4>We have implemented the following:</h4>

    <p>
      We, along with third-party vendors such as Google use first-party cookies (such as the Google
      Analytics cookies) and third-party cookies (such
      as the
      DoubleClick cookie) or other third-party identifiers together to compile data regarding user interactions with ad
      impressions and other ad service functions
      as they relate to our website.
    </p>

    <h4>Opting out:</h4>

    <p>
      Users can set preferences for how Google advertises to you using the Google Ad Settings page. Alternatively, you
      can opt out by visiting the Network
      Advertising Initiative Opt Out page or by using the Google Analytics Opt Out Browser add on.
    </p>

    <h3>Accessing your data</h3>

    <p>
      Our customers can always review, update or change personal information from within their account or by contacting
      us. Procedure Manager will also, when you request, provide you with
      information about whether we hold, or process on behalf of a third party, any of your personal information. Please
      contact us here if you need assistance in
      reviewing your information. Procedure Manager will respond to your access request to review the information we
      have on file
      for you within a reasonable time.
    </p>
    <p>
      We may also collect information on behalf of our customers, to provide the services, and we may not have a direct
      relationship with the individuals whose
      personal data is processed. If you are a customer or end-user of one of our customers, please contact them (as the
      data controller) directly if: (i) you
      would no longer like to be contacted by them; or (ii) to access, correct, amend, or delete inaccurate data. If
      requested to remove data by our customer, we
      will respond within a reasonable timeframe.
    </p>

    <h3>California Online Privacy Protection Act</h3>

    <p>
      CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy
      policy. The law's reach stretches well
      beyond California to require any person or company in the United States (and conceivably the world) that operates
      websites collecting Personally
      Identifiable
      Information from California consumers to post a conspicuous privacy policy on its website stating exactly the
      information being collected and those
      individuals or companies with whom it is being shared. - See more at:
      http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
    </p>

    <h3>According to CalOPPA, we agree to the following:</h3>

    <p>
      Users can visit our site anonymously.
      Once this privacy policy is created, we will add a link to it on our home page or as a minimum, on the first
      significant page after entering our website.
      Our Privacy Policy link includes the word 'Privacy' and can easily be found on the page specified above.

      You will be notified of any Privacy Policy changes:
      â˘ On our Privacy Policy Page
      Can change your personal information:
      â˘ By emailing us
    </p>

    <h3>How does our site handle Do Not Track signals?</h3>

    <p>
      We don't honor Do Not Track signals and Do Not Track, plant cookies, or use advertising when a Do Not Track (DNT)
      browser mechanism is in place. We don't
      honor them because:
      We need to use cookies to provide our services to our users
    </p>

    <h3>Does our site allow third-party behavioral tracking?</h3>

    <p>
      It's also important to note that we allow third-party behavioral tracking
    </p>

    <h3>COPPA (Children Online Privacy Protection Act)</h3>

    <p>
      When it comes to the collection of personal information from children under the age of 13 years old, the
      Children's Online Privacy Protection Act (COPPA)
      puts parents in control. The Federal Trade Commission, United States' consumer protection agency, enforces the
      COPPA Rule, which spells out what operators
      of websites and online services must do to protect children's privacy and safety online.

      We do not specifically market to children under the age of 13 years old.
      Do we let third-parties, including ad networks or plug-ins collect PII from children under 13?
    </p>

    <h3>Fair Information Practices</h3>

    <p>
      The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts
      they include have played a significant role
      in the development of data protection laws around the globe. Understanding the Fair Information Practice
      Principles and how they should be implemented is
      critical to comply with the various privacy laws that protect personal information.
    </p>

    <h3>In order to be in line with Fair Information Practices we will take the following responsive action, should a
      data breach occur:</h3>

    <p>
      We will notify you via email within 7 business days
      We will notify the users via in-site or in-app notification within 7 business days

      We also agree to the Individual Redress Principle which requires that individuals have the right to legally pursue
      enforceable rights against data
      collectors
      and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable
      rights against data users, but also that
      individuals have recourse to courts or government agencies to investigate and/or prosecute non-compliance by data
      processors.
    </p>

    <h6 class="text-right mt-5">Last updated: May 1, 2019</h6>
  </div>

  <div *ngIf="currentLanguage === Language.Hungarian" class="box">
    <h2 class="text-center mb-4">Adatvédelmi nyilatkozat</h2>

    <p>
      Ezen adatvédelmi nyilatkozat összeállításánál külön figyelmet fordítottunk azon felhasználók segítésére,
      akik meg szeretnék ismerni, hogyan használjuk online az Európai Únió Általános Adatvédelmi Rendeletében (GDPR)
      meghatározott személyes azonosító információit (PII). Az ameriaki és európai adatvédelmi jog szerint a
      személyes azonosító információk (PII) olyan információ vagy összeköthető információk mely alapján egy személyt
      azonosítani, vele kapcsolatba lépni vagy hollétét felfedni lehet.

      Kérjük olvassa el figyelmesen az adatvédelmi nyilatkozatunkat, hogy tiszta képet kapjon róla, hogyan gyűjtjük és
      kezeljük
      az ön által megadott személyes azonosító információkat a weboldalunkon és applikációinkban.
    </p>

    <h3>Milyen Személyes Azonosító Információkat gyűjtünk, azoktól akik meglátogatják és használják blogunkat,
      weboldalunkat és applikációinkat?</h3>

    <div>
      Amennyiben ön regisztrál, adatot tölt be vagy használja a szolgáltatásunkat, megfelelő helyeken a
      felhasználói élmény, biztonságos hozzáférés, egyedi tartalom, illetve a szolgáltatás rendeltetésszerű működésének érdekében
      elkérhetjük a következő adatokat:

      <ul>
        <li>Név</li>
        <li>Email cím</li>
        <li>IP cím</li>
      </ul>
    </div>

    <h3>Hogyan használjuk az ön által megadott információt?</h3>

    <div>
      Használhatjuk az ön által megadott személyes információkat regisztrációnál, adat kitöltésénél, szolgáltatás
      használatakor,
      marketing üzenetek küldésénél, blog/applikációk/weboldal használatokor és bármely más a szolgáltatással
      kapcsolatos
      funkcionalitások megvalósításakor, például:

      <ul>
        <li>Hogy személyre szabhassuk tapasztalatait, és biztosítsuk számára a tartalom, szolgáltatás és marketing
          üzenetek személyreszabását.
        </li>
        <li>Hogy fejleszteni tugyjuk szolgáltatáunkat, így jobban szolgálva az ön érdekeit.</li>
        <li>Hogyan jobban szolgálatára tudjuk állni, amennyiben kérdéssel/problémával/hobával fordul hozzánk</li>
        <li>Hogy gyorsan tudjuk javítani a szolgáltatás esetleges hibáit és gyorsabbban működjön a szolgáltatás</li>
        <li>Hogy email üzenetet küldhessünk önnek a szolgáltatásról és annak email üzenet küldéssel ellátott funkcióival
          kapcsolatosan
        </li>
      </ul>
    </div>

    <h3>Hogyan védjük az ön által megadott információkat?</h3>

    <p>
      Az ön személyes információt biztonságos hálózat mögött álló tárhelyen rögzítjük, melyet a rendszer üzemeltetésének
      érdekében
      csak nagyon limitált arra külön jogosultságot szerző és titokszerződés alatt álló személy ér el.

      Ezen felül minden adata biztonságos (Secure Socket Layer - SSL) csatornán utazik.
    </p>
    <p>
      Az ön adatainak biztonsága vádelmében számos biztonsági intézkedést és szabályt vezettünk be a szolgáltatás
      összes funkciónalitásába, ahol ön adatot ad meg vagy fér hozzá.
    </p>
    <p>
      Az adatokat, melyeket gyűjtünk biztonságosan kezeljük és tároljuk az EU határain belül.
      Minden felhasználóhoz köthető adatot a szolgáltatás licensz érvényességi ideéig plusz 90 napig tárolunk.
    </p>

    <h3>Használunk sütiket (cookies)?</h3>

    <p>
      Igen. A sütik olyan kicsi fájlok, melyeket weboldalak és szolgáltatók a böngészőjén keresztül küldenek és
      tárolnak az ön gépén, annak érdekében, hogy azonosítani tudják az ön gépét és további információkat tároljanak.
      Például, a szolgáltatásba való belépése után sütiben tároljuk az ön munkafolyamata egyedi azonosítóját.
      Segíthetnek az ön egyedi beállításainak megjegyzésében, hogy jobb felhasználói élményt nyújthassunk.
      Továbbá sütiket használunk arra is, hogy általános felhasználási adatokat gyűjtsünk az oldallal való
      interakcióiról, melyekről statisztikát készítve javíthatunk a szolgáltatásunk minőségén és új funkciókat hozhatunk
      létre.
    </p>

    <h3>Sütiket használunk a következőkre:</h3>

    <div>
      <ul>
        <li>Megjegyezzük a belépett felhasználó munkamenetét.</li>
        <li>Biztonságosabbá tegyük szolgáltatásunkat.</li>
        <li>Megértsük és elmentsük az ön egyedi szolgáltatás beállításait.</li>
        <li>
          Felhasználási statisztikához szükséges adatokat gyűjtsünk.
          Felhasználhatunk megbízható harmadik fél által nyújtott szolgáltatást, akik a nevünkben gyűjtik ezeket az
          adatokat.
        </li>
      </ul>
      <p>
        Ön beállíthatja böngészőjében, hogy minden alkalommal értesüljön róla, amennyiben a böngészője sütit ment el,
        illetve teljesen ki is kapcsolhatja a sütik használatát a böngészője beállításaiban.
      </p>
    </div>


    <h3>Amennyiben ön kikapcsolja a sütik használatát a böngészőjében:</h3>

    <p>
      Amennyiben ön kikapcsolja a sütik használatát, ön nem fogja tudni igénybe venni a mi szolgáltatásunkat.
    </p>

    <h3>Harmadik fél által nyújtott szolgáltatások</h3>

    <p>
      Mi nem áruljuk, cseréljük el vagy aduk át szenélyes azaonosító adatot (PII) más harmadik fél számára előzetes
      tájékoztatás nélkül.
      Ez nem terjed ki olyan harmadik felekre és általuk szolgáltatott szolgáltatásokra melyeket a weboldalunk,
      blogunk és applikációink üzemeltetéséhez, fejlesztéséhez és hozzá kapcsolódó üzletünkhoz használunk, amenniyben
      titok tartási kötelezettséget vállalnak.

      Továbbá kiadhatjuk információját, amenniyben arra a megfelelő törvények betartása,
      saját vagy mások jogainak, tulajdonának, biztonságának védelme vagy a felhasználási feltételeink betartatása
      miatt szükség van.
      <br>
      Azonban más, nem szemelyes azonosító információkat (non PII information) megoszthatunk harmadik féllel marketing,
      hírdetés és egyéb tevékenységek folytatása érdekében.
    </p>

    <h3>Adataihoz való hozzáférés</h3>

    <p>
      A felhasználóink mindig hozzáférhetnek, módosíthatják és törölhetik adataikat a felhasználói menü alatt vagy kapcsolaba lépve
      velünk.
      A Procedure Manager továbbá kérésre informálja önt, hogy tároljuk-e vagy átadtuk-e harmadik fél által nyújtott
      szolgáltatás részére az ön személyes adatát.
      Kérjük lépjen velünk kapcsolatba, amenyiben segítségre van szüksége az információi áttekintésében.
      A Procedure Manager megpróbál üzletileg ésszrű időn belül válaszolni az adatai átnézésére irányuló kérelmekre.
    </p>
    <p>
      A szolgáltatásaink üzemeltetése érdekében előfordul, hogy a vásárlóink nevében olyanoktól gyűjtünk információt és
      dolgozzuk fel azt,
      akikkel nem állunk közvetlen kapcsolatban.
      Kérjük amennyiben ön a vásárlónk felhasználója lépjen kapcsolatba velük, mint az adat kezelője, amennyiben:
      1. Nem szeretné a személyes adatainak gyűjtését.
      2. Személyes adatait szeretné lekérni, töröli vagy módosítani.
      Amennyiben a mi vásárlóink adat törlését kéik, megpróbálunk üzletileg ésszerű időn belül válaszolni.
    </p>

    <h3>Hogyan kezeljük a "Do Not Track" jelzést?</h3>

    <p>
      Nem vesszük figyelemne a "Do Not Track" jelzést, mivel a szolgáltatás működéséhez sütik használatára van
      szükségünk.
    </p>

    <h6 class="text-right mt-5">Utolsó frissítés: May 1, 2019</h6>
  </div>
</div>
