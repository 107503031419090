import { CoolDialogService } from '@angular-cool/dialogs';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LearningCertificateTemplateDTO } from '../../../../../../server/src/dto/learning-certificates.dto';
import { LanguageService } from '../../../shared/services/language/language.service';
import { LearningCertificateTemplatesService } from '../../../shared/services/learning-certificates/learning-certificate-templates.service';
import { EditLearningCertificateTemplateDialogConfig } from './edit-learning-certificate-template-dialog/edit-learning-certificate-template-dialog-config.interface';
import { EditLearningCertificateTemplateDialogComponent } from './edit-learning-certificate-template-dialog/edit-learning-certificate-template-dialog.component';

@Component({
  selector: 'app-learning-certificate-templates',
  templateUrl: './learning-certificate-templates.component.html',
  styleUrls: ['./learning-certificate-templates.component.scss'],
})
export class LearningCertificateTemplatesComponent implements OnInit {

  constructor(private _learningCertificateTemplatesService: LearningCertificateTemplatesService,
              private _dialogOpenService: MatDialog,
              private _dialogService: CoolDialogService,
              private _languageService: LanguageService) {

  }

  public displayedColumns = ['name', 'actions'];
  public isLoading: boolean;

  public dataSource: MatTableDataSource<LearningCertificateTemplateDTO>;

  @ViewChild(MatSort, { static: true })
  public sort: MatSort;

  public async ngOnInit() {
    try {
      this.isLoading = true;

      await this._loadDataAsync();
    } finally {
      this.isLoading = false;
    }
  }

  public async addNewPermissionRoleAsync() {
    await this.editTemplateAsync({
      id: null,
      name: '',
      templateFile: null,
    });
  }

  public async editTemplateAsync(template: LearningCertificateTemplateDTO) {
    const dialog = this._dialogOpenService.open<EditLearningCertificateTemplateDialogComponent, EditLearningCertificateTemplateDialogConfig>(
      EditLearningCertificateTemplateDialogComponent,
      {
        data: {
          template: template,
        },
      });

    await dialog.afterClosed().toPromise();

    await this._loadDataAsync();
  }

  public async removeTemplateAsync(template: LearningCertificateTemplateDTO) {
    const removeDialogResult = await this._dialogService.showDialog({
      titleText: await this._languageService.getTranslationAsync('Dashboard.LearningCertificateTemplates.RemoveTemplateTitle', { templateName: template.name }),
      questionText: await this._languageService.getTranslationAsync('Dashboard.LearningCertificateTemplates.RemoveTemplateDescription', { templateName: template.name }),
      confirmActionButtonText: await this._languageService.getTranslationAsync('Common.Remove'),
      cancelActionButtonText: await this._languageService.getTranslationAsync('Common.Cancel'),
      confirmActionButtonColor: 'warn',
    });

    if (!removeDialogResult.isConfirmed) {
      return;
    }

    await this._learningCertificateTemplatesService.removeLearningCertificateTemplateByIdAsync(template.id);

    await this._loadDataAsync();
  }

  public filterTemplates(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  private async _loadDataAsync() {
    const templates = await this._learningCertificateTemplatesService.getLearningCertificateTemplatesAsync();

    this.dataSource = new MatTableDataSource(templates);
    this.dataSource.sort = this.sort;
  }
}
