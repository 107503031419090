window.addEventListener('load', function () {
  (<any> window).cookieconsent.initialise({
    'palette': {
      'popup': {
        'background': '#252e39',
      },
      'button': {
        'background': '#dd5f17',
        'text': '#fff',
      },
    },
    'theme': 'classic',
    'content': {
      'href': '/privacy',
    },
  });
});