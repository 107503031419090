import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { v4 as uuid } from 'uuid';
import { FileDTO } from '../../../../../../server/src/dto/file.dto';
import { FileUtils } from '../../../../../../server/src/utils/file-utils';
import { HtmlUtils } from '../../utils/html-utils';
import { FileDisplayFormat } from '../file-display/file-display-format.enum';
import { FileUploadItem } from './file-upload-item.interface';

@Component({
  selector: 'app-file-upload-selector',
  templateUrl: './file-upload-selector.component.html',
  styleUrls: ['./file-upload-selector.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: FileUploadSelectorComponent,
    multi: true,
  }],
})
export class FileUploadSelectorComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  private _onChange: any;
  private _onTouched: any;

  constructor(private _changeDetector: ChangeDetectorRef) {

  }

  @ViewChild('dropFileForm', { static: true })
  public dropFileForm: ElementRef<HTMLFormElement>;

  @ViewChild('fileUploadInput', { static: true })
  public fileUploadInput: ElementRef<HTMLInputElement>;

  @Input()
  public name: string;

  @Input()
  public displayFormat: FileDisplayFormat;

  @Input()
  public single: boolean;

  @Input()
  public centered: boolean;

  public fileDraggedOver: boolean;

  public files: FileUploadItem[];

  public ngOnInit() {

  }

  public ngAfterViewInit(): void {
    if (!this.single) {
      this.fileUploadInput.nativeElement.setAttribute('multiple', '');
    }

    HtmlUtils.addMultipleEventListeners(
      this.dropFileForm.nativeElement,
      ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'],
      (e: Event) => {
        e.stopPropagation();
        e.preventDefault();
      });

    HtmlUtils.addMultipleEventListeners(this.dropFileForm.nativeElement, ['dragover', 'dragenter'], () => {
      this.fileDraggedOver = true;

      this._changeDetector.markForCheck();
    });

    HtmlUtils.addMultipleEventListeners(this.dropFileForm.nativeElement, ['dragleave', 'dragend', 'drop'], () => {
      this.fileDraggedOver = false;

      this._changeDetector.markForCheck();
    });

    HtmlUtils.addMultipleEventListeners(this.dropFileForm.nativeElement, ['drop'], async (e: any) => {
      this.fileUploadInput.nativeElement.files = e.dataTransfer.files;

      await this.onFileInputChange(this.fileUploadInput.nativeElement);
    });
  }

  public removeFile(file: FileDTO) {
    this.files.splice(this.files.indexOf(<FileUploadItem>file), 1);

    this._emitChange();
  }

  public async onFileInputChange(fileUploadInput: HTMLInputElement) {
    if (!fileUploadInput || !fileUploadInput.files.length) {
      return;
    }

    if (!this.files) {
      this.files = [];
    }

    await Promise.all(Array.from(fileUploadInput.files).map(async (file: File) => {
      const fileContent = await this._readFileAsync(file);

      if (this.single) {
        this.files.length = 0;
      }

      this.files.push({
        id: uuid(),
        url: <string>fileContent,
        name: file.name,
        type: FileUtils.getTypeOfFile(file.name),
        file: file,
      });
    }));

    this._emitChange();
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
  }

  public writeValue(files: FileUploadItem | FileUploadItem[]): void {
    if (this.single) {
      this.files = [];

      if (files) {
        this.files.push(<FileUploadItem>files);
      }
    } else {
      this.files = <FileUploadItem[]>files;
    }
  }

  private _emitChange() {
    const emitValue = this.single ? this.files[0] : this.files;

    this._onTouched(emitValue);
    this._onChange(emitValue);
  }

  private _readFileAsync(file: File): Promise<string | ArrayBuffer> {
    return new Promise(resolve => {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        resolve(reader.result);
      });

      reader.readAsDataURL(file);
    });
  }
}
