import { CoolHttp } from '@angular-cool/http';
import { Injectable } from '@angular/core';
import { CoolLazyCache } from 'cool-lazy-cache';
import { PCacheable, PCacheBuster } from 'ngx-cacheable';
import { Subject } from 'rxjs';
import { CompanyRoleDTO } from '../../../../../../server/src/dto/company-role.dto';
import { CompanyUserDTO } from '../../../../../../server/src/dto/company-user.dto';
import { DepartmentDTO } from '../../../../../../server/src/dto/department.dto';
import { PermissionRoleDTO } from '../../../../../../server/src/dto/permission-role.dto';
import { FeaturePermissions } from '../../../../../../server/src/models/feature-permissions.enum';

const departmentsCacheInvalidator = new Subject<void>();
const companyRolesCacheInvalidator = new Subject<void>();

@Injectable()
export class OrganizationService {
  private _permissionRolesCache: CoolLazyCache<PermissionRoleDTO[]>;

  constructor(private _http: CoolHttp) {
    this._permissionRolesCache = new CoolLazyCache<PermissionRoleDTO[]>(async () => {
      return await this._http.getAsync('api/companies/active/permission-roles');
    }, 1000 * 60);
  }

  @PCacheable({
    cacheBusterObserver: departmentsCacheInvalidator,
    maxAge: 1000 * 60,
  })
  public async getDepartmentsAsync(): Promise<DepartmentDTO[]> {
    return await this._http.getAsync('api/companies/active/departments');
  }

  @PCacheBuster({
    cacheBusterNotifier: departmentsCacheInvalidator,
  })
  public async createDepartmentAsync(name: string) {
    await this._http.postAsync('api/companies/active/departments', {
      name: name,
    });
  }

  @PCacheBuster({
    cacheBusterNotifier: departmentsCacheInvalidator,
  })
  public async updateDepartmentAsync(department: DepartmentDTO) {
    await this._http.postAsync(`api/companies/active/departments/${ department.id }`, department);
  }

  @PCacheBuster({
    cacheBusterNotifier: departmentsCacheInvalidator,
  })
  public async removeDepartmentAsync(departmentId: string) {
    await this._http.deleteAsync(`api/companies/active/departments/${ departmentId }`);
  }

  @PCacheable({
    cacheBusterObserver: companyRolesCacheInvalidator,
    maxAge: 1000 * 60,
  })
  public async getCompanyRolesAsync(): Promise<CompanyRoleDTO[]> {
    return await this._http.getAsync('api/companies/active/roles');
  }

  @PCacheBuster({
    cacheBusterNotifier: companyRolesCacheInvalidator,
  })
  public async createCompanyRoleAsync(name: string) {
    await this._http.postAsync('api/companies/active/roles', {
      name: name,
    });
  }

  @PCacheBuster({
    cacheBusterNotifier: companyRolesCacheInvalidator,
  })
  public async updateCompanyRoleAsync(companyRole: CompanyRoleDTO) {
    await this._http.postAsync(`api/companies/active/roles/${ companyRole.id }`, companyRole);
  }

  @PCacheBuster({
    cacheBusterNotifier: companyRolesCacheInvalidator,
  })
  public async removeCompanyRoleAsync(companyRoleId: string) {
    await this._http.deleteAsync(`api/companies/active/roles/${ companyRoleId }`);
  }

  public async getCompanyUsersAsync(fromId: string, limit: number, filter: string, userIds: string[] = []): Promise<CompanyUserDTO[]> {
    return await this._http.getAsync('api/companies/active/users', {
      params: {
        fromId: fromId,
        limit: limit.toString(),
        filter: filter || '',
        userIds: (userIds && userIds.join(',')) || '',
      },
    });
  }

  public async getNumberOfCompanyUsersAsync(filter?: string): Promise<number> {
    return (await this._http.getAsync('api/companies/active/users/count', {
      params: {
        filter: filter || '',
      },
    })).numberOfCompanyUsers;
  }

  public async updateCompanyUserAsync(companyUser: CompanyUserDTO) {
    return await this._http.postAsync(`api/companies/active/users/${ companyUser.id }`, companyUser);
  }

  public async removeCompanyUserAsync(companyUserId: string) {
    return await this._http.deleteAsync(`api/companies/active/users/${ companyUserId }`);
  }

  public async getAvailableFeaturePermissionsAsync(): Promise<FeaturePermissions[]> {
    return await this._http.getAsync('api/companies/active/permission-roles/feature-permissions');
  }

  public async getPermissionRolesAsync(): Promise<PermissionRoleDTO[]> {
    return await this._permissionRolesCache.getDataAsync();
  }

  public async upsertPermissionRoleAsync(permissionRole: Partial<PermissionRoleDTO>): Promise<PermissionRoleDTO[]> {
    this._permissionRolesCache.invalidateCache();

    return await this._http.postAsync(`api/companies/active/permission-roles`, permissionRole);
  }

  public async removePermissionRoleAsync(permissionRoleId: string): Promise<PermissionRoleDTO[]> {
    this._permissionRolesCache.invalidateCache();

    return await this._http.deleteAsync(`api/companies/active/permission-roles/${ permissionRoleId }`);
  }
}