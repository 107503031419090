<div>
  <app-page-header pageTitle="{{ 'Dashboard.Navigation.KnowledgeBaseArticles' | translate }}">
    <div>
      <div class="d-inline-block">
        <mat-form-field>
          <input matInput (keyup)="filterArticles($event.target.value)"
                 placeholder="{{ 'Common.Filter' | translate }}">
        </mat-form-field>
      </div>

      <button class="ml-5"
              mat-raised-button color="primary"
              *ngIf="canAddArticle"
              (click)="addNewArticleAsync()">
        <fa-icon icon="plus" class="mr-1"></fa-icon>

        {{ 'Dashboard.KnowledgeBase.AddNewArticle' | translate }}
      </button>
    </div>
  </app-page-header>

  <div class="w-100">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z4 w-100">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Common.Name' | translate }}</th>
        <td mat-cell *matCellDef="let article">
          <a (click)="viewArticleAsync(article); $event.preventDefault();" href="#" class="text-decoration-none">
            {{ article.name }}
            <span *ngIf="!article.isPublished" class="font-italic">({{ 'Common.Draft' | translate }})</span>
          </a>
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ 'Common.Actions' | translate }}</th>
        <td mat-cell *matCellDef="let article">
          <button mat-raised-button color="primary"
                  [attr.title]="'Common.View' | translate"
                  (click)="viewArticleAsync(article)">
            <fa-icon icon="eye"></fa-icon>
          </button>

          <button mat-raised-button color="primary" class="ml-2"
                  [attr.title]="'Common.Edit' | translate"
                  (click)="editArticleAsync(article)">
            <fa-icon icon="pencil-alt"></fa-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div class="text-center mt-3" *ngIf="isLoading">
      <app-loading-indicator></app-loading-indicator>
    </div>
  </div>
</div>