<div class="h-75">
  <div class="p-5 image-faded-background">
    <mat-card class="mat-elevation-z8 pt-4">
      <mat-card-header>
        <mat-card-title class="text-center">
          {{ 'Login.CompanySSOLogin' | translate }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="p-4 pb-2">
        <div *ngIf="!redirecting">
        <div>
          <mat-form-field>
            <input matInput name="identifier" [(ngModel)]="identifier"
                   placeholder="{{ 'Login.CompanyIdentifier' | translate }}" required>
          </mat-form-field>
        </div>

        <div class="text-center mt-3">
          <button (click)="ssoLogin()"
                  mat-raised-button color="primary"
                  [disabled]="!identifier">
            {{ 'Login.LoginWithSSO' | translate }}
          </button>
        </div>
        </div>
        <div *ngIf="redirecting">
          {{ 'Login.RedirectionToCompanySSOLogin' | translate }}
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>